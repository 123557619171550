import React, { PropsWithChildren } from "react";
import Label from "~/components/form/Label";

type PhaseInfoBoxElementProps = PropsWithChildren & {
	label: string;
	icon: React.ReactNode;
};

const PhaseInfoBoxElement: React.FunctionComponent<PhaseInfoBoxElementProps> = ({ label, icon, children }) => {
	return <div className="flex flex-row gap-x-4">
		<div className="flex flex-col justify-end items-end pb-0.5">
			{icon}
		</div>
		<div>
			<Label size="sm">{label}</Label>
			<dd className="font-semibold tracking-tight text-gray-900 text-sm">
				{children}
			</dd>
		</div>
	</div>;
};

export default PhaseInfoBoxElement;