import { UseQueryResult } from "react-query";
import { useMemo } from "react";

// @ts-ignore
type HookConfiguration<TQueryFnData, TError> = {
	hook: () => UseQueryResult<any, TError>;
	name: string;
};

export type AggregatedDataOptions = {
	isLoading: boolean;
	loadingPcent: number;
	status: Array<"idle" | "error" | "loading" | "success">;
};

export function useAggregatedData<TQueryFnData, TError>(
	hookConfigurations: HookConfiguration<TQueryFnData, TError>[],
): {
	data: { [key: string]: TQueryFnData | any };
	options: AggregatedDataOptions;
} {
	const data: {
		[key: string]: TQueryFnData;
	} = {};

	const isLoadingArray: boolean[] = [];
	const statusArray: Array<string | any> = [];

	hookConfigurations.forEach(({ hook, name }) => {
		const { isLoading, status, data: loadedData } = hook();

		isLoadingArray.push(isLoading);
		statusArray.push(status);
		if (loadedData !== undefined) data[name] = loadedData;
	});

	const loadingPcent = (statusArray.filter((s) => "success" === s).length / hookConfigurations.length) * 100;

	const memorizedReturn = useMemo(() => {
		return {
			data,
			options: {
				isLoading: isLoadingArray.every(Boolean),
				loadingPcent,
				status: statusArray,
			},
		};
	}, [data, isLoadingArray, loadingPcent, statusArray]);

	return memorizedReturn;
}
