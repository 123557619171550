import { NavLink } from "react-router-dom";
import clsx from "clsx";

interface Props {
	href: string;
	current: boolean;
	name: string;
	icon: any;
}

const NavItem: React.FC<Props> = ({ href, current, name, icon }) => {
	const Icon = icon;
	return (
		<NavLink
			to={href}
			className={clsx(
				current
					? "bg-primary-700 bg-opacity-50 text-secondary-500"
					: "text-gray-300 hover:bg-primary-700 hover:bg-opacity-50 hover:text-white",
				"transition-colors duration-150",
				"rounded-md px-3 py-2 text-sm font-medium tracking-wide flex items-center gap-2",
			)}
		>
			<Icon className={"h-6 w-6 shrink-0 text-current"} aria-hidden="true" />
			{name}
		</NavLink>
	);
};

export default NavItem;
