import { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import { CreateUserLocationType, LocationType, UserAvailableLocationType } from "~/modules/location/api/location/locationTypes.ts";

export const getAllLocations = (): AxiosApiResponsePromise<LocationType[]> => axiosApi.get("locations");
export const getUsersLocationsForAvailableStaffings = ({ userId }: {
	userId: string
}): AxiosApiResponsePromise<UserAvailableLocationType[]> => axiosApi.get(`users/${userId}/staffings/locations`);


export function createUserLocation({ userId, locationData }: {
	userId: string,
	locationData: CreateUserLocationType
}): AxiosApiResponsePromise<void> {
	return axiosApi.post(`users/${userId}/locations`, locationData);
}
