import { yupResolver } from "@hookform/resolvers/yup";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "~/components/form/Button";
import FormHasErrorsHint from "~/components/form/FormHasErrorsHint";
import FormSection, { FormSectionGroup } from "~/components/form/FormSection";
import Input from "~/components/form/Input";
import SubmitButton from "~/components/form/SubmitButton";
import Textarea from "~/components/form/Textarea";
import Headline from "~/components/Headline";
import Sidebar from "~/components/Sidebar/Sidebar.tsx";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	createInvoiceRecipient,
	updateInvoiceRecipient,
} from "~/modules/billing/api/invoiceRecipient/invoiceRecipientApiDispatchers.ts";
import {
	CreateOrUpdateInvoiceRecipientData,
	InvoiceRecipientType,
} from "~/modules/billing/api/invoiceRecipient/invoiceRecipientTypes.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type CreateOrUpdateInvoiceRecipientFormProps = {
	clientId: string,
	initialValues?: InvoiceRecipientType
	onCancelClick: () => void;
	onSuccess: () => void;
};

type CreateOrUpdateInvoiceRecipientFormData = Omit<CreateOrUpdateInvoiceRecipientData, "clientId">
{
}

const CreateOrUpdateInvoiceRecipientForm: React.FunctionComponent<CreateOrUpdateInvoiceRecipientFormProps> = ({
	clientId,
	initialValues,
	onCancelClick,
	onSuccess,
}) => {
	const [busy, setBusy] = useState(false);
	const isUpdateProcess = !!initialValues;
	const schema = useMemo(() => {
		return yup.object({
			comment: yup.string().default(""),
			displayName: yup.string().required().default(""),
			email: yup.string().default(""),
			invoiceByMail: yup.boolean().required(),
			invoiceHead: yup.string().required().default(""),
			supportContact: yup.string().default(""),
			supportContactPhone: yup.string().default(""),
		});
	}, []);

	const defaultValues = useMemo(() => {
		return {
			comment: initialValues?.comment || "",
			displayName: initialValues?.displayName || "",
			email: initialValues?.email || "",
			invoiceByMail: initialValues?.invoiceByMail || true,
			invoiceHead: initialValues?.invoiceHead || "",
			supportContact: initialValues?.supportContact || "",
			supportContactPhone: initialValues?.supportContactPhone || "",
		};
	}, [initialValues]);

	const {
		handleSubmit,
		formState: { isValid, isDirty, isSubmitted },
		control,
	} = useForm<CreateOrUpdateInvoiceRecipientFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<CreateOrUpdateInvoiceRecipientFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isDirty,
		isSubmitted,
		isValid,
		isLoading: busy,
		enableInitially: !isUpdateProcess,
	});

	const onSubmit = async (data: CreateOrUpdateInvoiceRecipientFormData) => {
		setBusy(true);

		try {
			if (!!initialValues) {

				await updateInvoiceRecipient({ clientId, invoiceRecipientId: initialValues.id, data });
			} else {
				await createInvoiceRecipient({ clientId, data });
			}
			onSuccess();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex flex-col justify-start w-full min-h-full"
		>
			<Sidebar.Header>
				<Headline type="h2">{!!initialValues ? "Rechnungsempfänger aktualisieren" : "Rechnungsempfänger erstellen"}</Headline>
			</Sidebar.Header>
			<Sidebar.Content>
				{busy && <Sidebar.BusyOverlay />}
				<FormSectionGroup>
					<FormSection title="Kontaktdaten">
						<div className="grid grid-cols-6 gap-x-6 gap-y-6">
							<div className="col-span-6">
								<Input
									name="displayName"
									control={control}
									label="Name"
								/>
							</div>
							<div className="col-span-6">
								<Input
									name="supportContact"
									control={control}
									label="Support Kontakt"
								/>
							</div>
							<div className="col-span-3">
								<Input
									name="supportContactPhone"
									control={control}
									label="Support Kontakt Telefon"
								/>
							</div>
							<div className="col-span-3">
								<Input
									name="email"
									control={control}
									label="Support Kontakt E-Mail"
								/>
							</div>
						</div>
					</FormSection>
					<FormSection title="Rechnunsanschrift">
						<Textarea
							control={control}
							name="invoiceHead"
						/>
					</FormSection>
					<FormSection title="Anmerkungen">
						<Textarea
							control={control}
							name="comment"
						/>
					</FormSection>
				</FormSectionGroup>
			</Sidebar.Content>
			<Sidebar.Footer>
				<FormHasErrorsHint show={isUpdateProcess && !isValid || (!isUpdateProcess && !isValid && isSubmitted)}
								   className="mr-2" />
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="none"
						onClick={onCancelClick}>
					abbrechen
				</Button>
			</Sidebar.Footer>
		</form>
	);
};

export default CreateOrUpdateInvoiceRecipientForm;