import AbsencesView from "modules/absence/components/AbsencesView";
import { useMemo } from "react";
import { useAggregatedData } from "~/hooks/useAggregateData.ts";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useUsersAbsences } from "~/modules/absence/api/absence/absenceQueries.ts";
import { useAllAbsenceTypes } from "~/modules/absence/api/absenceType/absenceTypeQueries.ts";
import { useUsersVacationLedgerReport } from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import { useUsersWorkingSchedules } from "~/modules/absence/api/workingSchedule/workingScheduleQueries.ts";
import { useAllHolidays } from "~/modules/absence/holiday/holidayQueries.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { PermissionNames } from "~/types/entityNames.ts";

type AbsencesPageProps = {
	userId: string;
};

const AbsencesPage: React.FunctionComponent<AbsencesPageProps> = ({ userId }) => {
	const viewGuard = userViewGuard(null, PermissionNames.CanManageAbsences);
	if (viewGuard) return viewGuard;

	const { data, options } = useAggregatedData([

		{
			hook: () => useUsersAbsences({ userId: userId, options: { enabled: !!userId } }),
			name: "absences",
		},
		{
			hook: () => useUsersVacationLedgerReport({
				userId: userId,
				options: { enabled: !!userId },
			}),
			name: "vacationLedgerReport",
		},
		{
			hook: () => useUsersWorkingSchedules({
				userId: userId,
				options: { enabled: !!userId },
			}),
			name: "workingSchedules",
		},
		{ hook: () => useAllHolidays(), name: "holidays" },
		{ hook: () => useAllAbsenceTypes(), name: "absenceTypes" },
	]);
	const {
		absences,
		workingSchedules,
		absenceTypes,
		vacationLedgerReport,
	} = useMemo(() => data, [data]);

	if (options.loadingPcent < 100) return <LoadingPage pcent={options.loadingPcent} />;

	return <AbsencesView
		absences={absences}
		absenceTypes={absenceTypes}
		userId={userId}
		workingSchedules={workingSchedules}
		vacationLedgerReport={vacationLedgerReport}
	/>;
};

export default AbsencesPage;