import { PropsWithChildren } from "react";
import clsx from "clsx";

interface TimeTrackingFormTagProps {
	onClick?: (any:any) => void;
	selected?: boolean;
}

const TimeTrackingFormTag: React.FC<PropsWithChildren<TimeTrackingFormTagProps>> = ({ children, selected, onClick }) => (
	<button
		onClick={onClick}
		className={clsx(
			"inline-flex items-center px-2 py-2 ring-1 -ring-offset-1",
			"rounded-md text-sm font-medium",
			"ring-gray-500/10",
			"transition-color duration-200",
			"focus:outline-none focus:ring-2 foucus:ring-ou focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-500",
			selected ? "bg-accent-500 hover:bg-accent-600 hover:text-white text-white" : "bg-gray-50 text-gray-600 ",
			{
				"cursor-pointer hover:ring-gray-500/30 hover:text-gray-800": undefined !== onClick,
			},
		)}
	>
		{children}
	</button>
);

export default TimeTrackingFormTag;
