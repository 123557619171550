import { All_LOCATIONS_QUERY_KEY } from "~/modules/location/api/location/locationQueries.ts";
import { PROJECTS_ORDERS_QUERY_KEY } from "~/modules/project/api/order/orderQueries.ts";
import { PROJECT_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import CreateProjectPhaseForm from "~/modules/project/components/forms/CreateProjectPhaseForm";
import { PROJECTS_PHASES_QUERY_KEY } from "~/modules/project/api/projectPhase/projectPhaseQueries.ts";
import Sidebar from "~/components/Sidebar";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

type CreateProjectPhaseSidebarPhase = {
	isOpen: boolean;
	clientId: string;
	projectId: string;
	setOpen: (open: boolean) => void;
	setSelectedPhaseId: (value: string) => void;
};

const CreateProjectPhaseSidebar: React.FunctionComponent<CreateProjectPhaseSidebarPhase> = ({
	isOpen,
	clientId,
	projectId,
	setOpen,
	setSelectedPhaseId,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(
		async (newPhaseId: string) => {
			await queryClient.refetchQueries(PROJECTS_PHASES_QUERY_KEY(projectId));
			await queryClient.refetchQueries(PROJECTS_ORDERS_QUERY_KEY(projectId));
			await queryClient.refetchQueries(PROJECT_QUERY_KEY(projectId));
			await queryClient.refetchQueries(All_LOCATIONS_QUERY_KEY);
			setSelectedPhaseId(newPhaseId);
			setOpen(false);
		},
		[queryClient],
	);

	return (
		<Sidebar
			closeOnOutsideClick={false}
			open={isOpen}
			setOpen={() => setOpen(!isOpen)}>
			<CreateProjectPhaseForm
				clientId={clientId}
				projectId={projectId}
				onSuccess={handleSuccess}
				onCancel={() => setOpen(false)} />
		</Sidebar>
	);
};

export default CreateProjectPhaseSidebar;
