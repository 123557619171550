import { Control, FieldErrors, FieldValues } from "react-hook-form";

import FormSection from "~/components/form/FormSection";
import PaymentTargetDaysInput, { PaymentTargetDaysInputFormData } from "~/components/form/PaymentTargetInput";
import RadioGroup from "~/components/form/RadioGroup";
import {
	FIXED_PRICE_OPTIONS,
	INVOICE_BY_MAIL_OPTIONS,
	MONTHLY_BILLING_OPTIONS,
	SKILL_BASED_OPTIONS,
	TRAVEL_EXPENSES_INCLUDED_OPTIONS,
} from "~/constants/form.ts";

type OrderAccountingFormSectionPropsType<T extends FieldValues> = {
	control: Control<T>;
	errors: FieldErrors<T>;
	showCustomPaymentTargetInput: boolean;
};

interface OrderAccountingFormSectionFormData extends PaymentTargetDaysInputFormData {
	isFixedPrice: boolean;
	isBilledMonthly: boolean;
	travelExpensesIncluded: boolean;
	invoiceByMail: boolean;
	isSkillBased: boolean;
}

const OrderAccountingFormSection = <T extends OrderAccountingFormSectionFormData>({
	control,
	showCustomPaymentTargetInput,
}: OrderAccountingFormSectionPropsType<T>) => {
	return (
		<FormSection title="Umfang">
			<div className="grid gap-x-6 gap-y-2 grid-cols-6">
				<div className="col-span-3">
					<RadioGroup
						control={control}
						label="Abrechnungsart"
						name="isFixedPrice"
						options={FIXED_PRICE_OPTIONS}
						numCols={2}
					/>
				</div>

				<div className="col-span-3">
					<RadioGroup
						control={control}
						label="Abrechnungsart"
						name="isBilledMonthly"
						options={MONTHLY_BILLING_OPTIONS}
						numCols={2}
					/>
				</div>

				<div className="col-span-3">
					<RadioGroup
						control={control}
						label="Reisekosten"
						name="travelExpensesIncluded"
						options={TRAVEL_EXPENSES_INCLUDED_OPTIONS}
						numCols={2}
					/>
				</div>

				<div className="col-span-3">
					<RadioGroup
						control={control}
						label="Rechnung"
						name="invoiceByMail"
						options={INVOICE_BY_MAIL_OPTIONS}
						numCols={2}
					/>
				</div>

				<div className="col-span-3">
					<RadioGroup
						control={control}
						label="Abrechnung Rollen"
						name="isSkillBased"
						options={SKILL_BASED_OPTIONS}
						numCols={2}
					/>
				</div>

				<PaymentTargetDaysInput
					className={"col-span-6"}
					control={control}
					showCustomPaymentTargetInput={showCustomPaymentTargetInput}
				/>
			</div>
		</FormSection>
	);
};

export default OrderAccountingFormSection;
