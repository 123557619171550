import Headline from "~/components/Headline/Headline.tsx";
import { PropsWithChildren } from "react";

interface Props {
	className?: string;
	displayName: string;
}

const CompaniesListHead: React.FC<PropsWithChildren<Props>> = ({ displayName, className, children }) => {
	return (
		<>
			<Headline className={className} type="h2">{displayName}</Headline>
			{children}
		</>
	);
};

export default CompaniesListHead;
