import { CheckCircleIcon, PencilIcon, TrashIcon, XCircleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useMemo } from "react";
import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";

type WorkingSchedulesTableProps = {
	children?: React.ReactNode;
	compactView?: boolean;
};

const WorkingScheduleTable: React.FunctionComponent<WorkingSchedulesTableProps> = ({ children, compactView }) => {
	const workdayNames = useMemo(() => {
		if (compactView) {
			return ["Mo", "Di", "Mi", "Do", "Fr"];
		}
		return ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag"];
	}, [compactView]);

	const dayHeaderCells = workdayNames.map((day) => {
		return (
			<th
				scope="col"
				className="w-[16%] text-center text-sm font-semibold text-gray-900"
				key={day}
			>
				{day}
			</th>
		);
	});

	return (
		<table className="min-w-full border-separate border-spacing-y-2">
			<thead>
			<tr>
				<th scope="col"
					className={clsx("text-left text-sm font-semibold text-gray-900", !compactView ? "py-3.5 pl-4 pr-3":"py-1 pl-1, pr-1")}>
					Gültig ab
				</th>
				<>
					{dayHeaderCells}
				</>

				{!compactView && <th scope="col"
									 className="relative py-3.5 pl-3 pr-4 sm:pr-0">
					<span className="sr-only">Edit</span>
				</th>}
			</tr>
			</thead>
			<tbody className="divide-y divide-gray-200">{children}</tbody>
		</table>
	);
};

type WorkingSchedulesTableRowProps = {
	workingDays: boolean[];
	validFrom: string;
	isDeletable: boolean;
	isEditable: boolean;
	onEditClick: () => void;
	onDeleteClick: () => void;
	compactView?: boolean;
};

const Row: React.FunctionComponent<WorkingSchedulesTableRowProps> = ({
	workingDays,
	validFrom,
	isDeletable,
	isEditable,
	onEditClick,
	onDeleteClick,
	compactView,
}) => {
	const workingDayCells = workingDays.map((workingDay, index) => {
		return (
			<td
				key={index}
				className={clsx(
					"text-sm",
					workingDay ? "text-green-500" : "text-red-500",
				)}
			>
				<div className="flex justify-center">
					{workingDay ? <CheckCircleIcon className="w-5 h-5" /> : <XCircleIcon className="w-5 h-5" />}
				</div>
			</td>
		);
	});

	const menuItems = useMemo(() => {
		const items = [
			{
				label: "Bearbeiten",
				onClick: onEditClick,
				icon: PencilIcon,
			},
		];
		if (isDeletable) {
			items.push({
				label: "Löschen",
				onClick: onDeleteClick,
				icon: TrashIcon,
			});
		}
		return items;
	}, [isDeletable]);

	return (
		<tr className={clsx(!compactView ? "bg-white": "bg-transparent")}>
			<td className={clsx("whitespace-nowrap text-sm", !compactView ? "py-5 pl-4 pr-3": "py-1 pl-0")}>
				{validFrom}
			</td>
			<>
				{workingDayCells}
			</>

			{(!compactView || isEditable) && <td className="relative whitespace-nowrap pl-3 pr-4 text-sm text-right">
				<ButtonWithPopover
					theme="dark"
					items={menuItems}
				/>
			</td>}
		</tr>
	);
};

export default Object.assign(WorkingScheduleTable, { Row });
