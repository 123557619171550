import { useMemo } from "react";
import { OrderType } from "~/modules/project/api/order/orderTypes.ts";
import { ProjectRole } from "~/modules/project/api/projectRole/projectRoleTypes.ts";
import { StaffingType } from "~/modules/project/api/staffing/staffingTypes.ts";
import { UserType } from "~/modules/user/api/user/userTypes.ts";
import SectionHeading from "~/components/headings/SectionHeading";
import Headline from "~/components/Headline/Headline.tsx";
import Timeline from "~/components/Timeline/Timeline.tsx";
import OrderView from "../OrderView";

interface OrdersProps {
	allUsers: UserType[];
	clientId: string;
	orders: OrderType[];
	projectPhaseId: string;
	projectRoles: ProjectRole[];
	staffings: StaffingType[];
	showBudget: boolean;
}

const Orders: React.FC<OrdersProps> = ({
	allUsers,
	clientId,
	orders,
	projectPhaseId,
	projectRoles,
	staffings,
	showBudget,
}) => {

	const phasesOrders = useMemo(() => {
		return orders.filter((order) => order.projectPhaseId === projectPhaseId);
	}, [projectPhaseId, orders]);

	return (
		<>
			<SectionHeading>
				<Headline type="h2">Bestellungen & Staffing</Headline>
			</SectionHeading>

			<Timeline>
				{phasesOrders.map((order, orderIndex) => {
					const orderStaffing = staffings.filter((s) => order.id === s.orderId);
					return (
						<OrderView
							allUsers={allUsers}
							clientId={clientId}
							isFirstInList={0 === orderIndex}
							key={order.id}
							orderData={order}
							projectRoles={projectRoles}
							showBudget={showBudget}
							staffings={orderStaffing}
							totalOrders={phasesOrders.length}
						/>
					);
				})}
			</Timeline>
		</>
	);
};

export default Orders;
