import VacationLedgerView from "modules/absence/components/VacationLedgerView";
import { useMemo } from "react";
import { useAggregatedData } from "~/hooks/useAggregateData.ts";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAllAbsenceTypes } from "~/modules/absence/api/absenceType/absenceTypeQueries.ts";
import {
	useUsersVacationLedgerEntries,
	useUsersVacationLedgerReport,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { PermissionNames } from "~/types/entityNames.ts";

type AbsencesPageProps = {
	userId:string;
};

const VacationLedgerPage: React.FunctionComponent<AbsencesPageProps> = ({userId}) => {
	const viewGuard = userViewGuard(null, PermissionNames.CanManageAbsences);
	if (viewGuard) return viewGuard;

	const { data, options } = useAggregatedData([
		{
			hook: () => useUsersVacationLedgerReport({
				userId: userId,
				options: { enabled: !!userId },
			}),
			name: "vacationLedgerReport",
		},
		{
			hook: () => useUsersVacationLedgerEntries({
				userId: userId,
				options: { enabled: !!userId },
			}),
			name: "vacationLedgerEntries",
		},
		{ hook: () => useAllAbsenceTypes(), name: "absenceTypes" },
		{ hook: () => useAllUsers(), name: "users" },
	]);
	const {
		vacationLedgerEntries,
		vacationLedgerReport,
		users
	} = useMemo(() => data, [data]);

	if (options.loadingPcent < 100) return <LoadingPage pcent={options.loadingPcent} />;

	return <VacationLedgerView
		userId={userId}
		users={users}
		vacationLedgerEntries={vacationLedgerEntries}
		vacationLedgerReport={vacationLedgerReport}
	/>;
};

export default VacationLedgerPage;