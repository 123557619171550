import { UserPlusIcon } from "@heroicons/react/24/outline";
import ButtonNewItem from "~/components/buttons/ButtonNewItem";

import Button from "~/components/form/Button";
import Headline from "~/components/Headline";
import { appRoutes, generateFullPath } from "~/constants/appRoute.ts";
import { ClientContactPersonType } from "~/modules/client/api/clientContactPerson/clientContactPersonTypes.ts";
import ContactCard from "~/modules/client/components/contact/components/ContactCard";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

interface ClientContactsListProps {
	contacts?: ClientContactPersonType[];
	displayName: string;
	onAddContactClick: () => void;
}

const ClientContactsList: React.FC<ClientContactsListProps> = ({ contacts, displayName, onAddContactClick }) => {
	if (undefined === contacts || (contacts && 0 === contacts.length))
		return (
			<>
				<div>
					<Headline type="h3">Noch keine Kontakte vorhanden</Headline>
					<p className="mt-1 text-sm text-gray-500">Füge neue Kontakte zu {displayName} hinzu.</p>
					<div className="mt-6">
						<Button onClick={onAddContactClick}>Neuen Kontakt anlegen</Button>
					</div>
				</div>
			</>
		);

	return (
		<>
			<div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
				{contacts.sort(byObjectProperty('firstName')).map(({ id, title, firstName, lastName, jobTitle, emailBusiness, phone }) => (
					<ContactCard
						key={id}
						title={title}
						clientDisplayName={displayName}
						firstName={firstName}
						lastName={lastName}
						description={jobTitle}
						emailBusiness={emailBusiness}
						phone={phone}
						href={generateFullPath(appRoutes.contact().path, { ":contactId": id })}
					/>
				))}
				<ButtonNewItem icon={UserPlusIcon} onClick={onAddContactClick}>
					Neuen Kontakt anlegen
				</ButtonNewItem>
			</div>
		</>
	);
};

export default ClientContactsList;
