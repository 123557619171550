import { useEffect, useState } from "react";

import CountryDisplay from "~/components/displays/CountryDisplay";
import LoadingSpinner from "~/components/LoadingSpinner";

interface Props {
	address: {
		street?: string;
		houseNumber?: string;
		zipCode?: string;
		city?: string;
		countryId?: string | number;
	};
	width?: number;
	height?: number;
}

const MapDisplay: React.FC<Props> = ({ address, width = 600, height = 360 }) => {
	const [imageUrl, setImageUrl] = useState<string | null>(null);
	const [mapsUrl, setMapsUrl] = useState<string | null>(null);
	const { street, houseNumber, zipCode, city, countryId } = address;
	const fullAddress = `${street} ${houseNumber}, ${zipCode} ${city}`;

	useEffect(() => {
		const fetchImage = async () => {
			const accessToken = import.meta.env.VITE_MAPBOX_PUBLIC_TOKEN;

			const geocodingResponse = await fetch(
				`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
					fullAddress,
				)}.json?access_token=${accessToken}`,
			);
			const geocodingData = await geocodingResponse.json();
			const coordinates = geocodingData.features[0].center; // [longitude, latitude]
			const encodedAddress = encodeURIComponent(fullAddress);

			const staticImageUrl = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l(${coordinates[0]},${coordinates[1]})/${coordinates[0]},${coordinates[1]},8,0,0/${width}x${height}?access_token=${accessToken}`;
			setImageUrl(staticImageUrl);
			setMapsUrl(`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`)
		};

		fetchImage();
	}, [address]);

	return (
		<div className="flex gap-4 justify-start items-start">
			<div>
				<span className="font-bold">
					{street} {houseNumber}
				</span>
				<br />
				{zipCode} {city}
				<br />
				<span className="text-gray-600">{countryId && <CountryDisplay id={countryId.toString()} />}</span>
			</div>
			<a href={mapsUrl!} target="_blank" className="bg-gray-500 flex flex-grow justify-center items-center w-[20rem] rounded overflow-hidden">
				{imageUrl ? <img src={imageUrl} alt={fullAddress}  className="w-full" /> : <LoadingSpinner />}
			</a>
		</div>
	);
};

export default MapDisplay;
