import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { yupResolver } from "@hookform/resolvers/yup";

import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as yup from "yup";
import Button from "~/components/form/Button";
import FormHasErrorsHint from "~/components/form/FormHasErrorsHint";
import Input from "~/components/form/Input";
import SubmitButton from "~/components/form/SubmitButton";
import Switch from "~/components/form/Switch";
import Headline from "~/components/Headline";
import Sidebar from "~/components/Sidebar";
import { createUserDeliverable } from "~/modules/timeTracking/api/deliverable/deliverableApiDispatchers.ts";
import { USER_DELIVERABLES_QUERY_KEY } from "~/modules/timeTracking/api/deliverable/deliverableQueries.ts";
import { CreateUserDeliverableDataType } from "~/modules/timeTracking/api/deliverable/deliverableTypes.ts";
import { TimeTrackingFormNamesEnum } from "~/modules/timeTracking/types/timeTrackingTypes.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";


type CreateUserDeliverableFormProps = {
	userId: string,
	projectId: string | null | undefined,
	projectTitle: string | null | undefined,
	setCurrentFormName: (formId: TimeTrackingFormNamesEnum | null) => void;
	timeTrackingTypeId: string,
};


type CreateUserDeliverableFormData = {
	displayName: string;
	isFavorite: boolean;
};
const CreateUserDeliverableForm: React.FunctionComponent<CreateUserDeliverableFormProps> = ({
	userId,
	projectId,
	projectTitle,
	setCurrentFormName,
	timeTrackingTypeId,
}) => {
	const queryClient = useQueryClient();
	const [busy, setBusy] = useState(false);
	const schema = useMemo(() => {
		return yup.object().shape({
			displayName: yup.string().required(),
			isFavorite: yup.boolean().required(),
		});
	}, []);

	const defaultValues = useMemo(() => {
		return {
			displayName: "",
			isFavorite: false,
		};
	}, []);

	const {
		control,
		handleSubmit,
		formState: { isValid, isSubmitted },
	} = useForm<CreateUserDeliverableFormData>({
		mode: "onChange",
		defaultValues: defaultValues,
		resolver: yupResolver<CreateUserDeliverableFormData>(schema),
	});
	let titleDeliverableFor = "";
	switch (timeTrackingTypeId) {
		case("1"):
			titleDeliverableFor = `Projekt "${projectTitle}"`;
			break;
		case("2"):
			titleDeliverableFor = "Erfassungen vom Typ \"Intern\"\"";
			break;
		case("3"):
			titleDeliverableFor = "Erfassungen vom Typ \"Sales\"\"";
			break;
	}

	const onSubmit = async (formData: CreateUserDeliverableFormData) => {
		setBusy(true);
		try {

			const deliverableData: CreateUserDeliverableDataType = {
				deliverableName: formData.displayName,
				isFavorite: formData.isFavorite,
				projectId: projectId,
				timeTrackingTypeId: timeTrackingTypeId,
			};

			await createUserDeliverable({ userId, deliverableData });
			await queryClient.refetchQueries(USER_DELIVERABLES_QUERY_KEY(userId));
			setCurrentFormName(TimeTrackingFormNamesEnum.TIME_TRACKING_FORM);
		} catch (error) {
			console.log(error);
		}
	};


	return (

		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex flex-col justify-start w-full min-h-full"
		>
			<Sidebar.Header>
				<Headline type="h2">Deliverable für {titleDeliverableFor} erstellen</Headline>
			</Sidebar.Header>
			<Sidebar.Content>
				<div className="grid gap-x-6 gap-y-2 grid-cols-6">
					<div className="col-span-6">
						<Input
							name={"displayName"}
							control={control}
							autoFocus={true}
							label={"Deliverable"}
						/>
					</div>
					<div className="mt-4">
						<Switch name={"isFavorite"}
								control={control}
								label={"Favorit"} />
					</div>
				</div>
			</Sidebar.Content>
			<Sidebar.Footer>


				<Button className="mr-auto"
						theme="accent"
						onClick={() => setCurrentFormName(TimeTrackingFormNamesEnum.TIME_TRACKING_FORM)}>
					<ChevronLeftIcon className="w-6 h-6 mx-2" />
				</Button>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={busy}
							  disabled={!isValid || busy}>
					Speichern
				</SubmitButton>
				<Button theme="none"
						onClick={() => setCurrentFormName(TimeTrackingFormNamesEnum.TIME_TRACKING_FORM)}>
					abbrechen
				</Button>
			</Sidebar.Footer>
		</form>
	);
};

export default CreateUserDeliverableForm;
