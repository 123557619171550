import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function useScrollRestoration(initialPageKey: string) {
	const location = useLocation();
	const navigate = useNavigate();

	const scrollToPosition = useCallback((scrollTop: number) => {
		document.documentElement.scrollTop = scrollTop;
	}, []);

	useEffect(() => {
		const key = "pageScroll-" + initialPageKey;
		const savedPosition = localStorage.getItem(key);

		if (savedPosition) {
			let scrollTop = 0;
			try {
				scrollTop = JSON.parse(savedPosition);
			} catch (e) {
			}
			scrollToPosition(scrollTop);
		}
	}, [location, initialPageKey, scrollToPosition]);


	const saveScrollPositionAndNavigate = useCallback((path: string, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		event.preventDefault();

		const position = document.documentElement.scrollTop || document.body.scrollTop;
		localStorage.setItem("pageScroll-" + initialPageKey, JSON.stringify(position));

		navigate(path);
	}, []);


	const saveScrollPosition = useCallback((position?: number) => {

		const scrollTop = position !== undefined ? position : document.documentElement.scrollTop || document.body.scrollTop;
		localStorage.setItem("pageScroll-" + initialPageKey, JSON.stringify(scrollTop));
	}, []);

	return {
		scrollToPosition,
		saveScrollPositionAndNavigate,
		saveScrollPosition,
	};
}

export default useScrollRestoration;
