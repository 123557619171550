import { getAllApprovalLikelihoods } from "~/modules/project/api/approvalLikelihood/approvalLikelihoodApiDispatchers.ts";
import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { QueriesOptions, useQuery } from "react-query";

export const ALL_APPROVAL_LIKELIHOODS_QUERY_KEY = ["allApprovalLikelihoods"];

export const useAllApprovalLikelihoods= (options?: QueriesOptions<any>) => useQuery(
	ALL_APPROVAL_LIKELIHOODS_QUERY_KEY,
	() => getAllApprovalLikelihoods().then(getDataFromResponse),
	options,
);