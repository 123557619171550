import { useMemo } from "react";
import { useAggregatedData } from "~/hooks/useAggregateData.ts";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useUsersVacationLedgerReport } from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import {
	useUsersWorkingSchedules,
	useWorkingSchedulesMinDate,
} from "~/modules/absence/api/workingSchedule/workingScheduleQueries.ts";
import WorkingSchedulesView from "~/modules/absence/components/WorkingSchedulesView";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { PermissionNames } from "~/types/entityNames.ts";

type WorkingSchedulesPageProps = {
	userId: string;
};

const WorkingSchedulesPage: React.FunctionComponent<WorkingSchedulesPageProps> = ({ userId }) => {
	const viewGuard = userViewGuard(null, PermissionNames.CanManageAbsences);
	if (viewGuard) return viewGuard;

	const { data, options } = useAggregatedData([
		{
			hook: () => useUsersWorkingSchedules({
				userId: userId,
				options: { enabled: !!userId },
			}),
			name: "workingSchedules",
		},
		{
			hook: () => useUsersVacationLedgerReport({
				userId: userId,
				options: { enabled: !!userId },
			}),
			name: "vacationLedgerReport",
		},
		{
			hook: () => useWorkingSchedulesMinDate(),
			name: "workingSchedulesMinDate",
		},
	]);
	const {
		workingSchedules,
		workingSchedulesMinDate,
		vacationLedgerReport,
	} = useMemo(() => data, [data]);

	if (options.loadingPcent < 100) return <LoadingPage pcent={options.loadingPcent} />;

	return <WorkingSchedulesView
		userId={userId}
		vacationLedgerReport={vacationLedgerReport}
		workingSchedules={workingSchedules}
		workingSchedulesMinDate={new Date(workingSchedulesMinDate.workingSchedulesMinDate)}
	/>;
};

export default WorkingSchedulesPage;