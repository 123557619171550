import { tv } from "tailwind-variants";
import { VariantProps } from "~/types/tailwindVariants";

export const buttonVariants = tv({
	base: ["inline-flex flex-shrink-0 gap-2 items-center justify-center tracking-wide transition transition-colors duration-200",
		"font-medium truncate focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-500"],
	variants: {
		theme: {
			plain:"_",
			primary: "bg-primary-500 hover:bg-primary-700 text-white",
			secondary: "bg-secondary-500 hover:bg-secondary-600 text-white",
			accent: "bg-accent-500 hover:bg-accent-700 text-white",
			danger: "bg-danger-500 hover:bg-danger-700 text-white",
			white: "bg-white hover:bg-opacity-50 text-gray-700",
			none: "bg-transparent text-gray-600 hover:text-gray-900 hover:underline",
		},
		size: {
			sm: "px-2 h-7 text-xs",
			md: "px-3 h-10 text-sm",
			auto: "px-0",
		},
		disabled: {
			true: "opacity-30 pointer-events-none",
		},
		rounded: {
			none:"none",
			md: "rounded-md",
			full: "rounded-full",
		},
		isGrouped: {
			true: "border-gray-300 border-t border-b border-r first:border-l last:border-r first:rounded-l-md last:rounded-r-md",
		},
	},
	defaultVariants: {
		size:"md",
		rounded: "full",
		disabled: false,
		theme: "secondary",
	},
});


export type ButtonVariantProps = VariantProps<typeof buttonVariants>;
export type ButtonThemeVariants = "primary" | "secondary" | "accent" | "danger" | "white" | "none" | "plain";
export type ButtonSizeVariants = "sm" | "md" | "auto";
export type ButtonBorderRadiusVariants = "md" | "full" | "none";

