import { useMemo } from "react";
import { mapDataToSelectOptionsData } from "~/utils/form/formUtils.ts";
import { FormInputOption } from "~/types/form.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";
import { useAllQuotas } from "~/modules/project/api/quota/quotaQueries.ts";


const useClientsQuotaSelectOptions = (clientId: string): FormInputOption[] => {
	const { data: allQuotasData } = useAllQuotas();
	return useMemo(() => {
		if (allQuotasData && clientId) {
			const filteredQuotasData = allQuotasData.filter((clientContactPerson) => {
				return clientContactPerson.clientId === clientId;
			}).sort(byObjectProperty("title"));
			return mapDataToSelectOptionsData(filteredQuotasData, "id", "title");
		}
		return [];
	}, [allQuotasData, clientId]);
};

export default useClientsQuotaSelectOptions;

