import React from "react";
import { Control, FieldValues } from "react-hook-form";
import FormSection from "~/components/form/FormSection";
import Input from "~/components/form/Input";
import Select from "~/components/form/Select";

import { FormInputOption } from "~/types/form.ts";

type UserEmploymentInformationFormSectionProps<T extends FieldValues> = {
	control: Control<T>;
	children?: React.ReactNode;
	careerLevelSelectOptions: FormInputOption[];
	companyLocationSelectOptions: FormInputOption[];
	employmentStatusSelectOptions: FormInputOption[];
};

type UserEmploymentInformationFormData = {
	employmentStatusId: string;
	careerLevelId: string;
	locationId: string;
	shortName: string;
}

const UserEmploymentInformationFormSection = <T extends UserEmploymentInformationFormData>({
	control,
	children,
	careerLevelSelectOptions,
	companyLocationSelectOptions,
	employmentStatusSelectOptions,
}: UserEmploymentInformationFormSectionProps<T>) => {
	return (
		<FormSection title="Mitarbeiterdaten">
			<div className="grid grid-cols-6 gap-x-6 gap-y-6">
				<div className="col-span-3">
					<Select
						name="employmentStatusId"
						label={"Anstellungsstatus"}
						optionsData={employmentStatusSelectOptions}
						control={control}
					/>
				</div>
				<div className="col-span-3">
					<Select
						name="careerLevelId"
						label={"Karrierestufe"}
						optionsData={careerLevelSelectOptions}
						control={control}
					/>
				</div>
				<div className="col-span-3">
					<Select
						name="locationId"
						label={"Standort"}
						optionsData={companyLocationSelectOptions}
						control={control}
					/>
				</div>
				<div className="col-span-3">
					<Input
						name="shortName"
						placeholder="MM"
						label="Namenskürzel"
						control={control} />
				</div>
				{children}
			</div>
		</FormSection>
	);
};

export default UserEmploymentInformationFormSection;
