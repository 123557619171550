import { useRef, useCallback } from "react";

interface UseScrollToElementReturn {
	currentRef: HTMLElement | null;
	setScrollToElementRef: (node: HTMLElement | null) => void;
	scrollToRef: (offset?: number) => void;
}

function useScrollToElement(): UseScrollToElementReturn {
	const elementRef = useRef<HTMLElement | null>(null);

	const setScrollToElementRef = useCallback((node: HTMLElement | null) => {
		if (node !== null) {
			elementRef.current = node;
		}
	}, []);

	const scrollToRef = useCallback((offset: number = 0) => {
		if (elementRef.current) {
			const topPosition = elementRef.current.offsetTop + offset;
			window.scrollTo({ top: topPosition, behavior: "smooth" });
		}
	}, []);

	return { setScrollToElementRef, scrollToRef, currentRef: elementRef.current };
}

export default useScrollToElement;
