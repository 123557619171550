import { UseQueryBaseParams } from "~/types/reactQueryTypes.ts";

import { getDataFromResponse } from "~/api/axiosUtils";
import { QueriesOptions, useQuery } from "react-query";

import {
	getTimeTracking,
	getUsersTimeTrackings,
	getUsersUntrackedDaysByMonth,
} from "~/modules/timeTracking/api/timeTracking/timeTrackingApiDispatchers.ts";
import { GetUsersTimeTrackingsFilterType } from "~/modules/timeTracking/api/timeTracking/timeTrackingTypes.ts";

export const TIME_TRACKING_BASE_QUERY_KEY = "timeTracking";

export const TIME_TRACKINGS_BY_USER_QUERY_KEY = (userId: string, filter?: GetUsersTimeTrackingsFilterType) => [TIME_TRACKING_BASE_QUERY_KEY, { userId }, filter];
export const TIME_TRACKING_QUERY_KEY = (timeTrackingId: string) => [TIME_TRACKING_BASE_QUERY_KEY, {timeTrackingId}];
export const useUsersTimeTrackings = ({ userId, filter, options }: {
	userId: string,
	filter?: GetUsersTimeTrackingsFilterType
} & UseQueryBaseParams) => useQuery(
	TIME_TRACKINGS_BY_USER_QUERY_KEY(userId, filter),
	() => getUsersTimeTrackings(userId, filter).then(getDataFromResponse),
	options,
);

export const USERS_UNTRACKED_DAYS_QUERY_KEY = (startOfMonth: string) => ["usersUntrackedDays", startOfMonth];

export const useUsersUntrackedDays = (userId: string, startOfMonth: string, options?: QueriesOptions<any>) => useQuery(
	USERS_UNTRACKED_DAYS_QUERY_KEY(startOfMonth),
	() => getUsersUntrackedDaysByMonth(userId, startOfMonth).then(getDataFromResponse),
	options,
);

export const useTimeTracking = ({ userId, timeTrackingId, options }: {
	userId: string, timeTrackingId: string, options?: QueriesOptions<any>
}) => useQuery(
	TIME_TRACKING_QUERY_KEY(timeTrackingId),
	() => getTimeTracking(userId, timeTrackingId).then(getDataFromResponse),
	options,
);