import { useMemo } from "react";
import { getSelectBoxDefaultOptionsData, mapDataToSelectOptionsData } from "~/utils/form/formUtils.ts";
import { FormInputOption } from "~/types/form.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";
import { useAllApprovalLikelihoods } from "~/modules/project/api/approvalLikelihood/approvalLikelihoodQueries.ts";


const useApprovalLikelihoodOptions = (includeCtaOption: boolean = false): FormInputOption[] => {
	const { data: approvalLikeliHoodsData } = useAllApprovalLikelihoods();
	return useMemo(() => {
		if (approvalLikeliHoodsData) {
			const approvalLikeliHoodsDataSorted = [...approvalLikeliHoodsData].sort(byObjectProperty("percentage"));
			const options = mapDataToSelectOptionsData(approvalLikeliHoodsDataSorted, "id", "displayName");

			if (includeCtaOption) {
				return [getSelectBoxDefaultOptionsData(), ...options];
			}
			return options;
		}
		return [];
	}, [approvalLikeliHoodsData, includeCtaOption]);
};

export default useApprovalLikelihoodOptions;

