import {
	createProgramEndpoint,
	getAllProgramsEndpoint, getProgramEndpoint,
	indexProgramsByClientIdEndpoint, updateProgramEndpoint,
} from "~/modules/project/api/program/programApiEndpoints.ts";
import { CreateProgramData, Program, UpdateProgramData } from "~/modules/project/api/program/programTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";


export function getProgram(clientId: string, programId: string): AxiosApiResponsePromise<Program> {
	return axiosApi.get(getProgramEndpoint(clientId, programId));
}

export function getAllPrograms(): AxiosApiResponsePromise<Program[]> {
	return axiosApi.get(getAllProgramsEndpoint);
}

export function indexProgramsByClientId(clientId: string): AxiosApiResponsePromise<Program[]> {
	return axiosApi.get(indexProgramsByClientIdEndpoint(clientId));
}

export function createProgram(clientId: string, data: CreateProgramData): AxiosApiResponsePromise<Program> {
	return axiosApi.post(createProgramEndpoint(clientId), { ...data });
}

export function updateProgram(clientId: string, programId: string, data: UpdateProgramData): AxiosApiResponsePromise<Program> {
	return axiosApi.put(updateProgramEndpoint(clientId, programId), { ...data });
}
