import { appRoutes, generateFullPath } from "~/constants/appRoute.ts";
import { useEffect, useMemo, useRef, useState } from "react";

import BreadcrumbsPortal from "~/components/Breadcrumbs";
import Button from "~/components/form/Button";
import ButtonNewItem from "~/components/buttons/ButtonNewItem";
import { ClientType } from "~/modules/client/api/client/clientTypes.ts";
import ClientItem from "modules/client/components/ClientItem";
import CompaniesListHead from "~/modules/client/components/CompaniesListHead";
import { CompanyType } from "~/modules/client/api/company/companyTypes.ts";
import ContentWrapper from "~/components/ContentWrapper";
import CreateClientSidebar from "modules/client/components/CreateClientSidebar";
import CreateCompanySidebar from "modules/client/components/CreateCompanySidebar";
import NoEntriesFound from "~/components/NoEntriesFound";
import PageHeading from "~/components/headings/PageHeading";
import UpdateCompanySidebar from "~/modules/client/components/UpdateCompanySidebar";
import { ProjectType } from "~/modules/project/api/project/projectTypes.ts";
import SearchInput from "~/components/form/SearchInput";
import SectionHeading from "~/components/headings/SectionHeading";
import { byObjectProperty } from "~/utils/sortFunctions.ts";
import { isMobileOrTablet } from "~/utils/mobileAndTabletDetection.ts";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ClientsViewProps {
	companies?: CompanyType[];
	projects?: ProjectType[];
	clients?: ClientType[];
}

const ClientsIndexView: React.FC<ClientsViewProps> = ({ companies, projects, clients }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [sidebarNewCustomerVisible, setSidebarNewCustomerVisible] = useState(false);
	const [companyIdToUpdate, setCompanyIdToUpdate] = useState<string | null>(null);
	const [selectedCompanyIdForNewClient, setSelectedCompanyIdForNewClient] = useState<string | null>(null);
	const [search, setSearch] = useState("");

	const searchInputRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		// autofocus only on wider screens
		if (searchInputRef.current && !isMobileOrTablet()) {
			searchInputRef.current.focus();
		}
	}, []);

	const filteredClients = useMemo(() => {
		if (clients) {
			return clients.filter((client) => client.displayName.toLowerCase().includes(search.trim().toLowerCase()));
		}

		return [];
	}, [search, clients]);

	return (
		<>
			<BreadcrumbsPortal pages={[appRoutes.clients()]}
							   className="bg-white" />

			<PageHeading title={t("clients.title", "Alle Kunden")} />
			<PageHeading.BottomBar>
				<div className="flex justify-between w-full">
					<div className="w-48">
						<SearchInput placeholder="Kunde..."
									 value={search}
									 onChange={setSearch}
									 ref={searchInputRef} />
					</div>
					<Button onClick={() => setSidebarNewCustomerVisible(true)}>
						{t("clients.btnAddNewClient", "Neuer Kunde")}
					</Button>
				</div>
			</PageHeading.BottomBar>

			<ContentWrapper>
				<SectionHeading sticky
								style={{ top: 73 }}>
					<SectionHeading.Tabs
						tabs={[
							{ name: "Kunden", value: "clients", active: true },
							{ name: "Kontakte", value: "contacts" },
						]}
						onChange={(tab) => {
							"clients" === tab && navigate(appRoutes.clients().path, { replace: true });
							"contacts" === tab && navigate(appRoutes.contacts().path, { replace: true });
						}}
					/>
				</SectionHeading>

				<div className="mt-8 flex flex-col gap-10">
					{0 === filteredClients.length ? (
						<NoEntriesFound />
					) : (
						companies &&
						projects &&
						companies.sort(byObjectProperty("displayName")).map(({ id, displayName }) => {
							const companyClients = filteredClients.filter((clients) => clients.companyId === id);
							if (companyClients.length === 0) return null;
							const clientIds = companyClients.map((client) => client.id);
							const companyProjects = projects.filter((project) => clientIds.includes(project.clientId));
							const projectCount = companyProjects.length;

							return (
								<div key={id}>
									<button className="flex flex-col"
											onClick={() => setCompanyIdToUpdate(id)}>
										<CompaniesListHead className="hover:text-gray-600"
														   displayName={displayName}>
											<p className="max-w-4xl text-sm text-gray-500">Projekte {projectCount}</p>
										</CompaniesListHead>
									</button>

									<ul
										role="list"
										className="my-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
									>
										{companyClients
											.sort(byObjectProperty("displayName"))
											.map(({ id, displayName, city }) => (
												<ClientItem
													displayName={displayName}
													city={city || ""}
													href={generateFullPath(appRoutes.client().path, {
														":clientId": id,
													})}
													key={`client-${id}`}
												/>
											))}
										<ButtonNewItem
											size="sm"
											onClick={() => setSelectedCompanyIdForNewClient(id)}
										/>
									</ul>
								</div>
							);
						})
					)}
				</div>
			</ContentWrapper>
			<CreateCompanySidebar
				isOpen={sidebarNewCustomerVisible}
				setOpen={() => setSidebarNewCustomerVisible(!sidebarNewCustomerVisible)}
			/>
			<CreateClientSidebar
				selectedCompanyId={selectedCompanyIdForNewClient}
				closeSidebar={() => setSelectedCompanyIdForNewClient(null)}
			/>
			<UpdateCompanySidebar isOpen={companyIdToUpdate !== null}
								  setOpen={() => setCompanyIdToUpdate(null)}
								  companyId={companyIdToUpdate!} />
		</>
	);
};

export default ClientsIndexView;
