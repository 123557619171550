import { UserPlusIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import ButtonNewItem from "~/components/buttons/ButtonNewItem";
import Headline from "~/components/Headline";
import { InvoiceRecipientType } from "~/modules/billing/api/invoiceRecipient/invoiceRecipientTypes.ts";
import CreateInvoiceRecipientSidebar
	from "~/modules/client/components/ClientDetailsView/components/ClientsInvoiceRecipientsList/components/CreateInvoiceRecipientSidebar";
import DeleteInvoiceRecipientModal
	from "~/modules/client/components/ClientDetailsView/components/ClientsInvoiceRecipientsList/components/DeleteInvoiceRecipientModal";
import InvoiceRecipientContactCard
	from "~/modules/client/components/ClientDetailsView/components/ClientsInvoiceRecipientsList/components/InvoiceRecipientContactCard/InvoiceRecipientContactCard.tsx";
import UpdateInvoiceRecipientSidebar
	from "~/modules/client/components/ClientDetailsView/components/ClientsInvoiceRecipientsList/components/UpdateInvoiceRecipientSidebar";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

interface ClientsInvoiceRecipientsListProps {
	clientId: string;
	invoiceRecipients?: InvoiceRecipientType[];
	clientDisplayName: string;
}

const ClientsInvoiceRecipientsList: React.FC<ClientsInvoiceRecipientsListProps> = ({
	clientId,
	clientDisplayName,
	invoiceRecipients,
}) => {
	const [showNewInvoiceRecipientForm, setShowNewInvoiceRecipientForm] = useState(false);
	const [invoiceRecipientIdToEdit, setInvoiceRecipientIdToEdit] = useState<string | null>(null);
	const [invoiceRecipientIdToDelete, setInvoiceRecipientIdToDelete] = useState<string | null>(null);

	const selectedInvoiceRecipient = invoiceRecipients?.find(({ id }) => id === invoiceRecipientIdToEdit) || null;
	const invoiceRecipientToDelete = invoiceRecipients?.find(({ id }) => id === invoiceRecipientIdToDelete) || null;

	return (
		<>
			{((invoiceRecipients && 0 === invoiceRecipients.length) &&
				<div>
					<Headline type="h3">Noch keine zentralen Rechnungsempfänger vorhanden</Headline>
					<p className="mt-1 text-sm text-gray-500">Füge neue Kontakte zu {clientDisplayName} hinzu.</p>
					<div className="mt-6 w-1/3">
						<ButtonNewItem onClick={() => setShowNewInvoiceRecipientForm(true)}>Neuen Rechnungsempfänger
							anlegen</ButtonNewItem>
					</div>
				</div>)
			}
			{invoiceRecipients && invoiceRecipients?.length > 0 && <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
				{invoiceRecipients.sort(byObjectProperty("displayName")).map(({
					id,
					displayName,
					email,
					supportContact,
					supportContactPhone,
				}) => (
					<InvoiceRecipientContactCard
						key={id}
						displayName={displayName}
						email={email}
						supportContact={supportContact}
						supportContactPhone={supportContactPhone}
						onDeleteClick={() => setInvoiceRecipientIdToDelete(id)}
						onEditClick={() => setInvoiceRecipientIdToEdit(id)}
					/>
				))}
				<ButtonNewItem icon={UserPlusIcon}
							   onClick={() => setShowNewInvoiceRecipientForm(true)}>
					Neuen zentralen Rechnungsempfänger anlegen
				</ButtonNewItem>
			</div>}
			<CreateInvoiceRecipientSidebar clientId={clientId}
										   isOpen={showNewInvoiceRecipientForm}
										   onClose={() => setShowNewInvoiceRecipientForm(false)} />
			<UpdateInvoiceRecipientSidebar
				invoiceRecipientData={selectedInvoiceRecipient}
				isOpen={!!selectedInvoiceRecipient}
				onClose={() => setInvoiceRecipientIdToEdit(null)} />

			<DeleteInvoiceRecipientModal clientId={clientId}
										 invoiceRecipientDisplayName={invoiceRecipientToDelete?.displayName!}
										 invoiceRecipientId={invoiceRecipientIdToDelete}
										 isOpen={invoiceRecipientIdToDelete !== null}
										 onClose={()=>setInvoiceRecipientIdToDelete(null)} />

		</>
	);
};

export default ClientsInvoiceRecipientsList;
