import { Control, FieldValues } from "react-hook-form";
import ComboBox from "~/components/form/ComboBox";
import FormSection from "~/components/form/FormSection";
import Input from "~/components/form/Input";

import { FormInputOption } from "~/types/form.ts";

type AddressFormSectionProps<T extends FieldValues> = {
	control: Control<T>;
	countrySelectOptions: FormInputOption[];
};

type AddressFormSectionFormDataType = {
	city: string | null;
	countryId?: string | null;
	houseNumber: string | null;
	street: string | null;
	zipCode: string | null;
};

const AddressFormSection = <T extends AddressFormSectionFormDataType>({
	control,
	countrySelectOptions,
}: AddressFormSectionProps<T>) => {
	return (
		<FormSection title="Adresse">
			<div className="grid gap-2 sm:grid-cols-4 gap-y-6">
				<div className="sm:col-start-1 col-span-3">
					<Input
						name="street"
						control={control}
						placeholder="Straßenname"
						label="Straße"
					/>
				</div>

				<div className="sm:col-span-1">
					<Input
						name="houseNumber"
						control={control}
						placeholder="22"
						label="Nr."
					/>
				</div>

				<div className="sm:col-start-1 sm:col-span-1">
					<Input
						name="zipCode"
						control={control}
						placeholder="12345"
						label="PLZ" />
				</div>

				<div className="sm:col-span-3">
					<Input
						name="city"
						control={control}
						placeholder="Hamburg"
						label="Stadt" />
				</div>

				<div className="col-span-full">
					<ComboBox
						allowNew={false}
						name="countryId"
						label={"Land"}
						control={control}
						optionsData={countrySelectOptions}
					/>
				</div>
			</div>
		</FormSection>
	);
};

export default AddressFormSection;
