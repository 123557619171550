import { PROJECT_QUERY_KEY, useProject } from "~/modules/project/api/project/projectQueries.ts";

import Sidebar from "~/components/Sidebar";
import UpdateProjectForm from "~/modules/project/components/forms/UpdateProjectForm";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

type AddProjectPhaseToProjectSidebarProps = {
	isOpen: boolean;
	setOpen: (open: boolean) => void;
	projectId: string;
};

const UpdateProjectSidebar: React.FunctionComponent<AddProjectPhaseToProjectSidebarProps> = ({
	isOpen,
	setOpen,
	projectId,
}) => {
	const { data: projectData } = useProject({ projectId });

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.invalidateQueries(PROJECT_QUERY_KEY(projectId));
		setOpen(false);
	}, [queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false} open={isOpen} setOpen={() => setOpen(!isOpen)}>
			{projectData && (
				<UpdateProjectForm
					projectData={projectData}
					onSuccess={handleSuccess}
					onCancel={() => setOpen(false)}
				/>
			)}
		</Sidebar>
	);
};

export default UpdateProjectSidebar;
