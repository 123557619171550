import { getAllPermissions, getAllRoles, getAuthenticatedUser } from "~/modules/auth/api/auth/authApiDispatchers.ts";
import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { QueriesOptions, useQuery } from "react-query";

export const GET_AUTHENTICATED_USER_QUERY_KEY = "authenticatedUser";

export const ALL_ROLES_QUERY_KEY = ["roles", "all"];


export const useAuthenticatedUserQuery = (options: QueriesOptions<any>) => useQuery(
	GET_AUTHENTICATED_USER_QUERY_KEY,
	() => getAuthenticatedUser().then(getDataFromResponse),
	options,
);

export const useAllRoles = (options?: QueriesOptions<any>) => useQuery(
	ALL_ROLES_QUERY_KEY,
	() => getAllRoles().then(getDataFromResponse),
	options,
);

export const ALL_PERMISSIONS_QUERY_KEY = ["permissions", "all"];

export const useAllPermissions = (options?: QueriesOptions<any>) => useQuery(
	ALL_PERMISSIONS_QUERY_KEY,
	() => getAllPermissions().then(getDataFromResponse),
	options,
);