import * as yup from "yup";
import FormHasErrorsHint from "~/components/form/FormHasErrorsHint";

import FormSection, { FormSectionGroup } from "~/components/form/FormSection";
import React, { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import CommentFormSection from "~/components/form/formSections/CommentFormSection";

import { ALL_CLIENTS_QUERY_KEY } from "~/modules/client/api/client/clientQueries.ts";
import AccountingDetailsFormSection from "~/components/form/formSections/AccountingDetailsFormSection";
import AddressFormSection from "~/components/form/formSections/AddressFormSection/AddressFormSection.tsx";
import { All_COMPANIES_QUERY_KEY } from "~/modules/client/api/company/companyQueries.ts";
import Button from "~/components/form/Button/Button.tsx";
import { CreateCompanyData } from "~/modules/client/api/company/companyTypes.ts";
import { DEFAULT_COUNTRY_ID } from "~/constants/form.ts";
import Headline from "~/components/Headline";
import Input from "~/components/form/Input";
import Sidebar from "~/components/Sidebar";
import SubmitButton from "~/components/form/SubmitButton";
import { createCompany } from "~/modules/client/api/company/companyApiDispatchers.ts";
import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";
import {
	useCompanyValidationComparisonData,
} from "~/hooks/form/validationComparisonData/useCompanyValidationComparisonData.ts";
import useCountrySelectOptions from "~/hooks/form/formOptionsData/useCountrySelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { useQueryClient } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";

type Props = {
	onSuccess: (newCompanyId: string) => void;
	onCancel: () => void;
};

interface CreateCompanyFormData extends Omit<CreateCompanyData, "paymentTargetDays"> {
	paymentTargetDays: number;
	paymentTargetDaysCustom?: number | null;
}

const CreateCompanyForm: React.FC<Props> = ({ onSuccess, onCancel }) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);
	const queryClient = useQueryClient();

	const { displayNameComparisonData, shortNameComparisonData, corporateNameComparisonData } =
		useCompanyValidationComparisonData();
	const schema = useMemo(() => {
		return yup.object({
			billingViaThirdParty: yup.boolean().required(),
			city: yup.string().default(""),
			comment: yup.string().default(""),
			corporateName: yup.string().required().unique(corporateNameComparisonData),
			countryId: yup.string().default(""),
			displayName: yup.string().required().unique(displayNameComparisonData),
			houseNumber: yup.string().default(""),
			invoiceByMail: yup.boolean().required(),
			isVatExempt: yup.boolean().required(),
			paymentTargetDays: yup.number().required(),
			paymentTargetDaysCustom: yup.number()
				.nullable()
				.when('paymentTargetDays', (field1Value:number[], schema) => {
					return field1Value[0] === 0
						? schema.required()
						: schema.nullable();
				}),
			shortName: yup.string().required().unique(shortNameComparisonData),
			street: yup.string().default(""),
			travelExpensesIncluded: yup.boolean().required(),
			zipCode: yup.string().default(""),
		});
	}, [corporateNameComparisonData, displayNameComparisonData, shortNameComparisonData]);

	const defaultValues = useMemo(() => {
		return {
			billingViaThirdParty: false,
			city: "",
			comment: "",
			corporateName: "",
			countryId: DEFAULT_COUNTRY_ID,
			displayName: "",
			houseNumber: "",
			invoiceByMail: true,
			isVatExempt: false,
			paymentTargetDays: 30,
			paymentTargetDaysCustom: null,
			shortName: "",
			street: "",
			travelExpensesIncluded: true,
			zipCode: "",
		};
	}, []);

	const {
		handleSubmit,
		control,
		watch,
		formState: { isValid, isDirty, isSubmitted },
		trigger
	} = useForm<CreateCompanyFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<CreateCompanyFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({ isDirty, isSubmitted, isValid, isLoading: busy });

	const showCustomPaymentTargetInput = watch("paymentTargetDays") === 0;

	const countrySelectOptions = useCountrySelectOptions();

	useEffect(() => {
		if (showCustomPaymentTargetInput) {
			trigger('paymentTargetDaysCustom');
		}
	}, [showCustomPaymentTargetInput]);

	const onSubmit: SubmitHandler<CreateCompanyFormData> = async (data) => {
		setBusy(true);
		const { paymentTargetDays, paymentTargetDaysCustom, ...rest } = data;
		let paymentTargetDaysValue =
			paymentTargetDays === 0 && paymentTargetDaysCustom ? paymentTargetDaysCustom : paymentTargetDays;

		const newCompanyData = { paymentTargetDays: paymentTargetDaysValue, ...rest };
		try {
			const newCompanyRespone = await createCompany(newCompanyData);
			const companyId = getDataFromResponse(newCompanyRespone).id;
			onSuccess(companyId);
			queryClient.invalidateQueries({ queryKey: All_COMPANIES_QUERY_KEY });
			queryClient.invalidateQueries({ queryKey: ALL_CLIENTS_QUERY_KEY });
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex flex-col justify-start w-full min-h-full"
		>
			<Sidebar.Header>
				<Headline type="h2">Neuen Kunden anlegen</Headline>
			</Sidebar.Header>
			<Sidebar.Content>
				{busy && <Sidebar.BusyOverlay />}
				<FormSectionGroup>
					<FormSection title="Kunde">
						<div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
							<div className="sm:col-start-1 col-span-full">
								<Input
									name="corporateName"
									control={control}
									label="Name Legaleinheit"
									placeholder="Grandioso Gmbh & Co. KG"
								/>
							</div>
							<div className="sm:col-start-1 col-span-3">
								<Input
									name="displayName"
									control={control}
									label="Kundenname"
									placeholder="Grandioso"
								/>
							</div>
							<div className="col-span-3">
								<Input
									name="shortName"
									control={control}
									placeholder="GD"
									label="Kürzel für Projektnummer"
								/>
							</div>
						</div>
					</FormSection>

					<AddressFormSection<CreateCompanyFormData>
						control={control}
						countrySelectOptions={countrySelectOptions}
					/>

					<AccountingDetailsFormSection<CreateCompanyFormData>
						control={control}
						showCustomPaymentTargetInput={showCustomPaymentTargetInput}
					/>
					<CommentFormSection
						control={control}
						label="Rechnungsbesonderheiten"
						placeholder="Hier können allgemeine Anmerkungen für die Rechnungserstellung hinterlegt werden."
						title="Kommentar" />
				</FormSectionGroup>
			</Sidebar.Content>
			<Sidebar.Footer>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					abbrechen
				</Button>
			</Sidebar.Footer>
		</form>
	);
};

export default CreateCompanyForm;
