import LoadingSpinner from "~/components/LoadingSpinner";

interface LoadingPageProps {
	pcent?: number;
}

const LoadingPage: React.FC<LoadingPageProps> = ({ pcent }) => (
	<section className="mx-auto max-w-7xl h-96 flex justify-center items-center">
		<div className="flex flex-col items-center gap-4">
			<LoadingSpinner />
			<p className="font-bold text-xs text-gray-400 select-none">
				LADE DATEN
				{undefined !== pcent && (
					<span className="ml-1 text-xs font-medium text-gray-400">{Math.round(pcent)}%</span>
				)}
			</p>
			{undefined !== pcent && (
				<>
					<div className="w-20 overflow-hidden rounded-full bg-gray-200">
						<div
							className="h-2 rounded-full bg-primary-500 transition-all duration-200 ease-in-out"
							style={{ width: `${pcent}%` }}
						/>
					</div>
				</>
			)}
		</div>
	</section>
);

export default LoadingPage;
