import clsx from "clsx";
import { PropsWithChildren } from "react";
import {
	CardColorVariants,
	CardPaddingVariants,
	CardShadowVariants,
	cardVariants,
} from "~/components/Card/cardVariants.ts";

type CardProps = PropsWithChildren & {
	className?: string;
	color?: CardColorVariants;
	padding?: CardPaddingVariants
	shadow?: CardShadowVariants
};

const Card: React.FunctionComponent<CardProps> = ({
		color, padding, shadow, className, children,
	}) => {
		return <div className={clsx(cardVariants({ color, padding, shadow }), className)}>{children}</div>;
	}
;

export default Card;