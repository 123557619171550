import { useAggregatedData } from "~/hooks/useAggregateData";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAllClients } from "~/modules/client/api/client/clientQueries";
import { useAllCompanies } from "~/modules/client/api/company/companyQueries.ts";
import ClientsIndexView from "~/modules/client/components/ClientsIndexView/ClientsIndexView.tsx";
import { useAllProjects } from "~/modules/project/api/project/projectQueries.ts";
import LoadingPage from "~/pages/LoadingPage";
import { PermissionNames } from "~/types/entityNames.ts";

const ClientsIndexPage: React.FC = () => {
	const guard = userViewGuard(PermissionNames.CanManageProjects);
	if(guard) return guard;

	const { data, options } = useAggregatedData([
		{ hook: () => useAllCompanies(), name: "companies" },
		{ hook: () => useAllProjects(), name: "projects" },
		{ hook: () => useAllClients(), name: "clients" },
	]);

	if (options.loadingPcent < 100) return <LoadingPage pcent={options.loadingPcent} />;
	return <ClientsIndexView {...data} />;
};

export default ClientsIndexPage;
