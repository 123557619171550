import { Menu, Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";

import clsx from "clsx";
import { Fragment } from "react";
import Button from "~/components/form/Button";
import SearchInput from "~/components/form/SearchInput";
import { SwitchPlain } from "~/components/form/Switch/Switch.tsx";
import { WorkingStatus } from "~/components/Status";
import { statusVariants } from "~/components/Status/statusVariants.ts";
import useWorkingStatusOptions from "~/hooks/form/formOptionsData/useWorkingStatusOptions.ts";
import { getWorkingStatusNameById, getWorkingStatusThemeById } from "~/modules/project/utils/projectUtils.ts";

interface SearchAndFilterBarProps {
	displayedProjectsCount: number;
	myProjectsFilter: boolean;
	onChangeMyProjectsFilter: (state: any) => void;
	onChangeSearch: (state: any) => void;
	onChangeStatus: (state: any) => void;
	searchValue: string | undefined;
	setSortOrder: (state: "asc" | "desc") => void;
	sortOrder: string;
	statusFilter: string[];
	totalProjectsCount: number;
}

const SearchAndFilterBar: React.FC<SearchAndFilterBarProps> = ({
	displayedProjectsCount,
	myProjectsFilter,
	onChangeMyProjectsFilter,
	onChangeSearch,
	onChangeStatus,
	searchValue,
	setSortOrder,
	sortOrder,
	statusFilter,
	totalProjectsCount,
}) => {
	const workingStatusOptions = useWorkingStatusOptions();

	return (
		<section aria-labelledby="filter-heading"
				 className="w-full flex flex-grow">
			<div className="w-full">
				<div className="mx-auto flex max-w-7xl items-center gap-x-5">
					<Menu as="div"
						  className="relative inline-block text-left">
						<SearchInput onChange={onChangeSearch}
									 value={searchValue || ""} />
					</Menu>

					<div className="flex items-center">
						<SwitchPlain value={myProjectsFilter}
									 onChange={onChangeMyProjectsFilter}
									 labelOn="Nur meine Projekte"
									 labelOff="Alle Projekte" />
					</div>
					<Button
						className="ml-auto"
						theme="white"
						onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
					>
						{sortOrder === "desc" ? (
							<>
								<span>Neueste zuerst</span>
								<ChevronDownIcon className="w-5 h-5 text-gray-400" />
							</>
						) : (
							<>
								<span>Älteste zuerst</span>
								<ChevronUpIcon className="w-5 h-5 text-gray-400" />

							</>
						)}
					</Button>
					<div className="flow-root">
						<Popover.Group className="-mx-4 flex items-center divide-x divide-gray-200">

							<Popover className="relative inline-block px-4 text-left">
								<Popover.Button
									className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
									<span>Status Filter</span>
									{statusFilter.length > 0 && (
										<span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
														{statusFilter.length}
													</span>
									)}
									<ChevronDownIcon
										className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
										aria-hidden="true"
									/>
								</Popover.Button>

								<Transition
									as={Fragment}
									enter="transition ease-out duration-100"
									enterFrom="transform opacity-0 scale-95"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-95"
								>
									<Popover.Panel
										className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
										<form className="space-y-4">
											{workingStatusOptions.map((option, index) => (
												<div key={option.value}
													 className="flex items-center">
													<input
														id={`statusFilter-${index}`}
														name={`statusFilter[]`}
														defaultValue={option.value}
														type="checkbox"
														defaultChecked={statusFilter.includes(option.value)}
														className="h-4 w-4 rounded border-gray-300 text-accent-600 focus:ring-accent-500 accent-accent-500 cursor-pointer"
														onChange={() => onChangeStatus(option.value)}
													/>
													<label
														htmlFor={`statusFilter-${index}`}
														className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900 cursor-pointer"
													>
														<WorkingStatus id={option.value} />
													</label>
												</div>
											))}
										</form>
									</Popover.Panel>
								</Transition>
							</Popover>
						</Popover.Group>
					</div>
				</div>
			</div>
			{(statusFilter.length > 0 || !!searchValue || myProjectsFilter) && (
				<div className="absolute left-0 w-full bg-gray-100 shadow-xl shadow-gray-100"
					 style={{ top: 73 }}>
					<div className="mx-auto max-w-7xl px-4 py-3 sm:flex sm:items-center sm:px-6 lg:px-8">
						<h3 className="text-sm font-medium text-gray-500">
							Aktive Filter
							<span className="sr-only">, active</span>
						</h3>

						<div aria-hidden="true"
							 className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block" />

						<div className="sm:ml-4 sm:mt-0">
							<div className="-m-1 flex flex-wrap items-center gap-x-1">
								{workingStatusOptions.filter(workingStatus => statusFilter.includes(workingStatus.value)).map((workingStatusOption,
									index) => {
									const theme = getWorkingStatusThemeById(workingStatusOption.value);
									return <span
										key={"statusFilter" + index}
										className={clsx(`inline-flex items-center 
										rounded-full
										py-1.5 pl-3 pr-2 
										text-sm font-medium`, statusVariants({
											theme,
											borderRadius: "full",
										}))}
									>
										{getWorkingStatusNameById(workingStatusOption.value)}
										<button
											type="button"
											className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
											onClick={() => onChangeStatus(workingStatusOption.value)}
										>
											<span className="sr-only">Remove filter for status option</span>
											<svg
												className="h-2 w-2"
												stroke="currentColor"
												fill="none"
												viewBox="0 0 8 8"
											>
												<path strokeLinecap="round"
													  strokeWidth="1.5"
													  d="M1 1l6 6m0-6L1 7" />
											</svg>
										</button>
									</span>;
								})}
								{myProjectsFilter && <span
									key={"myProjects"}
									className="inline-flex items-center rounded-full ring-1 ring-inset ring-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
								>
										<span>Meine Projekte</span>
										<button
											type="button"
											className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
											onClick={() => onChangeMyProjectsFilter(false)}
										>
											<span className="sr-only">Remove filter for Meine Projekte"</span>
											<svg
												className="h-2 w-2"
												stroke="currentColor"
												fill="none"
												viewBox="0 0 8 8"
											>
												<path strokeLinecap="round"
													  strokeWidth="1.5"
													  d="M1 1l6 6m0-6L1 7" />
											</svg>
										</button>
									</span>}

								{searchValue &&
									<span className="inline-flex items-center rounded-full ring-1 ring-inset ring-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
									>
										<span>{searchValue}</span>
										<button
											type="button"
											className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
											onClick={() => onChangeSearch("")}
										>
											<span className="sr-only">Remove filter for {searchValue}</span>
											<svg
												className="h-2 w-2"
												stroke="currentColor"
												fill="none"
												viewBox="0 0 8 8"
											>
												<path strokeLinecap="round"
													  strokeWidth="1.5"
													  d="M1 1l6 6m0-6L1 7" />
											</svg>
										</button>
									</span>
								}
							</div>
						</div>
						<span className="ml-auto text-xs text-gray-400">{displayedProjectsCount} von {totalProjectsCount} Projekten sichtbar</span>
					</div>
				</div>
			)}
		</section>
	);
};

export default SearchAndFilterBar;
