import { yupResolver } from "@hookform/resolvers/yup";
import { isDate } from "date-fns";

import React, { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as yup from "yup";
import FormHasErrorsHint from "~/components/form/FormHasErrorsHint";
import { useAllPermissions } from "~/modules/auth/api/auth/authQueries.ts";
import { updateUser } from "~/modules/user/api/user/userApiDispatchers.ts";

import { ALL_USERS_QUERY_KEY } from "~/modules/user/api/user/userQueries.ts";
import { UpdateUserData, UserWithConfidentialInformation } from "~/modules/user/api/user/userTypes.ts";
import Button from "~/components/form/Button/Button.tsx";
import { FormSectionGroup } from "~/components/form/FormSection";
import SubmitButton from "~/components/form/SubmitButton";
import Headline from "~/components/Headline/Headline.tsx";
import Sidebar from "~/components/Sidebar";
import UserEmploymentInformationFormSection
	from "~/modules/user/components/forms/formSections/UserEmploymentInformationFormSection";
import UserPermissionsFormSection from "~/modules/user/components/forms/formSections/UserPermissionsFormSection";
import UserPersonalInformationFormSection
	from "~/modules/user/components/forms/formSections/UserPersonalInformationFormSection";
import useCareerLevelOptions from "~/hooks/form/formOptionsData/useCareerLevelOptions.ts";
import useCompanyLocationSelectOptions from "~/hooks/form/formOptionsData/useCompanyLocationSelectOptions.ts";
import useEmploymentStatusSelectOptions from "~/hooks/form/formOptionsData/useEmploymentStatusSelectOptions.ts";
import useGenderSelectOptions from "~/hooks/form/formOptionsData/useGenderSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	useUserValidationComparisonData,
} from "~/hooks/form/validationComparisonData/useUserValidationComparisonData.ts";
import { PermissionNames } from "~/types/entityNames.ts";
import { FormDataPropertyObject } from "~/types/form.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type Props = {
	onSuccess: () => void;
	onCancel: () => void;
	userData: UserWithConfidentialInformation;
};

interface UpdateUserFormData
	extends Omit<UpdateUserData, "permissions" | "employmentStatusId" | "careerLevelId" | "locationId" | "dateOfBirth"> {
	careerLevelId: string;
	locationId: string;
	dateOfBirth: Date | null;
	employmentStatusId: string;
	permissions: FormDataPropertyObject;
}

const UpdateUserForm: React.FC<Props> = ({ onSuccess, onCancel, userData }) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);
	const queryClient = useQueryClient();

	const { emailComparisonData } = useUserValidationComparisonData();

	const schema = useMemo(() => {
		return yup.object({
			careerLevelId: yup.string().required(),
			locationId: yup.string().required(),
			dateOfBirth: yup.date().nullable().default(null),
			email: yup.string().email().required().uniqueEmail(emailComparisonData, userData.id),
			employmentStatusId: yup.string().required(),
			firstName: yup.string().required(),
			genderId: yup.string().required(),
			jobDescription: yup.string().default(""),
			lastName: yup.string().required(),
			nickname: yup.string().default(""),
			phone: yup.string().default(""),
			permissions: yup.object(),
			shortName: yup.string().required(),
		});
	}, [emailComparisonData, userData]);

	const { data: allPermissionsData } = useAllPermissions();

	const permissions = useMemo(() => {
		if (allPermissionsData) {
			return allPermissionsData.map((permission) => {
				return permission.name;
			});
		}
	}, [allPermissionsData]);

	const defaultValues = useMemo(() => {
		const permissions: FormDataPropertyObject = {};
		if (allPermissionsData) {
			allPermissionsData.forEach((permission) => {
				const userHasPermission = userData.permissions.find(userPermission => userPermission.name === permission.name);
				permissions[permission.name] = userHasPermission !== undefined;
			});

			const dateOfBirth = userData.dateOfBirth ? new Date(userData.dateOfBirth) : null;

			return {
				careerLevelId: userData.careerLevelId || "",
				locationId: userData.locationId || "",
				dateOfBirth: dateOfBirth,
				email: userData.email,
				employmentStatusId: userData.employmentStatusId || "",
				firstName: userData.firstName,
				genderId: userData.genderId,
				isActive: userData.isActive,
				jobDescription: userData.jobDescription,
				lastName: userData.lastName,
				nickname: userData.nickname,
				phone: userData.phone,
				permissions,
				shortName: userData.shortName,
			};
		}
		return {};
	}, [userData, allPermissionsData]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isValid, isSubmitted },
		trigger
	} = useForm<UpdateUserFormData>({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver<UpdateUserFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isDirty,
		isSubmitted,
		isValid,
		isLoading: busy,
		enableInitially: false,
	});

	useEffect(() => {
		trigger();
	}, []);

	const careerLevelSelectOptions = useCareerLevelOptions();
	const companyLocationSelectOptions = useCompanyLocationSelectOptions();
	const genderSelectOptions = useGenderSelectOptions();
	const employmentStatusSelectOptions = useEmploymentStatusSelectOptions();

	const onSubmit: SubmitHandler<UpdateUserFormData> = async (data) => {
		setBusy(true);
		let selectedPermissions: PermissionNames[] = [];

		if (data.permissions) {
			selectedPermissions = Object.keys(data.permissions).filter((key) =>
				data.permissions ? data.permissions[key] : false,
			) as PermissionNames[];
		}

		const dateOfBirth = isDate(data.dateOfBirth) ? formatDateToYYYYMMDD(data.dateOfBirth as Date) : null;

		const updatedUserData = { ...data, permissions: selectedPermissions, dateOfBirth };
		// @ToDo error handling for both requests
		try {
			await updateUser(userData.id, updatedUserData);
			await queryClient.refetchQueries({ queryKey: ALL_USERS_QUERY_KEY });
			onSuccess();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex flex-col justify-start w-full min-h-full"
		>
			<Sidebar.Header>
				<Headline type="h2">User bearbeiten</Headline>
			</Sidebar.Header>
			<Sidebar.Content>
				{busy && <Sidebar.BusyOverlay />}
				<FormSectionGroup>
					<UserPersonalInformationFormSection<UpdateUserFormData>
						control={control}
						genderSelectOptions={genderSelectOptions}
					/>
					<UserEmploymentInformationFormSection<UpdateUserFormData>
						control={control}
						employmentStatusSelectOptions={employmentStatusSelectOptions}
						companyLocationSelectOptions={companyLocationSelectOptions}
						careerLevelSelectOptions={careerLevelSelectOptions}
					>
					</UserEmploymentInformationFormSection>
					<UserPermissionsFormSection<UpdateUserFormData> control={control} permissions={permissions} />
				</FormSectionGroup>
			</Sidebar.Content>
			<Sidebar.Footer>
				<FormHasErrorsHint show={!isValid} className="mr-2"/>
				<SubmitButton busy={busy} disabled={!isDirty || !formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="none" onClick={onCancel}>
					abbrechen
				</Button>
			</Sidebar.Footer>
		</form>
	);
};

export default UpdateUserForm;
