
import axiosApi from "~/api/axiosApi";
import { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import {
	getAllTimeTrackingTypesEndpoint
} from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeApiEndpoints.ts";
import { TimeTrackingTypeType } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeTypes.ts";


export const getAllTimeTrackingTypes = (): AxiosApiResponsePromise<TimeTrackingTypeType[]> => axiosApi.get(getAllTimeTrackingTypesEndpoint);
