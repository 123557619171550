import { CLIENT_CONTACT_PERSONS_BY_CLIENT_QUERY_KEY } from "~/modules/client/api/clientContactPerson/clientContactPersonQueries.ts";
import CreateClientContactPersonForm from "~/modules/client/components/contact/forms/CreateClientContactPersonForm";
import Sidebar from "~/components/Sidebar";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

type AddContactPersonToClientSidebarProps = {
	isOpen: boolean;
	setOpen: (open: boolean) => void;
	clientId: string;
	companyId: string;
};

const AddContactPersonToClientSidebar: React.FunctionComponent<AddContactPersonToClientSidebarProps> = ({
	isOpen,
	setOpen,
	clientId,
	companyId,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(() => {
		queryClient.invalidateQueries(CLIENT_CONTACT_PERSONS_BY_CLIENT_QUERY_KEY(clientId));
		setOpen(false);
	}, [queryClient, clientId]);

	return (
		<Sidebar closeOnOutsideClick={false} open={isOpen} setOpen={() => setOpen(!isOpen)}>
			<CreateClientContactPersonForm
				clientId={clientId}
				companyId={companyId}
				onSuccess={handleSuccess}
				onCancel={() => setOpen(false)}
			/>
		</Sidebar>
	);
};

export default AddContactPersonToClientSidebar;
