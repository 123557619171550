import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { QueriesOptions, useQuery } from "react-query";
import { getAllSalesTypes } from "~/modules/client/api/salesType/salesTypeApiDispatchers.ts";

const ENTITY_QUERY_KEY = "salesType";

export const ALL_SALES_TYPES_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useAllSalesTypes = (options?: QueriesOptions<any>) => useQuery(
	ALL_SALES_TYPES_QUERY_KEY,
	() => getAllSalesTypes().then(getDataFromResponse),
	options,
);