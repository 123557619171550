import {
	getAllPermissionsEndpoint,
	getAllRolesEndpoint,
	getAuthenticatedUserEndpoint,
	loginEndpoint,
	logoutEndpoint,
	refreshEndpoint,
} from "~/modules/auth/api/auth/authApiEndpoints.ts";
import axiosApi, { axiosApiNoInterceptors } from "~/api/axiosApi.ts";
import { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import { AuthenticatedUser } from "~/modules/user/api/user/userTypes.ts";


export const getAuthenticatedUser = (): AxiosApiResponsePromise<AuthenticatedUser> => axiosApi.get(getAuthenticatedUserEndpoint);

export const login = (data: LoginData): AxiosApiResponsePromise<TokenResponse> => axiosApiNoInterceptors.post(loginEndpoint, { ...data });

export const logout = (): AxiosApiResponsePromise<void> => axiosApi.delete(logoutEndpoint);

export const refreshToken = (data: RefreshData): AxiosApiResponsePromise<TokenResponse> => axiosApi.post(refreshEndpoint, { ...data });


export function getAllRoles(): AxiosApiResponsePromise<Role[]> {
	return axiosApi.get(getAllRolesEndpoint);
}

export function getAllPermissions(): AxiosApiResponsePromise<Permission[]> {
	return axiosApi.get(getAllPermissionsEndpoint);
}

export function getAccessTokenForUser(userId: string): AxiosApiResponsePromise<TokenResponse> {
	return axiosApi.get(`admin/impersonate/${userId}`);
}
