import { useState } from "react";
import Button from "~/components/form/Button";
import { SelectPlain } from "~/components/form/Select/Select.tsx";
import { useAuth } from "~/contexts/AuthContext";
import useUserSelectOptions from "~/hooks/form/formOptionsData/useUsersSelectOptions.ts";

type ImpersonationWidgetProps = {};

const ImpersonationWidget: React.FunctionComponent<ImpersonationWidgetProps> = ({}) => {
	const { user, isImpersonating, impersonateUser, stopImpersonating } = useAuth();
	const userSelectOptions = useUserSelectOptions();
	const [selectedUserId, setSelectedUserId] = useState(user?.id);

	const handleImpersonate = async () => {
		if (selectedUserId) {
			await impersonateUser(selectedUserId);
		}
	};

	return <div className="">
		{!isImpersonating ? <><Button onClick={handleImpersonate}>Impersonate</Button>
			<SelectPlain value={selectedUserId} optionsData={userSelectOptions} onChange={(value) => {
				setSelectedUserId(value);
			}}></SelectPlain></> : <Button onClick={stopImpersonating}>Stop Impersonating</Button>}
	</div>;
};

export default ImpersonationWidget;