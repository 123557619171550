import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { QueriesOptions, useQuery } from "react-query";
import { getAllCountries } from "~/modules/country/api/country/countryApiDispatchers.ts";

const ENTITY_QUERY_KEY = "country";

export const ALL_COUNTRIES_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useAllCountries= (options?: QueriesOptions<any>) => useQuery(
	ALL_COUNTRIES_QUERY_KEY,
	() => getAllCountries().then(getDataFromResponse),
	options,
);