import { yupResolver } from "@hookform/resolvers/yup";
import React, { useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import ButtonNewItem from "~/components/buttons/ButtonNewItem";

import Button from "~/components/form/Button/Button.tsx";
import ComboBox from "~/components/form/ComboBox";

import FormSection, { FormSectionGroup } from "~/components/form/FormSection";
import SubmitButton from "~/components/form/SubmitButton";
import Switch from "~/components/form/Switch";
import Headline from "~/components/Headline";
import Sidebar from "~/components/Sidebar";
import useProjectsProjectRolesOptions from "~/hooks/form/formOptionsData/useProjectsProjectRolesOptions.ts";
import useUserSelectOptions, { staffableUsersFilterFn } from "~/hooks/form/formOptionsData/useUsersSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { createStaffing } from "~/modules/project/api/staffing/staffingApiDispatchers.ts";
import { useProjectsStaffings } from "~/modules/project/api/staffing/staffingQueries.ts";
import { CreateStaffingData } from "~/modules/project/api/staffing/staffingTypes.ts";
import StaffingBudgetFormSection from "~/modules/project/components/forms/formSections/StaffingBudgetFormSection";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type CreateStaffingFormProps = {
	onSuccess: (staffedUserId: string) => void;
	onCancel: () => void;
	onCreateUserClick: () => void;
	orderId: string;
	projectId: string;
	projectPhaseId: string;
	selectedUserId: string | null;
};

interface CreateStaffingFormData
	extends Omit<CreateStaffingData, "orderId" | "projectId" | "projectPhaseId"> {
}

const CreateStaffingForm: React.FC<CreateStaffingFormProps> = ({
	onSuccess,
	onCancel,
	orderId,
	onCreateUserClick,
	projectId,
	projectPhaseId,
	selectedUserId,
}) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);

	const schema = useMemo(() => {
		return yup.object({
			dailyRateCents: yup.number().required(),
			isActive: yup.boolean().required(),
			manDays: yup.number().required(),
			projectRoleName: yup.string().required(),
			userId: yup.string().required(),
		});
	}, []);

	const defaultValues = useMemo(() => {
		return {
			dailyRateCents: 0,
			manDays: 0,
			isActive: false,
			projectRoleName: "",
			userId: selectedUserId || "",
		};
	}, [selectedUserId]);

	const {
		handleSubmit,
		control,
		formState: { errors, isValid, isDirty, isSubmitted },
		watch,
	} = useForm<CreateStaffingFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<CreateStaffingFormData>(schema),
	});

	const userSelectOptions = useUserSelectOptions(staffableUsersFilterFn);
	const projectRoleSelectOptions = useProjectsProjectRolesOptions({ projectId });
	const formIsSubmittable = useFormIsSubmittable({
		isValid,
		isSubmitted,
		isLoading: busy,
		isDirty,
	});

	const { data: projectsStaffingsData } = useProjectsStaffings({ projectId });

	const filteredUserSelectOptions = useMemo(() => {
		if (projectsStaffingsData) {
			const assignedUserIds = projectsStaffingsData
				.filter(staffing => staffing.orderId === orderId)
				.map((staffing) => staffing.userId);

			return userSelectOptions.filter((user) => !assignedUserIds.includes(user.value));
		}
		return userSelectOptions;
	}, [projectsStaffingsData, projectRoleSelectOptions, userSelectOptions, orderId]);
	const onSubmit: SubmitHandler<CreateStaffingFormData> = async (data: CreateStaffingFormData) => {

		setBusy(true);
		try {

			const staffingData = {
				...data,
				projectId: projectId,
				projectPhaseId: projectPhaseId,
				orderId: orderId,
			};
			await createStaffing({ projectId, staffingData });
			onSuccess(data.userId);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex flex-col justify-start w-full min-h-full"
		>
			<Sidebar.Header>
				<Headline type="h2">Neues Staffing erstellen</Headline>
			</Sidebar.Header>
			<Sidebar.Content>
				{busy && <Sidebar.BusyOverlay />}

				<FormSectionGroup>
					<FormSection title="Projektmitarbeiter:in">
						<div className="grid gap-x-6 gap-y-2 grid-cols-6">
							<div className="col-span-6">
								<ComboBox
									placeholder="Mitarbeiter:in auswählen"
									allowNew={false}
									name={"userId"}
									control={control}
									optionsData={filteredUserSelectOptions}
								/>
							</div>
							<div className="col-span-6">
								{!selectedUserId && <ButtonNewItem size="sm"
																   theme="inline"
																   onClick={onCreateUserClick}>Nord.Work User
									hinzufügen</ButtonNewItem>}
							</div>
						</div>
					</FormSection>
					<FormSection title="Zeiterfassung">
						<div className="grid gap-x-6 gap-y-2 grid-cols-6">
							<div className="col-span-6">
								<Switch name={"isActive"}
										control={control}
										labelOn={"Aktiv"}
										labelOff={"Inaktiv"} />
							</div>
						</div>
					</FormSection>
					<FormSection title="Rolle im Projekt">
						<div className="grid gap-x-6 gap-y-2 grid-cols-6">
							<div className="col-span-6">
								<ComboBox
									placeholder="Name der Rolle"
									name={"projectRoleName"}
									control={control}
									optionsData={projectRoleSelectOptions}
								/>
							</div>
						</div>
					</FormSection>
					<StaffingBudgetFormSection control={control}
											   errors={errors}
											   watch={watch} />
				</FormSectionGroup>
			</Sidebar.Content>
			<Sidebar.Footer>
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					abbrechen
				</Button>
			</Sidebar.Footer>
		</form>
	);
};

export default CreateStaffingForm;
