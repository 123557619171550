import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as yup from "yup";
import Button from "~/components/form/Button";
import SubmitButton from "~/components/form/SubmitButton";
import Headline from "~/components/Headline";
import Sidebar from "~/components/Sidebar/Sidebar.tsx";
import { LocationType, UserAvailableLocationType } from "~/modules/location/api/location/locationTypes.ts";
import { PROJECT_BASE_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import { SelectedStaffingDataType, UsersActiveStaffingType } from "~/modules/project/api/staffing/staffingTypes.ts";
import { UserAvailableDeliverableType } from "~/modules/timeTracking/api/deliverable/deliverableTypes.ts";
import {
	createTimeTracking,
	updateTimeTracking,
} from "~/modules/timeTracking/api/timeTracking/timeTrackingApiDispatchers.ts";
import { TIME_TRACKINGS_BY_USER_QUERY_KEY } from "~/modules/timeTracking/api/timeTracking/timeTrackingQueries.ts";
import { TimeTrackingFormData } from "~/modules/timeTracking/api/timeTracking/timeTrackingTypes.ts";
import {
	createTimeTrackingFormDefaultValues,
} from "~/modules/timeTracking/components/forms/CreateTimeTrackingForm/createTimeTrackingFormDefaultValues.ts";
import TimeTrackingFormSection from "~/modules/timeTracking/components/forms/formSections/TimeTrackingFormSection";
import { TimeTrackingFormNamesEnum } from "~/modules/timeTracking/types/timeTrackingTypes.ts";
import { getStartAndEndOfMonth } from "~/modules/timeTracking/utils/timeTrackingUtils.ts";
import { UserType } from "~/modules/user/api/user/userTypes.ts";
import { TimeTrackingTypeId } from "~/types/entityIds.ts";
import { formatDateToYYYYMMDD, formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type CreateTimeTrackingFormProps = {
	activeStaffings: UsersActiveStaffingType[];
	availableDeliverables: UserAvailableDeliverableType[];
	availableLocations: UserAvailableLocationType[];
	date: Date | null,
	initialValues: TimeTrackingFormData;
	timeTrackingId?: string;
	locations: LocationType[];
	parentIsVisible: boolean;
	onSuccess: () => void;
	setCurrentTimeTrackingFormValues: (values: TimeTrackingFormData) => void;
	selectedStaffingData: SelectedStaffingDataType | null;
	selectedStaffingId: string | null;
	selectedTimeTrackingTypeId: string;
	selectedTimeTrackingTypeName: string;
	selectedUserId: UserType["id"];
	setCurrentFormName: (formId: TimeTrackingFormNamesEnum | null) => void;
	users: UserType[];
};

const minutesMinMaxErrorMessage = "Bitte einen Wert zwischen 0 und 59 eingeben";
const hoursMinMaxErrorMessage = "Bitte einen Wert zwischen 0 und 24 eingeben";

const schema = yup.object({
	hours: yup
		.number()
		.min(0, hoursMinMaxErrorMessage)
		.max(24, hoursMinMaxErrorMessage)
		.required()
		.test("minTime", "Bitte gib einen Wert ein.", function(value) {
			return !(value === 0 && this.parent.minutes === 0);
		}),
	minutes: yup.number().min(0, minutesMinMaxErrorMessage).max(59, minutesMinMaxErrorMessage).required(),
	locationName: yup.string().required(),
	text: yup.string().required(),
});

const CreateOrUpdateTimeTrackingForm: React.FunctionComponent<CreateTimeTrackingFormProps> = ({
	availableDeliverables,
	availableLocations,
	date,
	initialValues,
	timeTrackingId,
	locations,
	onSuccess,
	parentIsVisible,
	setCurrentTimeTrackingFormValues,
	selectedStaffingData,
	selectedStaffingId,
	selectedTimeTrackingTypeId,
	selectedTimeTrackingTypeName,
	selectedUserId,
	setCurrentFormName,
	users,
}) => {

	const queryClient = useQueryClient();
	const [isBusy, setIsBusy] = useState(false);

	const isUpdateForm = timeTrackingId !== undefined;
	const defaultValues = useMemo(() => {
		return initialValues;
	}, []);

	useEffect(() => {
		if (!parentIsVisible) {
			reset(createTimeTrackingFormDefaultValues);
		}
	}, [parentIsVisible]);


	useEffect(() => {
		reset(initialValues);
	}, [initialValues, parentIsVisible]);

	const {
		handleSubmit,
		control,
		watch,
		setValue,
		getValues,
		formState: { errors, isValid, isSubmitted },
		trigger,
		reset,
	} = useForm<TimeTrackingFormData>({
		defaultValues,
		mode: "onChange",
		resolver: yupResolver<TimeTrackingFormData>(schema),
	});

	const onSubmit: SubmitHandler<TimeTrackingFormData> = async (data) => {
		setIsBusy(true);
		const timeTrackingDate = formatDateToYYYYMMDD(date!!);
		const timeTrackingBaseData = {
			locationName: data.locationName,
			staffingId: selectedStaffingId,
			timeTrackingTypeId: selectedTimeTrackingTypeId!,
			minutes: data.hours * 60 + data.minutes,
			userId: selectedUserId,
			text: data.text,
		};

		try {

			if (!isUpdateForm) {
				const createTimeTrackingData = {
					...timeTrackingBaseData,
					date: timeTrackingDate,
				};

				await createTimeTracking(selectedUserId, createTimeTrackingData);
			} else {


				await updateTimeTracking({ userId: selectedUserId, timeTrackingId, data: timeTrackingBaseData });
			}


			const timeTrackingFilterData = getStartAndEndOfMonth(new Date(timeTrackingDate));
			await queryClient.refetchQueries(TIME_TRACKINGS_BY_USER_QUERY_KEY(selectedUserId, timeTrackingFilterData));
			queryClient.invalidateQueries([PROJECT_BASE_QUERY_KEY]);
			onSuccess();
			reset();
		} catch (error) {
			console.log(error);
		}

		setIsBusy(false);
	};

	const handleFormSwitch = useCallback((newFormId: TimeTrackingFormNamesEnum | null) => {
		setCurrentTimeTrackingFormValues(getValues());
		setCurrentFormName(newFormId);
	}, []);

	const dayName = date ? date.toLocaleDateString("de-DE", { weekday: "short" }) : "";
	const formattedDate = date ? `${dayName}, ${formatDateWithGermanMonth(date, false)}` : "";

	return <>
		<Sidebar.Header>
			<Headline type="h2">
				{selectedTimeTrackingTypeId !== TimeTrackingTypeId.Project ? <span>Typ: {selectedTimeTrackingTypeName}</span> :
					<span>{`${selectedStaffingData?.phaseTitle} |  ${selectedStaffingData?.orderTitle}`}</span>}
			</Headline>
		</Sidebar.Header>
		<div className="block text-right flex-col font-bold text-gray-500 text-xs pr-4 pt-2 ">
			{formattedDate}
		</div>
		<Sidebar.Content>
			<form id={TimeTrackingFormNamesEnum.TIME_TRACKING_FORM}
				  onSubmit={handleSubmit(onSubmit)}
				  onKeyDown={preventSubmitOnEnter}
			>
				<TimeTrackingFormSection
					availableDeliverables={availableDeliverables}
					availableLocations={availableLocations}
					control={control}
					errors={errors}
					isEditForm={false}
					isSubmitted={isSubmitted}
					locations={locations}
					selectedStaffingData={selectedStaffingData}
					selectedTimeTrackingTypeId={selectedTimeTrackingTypeId}
					setCurrentFormName={handleFormSwitch}
					setValue={setValue}
					selectedUserId={selectedUserId}
					trigger={trigger}
					users={users}
					watch={watch} />
			</form>
		</Sidebar.Content>
		<Sidebar.Footer>
			{(!isUpdateForm || selectedTimeTrackingTypeId === TimeTrackingTypeId.Project) && <Button className="mr-auto"
																									 theme="none"
																									 onClick={() => handleFormSwitch(null)}>
				<ChevronLeftIcon className="w-6 h-6" />{isUpdateForm ? "Projektauswahl" : "Zurück"}

			</Button>}
			<SubmitButton formName={TimeTrackingFormNamesEnum.TIME_TRACKING_FORM}
						  busy={isBusy}
						  disabled={!isValid}>
				{!isUpdateForm ? "Speichern" : "Aktualisieren"}
			</SubmitButton>
			<Button theme="none"
					onClick={onSuccess}>
				abbrechen
			</Button>
		</Sidebar.Footer>
	</>;
};

export default CreateOrUpdateTimeTrackingForm;