import { useMemo } from "react";
import {
	createSelectBoxOptionData,
} from "~/utils/form/formUtils.ts";
import { FormInputOption } from "~/types/form.ts";

const useYearSelectOptions = (minYear = DEFAULT_MIN_YEAR_FOR_YEAR_SELECT, maxYear: number): FormInputOption[] => {
	if (!maxYear) {
		throw new Error("maxYear is required");
	}
	return useMemo(() => {
		let options: FormInputOption[] = [];
		while (minYear <= maxYear) {
			options.push(createSelectBoxOptionData(minYear.toString(), minYear.toString()));
			minYear++;
		}
		return options;

	}, []);
};

export default useYearSelectOptions;

