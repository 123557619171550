import { QueriesOptions, useQuery } from "react-query";
import { indexUsersAbsences } from "~/modules/absence/api/absence/absenceApiDispatchers.ts";
import { getDataFromResponse } from "~/api/axiosUtils.ts";

export const ABSENCE_BASE_QUERY_KEY = "absences";

export const USERS_ABSENCES_QUERY_KEY = (userId: string) => [ABSENCE_BASE_QUERY_KEY, { userId }];

export const useUsersAbsences = ({ userId, options }: { userId: string, options?: QueriesOptions<any> }) => useQuery(
	USERS_ABSENCES_QUERY_KEY(userId),
	() => indexUsersAbsences({ userId }).then(getDataFromResponse),
	options,
);


