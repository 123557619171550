import { EnvelopeIcon, PencilIcon, PhoneIcon, TrashIcon } from "@heroicons/react/20/solid";
import Card from "~/components/Card";
import FormInput from "~/components/form/FormInput";
import Label from "~/components/form/Label";
import MailTo from "~/components/MailTo";
import HeroSection, { HeroSectionHighlights, heroSectionBackgroundColor } from "~/components/sections/HeroSection";

import Avatar from "~/components/Avatar";
import BreadcrumbsPortal from "~/components/Breadcrumbs";
import Button from "~/components/form/Button";
import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import { ClientContactPersonType } from "~/modules/client/api/clientContactPerson/clientContactPersonTypes.ts";
import ContentWrapper from "~/components/ContentWrapper";
import Headline from "~/components/Headline";
import MapDisplay from "~/components/displays/MapDisplay";
import Stats from "~/components/Stats";
import UpdateContactPersonSidebar from "~/modules/client/components/contact/components/UpdateContactSidebar";
import { appRoutes } from "~/constants/appRoute.ts";
import { useState } from "react";
import DeleteClientContactPersonModal from "~/modules/client/components/DeleteClientContactPersonModal";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";

interface Props {
	clientContactPersonData: ClientContactPersonType;
	additionalData: {
		clientDisplayName: string;
		genderName: string;
		languageDisplayName: string;
		salesFunnelDisplayName: string;
		salesTypeDisplayName: string;
	};
}

const ContactDetailsView: React.FC<Props> = ({ clientContactPersonData, additionalData }) => {
	const {
		id,
		city,
		clientId,
		countryId,
		dateOfBirth,
		emailBusiness,
		emailPrivate,
		firstName,
		houseNumber,
		jobTitle,
		lastName,
		//managedBy,
		mobilePhone,
		nobilityTitle,
		phone,
		street,
		title,
		useInformalSalutation,
		zipCode,
	} = clientContactPersonData;

	const { clientDisplayName, genderName, languageDisplayName, salesFunnelDisplayName, salesTypeDisplayName } =
		additionalData;

	const [showUpdateForm, setShowUpdateForm] = useState(false);
	const [showDeletionModal, setShowDeletionModal] = useState(false);
	const showMap = !!street && !!houseNumber && !!zipCode && !!city && countryId;

	return (
		<>
			<BreadcrumbsPortal
				className={heroSectionBackgroundColor}
				pages={[
					appRoutes.contacts(),
					{
						path: appRoutes.contact().path,
						displayName: `${firstName} ${lastName}`,
					},
				]}
			/>

			<HeroSection>
				<div className="full-w md:flex md:items-center md:justify-between md:space-x-5 gap-2">
					<div className="flex items-center space-x-5">
						<div className="flex-shrink-0">
							<Avatar firstName={firstName}
									lastName={lastName}
									width="w-20"
									height="h-20" />
						</div>
						<div>
							<Headline type="h1"
									  color="text-primary-500">
								{title} {nobilityTitle} {firstName} {lastName}
							</Headline>
							<p className="text-sm font-medium text-primary-500 text-opacity-50">{jobTitle}</p>
						</div>
					</div>
					<div className="flex justify-between gap-2">
						{emailBusiness && (
							<Button
								theme="secondary"
								onClick={() => (window.location.href = `mailto:${emailBusiness}`)}
							>
								<EnvelopeIcon className="h-5 w-5 text-current"
											  aria-hidden="true" />
								Email (geschäftlich)
							</Button>
						)}
						{phone && (
							<Button theme="secondary"
									onClick={() => (window.location.href = `tel:${phone}`)}>
								<PhoneIcon className="h-5 w-5 text-current"
										   aria-hidden="true" />
								Anrufen
							</Button>
						)}
						<ButtonWithPopover
							theme="dark"
							items={[
								{
									label: "bearbeiten",
									onClick: () => setShowUpdateForm(true),
									icon: PencilIcon,
								},
								{
									label: "löschen",
									onClick: () => setShowDeletionModal(true),
									icon: TrashIcon,
								},
							]}
						/>
					</div>
				</div>
			</HeroSection>
			<HeroSectionHighlights>
				<Stats
					fields={[
						{ label: "Unternehmen", element: clientDisplayName },
						{ label: "Sales Funnel", element: salesFunnelDisplayName },
						{ label: "Sales Type", element: salesTypeDisplayName },
						{ label: "Sprache", element: languageDisplayName },
						{ label: "Ansprache", element: useInformalSalutation ? "Du" : "Sie" },

					]}
				/>
			</HeroSectionHighlights>

			<div className="flex flex-col gap-y-8">
				<ContentWrapper className="w-full grid grid-cols-2 gap-x-2">
					<Card className="w-full">
						<div className="grid gap-x-4 gap-y-4 grid-cols-2">
							{[
								{
									label: "Email geschäftlich",
									value: emailBusiness ? <MailTo to={emailBusiness} /> : "n/a",
								},
								{ label: "Email privat", value: emailPrivate ? <MailTo to={emailPrivate} /> : "n/a" },
								{ label: "Telefon", value: phone || "n/a" },
								{ label: "Mobil", value: mobilePhone || "n/a" },
								{
									label: "Geburtstag",
									value: dateOfBirth ? formatDateWithGermanMonth(new Date(dateOfBirth)) : "n/a",
								},
								{ label: "Geschlecht", value: genderName || "n/a" },
							].map(({ label, value }, index) => (
								<FormInput key={`contacts-${index}`}
									 className="sm:col-span-1">
									<Label>{label}</Label>
									<div className="text-sm font-bold text-gray-900">{value}</div>
								</FormInput>
							))}
						</div>
					</Card>
					<Card>
						<div className="text-sm font-medium text-gray-500">Addresse</div>
						{showMap ? <MapDisplay address={{ street, houseNumber, zipCode, city, countryId }} /> : "n/a"}
					</Card>
				</ContentWrapper>
			</div>

			<UpdateContactPersonSidebar
				isOpen={showUpdateForm}
				setOpen={() => setShowUpdateForm(false)}
				contactPersonData={clientContactPersonData}
			/>

			<DeleteClientContactPersonModal clientId={clientId}
											clientContactPersonId={id}
											isOpen={showDeletionModal}
											onClose={() => setShowDeletionModal(false)} />


		</>
	);
};

export default ContactDetailsView;
