import { yupResolver } from "@hookform/resolvers/yup";
import { isDate } from "date-fns";
import React, { useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as yup from "yup";
import { getDataFromResponse } from "~/api/axiosUtils.ts";
import Button from "~/components/form/Button";
import FormHasErrorsHint from "~/components/form/FormHasErrorsHint";
import FormSection, { FormSectionGroup } from "~/components/form/FormSection";
import SubmitButton from "~/components/form/SubmitButton";
import Switch from "~/components/form/Switch";
import Headline from "~/components/Headline/Headline.tsx";
import Hint from "~/components/Hint";
import Sidebar from "~/components/Sidebar";
import { COMPANY_LOCATION_DEFAULT_ID } from "~/constants/form.ts";
import useCareerLevelOptions from "~/hooks/form/formOptionsData/useCareerLevelOptions.ts";
import useCompanyLocationSelectOptions from "~/hooks/form/formOptionsData/useCompanyLocationSelectOptions.ts";
import useEmploymentStatusSelectOptions from "~/hooks/form/formOptionsData/useEmploymentStatusSelectOptions.ts";
import useGenderSelectOptions from "~/hooks/form/formOptionsData/useGenderSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	useUserValidationComparisonData,
} from "~/hooks/form/validationComparisonData/useUserValidationComparisonData.ts";
import { useAllPermissions } from "~/modules/auth/api/auth/authQueries.ts";
import { createUser, sendUserInvitation } from "~/modules/user/api/user/userApiDispatchers.ts";

import { ALL_USERS_QUERY_KEY } from "~/modules/user/api/user/userQueries.ts";

import { CreateUserData, UserWithConfidentialInformation } from "~/modules/user/api/user/userTypes.ts";
import UserEmploymentInformationFormSection
	from "~/modules/user/components/forms/formSections/UserEmploymentInformationFormSection";
import UserPermissionsFormSection from "~/modules/user/components/forms/formSections/UserPermissionsFormSection";
import UserPersonalInformationFormSection
	from "~/modules/user/components/forms/formSections/UserPersonalInformationFormSection";
import { PermissionNames } from "~/types/entityNames.ts";
import { FormDataPropertyObject } from "~/types/form.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type Props = {
	isAdminMode?: boolean;
	onSuccess: (newUserData: UserWithConfidentialInformation) => void;
	onCancel: () => void;
};

interface CreateUserFormData
	extends Omit<CreateUserData, "permissions" | "employmentStatusId" | "careerLevelId" | "locationId" | "dateOfBirth" > {
	isActive: boolean;
	dateOfBirth: Date | null;
	careerLevelId: string;
	locationId: string;
	employmentStatusId: string;
	permissions: FormDataPropertyObject;

}

const CreateUserForm: React.FC<Props> = ({ isAdminMode, onSuccess, onCancel }) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);
	const queryClient = useQueryClient();

	const { emailComparisonData } = useUserValidationComparisonData();

	const schema = useMemo(() => {
		return yup.object({
			careerLevelId: yup.string().required(),
			locationId: yup.string().required(),
			dateOfBirth: yup.date().nullable().default(null),
			email: yup.string()
				.email()
				.required()
				.uniqueEmail(emailComparisonData),
			employmentStatusId: yup.string().required(),
			firstName: yup.string().required(),
			genderId: yup.string().required(),
			isActive: yup.boolean().required(),
			jobDescription: yup.string().default(""),
			lastName: yup.string().required(),
			nickname: yup.string().default(""),
			phone: yup.string().default(""),
			permissions: yup.object(),
			shortName: yup.string().required().default(""),
		});
	}, [emailComparisonData]);

	const { data: allPermissionsData } = useAllPermissions();

	const permissions = useMemo(() => {
		if (allPermissionsData) {
			return allPermissionsData.map((permission) => {
				return permission.name;
			});
		}
	}, [allPermissionsData]);

	const defaultValues = useMemo(() => {
		return {
			careerLevelId: "",
			locationId: COMPANY_LOCATION_DEFAULT_ID,
			dateOfBirth: null,
			email: "",
			employmentStatusId: "",
			firstName: "",
			genderId: "",
			isActive: true,
			jobDescription: "",
			lastName: "",
			nickname: "",
			phone: "",
			permissions: permissions
				? permissions.reduce((values, permission) => ({ ...values, [permission]: false }), {})
				: {},
		};
	}, [permissions]);


	const {
		handleSubmit,
		control,
		watch,
		formState: { isDirty, isValid, isSubmitted },
	} = useForm<CreateUserFormData>({
		defaultValues,
		mode: "onSubmit",
		resolver: yupResolver<CreateUserFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({ isDirty, isSubmitted, isValid, isLoading: busy });

	const careerLevelSelectOptions = useCareerLevelOptions();
	const companyLocationSelectOptions = useCompanyLocationSelectOptions();
	const genderSelectOptions = useGenderSelectOptions();
	const employmentStatusSelectOptions = useEmploymentStatusSelectOptions(!isAdminMode);

	const onSubmit: SubmitHandler<CreateUserFormData> = async (data) => {
		setBusy(true);
		let selectedPermissions: PermissionNames[] = [];

		if (data.permissions) {
			selectedPermissions = Object.keys(data.permissions).filter((key) =>
				data.permissions ? data.permissions[key] : false,
			) as PermissionNames[];
		}

		const dateOfBirth = isDate(data.dateOfBirth) ? formatDateToYYYYMMDD(data.dateOfBirth as Date) : null;

		const newUserData = { ...data, permissions: selectedPermissions, dateOfBirth };

		try {

			if (!isAdminMode) {
				newUserData.isActive = false;
				newUserData.permissions = [];
			}
			const newUserResponse = await createUser(newUserData);
			const userData = getDataFromResponse(newUserResponse);
			await queryClient.invalidateQueries({ queryKey: ALL_USERS_QUERY_KEY });

			if (isAdminMode && userData.isActive) {
				try {
					await sendUserInvitation(userData.id);
				} catch (error) {
					console.log(error);
				}
			}
			onSuccess(userData);
		} catch (error) {
			console.log(error);
		}
	};
	const isActive = watch("isActive");

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex flex-col justify-start w-full min-h-full"
		>
			<Sidebar.Header>
				<Headline type="h2">{isAdminMode ? "Neuen User anlegen" : "Neuen inaktiven User anlegen"}</Headline>
			</Sidebar.Header>
			<Sidebar.Content>
				{busy && <Sidebar.BusyOverlay />}
				<FormSectionGroup>
					{!isAdminMode &&
						<FormSection title="Hinweis">
							<Hint size="sm">
								<p>Es wird ein inaktiver User angelegt, der vorerst keinen Zugriff auf Nord.Work hat. Er
									/ sie kann jedoch einem Staffing zugeordnet werden.</p>
								<p>Die Aktivierung kann nur durch einen User mit Admin-Rechten vorgenommen
									werden.</p>
							</Hint>
						</FormSection>}
					<UserPersonalInformationFormSection<CreateUserFormData>
						control={control}
						genderSelectOptions={genderSelectOptions}
					/>
					<UserEmploymentInformationFormSection<CreateUserFormData>
						control={control}
						employmentStatusSelectOptions={employmentStatusSelectOptions}
						companyLocationSelectOptions={companyLocationSelectOptions}
						careerLevelSelectOptions={careerLevelSelectOptions}
					/>
					{isAdminMode &&
						<><FormSection title="Nord.Work User Status">
							<Switch name="isActive"
									label={isActive ? "Aktiv" : "Inaktiv"}
									control={control} />
							{isActive && (
								<div className="mt-4">
									<Hint>
										Als "aktiv" angelegte User erhalten automatisch eine Einladungs-E-Mail, mithilfe
										derer sie ihr Passwort festlegen und sich danach anmelden können.
									</Hint>
								</div>
							)}
						</FormSection>
							<UserPermissionsFormSection<CreateUserFormData> control={control}
																			permissions={permissions} />
						</>}
				</FormSectionGroup>
			</Sidebar.Content>
			<Sidebar.Footer>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					abbrechen
				</Button>
			</Sidebar.Footer>
		</form>
	);
};

export default CreateUserForm;
