import { PropsWithChildren } from "react";

type HeaderWithIconProps = PropsWithChildren & {
	icon: React.ReactNode;
};

const HeaderWithIcon: React.FunctionComponent<HeaderWithIconProps> = ({ icon, children }) => {
	return <div className="flex flex-row items-center gap-x-2 mb-3">
		{icon}
		<div className="text-primary-500 font-bold uppercase">{children}</div>
	</div>;
};

export default HeaderWithIcon;