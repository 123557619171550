import { Control, useController } from "react-hook-form";
import React, { ForwardedRef, forwardRef } from "react";
import FormInput from "~/components/form/FormInput";

import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { InputPlain } from "~/components/form/Input/Input.tsx";
import Label from "~/components/form/Label";
import clsx from "clsx";

interface SharedProps
	extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "name" | "onChange" | "onBlur" | "type"> {
	minValue: number;
	maxValue: number;
	className?: string;
	errorIconOnly?: boolean;
	name: string;
	label?: string;
	error?: string;
}

interface CurrencyInputProps extends SharedProps {
	value: number;
	onChange: (value: number) => void;
	onBlur?: () => void;
}

export const RestrictedNumberInputPlain = forwardRef<HTMLInputElement, CurrencyInputProps>(
	(
		{
			className,
			value,
			onBlur,
			onChange,
			label,
			error,
			errorIconOnly,
			name,
			minValue,
			maxValue,
			...props
		}: CurrencyInputProps,
		ref: ForwardedRef<HTMLInputElement>,
	) => {
		function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
			e.target.select(); // Select input value
		}

		function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
			const inputValue = e.target.value;

			// Reject non-digit characters
			const newValue = inputValue.replace(/[^0-9]/g, "");

			if (!newValue) {
				onChange(0);
				return;
			}

			const numericValue = parseInt(newValue);

			// Check against max and min values
			if (isNaN(numericValue) || numericValue < minValue || numericValue > maxValue) {
				onChange(0);
			} else {
				onChange(numericValue);
			}
		}

		function handleBlur() {
			const inputValue = value ? value.toString():"";
			const numericValue = parseInt(inputValue);

			if (numericValue < minValue || numericValue > maxValue) {
				onChange(0);
			}
			onBlur && onBlur();
		}

		return (
			<FormInput className={clsx(props.disabled && "opacity-50")}>
				{label && <Label htmlFor={name}>{label}</Label>}
				<div className="relative rounded-md shadow-sm">
					<InputPlain
						name={name}
						className={className}
						max={maxValue}
						type="number"
						value={value}
						onChange={handleChange}
						onBlur={handleBlur}
						onFocus={handleFocus}
						ref={ref}
						{...props}
					/>
					{error && (
						<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
							<ExclamationCircleIcon className="h-5 w-5 text-red-500"
												   aria-hidden="true" />
						</div>
					)}
				</div>
				{error && !errorIconOnly && (
					<p className="mt-2 text-sm text-red-600"
					   id="email-error">
						{error}
					</p>
				)}
			</FormInput>
		);
	},
);

interface CurrencyInputControllerProps extends SharedProps {
	name: string;
	control: Control<any>;
	defaultValue?: number;
	useControllerError?: boolean;
	rules?: any;
}

const RestrictedNumberInput: React.FC<CurrencyInputControllerProps> = ({
	name,
	control,
	defaultValue = 0,
	useControllerError,
	error,
	rules,
	...rest
}) => {
	const {
		field,
		fieldState: { error: errorFromRHF },
	} = useController({
		name,
		control,
		rules,
	});

	let errorMessage = useControllerError ? errorFromRHF?.message : error;
	return <RestrictedNumberInputPlain {...field} {...rest} error={errorMessage} />;
};

export default RestrictedNumberInput;
