import { Control, Controller } from "react-hook-form";

import { Switch as BaseSwitch } from "@headlessui/react";
import React from "react";
import clsx from "clsx";

type SharedProps = {
	label?: string;
	labelOn?: string;
	labelOff?: string;
	allLabelsVisible?: boolean;
	defaultValue?: boolean;
};

type SwitchPlainProps = SharedProps & {
	name?: string;
	value?: boolean;
	onChange?: (value: boolean) => void;
};

export const SwitchPlain: React.FC<SwitchPlainProps> = React.forwardRef<HTMLButtonElement, SwitchPlainProps>(
	({ name, value, onChange, allLabelsVisible=false, label, labelOn, labelOff }, ref) => {
		return (
			<BaseSwitch.Group as="div" className="flex items-center gap-3">
				{allLabelsVisible && (
					<BaseSwitch.Label
						as="span"
						className={clsx(
							"text-sm font-medium cursor-pointer",
							"transition-color duration-200",
							value ? "text-gray-300" : "text-gray-700",
						)}
					>
						{labelOff}
					</BaseSwitch.Label>
				)}
				<BaseSwitch
					checked={value}
					onChange={onChange}
					name={name}
					ref={ref}
					className={clsx(
						value ? "bg-primary-500" : "bg-gray-200",
						"relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full " +
							"border-2 border-transparent " +
							"transition-colors duration-200 ease-in-out " +
							"focus:outline-none focus:ring-2 focus:ring-offset-2",
					)}
				>
					<span
						aria-hidden="true"
						className={clsx(
							value ? "translate-x-5" : "translate-x-0",
							"pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
						)}
					/>
				</BaseSwitch>
				{allLabelsVisible ? (
					<BaseSwitch.Label
						as="span"
						className={clsx(
							"text-sm font-medium cursor-pointer",
							"transition-color duration-200",
							!value ? "text-gray-300" : "text-gray-700",
						)}
					>
						{labelOn}
					</BaseSwitch.Label>
				) : (
					(label || (labelOn && labelOff)) && (
						<BaseSwitch.Label as="span" className="text-sm font-medium text-gray-700 cursor-pointer">
							{labelOn && labelOff ? (value ? labelOn : labelOff) : label}
						</BaseSwitch.Label>
					)
				)}
			</BaseSwitch.Group>
		);
	},
);

type SwitchProps = SharedProps & {
	name: string;
	control: Control<any>;
};

const Switch: React.FC<SwitchProps> = ({ control, name, ...props }) => {
	return <Controller control={control} name={name} render={({ field }) => <SwitchPlain {...field} {...props} />} />;
};

export default Switch;
