import { Children, PropsWithChildren } from "react";

import clsx from "clsx";

interface TimelineProps {
	theme?: "primary" | "secondary" | "accent";
}

const Timeline: React.FC<PropsWithChildren<TimelineProps>> = ({ children, theme = "primary" }) => (
	<ul role="list" className="space-y-12 mt-6">
		{Children.map(children, (child, i) => (
			<li key={i} className="relative flex gap-x-4">
				<div
					className={clsx("absolute left-0 top-0 flex w-6 justify-center", {
						"-bottom-12": Children.count(children) - 1 > i,
						"-bottom-0": Children.count(children) - 1 === i,
					})}
				>
					<div className="w-px bg-gray-300"></div>
				</div>
				<div className="relative flex h-6 w-6 flex-none items-center justify-center bg-gray-100 mt-5">
					<div
						className={clsx("h-1.5 w-1.5 rounded-full bg-white ring-1", {
							"ring-primary-700": "primary" === theme,
							"ring-secondary-700": "secondary" === theme,
							"ring-accent-700": "accent" === theme,
						})}
					/>
				</div>
				<div className="w-full">{child}</div>
			</li>
		))}
	</ul>
);

export default Timeline;
