import AdminUsersView from "modules/user/components/AdminUsersView";
import React from "react";
import { useAggregatedData } from "~/hooks/useAggregateData";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAllPermissions } from "~/modules/auth/api/auth/authQueries.ts";
import { useAllCompanyLocations } from "~/modules/client/api/companyLocation/companyLocationQueries.ts";
import { useAllLocations } from "~/modules/location/api/location/locationQueries.ts";
import { useAllCareerLevels } from "~/modules/user/api/careerLevel/careerLevelQueries.ts";
import { useAllEmploymentStatuses } from "~/modules/user/api/employmentStatus/employmentStatusQueries.ts";
import { useAllGenders } from "~/modules/user/api/gender/genderQueries.ts";
import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";
import { UserWithConfidentialInformation } from "~/modules/user/api/user/userTypes.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { PermissionNames } from "~/types/entityNames.ts";

type AdminUsersPageProps = {};

const AdminUsersPage: React.FunctionComponent<AdminUsersPageProps> = () => {
	const guard = userViewGuard(PermissionNames.CanManageUsers);
	if(guard) return guard;

	const { data, options } = useAggregatedData([
		{ hook: () => useAllUsers(), name: "users" },
		{ hook: () => useAllCareerLevels(), name: "careerLevels" },
		{ hook: () => useAllCompanyLocations(), name: "companyLocations" },
		{ hook: () => useAllLocations(), name: "locations" },
		{ hook: () => useAllEmploymentStatuses(), name: "employmentStatuses" },
		{ hook: () => useAllGenders(), name: "genders" },
		{ hook: () => useAllPermissions(), name: "permissions" },
	]);

	const { users, careerLevels, employmentStatuses, locations, genders, permissions } = data;

	if (options.loadingPcent < 100) return <LoadingPage pcent={options.loadingPcent} />;

	return (
		<AdminUsersView
			allUsersData={users as UserWithConfidentialInformation[]}
			allCareerLevelsData={careerLevels}
			allLocationsData={locations}
			allEmploymentStatusesData={employmentStatuses}
			allGendersData={genders}
			allPermissionsData={permissions}
		/>
	);
};

export default AdminUsersPage;
