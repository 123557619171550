import Decimal from "decimal.js-light";
import React, { useCallback } from "react";
import { useQueryClient } from "react-query";
import Sidebar from "~/components/Sidebar";
import { USERS_ABSENCES_QUERY_KEY } from "~/modules/absence/api/absence/absenceQueries.ts";
import {
	USERS_VACATION_LEDGER_ENTRIES_QUERY_KEY, USERS_VACATION_LEDGER_REPORT_QUERY_KEY,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import CreateAbsenceForm from "~/modules/absence/components/CreateAbsenceForm";
import useAbsenceTypeOptions from "~/modules/absence/hooks/useAbsenceTypeOptions.tsx";

type CreateAbsenceSidebarProps = {
	onClose: () => void;
	isOpen: boolean;
	remainingVacationDays: Decimal;
	userId: string,
	workingSchedules: WorkingScheduleType[];
};

const CreateAbsenceSidebar: React.FunctionComponent<CreateAbsenceSidebarProps> = ({
	onClose,
	isOpen,
	remainingVacationDays,
	userId,
	workingSchedules,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(
		async () => {
			queryClient.refetchQueries(USERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(userId));
			await queryClient.refetchQueries(USERS_ABSENCES_QUERY_KEY(userId));
			await queryClient.invalidateQueries(USERS_VACATION_LEDGER_REPORT_QUERY_KEY(userId));
			onClose();
		},
		[queryClient],
	);

	const absenceTypeOptions = useAbsenceTypeOptions();

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={onClose}>
			<CreateAbsenceForm
				absenceTypeSelectOptions={absenceTypeOptions}
				onSuccess={handleSuccess}
				remainingVacationDays={remainingVacationDays}
				onCancel={onClose}
				workingSchedules={workingSchedules}
				userId={userId}
			/>
		</Sidebar>
	);
};

export default CreateAbsenceSidebar;
