//@ts-nocheck
import { CurrencyEuroIcon, FolderPlusIcon, HomeIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "~/components/form/Button";
import Headline from "~/components/Headline";
import Sidebar from "~/components/Sidebar";
import { useAuth } from "~/contexts/AuthContext";
import { LocationType, UserAvailableLocationType } from "~/modules/location/api/location/locationTypes.ts";
import { UsersActiveStaffingType } from "~/modules/project/api/staffing/staffingTypes.ts";
import { UserAvailableDeliverableType } from "~/modules/timeTracking/api/deliverable/deliverableTypes.ts";
import { TimeTrackingTypeType } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeTypes.ts";
import Projects from "~/modules/timeTracking/components/components/Projects";
import CreateOrUpdateTimeTrackingForm from "~/modules/timeTracking/components/forms/CreateTimeTrackingForm";
import {
	createTimeTrackingFormDefaultValues,
} from "~/modules/timeTracking/components/forms/CreateTimeTrackingForm/createTimeTrackingFormDefaultValues.ts";
import CreateUserDeliverableForm from "~/modules/timeTracking/components/forms/CreateUserDeliverableForm";
import CreateUserLocationForm from "~/modules/timeTracking/components/forms/CreateUserLocationForm";

import { TimeTrackingFormNamesEnum } from "~/modules/timeTracking/types/timeTrackingTypes.ts";
import { getStaffingDetailsFromActiveStaffings } from "~/modules/timeTracking/utils/timeTrackingUtils.ts";
import { UserType } from "~/modules/user/api/user/userTypes.ts";
import { EmploymentStatusId, TimeTrackingTypeId } from "~/types/entityIds.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";

type CreateTimeTrackingSidebarProps = {
	activeStaffings: UsersActiveStaffingType[];
	availableDeliverables: UserAvailableDeliverableType[];
	availableLocations: UserAvailableLocationType[];
	close: () => void;
	isOpen: boolean;
	date: null | Date,
	locations: LocationType[];
	selectedUserId: UserType["id"];
	timeTrackingTypes: TimeTrackingTypeType[];
	users: UserType[];
};

const CreateTimeTrackingSidebar: React.FunctionComponent<CreateTimeTrackingSidebarProps> = ({
	activeStaffings,
	availableDeliverables,
	availableLocations,
	close,
	isOpen,
	date,
	locations,
	selectedUserId,
	timeTrackingTypes,
	users,
}) => {
	const { t } = useTranslation();
	const { user } = useAuth();
	const userIsFreelancer = user!.employmentStatusId === EmploymentStatusId.Freelancer;

	const [selectedTimeTrackingTypeId, setSelectedTimeTrackingTypeId] = useState<string>("1");
	const [selectedStaffingId, setSelectedStaffingId] = useState<string | null>(null);
	const [currentFormName, setCurrentFormName] = useState<null | TimeTrackingFormNamesEnum>(null);
	const [currentTimeTrackingFormValues, setCurrentTimeTrackingFormValues] = useState(createTimeTrackingFormDefaultValues);
	const selectedStaffingData = useMemo(() => {
		if (selectedStaffingId && activeStaffings) {
			return getStaffingDetailsFromActiveStaffings(selectedStaffingId, activeStaffings);
		}

		return null;
	}, [selectedStaffingId, activeStaffings]);

	const selectedStaffingProjectId = selectedTimeTrackingTypeId === TimeTrackingTypeId.Project ? selectedStaffingData?.projectId : null;

	const closeAndReset = useCallback(() => {
		close();
		// reset state after closing animation ends
		setTimeout(() => {
			setCurrentTimeTrackingFormValues(createTimeTrackingFormDefaultValues);
			setSelectedTimeTrackingTypeId("1");
			setSelectedStaffingId(null);
			setCurrentFormName(null);
		}, 800);
	}, []);


	const handleTimeTrackingTypeClick = useCallback((value: string) => {
		if (value !== "1") {
			setSelectedTimeTrackingTypeId(value);
			setCurrentFormName(TimeTrackingFormNamesEnum.TIME_TRACKING_FORM);
		}
	}, []);

	const handleStaffingIdClick = useCallback((value: string) => {
		setSelectedStaffingId(value);
		setCurrentFormName(TimeTrackingFormNamesEnum.TIME_TRACKING_FORM);
	}, []);

	useEffect(() => {
		if (currentFormName == null) {
			setSelectedTimeTrackingTypeId("1");
		}
	}, [currentFormName]);

	const selectedTimeTrackingTypeName = useMemo(() => {
		if (selectedTimeTrackingTypeId, timeTrackingTypes) {
			const name = timeTrackingTypes.find(({ id }) => id === selectedTimeTrackingTypeId)!.name;
			return t(normalizeTKey(`entities:timeTrackingTypes.${name}`));
		}
		return "";
	}, [selectedTimeTrackingTypeId, timeTrackingTypes]);

	const title = userIsFreelancer ? "Bestellung auswählen" : "Abwesenheitstyp / Bestellung wählen";

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={closeAndReset}>
			<div className="flex flex-col justify-start w-full min-h-full">
				{currentFormName === null &&
					<>
						<Sidebar.Header>
							<Headline type="h2">{title}</Headline>
						</Sidebar.Header>
						<Sidebar.Content>
							{!userIsFreelancer && <div className="mb-10 grid grid-cols-3 gap-1">
								{timeTrackingTypes.map(({ id, name }) => (
										<button key={"timeTrackingTypeOption" + name} onClick={() => handleTimeTrackingTypeClick(id)}
												className={clsx("flex flex-col gap-1 justify-center items-center py-0",
													selectedTimeTrackingTypeId === id
														? "bg-primary-500 text-white hover:bg-primary-700"
														: "ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50",
													"flex items-center justify-center rounded-md py-2 px-2 text-xs font-semibold sm:flex-1 cursor-pointer",
													"focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-500",
												)}>
											{"1" === id && <FolderPlusIcon className="w-6 h-6 text-current" />}
											{"2" === id && <HomeIcon className="w-6 h-6 text-current" />}
											{"3" === id && <CurrencyEuroIcon className="w-6 h-6 text-current" />}
											<div className="text-sm text-current font-normal">
												{t(normalizeTKey(`entities:timeTrackingTypes.${name}`))}
											</div>
										</button>
									),
								)}
							</div>}
							<div className={!userIsFreelancer ? "mt-10" : ""}>
								<Projects
									data={activeStaffings}
									value={selectedStaffingId}
									onChange={handleStaffingIdClick}
								/>
							</div>
						</Sidebar.Content>
						<Sidebar.Footer>
							<Button theme="none"
									onClick={closeAndReset}>
								abbrechen
							</Button>
						</Sidebar.Footer>
					</>}
				{currentFormName === TimeTrackingFormNamesEnum.TIME_TRACKING_FORM &&
					<CreateOrUpdateTimeTrackingForm
						activeStaffings={activeStaffings}
						availableDeliverables={availableDeliverables}
						availableLocations={availableLocations}
						date={date}
						parentIsVisible={isOpen}
						initialValues={currentTimeTrackingFormValues}
						locations={locations}
						onSuccess={closeAndReset}
						setCurrentTimeTrackingFormValues={setCurrentTimeTrackingFormValues}
						selectedStaffingData={selectedStaffingData}
						selectedStaffingId={selectedStaffingId}
						selectedTimeTrackingTypeId={selectedTimeTrackingTypeId}
						selectedTimeTrackingTypeName={selectedTimeTrackingTypeName}
						selectedUserId={selectedUserId}
						setCurrentFormName={setCurrentFormName}
						users={users} />}

				{currentFormName === TimeTrackingFormNamesEnum.DELIVERABLE_FORM &&
					<CreateUserDeliverableForm
						userId={selectedUserId}
						projectId={selectedStaffingProjectId}
						projectTitle={selectedStaffingData?.projectTitle}
						setCurrentFormName={setCurrentFormName}
						timeTrackingTypeId={selectedTimeTrackingTypeId} />}

				{currentFormName === TimeTrackingFormNamesEnum.LOCATION_FORM &&
					<CreateUserLocationForm
						userId={selectedUserId}
						projectId={selectedStaffingProjectId}
						projectTitle={selectedStaffingData?.projectTitle}
						setCurrentFormName={setCurrentFormName}
						timeTrackingTypeId={selectedTimeTrackingTypeId} />}

			</div>
		</Sidebar>
	);
};

export default CreateTimeTrackingSidebar;
