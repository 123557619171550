import { QueriesOptions, useQuery } from "react-query";
import { indexAbsenceTypes } from "~/modules/absence/api/absenceType/absenceTypeApiDispatchers.ts";
import { getDataFromResponse } from "~/api/axiosUtils.ts";

export const ABSENCE_TYPE_BASE_QUERY_KEY = "absenceTypes";

export const ALL_ABSENCE_TYPES_QUERY_KEY = [ABSENCE_TYPE_BASE_QUERY_KEY, 'all'];

export const useAllAbsenceTypes= (options?: QueriesOptions<any>) => useQuery(
	ALL_ABSENCE_TYPES_QUERY_KEY,
	() => indexAbsenceTypes().then(getDataFromResponse),
	options,
);