import { useCallback } from "react";
import { useQueryClient } from "react-query";
import Sidebar from "~/components/Sidebar/Sidebar.tsx";
import {
	MONTHLY_BILLING_REPORT_QUERY_KEY,
} from "~/modules/billing/api/monthlyBillingReport/monthlyBillingReportQueries.ts";
import CreateOrUpdateInvoiceForm from "~/modules/billing/components/forms/CreateOrUpdateInvoiceForm";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";

type CreateInvoiceSidebarProps = {
	currentMonthFormatted: string;
	isOpen: boolean;
	setOpen: (open: boolean) => void;
	initialValues?: CreateOrUpdateInvoiceData,
};

const CreateOrUpdateInvoiceSidebar: React.FunctionComponent<CreateInvoiceSidebarProps> = ({
	isOpen,
	initialValues,
	setOpen,
}) => {
	const queryClient = useQueryClient();
	const onSuccess = useCallback(async () => {
		if (initialValues) {
			const month = formatDateToYYYYMMDD(new Date(initialValues.monthOfBilling));
			await queryClient.refetchQueries(MONTHLY_BILLING_REPORT_QUERY_KEY(month));
			setOpen(false);
		}
	}, [initialValues, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => setOpen(!isOpen)}>
			<>
				{initialValues && <CreateOrUpdateInvoiceForm initialValues={initialValues}
															 onSuccess={onSuccess}
															 onCancel={() => setOpen(false)} />}
			</>
		</Sidebar>
	);
};

export default CreateOrUpdateInvoiceSidebar;