import { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import {
	createUserEndpoint,
	getAllUsersEndpoint,
	resetPasswordEndpoint, sendUserInvitationEndpoint,
	updateUserEndpoint, updateUserStatusEndpoint,
} from "~/modules/user/api/user/userApiEndpoints.ts";
import { CreateUserData, UpdateUserData, UserType, UserWithConfidentialInformation } from "~/modules/user/api/user/userTypes.ts";

export function getAllUsers(): AxiosApiResponsePromise<UserType[] | UserWithConfidentialInformation[]> {
	return axiosApi.get(getAllUsersEndpoint);
}

export function resetPassword(password: string, passwordConfirmation: string, token: string): AxiosApiResponsePromise<UserType[]> {
	return axiosApi.patch(resetPasswordEndpoint, { password, passwordConfirmation, token });
}

export function createUser(user: CreateUserData): AxiosApiResponsePromise<UserWithConfidentialInformation> {
	return axiosApi.post(createUserEndpoint, user);
}

export function updateUser(userId: string, user: UpdateUserData): AxiosApiResponsePromise<UserType> {
	return axiosApi.put(updateUserEndpoint(userId), user);
}

export function updateUserStatus(userId: string, isActive: boolean): AxiosApiResponsePromise<UserType> {
	return axiosApi.patch(updateUserStatusEndpoint(userId), { isActive });
}

export function sendUserInvitation(userId: string): AxiosApiResponsePromise<UserType> {
	return axiosApi.post(sendUserInvitationEndpoint(userId));
}
