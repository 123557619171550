export default {
	select: {
		defaultOption: "-- Bitte auswählen --",
	},
	validationErrors: {
		mixed: {
			required: "Dieses Feld darf nicht leer sein.",
		},
		string: {
			email: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
		},
	},
};