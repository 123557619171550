import { Fragment, MouseEvent } from "react";
import { TimeTrackingFormData } from "~/modules/timeTracking/api/timeTracking/timeTrackingTypes.ts";
import TimeTrackingFormTag from "~/modules/timeTracking/components/components/TimeTrackingFormTag";

/*
const LocationsGroup: React.FC<PropsWithChildren> = ({ children }) => (
	<div className="flex gap-1 ring-1 ring-inset ring-gray-300 rounded-md p-2">{children}</div>
);
*/

interface LocationsProps {
	data: Array<TimeTrackingFormData["locationName"] | undefined>[];
	onClick: (locationName: string, event: MouseEvent) => void;
}

const Locations: React.FC<LocationsProps> = ({ data, onClick }) =>
	data.map(
		(lGroup, lGroupIndex) =>
			0 < lGroup.length && (
				<Fragment key={lGroupIndex}>
					{lGroup.map(
						(locationName, lGroupIndex) =>
							undefined !== locationName && (
								<TimeTrackingFormTag key={`deliverable-${lGroupIndex}`}
												 onClick={(event) => onClick(locationName, event)}>
									{locationName}
								</TimeTrackingFormTag>
							),
					)}
				</Fragment>
			),
	);


export default Locations;
