import { PropsWithChildren, ReactNode } from "react";

interface StatsProps {
	fields: {
		label?: string;
		element: string | number | ReactNode;
		unit?: string;
		flexBasePercentage?: number;
	}[];
}

const Stats: React.FC<StatsProps> = ({ fields }) => {
	return (
		/*<div className={`grid grid-cols-1 gap-px sm:grid-cols-2 lg:grid-cols-5 text-white`}>*/
		<div className={`flex justify-between`}>
			{fields.map(({ label, element, unit, flexBasePercentage }, fieldIndex) => (
				<div key={`field-${fieldIndex}-${label}`} className="flex" style={{flexBasis: flexBasePercentage ? `${flexBasePercentage}%` :"auto"}}>
					{fieldIndex !== 0 && <div className="border-r border-white opacity-40 h-full mt-2 mx-4" />}
					<div className="flex flex-col gap-y-1">
						{label && <Label>{label}</Label>}
						<div className="flex items-baseline gap-x-1">
							<Element>{element}</Element>
							{unit ? <span className="text-sm opacity-50">{unit}</span> : null}
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

const Label: React.FC<PropsWithChildren> = ({ children }) => (
	<div className="text-xs font-normal opacity-50">{children}</div>
);
const Element: React.FC<PropsWithChildren> = ({ children }) => <div className="text-xl font-bold">{children}</div>;

export default Object.assign(Stats, { Label, Element });
