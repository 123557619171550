import { tv } from "tailwind-variants";
import { VariantProps } from "~/types/tailwindVariants";

export const cardVariants = tv({
	base: "rounded-lg shadow-md",
	variants: {
		color: {
			white: "bg-white text-gray-800",
			gray: "bg-gray-200 text-gray-800",
		},
		padding: {
			sm: "px-3 py-2",
			md: "px-4 py-3",
			lg: "px-8 py-4",
		},
		shadow: {
			none: "shadow-none",
			sm: "shadow-sm",
			md: "shadow",
			lg: "shadow-lg",
		},

	},
	defaultVariants: {
		color: "white",
		padding: "md",
		shadow: "md",
	},
});


export type CardVariantProps = VariantProps<typeof cardVariants>;
export type CardColorVariants = "white" | "gray";
export type CardPaddingVariants = "sm" | "md" | "lg";
export type CardShadowVariants = "none" | "sm" | "md" | "lg";
