import { tv } from "tailwind-variants";
import { VariantProps } from "~/types/tailwindVariants";

export const labelVariants = tv({
	base: "block text-gray-500",
	variants: {
		lineHeight: {
			none: "",
			sm: "leading-3",
			md: "leading-6",
		},
		size: {
			sm: "text-xs",
			md: "text-sm font-medium ",
		},
	},
	defaultVariants: {
		lineHeight: "md",
		size: "md",
	},
});


export type LabelVariants = VariantProps<typeof labelVariants>;
export type LabelSizeVariants = "sm" | "md";
export type LabelLineHeightVariants = "none" | "sm" | "md";

