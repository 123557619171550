import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "~/components/form/Button/Button.tsx";
import ComboBox from "~/components/form/ComboBox";

import FormSection, { FormSectionGroup } from "~/components/form/FormSection";
import BudgetFormSection from "~/components/form/formSections/BudgetFormSection";
import CommentFormSection from "~/components/form/formSections/CommentFormSection";
import PlaceOfPerformanceFormSection from "~/components/form/formSections/PlaceOfPerformanceFormSection";
import TitleFormSection from "~/components/form/formSections/TitleFormSection";
import RadioGroup from "~/components/form/RadioGroup";
import SubmitButton from "~/components/form/SubmitButton";
import Headline from "~/components/Headline";
import Sidebar from "~/components/Sidebar";
import useApprovalLikelihoodOptions from "~/hooks/form/formOptionsData/useApprovalLikelihoodOptions.ts";
import useCountrySelectOptions from "~/hooks/form/formOptionsData/useCountrySelectOptions.ts";
import useLocationOptions from "~/hooks/form/formOptionsData/useLocationOptions.ts";
import useUserSelectOptions, { employeesFilterFn } from "~/hooks/form/formOptionsData/useUsersSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	useProjectPhaseTitleValidationComparisonData,
} from "~/hooks/form/validationComparisonData/useProjectPhaseTitleValidationComparisonData.ts";
import { useAllLocations } from "~/modules/location/api/location/locationQueries.ts";
import { updateProjectPhase } from "~/modules/project/api/projectPhase/projectPhaseApiDispatchers.ts";
import { ProjectPhaseType, UpdateProjectPhaseData } from "~/modules/project/api/projectPhase/projectPhaseTypes.ts";
import { WorkingStatusId } from "~/types/entityIds.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type CreateProjectPhaseFormProps = {
	phaseData: ProjectPhaseType;
	onSuccess: () => void;
	onCancel: () => void;
};

interface UpdateProjectPhaseFormData extends Omit<UpdateProjectPhaseData, "projectId"> {
}

const UpdateProjectPhaseForm: React.FC<CreateProjectPhaseFormProps> = ({ phaseData, onSuccess, onCancel }) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);
	const titleComparisonData = useProjectPhaseTitleValidationComparisonData(phaseData.projectId);
	const { data: locationData } = useAllLocations();

	const schema = useMemo(() => {
		return yup.object({
			approvalLikelihoodId: yup.string().required(),
			budgetCentsPlanned: yup.number().required(),
			comment: yup.string().default(null),
			countryId: yup.string().required(),
			locationName: yup.string().required(),
			manDaysPlanned: yup.number().required(),
			phaseManagedBy: yup.string().required(),
			title: yup.string().required().unique(titleComparisonData, phaseData.id),
		});
	}, [titleComparisonData, phaseData]);

	const defaultValues = useMemo(() => {
		const defaultValues = {
			approvalLikelihoodId: phaseData.approvalLikelihoodId || "1",
			budgetCentsPlanned: phaseData.budgetCentsPlanned || 0,
			comment: phaseData.comment || "",
			countryId: phaseData.countryId,
			locationName: "",
			manDaysPlanned: parseFloat(phaseData.manDaysPlanned) || 0,
			phaseManagedBy: phaseData.phaseManagedBy || "",
			title: phaseData.title,
		};

		if (locationData) {
			const location = locationData.find((location) => location.id === phaseData.locationId);
			if (location) {
				defaultValues.locationName = location.displayName;
			}
		}

		return defaultValues;
	}, [phaseData, locationData]);

	const {
		handleSubmit,
		control,
		formState: { errors, isDirty, isValid, isSubmitted },
		trigger,
	} = useForm<UpdateProjectPhaseFormData>({
		mode: "onChange",
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateProjectPhaseFormData>(schema),
	});
	const approvalLikelihoodOptions = useApprovalLikelihoodOptions();
	const countrySelectOptions = useCountrySelectOptions();
	const managerSelectOptions = useUserSelectOptions(employeesFilterFn);
	const locationOptions = useLocationOptions();

	const showApprovalLikelihoodInput = phaseData.workingStatusId === WorkingStatusId.Quotation;
	const formIsSubmittable = useFormIsSubmittable({
		isDirty,
		isSubmitted,
		isValid,
		isLoading: busy,
		enableInitially: false,
	});

	useEffect(() => {
		trigger();
	}, []);

	const onSubmit: SubmitHandler<UpdateProjectPhaseFormData> = async (data: UpdateProjectPhaseFormData) => {
		setBusy(true);

		const updatedPhaseData = {
			...data,
			projectId: phaseData.projectId,
		};

		try {
			await updateProjectPhase({
				projectId: phaseData.projectId,
				projectPhaseId: phaseData.id,
				phaseData: updatedPhaseData,
			});
			onSuccess();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex flex-col justify-start w-full min-h-full"
		>
			<Sidebar.Header>
				<Headline type="h2">Projektphase bearbeiten</Headline>
			</Sidebar.Header>
			<Sidebar.Content>
				{busy && <Sidebar.BusyOverlay />}
				<FormSectionGroup>
					<TitleFormSection
						control={control}
						title="Name" />

					{showApprovalLikelihoodInput && <FormSection title="Bewertung">
						<div className="grid gap-x-6 gap-y-2 grid-cols-6">
							<div className="col-span-6">
								<RadioGroup
									control={control}
									name="approvalLikelihoodId"
									options={approvalLikelihoodOptions}
									label="Bewertung"
								/>
							</div>
						</div>
					</FormSection>}
					<BudgetFormSection control={control}
									   title="Plandaten"
									   errors={errors} />

					<PlaceOfPerformanceFormSection
						errors={errors}
						control={control}
						locationOptions={locationOptions}
						countrySelectOptions={countrySelectOptions}
					/>

					<FormSection title="Verantwortlicher der Phase ">
						<div className="grid gap-x-6 gap-y-2 grid-cols-6">
							<div className="col-span-6">
								<ComboBox
									allowNew={false}
									name="phaseManagedBy"
									label={"Angebot Manager"}
									error={errors.phaseManagedBy?.message}
									optionsData={managerSelectOptions}
									control={control}
								/>
							</div>
						</div>
					</FormSection>
					<CommentFormSection
						control={control}
						placeholder={"Hier können allgemeine Anmerkungen zur Projektphase hinterlegt werden."}
						label="Kommentar"
						title="Anmerkungen"
					/>
				</FormSectionGroup>
			</Sidebar.Content>
			<Sidebar.Footer>
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					abbrechen
				</Button>
			</Sidebar.Footer>
		</form>
	);
};

export default UpdateProjectPhaseForm;
