import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import Decimal from "decimal.js-light";
import React from "react";
import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import { formatVacationDays } from "~/modules/absence/utils/vacationLedgerFormUtils.ts";

type VacationLedgerTableProps = {
	children?: React.ReactNode;
};

const VacationLedgerTable: React.FunctionComponent<VacationLedgerTableProps> = ({ children }) => {
	return (
		<table className="min-w-full border-separate border-spacing-y-2">
			<thead>
			<tr>
				<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
					Typ
				</th>
				<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
					Kommentar
				</th>
				<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
					Gültig ab
				</th>
				<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
					Urlaubstage
				</th>
				<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
					Erstellt von
				</th>
				<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
					<span className="sr-only">Edit</span>
				</th>
			</tr>
			</thead>
			<tbody className="divide-y divide-gray-200 bg-white">{children}</tbody>
		</table>
	);
};




type VacationLedgerTableRowProps = {
	comment: string | null;
	createdBy: string | null;
	isDeletable: boolean;
	isEditable: boolean;
	onDeleteClick: () => void;
	onEditClick: () => void;
	type: string;
	vacationDays: Decimal;
	validFrom: string;
};

const Row: React.FunctionComponent<VacationLedgerTableRowProps> = ({
																	   comment,
																	   createdBy,
																	   isDeletable,
																	   isEditable,
																	   onDeleteClick,
																	   onEditClick,
																	   type,
																	   vacationDays,
																	   validFrom,
																   }) => {

	return (
		<tr className="bg-white hover:bg-gray-200">
			<td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm">
				{type || "--"}
			</td>
			<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
				{comment || "--"}
			</td>
			<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{validFrom}</td>
			<td className={clsx("whitespace-nowrap px-3 py-5 text-sm font-bold", vacationDays.isNegative() ? "text-red-500" : "text-green-500")}>
				{formatVacationDays(vacationDays) || "--"}
			</td>
			<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
				{createdBy || "--"}
			</td>
			<td className="relative whitespace-nowrap ml-auto pr-3 text-sm text-end">
				{(isEditable || isDeletable) && <ButtonWithPopover
					theme="dark"
					items={[
						{
							label: "Bearbeiten",
							onClick: onEditClick,
							icon: PencilIcon,
						},
						{
							label: "Löschen",
							onClick: onDeleteClick,
							icon: TrashIcon
						},
					]}
				/>}
			</td>
		</tr>
	);
};


export default Object.assign(VacationLedgerTable, { Row });
