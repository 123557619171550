import { endOfMonth, startOfMonth } from "date-fns";
import { SelectedStaffingDataType, UsersActiveStaffingType } from "~/modules/project/api/staffing/staffingTypes.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";

export function minutesToWorkdays(minutes: number, precision: number = 0): number {
	const result = minutes / 60 / 8;
	return Math.round(result * Math.pow(10, precision)) / Math.pow(10, precision);
}

export function formatManDays(manDays: number): string {
	return manDays.toLocaleString("de-DE", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
}

export function getStartAndEndOfMonth(date: Date): { startDate: string, endDate: string } {

	const startDate = formatDateToYYYYMMDD(startOfMonth(date));
	const endDate = formatDateToYYYYMMDD(endOfMonth(date));

	return { startDate, endDate };
}

export function getStaffingDetailsFromActiveStaffings(staffingId: string, projects: UsersActiveStaffingType[]): SelectedStaffingDataType & {
	projectTitle: string,
	phaseTitle: string,
	orderTitle: string
} | null {
	for (const project of projects) {
		for (const phase of project.projectPhases) {
			for (const order of phase.orders) {
				for (const staffing of order.staffings) {
					if (staffing.id === staffingId) {
						return {
							...staffing,
							projectTitle: project.title,
							phaseTitle: phase.title,
							orderTitle: order.title,
						};
					}
				}
			}
		}
	}
	return null;
}
