import { PropsWithChildren } from "react";
import clsx from "clsx";

interface HeadlineProps {
	type: "h1" | "h2" | "h3" | "h4";
	color?: string;
	className?: string;
}

const Headline: React.FC<PropsWithChildren<HeadlineProps>> = ({ children, type, color, className }) => {
	const defaultStyles = "flex items-center gap-2 font-Roboto";
	let headlineStyles;
	switch (type) {
		case "h1":
			headlineStyles = clsx("text-5xl font-bold", color, { "text-gray-900": undefined === color });
			break;
		case "h2":
			headlineStyles = clsx("text-xl font-bold", color, { "text-gray-500": undefined === color });
			break;
		case "h3":
			headlineStyles = clsx("text-base font-semibold leading-6", color, {
				"text-gray-900": undefined === color,
			});
			break;
		default:
			break;
	}

	const Element = type;

	return <Element className={clsx(defaultStyles, headlineStyles, className)}>{children}</Element>;
};

export default Headline;
