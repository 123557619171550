import ProjectDetailsView from "modules/project/components/ProjectDetailsView";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAggregatedData } from "~/hooks/useAggregateData";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAllClients } from "~/modules/client/api/client/clientQueries";
import { ClientType } from "~/modules/client/api/client/clientTypes.ts";
import { useAllClientContactPersons } from "~/modules/client/api/clientContactPerson/clientContactPersonQueries";
import { useCompany } from "~/modules/client/api/company/companyQueries.ts";
import { useAllCountries } from "~/modules/country/api/country/countryQueries";
import { useAllLocations } from "~/modules/location/api/location/locationQueries.ts";
import { useProjectsOrders } from "~/modules/project/api/order/orderQueries";
import { useProject } from "~/modules/project/api/project/projectQueries";
import { useProjectsProjectPhases } from "~/modules/project/api/projectPhase/projectPhaseQueries";
import { useProjectProjectRoles } from "~/modules/project/api/projectRole/projectRoleQueries";
import { useProjectsStaffings } from "~/modules/project/api/staffing/staffingQueries";
import { useAllWorkingStatuses } from "~/modules/project/api/workingStatus/workingStatusQueries";
import { useAllUsers } from "~/modules/user/api/user/userQueries";
import LoadingPage from "~/pages/LoadingPage";
import { PermissionNames } from "~/types/entityNames.ts";


interface Props {
}

const ProjectDetailsPage: React.FC<Props> = () => {
		const guard = userViewGuard(PermissionNames.CanManageProjects);
		if (guard) return guard;

		const { projectId } = useParams();
		if (undefined === projectId) return <div>error</div>;

		const { data, options } = useAggregatedData([
			{ hook: () => useProject({ projectId }), name: "project" },
			{ hook: () => useAllUsers(), name: "users" },
			{ hook: () => useProjectsOrders({ projectId }), name: "orders" },
			{ hook: () => useProjectsProjectPhases({ projectId }), name: "phases" },
			{ hook: () => useProjectsStaffings({ projectId }), name: "staffings" },
			{ hook: () => useAllClients(), name: "clients" },
			{ hook: () => useAllClientContactPersons(), name: "clientContactPersons" },
			{ hook: () => useProjectProjectRoles({ projectId }), name: "projectRoles" },
			{ hook: () => useAllWorkingStatuses(), name: "workingStatuses" },
			{ hook: () => useAllLocations(), name: "locations" },
			{ hook: () => useAllCountries(), name: "countries" },
		]);
		const {
			project,
			users,
			phases,
			orders,
			staffings,
			clients,
			clientContactPersons,
			projectRoles,
			workingStatuses,
			locations,
			countries,
		} = useMemo(() => data, [data]);

		const client = useMemo(() => {
			if (project && clients) {
				const client = clients.find((client: ClientType) => client.id === project.clientId);
				if (client) return client;
			}
			return null;
		}, [project, clients]);


		const {
			data: companyData,
			isLoading: companyIsLoading,
		} = useCompany(client?.companyId, { enabled: !!client?.companyId });


		if (options.loadingPcent < 100 || companyIsLoading || !companyData) return <LoadingPage pcent={options.loadingPcent} />;

		return (
			<ProjectDetailsView
				projectData={project}
				phases={phases}
				orders={orders}
				staffings={staffings}
				allUsers={users}
				client={client}
				company={companyData}
				clientContactPersons={clientContactPersons}
				projectRoles={projectRoles}
				workingStatuses={workingStatuses}
				locations={locations}
				countries={countries}
			/>
		);
	}
;

export default ProjectDetailsPage;

