import "./index.css";
/*import "@mantine/core/styles.css";
import '@mantine/dates/styles.css';*/
import * as React from "react";
import * as ReactDOM from "react-dom/client";

import App from "~/App";
import { BrowserRouter } from "react-router-dom";
import { appRoutes } from "~/constants/appRoute.ts";
import initI18n from "./i18n/setup";

initI18n({ debug: false });

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<React.StrictMode>
		<BrowserRouter basename={appRoutes.base().path}>
			<App />
		</BrowserRouter>
	</React.StrictMode>,
);
