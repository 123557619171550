import React, { PropsWithChildren, useEffect, useState } from "react";

import Button from "~/components/form/Button";
import Headline from "~/components/Headline";
import Hint from "~/components/Hint";
import LoadingSpinner from "~/components/LoadingSpinner";
import Modal from "~/components/Modal";

export type DeleteEntityTextElements = {
	headerText: string;
	bodyText: string;
	confirmationText?: string;
	deleteButtonText: string;
	confirmationButtonText?: string;
};

interface DeleteEntityViewProps extends PropsWithChildren {
	busy: boolean;
	onCloseClick: () => void;
	error?: string | null;
	handleConfirmationClick: () => void;
	isOpen: boolean;
	texts: DeleteEntityTextElements;
}

const DeleteEntityView: React.FunctionComponent<DeleteEntityViewProps> = ({
	busy,
	isOpen,
	children,
	onCloseClick,
	error,
	handleConfirmationClick,
	texts,
}) => {
	const [showConfirmation, setShowConfirmation] = useState(false);
	useEffect(() => {
		if (isOpen) {
			setShowConfirmation(false);
		}
	}, [isOpen]);
	return (
		<Modal open={isOpen}
			   setOpen={onCloseClick}>
			<div className="flex flex-col gap-3">
				<Headline type="h2">{texts.headerText}</Headline>
				{busy ? (
					<LoadingSpinner />
				) : (
					<>
						<div>
							{children}
							{!showConfirmation && <p>{texts.bodyText}</p>}
							{showConfirmation && !error &&
								<p>{texts.confirmationText || "Bist du sicher? Das Löschen kann nicht rückgängig gemacht werden!"}</p>}
							{showConfirmation && error && <Hint size="sm" theme="error">{error}</Hint>}
						</div>
						<div className="mt-4">
							{!showConfirmation && (
								<Button theme="danger"
										onClick={() => setShowConfirmation(true)}>
									{texts.deleteButtonText}
								</Button>
							)}
							{showConfirmation && !error && (
								<Button theme="danger"
										onClick={handleConfirmationClick}>
									{texts.confirmationButtonText || "Endgültig löschen"}
								</Button>
							)}
							<Button theme="none"
									onClick={onCloseClick}>
								abbrechen
							</Button>

						</div>
					</>
				)}
			</div>
		</Modal>
	);
};

export default DeleteEntityView;
