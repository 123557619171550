import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { PopoverProps } from "@mantine/core";
import { DatePickerInput as MantineDatePickerInput, DatesProvider } from "@mantine/dates";
import "dayjs/locale/de";
import React from "react";
import { Control, useController } from "react-hook-form";
import FormInput from "~/components/form/FormInput";
import FormInputError from "~/components/form/FormInputError";
import Label from "~/components/form/Label";

type SharedPropsType = {
	name: string;
	disabled?: boolean;
	label?: string;
	error?: string;
	minDate?: Date;
	position?: PopoverProps["position"];
};

type DateInputProps = SharedPropsType & {
	control: Control<any>;
};

const DatePickerInput: React.FunctionComponent<DateInputProps> = ({
	control,
	name,
	disabled,
	label,
	minDate,
	error,
	position,
}) => {
	const {
		field: { onChange, value },
		fieldState: { error: controlError },
	} = useController({
		name,
		control,
	});

	const errorMessage = error || controlError?.message;
	let inputValue = value || null;
	return (
		<FormInput>
			{label && <Label htmlFor={name}>{label}</Label>}
			<div className="relative rounded-md shadow-sm">
				<DatesProvider settings={{ locale: "de", timezone: "UTC" }}>

					<MantineDatePickerInput
						classNames={{
							input: `flex w-full rounded-md border-0 px-1.5 h-10 items-center
									shadow-sm
									ring-1 ring-inset ring-gray-300 focus:ring-blue-500 focus:ring-2 focus:ring-inset
									text-sm`,
							section: `absolute right-0 top-0 h-full 
										flex items-center 
										pr-2
										[&_button]:rounded-full [&_button]:hover:bg-gray-200 [&_button]:w-5 [&_button]:h-5
										[&_button]:flex [&_button]:items-center [&_button]:justify-center
										[&_svg]:text-gray-500`,
							calendarHeader: "flex justify-between h-10 bg-white",
							calendarHeaderLevel: "flex gap-1 justify-center items-center hover:bg-gray-100 rounded-full px-4",
							calendarHeaderControl: "flex items-center justify-center w-10 h-10 hover:bg-gray-100 rounded-full",
							calendarHeaderControlIcon: "text-gray-400 data-[direction=\"previous\"]:rotate-90 data-[direction=\"next\"]:-rotate-90",
							month: "border-collapse",
							monthsListCell: "px-2 py-1 text-center hover:bg-primary-400 hover:text-white rounded-md text-sm py-2",
							monthsList: "w-full",
							yearsListCell: "px-2 py-1 text-center hover:bg-primary-400 hover:text-white rounded-md text-sm py-2",
							yearsList: "w-full",
							weekday: "text-gray-400 capitalize font-light text-sm",
							day: `w-8 h-8 inline-flex items-center justify-center rounded-md
									text-sm
									hover:bg-primary-400 hover:text-white  
									data-[outside]:text-gray-300  
									data-[selected]:bg-primary-500 data-[selected]:text-white
									data-[weekend]:text-accent-500 data-[weekend]:hover:text-white
							`,
						}}

						popoverProps={{
							position: position || "bottom-start",
							classNames: {
								dropdown: "absolute z-50 border border-gray-200 bg-white rounded-md shadow-lg p-2 text-gray-800 min-w-[16rem]",
							},
						}}
						valueFormat="DD. MMMM YYYY"
						name={name}
						minDate={minDate}
						disabled={disabled}
						value={inputValue}
						onChange={onChange}
						clearable
					/>
				</DatesProvider>
				{errorMessage && (
					<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
						<ExclamationCircleIcon className="h-5 w-5 text-red-500"
											   aria-hidden="true" />
					</div>
				)}
			</div>
			{errorMessage && <FormInputError>{errorMessage}</FormInputError>}
		</FormInput>
	);
};

export default DatePickerInput;
