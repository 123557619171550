import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { t } from "i18next";
import { generatePath, NavLink } from "react-router-dom";
import Headline from "~/components/Headline";
import { Popover } from "@mantine/core";
import Status from "~/components/Status";
import { appRoutes } from "~/constants/appRoute.ts";
import { BillingReportStaffedUserType } from "~/modules/billing/api/monthlyBillingReport/monthlyBillingReportTypes.ts";
import DownloadInvoiceButton
	from "~/modules/billing/components/MonthlyBillingReportView/components/DownloadInvoiceButton";
import { OrderInvoicingStatus } from "~/modules/billing/types/billingEnums.ts";
import { getInvoicingStatusTheme } from "~/modules/billing/utils/monthlyInvoicingUtils.ts";
import { UserType } from "~/modules/user/api/user/userTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";

const ListElement: React.FC<{ label: string; value: string }> = ({ label, value }) => (
	<div className="py-2 flex flex-row gap-x-5">
		<dt className="w-32 text-sm font-medium leading-6 text-gray-900">{label}</dt>
		<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">{value}</dd>
	</div>
);

interface BillableProjectCardProps extends React.PropsWithChildren {
	projectId: string;
	projectNumber: string;
	projectTitle: string;
}

const BillableProjectCard: React.FunctionComponent<BillableProjectCardProps> = ({
	projectId,
	projectNumber,
	projectTitle,
	children,
}) => {
	return <div className="w-full rounded bg-gray-200 px-4 py-4">
		<div className="flex justify-between mb-2">
			<NavLink to={generatePath(appRoutes.project().path, { projectId })}>
				<Headline type="h2"
						  className="text-gray-700 hover:text-gray-500">
					{projectTitle} ({projectNumber})
				</Headline>
			</NavLink>
		</div>
		<div className="flex flex-col gap-y-4">
			{children}
		</div>
	</div>;
};

type BillableProjectPhaseProps = {
	allUsersData: UserType[];
	comment: string | null;
	companyName: string;
	hasFreelancers: string;
	manager: string;
	monthlyClosing: string;
	orderId: string;
	orderTitle: string;
	phaseTitle: string;
	projectTitle: string;
	projectNumber: string;
	setSelectedOrderId: (orderId: string) => void;
	staffedUsers: BillingReportStaffedUserType[];
	month: string;
	status: OrderInvoicingStatus;
	total: string;
}


const BillableProjectPhase: React.FunctionComponent<BillableProjectPhaseProps> = ({
	allUsersData,
	comment,
	companyName,
	hasFreelancers,
	manager,
	monthlyClosing,
	orderId,
	orderTitle,
	phaseTitle,
	setSelectedOrderId,
	staffedUsers,
	month,
	status,
	total,
}) => {
	const statusTheme = getInvoicingStatusTheme(status);
	const statusDisplayName = t(normalizeTKey(`entities:orderInvoicingStatus.${status}`));

	const staffedUsersContent = (<div>{staffedUsers.map((staffedUser) => {
		const user = allUsersData.find((user) => user.id === staffedUser.userId);
		if (user) {
			return (
				<div className="flex flex-row gap-x-2 p-1 items-center"
					 key={"staffedUser" + staffedUser.userId}>
					{staffedUser.hasClosedMonth ? <CheckCircleIcon className="w-5 h-5 text-success-500" /> :
						<XCircleIcon className="w-5 h-5 text-danger-500" />}
					<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">{user.fullName}</dd>
				</div>
			);
		}
		return null;
	})}</div>);

	return (
		<div className="w-full">
			<div className="flex flex-row pr-4 py-2 font-bold text-gray-600">
				<span className="mr-2">Phase: {phaseTitle}</span>
				<button onClick={() => setSelectedOrderId(orderId)}>
					<Status theme={statusTheme}>{statusDisplayName}</Status></button>
			</div>
			<div className="grid grid-cols-4 gap-x-5 rounded bg-white px-4 py-2">
				<dl className="col-span-2 divide-y divide-gray-100 flex-none">
					<ListElement label="Bestellung"
								 value={orderTitle} />
					<ListElement label="Firma"
								 value={companyName} />
					<ListElement label="Manager"
								 value={manager} />
				</dl>
				<dl className="divide-y divide-gray-100 flex-none">
					<ListElement label="EUR (netto)"
								 value={total} />
					<div className="py-2 flex flex-row gap-x-5">
						<dt className="w-32 text-sm font-medium leading-6 text-gray-900">Monatsabschluss</dt>
						<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">
							<Popover position="bottom-start"
									 shadow="md"
									 offset={2}
									 classNames={{
										 dropdown: "absolute z-50 border border-gray-200 bg-white rounded-md shadow-lg pl-1.5 pr-3 py-1 text-gray-800 min-w-[5rem]",
									 }}>
								<Popover.Target>
									<button className="hover:text-gray-500 w-full">{monthlyClosing}</button>
								</Popover.Target>
								<Popover.Dropdown>
									{staffedUsersContent}
								</Popover.Dropdown>
							</Popover>
						</dd>
					</div>
					<ListElement label="Freelancer"
								 value={hasFreelancers} />
				</dl>
				<dl className="divide-y divide-gray-100 flex-none">
					<DownloadInvoiceButton orderId={orderId}
										   month={month} />
					<ListElement label="Besonderheiten"
								 value={comment || "Keine"} />
				</dl>
			</div>
		</div>);
};

export default Object.assign(BillableProjectCard, { BillableProjectPhase });