import memoizeOne from 'memoize-one';

import { CustomFormatter } from '../format';

const normalizeDate = (value: any) => {
	if (value instanceof Date && !isNaN(value.getTime())) {
		return value as Date;
	}
	if (typeof value === 'number' && !isNaN(value)) {
		return new Date(value);
	}
	if (typeof value === 'string') {
		const timestamp = Date.parse(value);
		if (isNaN(timestamp)) {
			throw new Error(`Unexpected serialized Date value: ${value}`);
		}
		return new Date(timestamp);
	}
	throw new Error('Invalid value supplied, expected Date');
};

const createTimeFormatter = memoizeOne(
	(locale?: string) =>
		new Intl.DateTimeFormat(locale, {
			hour: 'numeric',
			minute: '2-digit',
		})
);
export const time: CustomFormatter = (value, lng) => createTimeFormatter(lng).format(normalizeDate(value));

const createDateFormatter = memoizeOne(
	(locale?: string) =>
		new Intl.DateTimeFormat(locale, {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		})
);
export const date: CustomFormatter = (value, lng) => createDateFormatter(lng).format(normalizeDate(value));

const createWeekdayFormatter = memoizeOne(
	(locale?: string) =>
		new Intl.DateTimeFormat(locale, {
			weekday: 'long',
		})
);
export const weekday: CustomFormatter = (value, lng) => createWeekdayFormatter(lng).format(normalizeDate(value));

export const dateTime: CustomFormatter = (value, lng) => `${date(value, lng)}, ${time(value, lng)}`;
