import { useMemo } from "react";
import { getSelectBoxDefaultOptionsData, createSelectBoxOptionData } from "~/utils/form/formUtils.ts";
import { FormInputOption } from "~/types/form.ts";
import { useAllCompanyLocations } from "~/modules/client/api/companyLocation/companyLocationQueries.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";


const useCompanyLocationSelectOptions = (includeCtaOption: boolean = false): FormInputOption[] => {
	const { data: companyLocationsData } = useAllCompanyLocations();
	return useMemo(() => {
		if (companyLocationsData) {
			const companyLocationsSorted = [...companyLocationsData].sort(byObjectProperty("listPosition"));
			const options = companyLocationsSorted.map((companyLocation) => {
				// @ts-ignore
				const label = companyLocation.location.displayName;
				return createSelectBoxOptionData(label, companyLocation.id);
			});

			if (includeCtaOption) {
				return [getSelectBoxDefaultOptionsData(), ...options];
			}
			return options;
		}
		return [];
	}, [companyLocationsData, includeCtaOption]);
};

export default useCompanyLocationSelectOptions;

