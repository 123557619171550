import { AuthTokens, deleteTokens, getAccessToken, getRefreshToken, setTokens } from "~/services/tokenService";
import axios, { InternalAxiosRequestConfig } from "axios";

import { API_URL } from "~/constants/envConstants";
import { appRoutes } from "~/constants/appRoute.ts";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { refreshEndpoint } from "~/modules/auth/api/auth/authApiEndpoints";
import { setIntendedLocation } from "~/services/intendedLocation";

const baseConfig = {
	baseURL: API_URL,
	headers: { Accept: "application/json" },
};

const axiosApi = axios.create(baseConfig);

export const axiosApiNoInterceptors = axios.create(baseConfig);

axiosApi.interceptors.request.use((request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
	if (request.headers) {
		request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
	}

	if (import.meta.env.VITE_SEND_XDEBUG_SESSION_START) {
		// add Xdebug start session parameter
		const fullUrl = request.baseURL + "/" + request.url;
		const url = new URL(fullUrl);
		url.searchParams.set("XDEBUG_SESSION_START", "PHPSTORM");
		request.url = url.toString();
	}

	return request;
});

export const refreshTokensAndSession = async () => {
	try {
		const tokenRefreshResponse = await axiosApiNoInterceptors.post(refreshEndpoint, {
			refreshToken: getRefreshToken(),
		});
		const newTokens: AuthTokens = {
			accessToken: tokenRefreshResponse.data.access_token,
			refreshToken: tokenRefreshResponse.data.refresh_token,
		};
		setTokens(newTokens);
		return Promise.resolve();
	} catch (error) {
		deleteTokens();
		setIntendedLocation();
		window.location.href = appRoutes.login().path;
	}
};

createAuthRefreshInterceptor(axiosApi, refreshTokensAndSession);

export default axiosApi;
