import { UserWithConfidentialInformation } from "~/modules/user/api/user/userTypes.ts";
import Sidebar from "~/components/Sidebar";
import UpdateUserForm from "~/modules/user/components/forms/UpdateUserForm";

type CreateUserSidebarProps = {
	isOpen: boolean;
	closeSidebar: () => void;
	userData: UserWithConfidentialInformation;
};

const UpdateUserSidebar: React.FunctionComponent<CreateUserSidebarProps> = ({ isOpen, closeSidebar, userData }) => {
	return (
		<Sidebar closeOnOutsideClick={false} open={isOpen} setOpen={closeSidebar}>
			<UpdateUserForm userData={userData} onSuccess={closeSidebar} onCancel={closeSidebar} />

		</Sidebar>
	);
};

export default UpdateUserSidebar;
