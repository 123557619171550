export enum GenderNames {
	Male = "male",
	Female = "female",
	Diverse = "divers",
}

export enum PermissionNames {
	CanManageAbsences = "manage-absences",
	CanManageProjects = "manage-projects",
	CanManageInvoices = "manage-invoices",
	CanManageUsers = "manage-users",
	CanManageTimeTrackings = "manage-time-trackings",
}

export enum RoleNames {
	Admin = "admin",
	ProjectManager = "project-manager",
	InvoiceManager = "invoice-manager",
	TeamMember = "team-member",
	HrManager = "hr-manager",
}

export enum EmploymentStatusNames {
	Employed = "employed",
	Freelancer = "freelancer",
	Left = "left",
	Student = "student",
	Intern = "intern",
}

export enum WorkingStatusNames {
	Active = "active",
	Quotation = "quotation",
	Hold = "hold",
	Cancelled = "cancelled",
	Closed = "closed",
}

export enum AbsenceTypeNames {
	Vacation = "vacation",
	Sick = "sick",
	Holiday = "holiday",
	Training = "training",
	Sabbatical = "sabbatical",
	MaternityLeave = "maternity_leave",
	ParentalLeave = "parental_leave",
	InternalEvent = "internal_event",
	Special = "special",
}

export enum TimeTrackingTypeNames {
	Project = "project",
	Internal = "internal",
	Sales = "sales",
}
