//@ts-nocheck
import { CurrencyEuroIcon, FolderPlusIcon, HomeIcon } from "@heroicons/react/20/solid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserAvailableDeliverableType } from "~/modules/timeTracking/api/deliverable/deliverableTypes.ts";
import { LocationType, UserAvailableLocationType } from "~/modules/location/api/location/locationTypes.ts";
import { UsersActiveStaffingType } from "~/modules/project/api/staffing/staffingTypes.ts";
import { UserType } from "~/modules/user/api/user/userTypes.ts";
import Button from "~/components/form/Button";
import { RadioGroupPlain } from "~/components/form/RadioGroup/RadioGroup.tsx";
import Headline from "~/components/Headline";
import Sidebar from "~/components/Sidebar";
import {
	TimeTrackingExtendedType,
	TimeTrackingFormData,
} from "~/modules/timeTracking/api/timeTracking/timeTrackingTypes.ts";
import { TimeTrackingTypeType } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeTypes.ts";
import Projects from "~/modules/timeTracking/components/components/Projects";
import CreateOrUpdateTimeTrackingForm from "~/modules/timeTracking/components/forms/CreateTimeTrackingForm";
import {
	createTimeTrackingFormDefaultValues,
} from "~/modules/timeTracking/components/forms/CreateTimeTrackingForm/createTimeTrackingFormDefaultValues.ts";
import CreateUserDeliverableForm from "~/modules/timeTracking/components/forms/CreateUserDeliverableForm";
import CreateUserLocationForm from "~/modules/timeTracking/components/forms/CreateUserLocationForm";

import { TimeTrackingFormNamesEnum } from "~/modules/timeTracking/types/timeTrackingTypes.ts";
import { getStaffingDetailsFromActiveStaffings } from "~/modules/timeTracking/utils/timeTrackingUtils.ts";
import { TimeTrackingTypeId } from "~/types/entityIds.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";

type UpdateTimeTrackingSidebarProps = {
	activeStaffings: UsersActiveStaffingType[];
	availableDeliverables: UserAvailableDeliverableType[];
	availableLocations: UserAvailableLocationType[];
	close: () => void;
	isOpen: boolean;
	date: null | Date,
	locations: LocationType[];
	timeTrackingData: TimeTrackingExtendedType | null;
	selectedUserId: UserType["id"];
	timeTrackingTypes: TimeTrackingTypeType[];
	users: UserType[];
};

const UpdateTimeTrackingSidebar: React.FunctionComponent<UpdateTimeTrackingSidebarProps> = ({
																								activeStaffings,
																								availableDeliverables,
																								availableLocations,
																								close,
																								isOpen,
																								date,
																								locations,
																								selectedUserId,
																								timeTrackingData,
																								timeTrackingTypes,
																								users,
																							}) => {
	const { t } = useTranslation();

	const [selectedTimeTrackingTypeId, setSelectedTimeTrackingTypeId] = useState<string>("1");
	const [selectedStaffingId, setSelectedStaffingId] = useState<string | null>(null);
	const [currentFormName, setCurrentFormName] = useState<TimeTrackingFormNamesEnum>(TimeTrackingFormNamesEnum.TIME_TRACKING_FORM);
	const [currentTimeTrackingFormValues, setCurrentTimeTrackingFormValues] = useState(createTimeTrackingFormDefaultValues);

	useEffect(() => {
		if (timeTrackingData) {
			const hours = Math.floor(timeTrackingData.minutes / 60);
			const minutes = timeTrackingData.minutes % 60;
			const formData = {
				hours,
				minutes,
				locationName: timeTrackingData.locationName,
				text: timeTrackingData.text,
			};
			setSelectedStaffingId(timeTrackingData.staffingId);
			setSelectedTimeTrackingTypeId(timeTrackingData.timeTrackingTypeId)
			setCurrentTimeTrackingFormValues(formData);
		}
	}, [timeTrackingData]);

	const selectedStaffingData = useMemo(() => {
		if (selectedStaffingId && activeStaffings) {
			return getStaffingDetailsFromActiveStaffings(selectedStaffingId, activeStaffings);
		}

		return null;
	}, [selectedStaffingId, activeStaffings]);

	const selectedStaffingProjectId = selectedTimeTrackingTypeId === TimeTrackingTypeId.Project ? selectedStaffingData?.projectId : null;

	const closeAndReset = useCallback(() => {
		close();
		// reset state after closing animation ends
		setTimeout(() => {
			setCurrentTimeTrackingFormValues(null);
			setSelectedTimeTrackingTypeId("1");
			setSelectedStaffingId(null);
			setCurrentFormName(TimeTrackingFormNamesEnum.TIME_TRACKING_FORM);
		}, 800);
	}, []);

	const handleStaffingIdClick = useCallback((value: string) => {
		setSelectedStaffingId(value);
		setCurrentFormName(TimeTrackingFormNamesEnum.TIME_TRACKING_FORM);
	}, []);


	const selectedTimeTrackingTypeName = useMemo(() => {
		if (selectedTimeTrackingTypeId, timeTrackingTypes) {
			const name = timeTrackingTypes.find(({ id }) => id === selectedTimeTrackingTypeId)!.name;
			return t(normalizeTKey(`entities:timeTrackingTypes.${name}`));
		}
		return "";
	}, [selectedTimeTrackingTypeId, timeTrackingTypes]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={closeAndReset}>
			<div className="flex flex-col justify-start w-full min-h-full">
				{currentFormName === null &&
					<>
						<Sidebar.Header>
							<Headline type="h2">Projekt / Bestellung wählen</Headline>
						</Sidebar.Header>
						<Sidebar.Content>
							<Projects
								data={activeStaffings}
								value={selectedStaffingId}
								onChange={handleStaffingIdClick}
							/>
						</Sidebar.Content>
						<Sidebar.Footer>
							<Button theme="none"
									onClick={closeAndReset}>
								abbrechen
							</Button>
						</Sidebar.Footer>
					</>}
				{currentFormName === TimeTrackingFormNamesEnum.TIME_TRACKING_FORM &&
					<CreateOrUpdateTimeTrackingForm
						activeStaffings={activeStaffings}
						availableDeliverables={availableDeliverables}
						availableLocations={availableLocations}
						date={date}
						parentIsVisible={isOpen}
						initialValues={currentTimeTrackingFormValues}
						locations={locations}
						onSuccess={closeAndReset}
						setCurrentTimeTrackingFormValues={setCurrentTimeTrackingFormValues}
						selectedStaffingData={selectedStaffingData}
						selectedStaffingId={selectedStaffingId}
						selectedTimeTrackingTypeId={selectedTimeTrackingTypeId}
						selectedTimeTrackingTypeName={selectedTimeTrackingTypeName}
						selectedUserId={selectedUserId}
						setCurrentFormName={setCurrentFormName}
						timeTrackingId={timeTrackingData?.id}
						users={users} />}

				{currentFormName === TimeTrackingFormNamesEnum.DELIVERABLE_FORM &&
					<CreateUserDeliverableForm
						userId={selectedUserId}
						projectId={selectedStaffingProjectId}
						projectTitle={selectedStaffingData?.projectTitle}
						setCurrentFormName={setCurrentFormName}
						timeTrackingTypeId={selectedTimeTrackingTypeId} />}

				{currentFormName === TimeTrackingFormNamesEnum.LOCATION_FORM &&
					<CreateUserLocationForm
						userId={selectedUserId}
						projectId={selectedStaffingProjectId}
						projectTitle={selectedStaffingData?.projectTitle}
						setCurrentFormName={setCurrentFormName}
						timeTrackingTypeId={selectedTimeTrackingTypeId} />}

			</div>
		</Sidebar>
	);
};

export default UpdateTimeTrackingSidebar;
