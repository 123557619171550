import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as yup from "yup";
import Button from "~/components/form/Button/Button.tsx";
import FormHasErrorsHint from "~/components/form/FormHasErrorsHint";
import { FormSectionGroup } from "~/components/form/FormSection";

import AddressFormSection from "~/components/form/formSections/AddressFormSection";
import SubmitButton from "~/components/form/SubmitButton";
import Headline from "~/components/Headline";
import Sidebar from "~/components/Sidebar";
import useClientSelectOptions from "~/hooks/form/formOptionsData/useClientSelectOptions.ts";
import useCountrySelectOptions from "~/hooks/form/formOptionsData/useCountrySelectOptions.ts";
import useGenderSelectOptions from "~/hooks/form/formOptionsData/useGenderSelectOptions.ts";
import useLanguageSelectOptions from "~/hooks/form/formOptionsData/useLanguageSelectOptions.ts";
import useSalesFunnelSelectOptions from "~/hooks/form/formOptionsData/useSalesFunnelSelectOptions.ts";
import useSalesTypeSelectOptions from "~/hooks/form/formOptionsData/useSalesTypeSelectOptions.ts";
import useUserSelectOptions, { employeesFilterFn } from "~/hooks/form/formOptionsData/useUsersSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { ClientType } from "~/modules/client/api/client/clientTypes.ts";
import {
	updateClientContactPerson,
} from "~/modules/client/api/clientContactPerson/clientContactPersonApiDispatchers.ts";
import {
	ALL_CLIENT_CONTACT_PERSONS_QUERY_KEY,
	CLIENT_CONTACT_PERSON_QUERY_KEY,
} from "~/modules/client/api/clientContactPerson/clientContactPersonQueries.ts";

import {
	ClientContactPersonType,
	UpdateClientContactPersonData,
} from "~/modules/client/api/clientContactPerson/clientContactPersonTypes.ts";
import ContactCompanyFormSection
	from "~/modules/client/components/contact/forms/formSections/ContactCompanyFormSection";
import ContactContactFormSection
	from "~/modules/client/components/contact/forms/formSections/ContactContactFormSection";
import ContactPersonalInformationFormSection
	from "~/modules/client/components/contact/forms/formSections/ContactPersonalInformationFormSection";
import ContactSalesFormSection from "~/modules/client/components/contact/forms/formSections/ContactSalesFormSection";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter, transformEmptyDateValueToNull } from "~/utils/form/formUtils.ts";

type Props = {
	allClientsData: ClientType[];
	onSuccess: () => void;
	onCancel: () => void;
	contactPersonData: ClientContactPersonType;
};

interface UpdateClientContactPersonFormData extends Omit<UpdateClientContactPersonData, "newClientId" | "dateOfBirth"> {
	dateOfBirth: Date | null;
}

const UpdateClientContactPersonForm: React.FC<Props> = ({ allClientsData, onSuccess, onCancel, contactPersonData }) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);
	const queryClient = useQueryClient();
	const clientSelectOptions = useClientSelectOptions();

	const schema = useMemo(() => {
		return yup.object({
			clientId: yup.string().required(),
			city: yup.string().default(""),
			countryId: yup.string().required(),
			dateOfBirth: yup.date().transform(transformEmptyDateValueToNull).nullable().required(),
			emailBusiness: yup.string().email().default(""),
			emailPrivate: yup.string().email().default(""),
			firstName: yup.string().required(),
			genderId: yup.string().required(),
			houseNumber: yup.string().default(""),
			jobTitle: yup.string().default(""),
			languageId: yup.string().required(),
			lastName: yup.string().required(),
			managedBy: yup.string().required(),
			mobilePhone: yup.string().default(""),
			nobilityTitle: yup.string().default(""),
			phone: yup.string().default(""),
			salesFunnelId: yup.string().required(),
			salesTypeId: yup.string().required(),
			street: yup.string().default(""),
			title: yup.string().default(""),
			useInformalSalutation: yup.boolean().required(),
			zipCode: yup.string().default(""),
		});
	}, []);

	const defaultValues = useMemo(() => {
		return {
			clientId: contactPersonData.clientId,
			city: contactPersonData.city || "",
			countryId: contactPersonData.countryId,
			dateOfBirth: contactPersonData.dateOfBirth ? new Date(contactPersonData.dateOfBirth) : null,
			emailBusiness: contactPersonData.emailBusiness || "",
			emailPrivate: contactPersonData.emailPrivate || "",
			firstName: contactPersonData.firstName,
			genderId: contactPersonData.genderId,
			houseNumber: contactPersonData.houseNumber || "",
			jobTitle: contactPersonData.jobTitle || "",
			languageId: contactPersonData.languageId,
			lastName: contactPersonData.lastName,
			managedBy: contactPersonData.managedBy,
			mobilePhone: contactPersonData.mobilePhone || "",
			nobilityTitle: contactPersonData.nobilityTitle || "",
			phone: contactPersonData.phone || "",
			salesFunnelId: contactPersonData.salesFunnelId,
			salesTypeId: contactPersonData.salesTypeId,
			street: contactPersonData.street || "",
			title: contactPersonData.title || "",
			useInformalSalutation: contactPersonData.useInformalSalutation,
			zipCode: contactPersonData.zipCode || "",
		};
	}, [contactPersonData]);

	const {
		handleSubmit,
		control,
		formState: { errors, isDirty, isSubmitted, isValid },
		setValue,
		watch,
	} = useForm<UpdateClientContactPersonFormData>({
		mode: "onChange",
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateClientContactPersonFormData>(schema),
	});

	const countrySelectOptions = useCountrySelectOptions();
	const genderSelectOptions = useGenderSelectOptions();
	const languageSelectOptions = useLanguageSelectOptions();
	const managerSelectOptions = useUserSelectOptions(employeesFilterFn);
	const salesFunnelSelectOptions = useSalesFunnelSelectOptions();
	const salesTypeSelectOptions = useSalesTypeSelectOptions();

	const selectedClientId = watch("clientId");

	const formIsSubmittable = useFormIsSubmittable({
		isDirty,
		isLoading: busy,
		isValid,
		isSubmitted,
		enableInitially: false,
	});

	useEffect(() => {
		if (selectedClientId) {
			const selectedClientData = allClientsData?.find((client) => client.id === selectedClientId);
			if (selectedClientData) {
				setValue("street", selectedClientData.street);
				setValue("houseNumber", selectedClientData.houseNumber);
				setValue("zipCode", selectedClientData.zipCode);
				setValue("city", selectedClientData.city);
				setValue("countryId", selectedClientData.countryId);
			}
		}
	}, [selectedClientId, allClientsData]);

	const onSubmit: SubmitHandler<UpdateClientContactPersonFormData> = async (data) => {
		setBusy(true);
		try {
			const dateOfBirth = data.dateOfBirth ? formatDateToYYYYMMDD(new Date(data.dateOfBirth)) : null;
			const updateData: UpdateClientContactPersonData = { ...data, dateOfBirth };
			if (updateData.clientId !== contactPersonData.clientId) {
				updateData.newClientId = updateData.clientId;
			}
			updateData.dateOfBirth = data.dateOfBirth ? formatDateToYYYYMMDD(new Date(data.dateOfBirth)) : null;
			await updateClientContactPerson(contactPersonData.clientId, contactPersonData.id, updateData);
			await queryClient.refetchQueries({ queryKey: CLIENT_CONTACT_PERSON_QUERY_KEY(contactPersonData.id) });
			queryClient.refetchQueries({ queryKey: ALL_CLIENT_CONTACT_PERSONS_QUERY_KEY });
			onSuccess();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex flex-col justify-start w-full min-h-full"
		>
			<Sidebar.Header>
				<Headline type="h2">Kontaktdaten aktualisieren</Headline>
			</Sidebar.Header>
			<Sidebar.Content>
				{busy && <Sidebar.BusyOverlay />}
				<FormSectionGroup>
					<ContactCompanyFormSection<UpdateClientContactPersonFormData>
						control={control}
						clientSelectOptions={clientSelectOptions}
						errors={errors}
					/>
					<ContactPersonalInformationFormSection<UpdateClientContactPersonFormData>
						control={control}
						errors={errors}
						genderSelectOptions={genderSelectOptions}
					/>

					<ContactContactFormSection<UpdateClientContactPersonFormData>
						control={control}
					/>

					<AddressFormSection
						control={control}
						countrySelectOptions={countrySelectOptions}
					/>

					<ContactSalesFormSection
						control={control}
						errors={errors}
						languageSelectOptions={languageSelectOptions}
						managerSelectOptions={managerSelectOptions}
						salesFunnelSelectOptions={salesFunnelSelectOptions}
						salesTypeSelectOptions={salesTypeSelectOptions}
					/>
				</FormSectionGroup>
			</Sidebar.Content>
			<Sidebar.Footer>
				<FormHasErrorsHint show={!isValid}
								   className="mr-2" />
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					abbrechen
				</Button>
			</Sidebar.Footer>
		</form>
	);
};

export default UpdateClientContactPersonForm;
