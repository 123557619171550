import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import React, { ForwardedRef, forwardRef, useEffect, useState } from "react";
import { Control, useController } from "react-hook-form";
import FormInput from "~/components/form/FormInput";
import { InputPlain } from "~/components/form/Input/Input.tsx";
import Label from "~/components/form/Label";
import { formatNumberToCurrency } from "~/utils/currencyUtils.ts";

interface SharedProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "name" | "onChange" | "onBlur"> {
	className?: string;
	errorIconOnly?: boolean;
	name: string;
	label?: string;
	error?: string;
	maxValue?: number;
}

interface CurrencyInputProps extends SharedProps {
	value: number;
	onChange: (value: number) => void;
	onBlur?: () => void;
}

export const CurrencyInputPlain = forwardRef<HTMLInputElement, CurrencyInputProps>(
	({
		 className,
		 value,
		 onBlur,
		 onChange,
		 label,
		 error,
		 errorIconOnly,
		 name,
		 maxValue,
		 ...props
	 }: CurrencyInputProps, ref: ForwardedRef<HTMLInputElement>) => {
		const [displayValue, setDisplayValue] = useState<string>(formatNumberToCurrency(value));

		useEffect(() => {
			setDisplayValue(formatNumberToCurrency(value));
		}, [value]);

		function handleKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
			// keyCode 38 and 40 correspond to arrow up and arrow down, respectively
			if (e.key === "ArrowUp" || e.key === "ArrowDown") {
				e.preventDefault();  // Prevent scrolling
				let numberValue = value;
				const incrementValue = e.shiftKey ? 1000 : 100;  // If shift is pressed, increase/decrease by 1000, else 100
				if (e.key === "ArrowUp") {  // arrow up key
					numberValue += incrementValue;  // Increase
					if (maxValue !== undefined && numberValue > maxValue) {
						numberValue = maxValue;
					}
				} else if (e.key === "ArrowDown") {  // arrow down key
					numberValue -= incrementValue;  // Decrease
					if (numberValue < 0) {
						numberValue = 0;
					}
				}
				onChange(numberValue);
			}
		}

		function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
			e.target.select();  // Select input value
		}

		function handleBlur() {
			const isValidFormat = /^(\d{1,3}(\.\d{3})*|\d+)(,\d{1,2})?$/.test(displayValue);
			if (isValidFormat) {
				let normalizedValue = displayValue.replace(/\./g, "").replace(",", ".");
				if (!normalizedValue.includes(".")) {
					normalizedValue += ".00";
				} else if (normalizedValue.split(".")[1].length === 1) {
					normalizedValue += "0";
				}

				let numberValue = parseFloat(normalizedValue) * 100;
				numberValue = Math.round(numberValue);

				if (maxValue !== undefined && numberValue > maxValue) {
					numberValue = maxValue;
					setDisplayValue(formatNumberToCurrency(maxValue));
				}
				onChange(numberValue);
			} else {
				onChange(0);
			}
			onBlur && onBlur();
		}

		function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
			const newValue = e.target.value.replace(/[^0-9.,]/g, "");
			setDisplayValue(newValue);
		}

		return (
			<FormInput className={clsx(props.disabled && "opacity-50")}>
				{label && <Label htmlFor={name}>{label}</Label>}
				<div className="relative rounded-md shadow-sm">
					<InputPlain
						name={name}
						max={maxValue}
						type="text"
						value={displayValue}
						onChange={handleChange}
						onBlur={handleBlur}
						onFocus={handleFocus}
						onKeyDown={handleKeydown}
						ref={ref}
						{...props}
					/>
					{error && (
						<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
							<ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
						</div>
					)}
				</div>
				{error && !errorIconOnly && (
					<p className="mt-2 text-sm text-red-600" id="email-error">
						{error}
					</p>
				)}
			</FormInput>
		);

	},
);

interface CurrencyInputControllerProps extends SharedProps {
	name: string;
	control: Control<any>;
	defaultValue?: number;
	useControllerError?: boolean;
	rules?: any;
}

const CurrencyInput: React.FC<CurrencyInputControllerProps> = ({
																   name,
																   control,
																   maxValue,
																   defaultValue = 0,
																   useControllerError,
																   error,
																   rules,
																   ...rest
															   }) => {
	const { field, fieldState: { error: errorFromRHF } } = useController({
		name,
		control,
		rules,
	});

	let errorMessage = useControllerError ? errorFromRHF?.message : error;
	return <CurrencyInputPlain {...field} {...rest} error={errorMessage} maxValue={maxValue} />;
};


export default CurrencyInput;
