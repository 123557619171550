import clsx from "clsx";
import { PropsWithChildren } from "react";

type ParagraphProps = PropsWithChildren & {
	className?: string;
};

const Paragraph: React.FunctionComponent<ParagraphProps> = ({ children,className }) => {
	return <div className={clsx("font-medium", className)}>{children}</div>;
};

export default Paragraph;