import React, { ReactNode } from "react";
import { RadioGroup as HeadlessUIRadioGroup } from "@headlessui/react";
import { Control, Controller } from "react-hook-form";
import FormInput from "~/components/form/FormInput";
import Label from "~/components/form/Label";
import clsx from "clsx";

interface RadioGroupPlainProps {
	label?: string;
	name: string;
	options: {
		value: string | number | boolean;
		displayName?: string;
		element?: ReactNode;
		disabled?: boolean;
	}[];
	value: string | null;
	onChange: (value: string) => void;
	numCols?: 2 | 3 | 4 | 5;
}

export const RadioGroupPlain: React.FC<RadioGroupPlainProps> = ({ label, options, name = "", value, onChange, numCols = 4 }) => {
	return (
		<FormInput>
			{label && <Label>{label}</Label>}
			<HeadlessUIRadioGroup value={value} onChange={onChange} name={name}>
				<div
					className={clsx("grid gap-1", {
						"grid-cols-2": 2 === numCols,
						"grid-cols-3": 3 === numCols,
						"grid-cols-4": 4 === numCols,
						"grid-cols-5": 5 === numCols,
					})}
				>
					{options.map(({ displayName, element, value: optValue, disabled }, optIndex) => (
						<HeadlessUIRadioGroup.Option
							key={optIndex}
							value={optValue}
							className={({ checked }) =>
								clsx(
									checked
										? "bg-primary-500 text-white hover:bg-primary-700"
										: "ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50",
									"flex items-center justify-center rounded-md py-2 px-2 text-xs font-semibold sm:flex-1 cursor-pointer",
									"focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-500",
								)
							}
							disabled={disabled}
						>
							<HeadlessUIRadioGroup.Label as="span">
								{element || displayName || optValue}
							</HeadlessUIRadioGroup.Label>
						</HeadlessUIRadioGroup.Option>
					))}
				</div>
			</HeadlessUIRadioGroup>
		</FormInput>
	);
};

interface Props {
	label?: string;
	name: string;
	options: {
		value: string | number | boolean;
		displayName?: string;
		element?: React.ReactNode;
		disabled?: boolean;
	}[];
	control: Control<any>;
	numCols?: 2 | 3 | 4 | 5;
}

const RadioGroup: React.FC<Props> = ({ label, options, control, name = "", numCols = 4 }) => {
	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange, value } }) => (
				<RadioGroupPlain
					label={label}
					options={options}
					name={name}
					value={value}
					onChange={onChange}
					numCols={numCols}
				/>
			)}
		/>
	);
};

export default RadioGroup;

