import { useMemo } from "react";
import { getSelectBoxDefaultOptionsData, mapDataToSelectOptionsData } from "~/utils/form/formUtils.ts";
import { FormInputOption } from "~/types/form.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";
import { useAllClients } from "~/modules/client/api/client/clientQueries.ts";


const useClientSelectOptions = (includeCtaOption: boolean = false): FormInputOption[] => {
	const { data: allClientsData } = useAllClients();
	return useMemo(() => {
		if (allClientsData) {
			const clientsSorted = [...allClientsData].sort(byObjectProperty("displayName"));
			const options = mapDataToSelectOptionsData(clientsSorted, "id", "displayName");

			if (includeCtaOption) {
				return [getSelectBoxDefaultOptionsData(), ...options];
			}
			return options;
		}
		return [];
	}, [allClientsData, includeCtaOption]);
};

export default useClientSelectOptions;

