import { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import {
	CreateOrUpdateInvoiceRecipientData,
	InvoiceRecipientType,
} from "~/modules/billing/api/invoiceRecipient/invoiceRecipientTypes.ts";

export function indexInvoiceRecipientByClient(clientId: string): AxiosApiResponsePromise<InvoiceRecipientType[]> {
	return axiosApi.get(`clients/${clientId}/invoice-recipients`);
}

export function getInvoiceRecipient(clientId: string,
	invoiceRecipientId: string): AxiosApiResponsePromise<InvoiceRecipientType> {
	return axiosApi.get(`clients/${clientId}/invoice-recipients/${invoiceRecipientId}`);
}

export function createInvoiceRecipient({ clientId, data }: {
	clientId: string,
	data: CreateOrUpdateInvoiceRecipientData
}): AxiosApiResponsePromise<InvoiceRecipientType> {
	return axiosApi.post(`clients/${clientId}/invoice-recipients`, { ...data });
}

export function updateInvoiceRecipient({ clientId, invoiceRecipientId, data }: {
	clientId: string,
	invoiceRecipientId: string,
	data: CreateOrUpdateInvoiceRecipientData
}): AxiosApiResponsePromise<InvoiceRecipientType> {
	return axiosApi.put(`clients/${clientId}/invoice-recipients/${invoiceRecipientId}`, { ...data });
}

export function deleteInvoiceRecipient({ clientId, invoiceRecipientId }: {
	clientId: string,
	invoiceRecipientId: string
}): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`clients/${clientId}/invoice-recipients/${invoiceRecipientId}`);
}