import React, { ChangeEvent, forwardRef } from "react";
import { Control, useController } from "react-hook-form";
import FormInput from "~/components/form/FormInput";
import FormInputError from "~/components/form/FormInputError";
import Label from "~/components/form/Label";

type SharedPropsType = {
	label?: string;
	placeholder?: string;
	error?: string;
};

type TextareaPlainProps = SharedPropsType & {
	name?: string;
	value?: string;
	onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
	onBlur?: () => void;
};

export const TextareaPlain = forwardRef<HTMLTextAreaElement, TextareaPlainProps>(
	({ name, label, placeholder, error, value="", onChange, onBlur }, ref) => {
		return (
			<FormInput className="col-span-full">
				{label && <Label>{label}</Label>}
				<div>
					<textarea
						rows={3}
						className="block w-full rounded-md border-0 p-1.5 shadow-sm text-gray-900 placeholder:text-gray-400 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
						name={name}
						id={name}
						value={value}
						ref={ref}
						onChange={onChange}
						onBlur={onBlur}
					/>
				</div>
				{error && <FormInputError>{error}</FormInputError>}
				{placeholder && <p className="mt-3 text-sm leading-6 text-gray-600">{placeholder}</p>}
			</FormInput>
		);
	},
);

type TextareaProps = SharedPropsType & {
	name: string;
	control: Control<any>;
};

const Textarea: React.FC<TextareaProps> = ({ name, control, label, placeholder }) => {
	const {
		field: { ref, value, ...inputProps },
		fieldState: { error },
	} = useController({
		name,
		control,
	});

	return (
		<TextareaPlain
			ref={ref}
			value={value}
			{...inputProps}
			label={label}
			placeholder={placeholder}
			error={error?.message}
		/>
	);
};

export default Textarea