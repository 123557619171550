import { CSSProperties, PropsWithChildren, ReactNode, useEffect, useState } from "react";

import Headline from "~/components/Headline/Headline";
import clsx from "clsx";

interface Props {
	title?: string;
	theme?: "default" | "dark" | "none";
	sticky?: boolean;
	style?: CSSProperties;
}

const SectionHeading: React.FC<PropsWithChildren<Props>> = ({ children, title, theme = "default", sticky, style }) => (
	<div
		className={clsx(
			"border-b pb-5 sm:pb-0",
			{
				"bg-gray-100 border-gray-200": "default" === theme,
				"border-gray-300": "dark" === theme,
				"border-transparent": "none" === theme,
			},
			undefined === sticky ? "relative" : "sticky z-10 shadow-xl shadow-gray-100",
		)}
		style={style}
	>
		{title ? (
			<div className="md:flex md:items-center md:justify-between">
				<Headline type="h3">{title}</Headline>
			</div>
		) : (
			<div className="pt-1">{children}</div>
		)}
	</div>
);

const Buttons: React.FC<PropsWithChildren> = ({ children }) => (
	<div className="mt-3 flex justify-end items-center gap-1 md:absolute md:right-0 md:top-1 md:-mt-1.5">
		{children}
	</div>
);

interface TabsProps {
	tabs: {
		name: string;
		value: string;
		icon?: ReactNode;
		active?: boolean;
	}[];
	onChange: (tab: any) => void;
	deselectable?: boolean;
}

const Tabs: React.FC<TabsProps> = ({ tabs, onChange, deselectable = false }) => {
	const defaultActiveIndex = tabs.findIndex((tab) => true === tab.active);
	const [activeTab, setActiveTab] = useState<number | undefined>(
		deselectable ? (-1 < defaultActiveIndex ? defaultActiveIndex : undefined) : Math.max(0, defaultActiveIndex),
	);

	useEffect(() => {
		if (undefined !== activeTab) {
			onChange(tabs[activeTab].value);
		} else {
			onChange(undefined);
		}
	}, [activeTab]);

	const onClickTab = (tabIndex: number) => {
		setActiveTab(activeTab === tabIndex && deselectable ? undefined : tabIndex);
	};

	return (
		<div className="mt-4">
			<div className="sm:hidden">
				<label htmlFor="current-tab" className="sr-only">
					Select a tab
				</label>
				<select
					id="current-tab"
					name="current-tab"
					className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
					defaultValue={activeTab}
					onChange={(e) => setActiveTab(parseInt(e.target.value, 10))}
				>
					{tabs.map(({ name }, tabIndex) => (
						<option key={`sectionTabs-m-${tabIndex}`} value={tabIndex}>
							{name}
						</option>
					))}
				</select>
			</div>
			<div className="hidden sm:block">
				<nav className="-mb-px flex space-x-8">
					{tabs.map(({ name, icon }, tabIndex) => (
						<div
							key={`sectionTabs-${tabIndex}`}
							onClick={onClickTab.bind(this, tabIndex)}
							className={clsx(
								activeTab === tabIndex
									? "border-secondary-500 text-secondary-500"
									: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
								"whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium cursor-pointer",
								"flex align-center gap-2",
							)}
							aria-current={activeTab === tabIndex ? "page" : undefined}
						>
							{icon && icon}
							{name}
						</div>
					))}
				</nav>
			</div>
		</div>
	);
};

export default Object.assign(SectionHeading, { Buttons, Tabs });
