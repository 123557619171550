import { Outlet } from "react-router-dom";

const RootPage: React.FC = () => {
	return (
		<div className="font-body font-normal h-screen flex justify-center items-center">
			<Outlet />
		</div>
	);
};

export default RootPage;
