import React, { useCallback } from "react";
import { useQueryClient } from "react-query";
import Sidebar from "~/components/Sidebar";
import {
	USERS_VACATION_LEDGER_ENTRIES_QUERY_KEY,
	USERS_VACATION_LEDGER_REPORT_QUERY_KEY,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import { USERS_WORKING_SCHEDULES_QUERY_KEY } from "~/modules/absence/api/workingSchedule/workingScheduleQueries.ts";
import CreateWorkingScheduleForm from "~/modules/absence/components/CreateWorkingScheduleForm";

type CreateWorkingScheduleSidebarProps = {
	onClose: () => void;
	isOpen: boolean;
	userId: string,
	workingSchedulesMinDate: Date;
};

const CreateWorkingScheduleSidebar: React.FunctionComponent<CreateWorkingScheduleSidebarProps> = ({
	onClose,
	isOpen,
	userId,
	workingSchedulesMinDate,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(
		async () => {
			queryClient.refetchQueries(USERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(userId));
			await queryClient.invalidateQueries(USERS_WORKING_SCHEDULES_QUERY_KEY(userId));
			await queryClient.invalidateQueries(USERS_VACATION_LEDGER_REPORT_QUERY_KEY(userId));
			onClose();
		},
		[queryClient],
	);


	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={onClose}>
			<CreateWorkingScheduleForm
				onCancel={onClose}
				onSuccess={handleSuccess}
				userId={userId}
				workingSchedulesMinDate={workingSchedulesMinDate}
			/>
		</Sidebar>
	);
};

export default CreateWorkingScheduleSidebar;
