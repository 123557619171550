import { Control, FieldErrors, FieldValues } from "react-hook-form";
import ComboBox from "~/components/form/ComboBox";
import FormSection from "~/components/form/FormSection";

import { FormInputOption } from "~/types/form.ts";

type ClientAndContactPersonFormSectionProps<T extends FieldValues> = {
	control: Control<T>;
	errors: FieldErrors<T>;
	clientSelectOptions: FormInputOption[];
	clientContactPersonSelectOptions: FormInputOption[];
	clientInputDisabled: boolean;
	clientContactPersonInputDisabled: boolean;
};

type ClientAndContactPersonFormSectionType = {
	clientId: string | undefined;
	clientContactPersonId: string | undefined;
};

const ClientAndContactPersonFormSection = <T extends ClientAndContactPersonFormSectionType>({
	control,
	errors,
	clientInputDisabled,
	clientContactPersonInputDisabled,
	clientSelectOptions,
	clientContactPersonSelectOptions,
}: ClientAndContactPersonFormSectionProps<T>) => {

	return (
		<FormSection title="Kunde & Ansprechpartner">
			<div className="grid gap-x-6 gap-y-2 grid-cols-6">
				<div className="col-span-6">
					<ComboBox
						allowNew={false}
						placeholder="Kunde auswählen"
						name="clientId"
						disabled={clientInputDisabled}
						label="Kunde"
						optionsData={clientSelectOptions}
						control={control}
						error={errors.clientId?.message as string}
					/>
				</div>
				<div className="col-span-6">
					<ComboBox
						allowNew={false}
						name="clientContactPersonId"
						disabled={clientContactPersonInputDisabled}
						label="Ansprechpartner"
						optionsData={clientContactPersonSelectOptions}
						control={control}
						error={errors.clientContactPersonId?.message as string}
					/>
				</div>
			</div>
		</FormSection>
	);
};

export default ClientAndContactPersonFormSection;
