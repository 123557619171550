import { useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { appRoutes } from "~/constants/appRoute.ts";
import { useAuth } from "~/contexts/AuthContext";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { PermissionNames } from "~/types/entityNames.ts";

type LandingPageProps = {};

const LandingPage: React.FunctionComponent<LandingPageProps> = ({}) => {
	const {  user,hasAnyPermission } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		const options = {replace: true};

		if(hasAnyPermission(PermissionNames.CanManageProjects)) {
			navigate(appRoutes.projects().path, options);
		}
		else if(hasAnyPermission(PermissionNames.CanManageInvoices)) {
			navigate(appRoutes.monthlyBillingReport().path, options);
		}
		else if(hasAnyPermission(PermissionNames.CanManageUsers)) {
			navigate(appRoutes.users().path, options);
		}
		else {
			navigate(generatePath(appRoutes.timeTracking().path,{userId:user!.id}), options);
		}
	}, []);
	return <LoadingPage/>;
};

export default LandingPage;