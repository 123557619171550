import MainNavigation from "components/MainNavigation";
import { Outlet } from "react-router-dom";

const AppLayout: React.FC = () => {
	return (
		<div className="font-body font-normal">
			<MainNavigation />
			<main className="pb-10 lg:pl-64">
				<div id="breadcrumb" />
				<Outlet />
			</main>
		</div>
	);
};

export default AppLayout;
