import {
	ButtonNewItemSizeVariantProps, ButtonNewItemThemeVariantProps,
	buttonNewItemVariants,
} from "~/components/buttons/ButtonNewItem/buttonNewItemVariants.ts";
import { HeroIconType } from "~/types/heroIconTypes.ts";
import { PlusIcon } from "@heroicons/react/24/outline";
import { PropsWithChildren } from "react";

interface Props {
	onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	disabled?: boolean;
	icon?: HeroIconType;
	size?: ButtonNewItemSizeVariantProps;
	theme?: ButtonNewItemThemeVariantProps;
}

const ButtonNewItem: React.FC<PropsWithChildren<Props>> = ({
	onClick,
	disabled = false,
	icon,
	size = "default",
	theme,
	children,
}) => {
	const { button, icon: iconVariants } = buttonNewItemVariants({ disabled,size, theme });
	const Icon = icon ?? PlusIcon;
	return (
		<button
			type="button"
			className={button()}
			onClick={onClick}
			disabled={disabled}
		>
			<Icon
				className={iconVariants()}
			/>
			{children && <div className="text-sm font-medium">{children}</div>}
		</button>
	);
};

export default ButtonNewItem;
