import { Navigate, useParams } from "react-router-dom";
import { appRoutes } from "~/constants/appRoute.ts";
import { useAuth } from "~/contexts/AuthContext";
import { HasAnyPermissionParamsType } from "~/contexts/AuthContext/AuthContext.tsx";


const userViewGuard = (permissionName: HasAnyPermissionParamsType, ownerShipPermissionName?:HasAnyPermissionParamsType) => {
	const { hasAnyPermission } = useAuth();
	const { user } = useAuth();
	const { userId:userIdParams } = useParams();
	const permissionCheckPassed = hasAnyPermission(permissionName);

	if (!ownerShipPermissionName && permissionCheckPassed) {
		return null;
	}
	else if (permissionCheckPassed && ownerShipPermissionName) {
		if(userIdParams === user!.id) {
			return null;
		}
		else if(hasAnyPermission(ownerShipPermissionName)) {
			return null;
		}
	}
	return <Navigate to={appRoutes.landing().path} />;

};

export default userViewGuard;
