import { PropsWithChildren } from "react";
import clsx from "clsx";

interface TagProps {
	onClick?: () => void;
}

const Tag: React.FC<PropsWithChildren<TagProps>> = ({ children, onClick }) => (
	<span
		onClick={onClick}
		className={clsx(
			"inline-flex items-center px-2 py-1 ring-1 ring-inset",
			"rounded-md text-sm font-medium",
			"bg-gray-50 text-gray-600 ring-gray-500/10",
			"transition-color duration-200",
			{
				"cursor-pointer hover:ring-gray-500/30 hover:text-gray-800": undefined !== onClick,
			},
		)}
	>
		{children}
	</span>
);

export default Tag;
