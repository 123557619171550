import { yupResolver } from "@hookform/resolvers/yup";
import { format, startOfDay } from "date-fns";
import Decimal from "decimal.js-light";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/form/Button";
import FormHasErrorsHint from "~/components/form/FormHasErrorsHint";
import SubmitButton from "~/components/form/SubmitButton";
import Headline from "~/components/Headline";
import Sidebar from "~/components/Sidebar/Sidebar.tsx";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { updateAbsence } from "~/modules/absence/api/absence/absenceApiDispatchers.ts";
import CreateOrUpdateAbsenceFormSection
	from "~/modules/absence/components/formSections/CreateOrUpdateAbsenceFormSection";
import { FormInputOption } from "~/types/form.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

interface UpdateAbsenceData {
	absenceTypeId: string;
	startDate: Date;
	endDate: Date;
	comment: string | null;
	firstDayIsHalf: boolean;
	lastDayIsHalf: boolean;
}

type UpdateAbsenceProps = {
	absenceData: AbsenceType;
	absenceTypeSelectOptions: FormInputOption[];
	remainingVacationDays: Decimal;
	onSuccess: () => void;
	onCancel: () => void;
	workingSchedules: WorkingScheduleType[];
	userId: string,
};

const UpdateAbsenceForm: React.FunctionComponent<UpdateAbsenceProps> = ({
	absenceData,
	absenceTypeSelectOptions,
	onSuccess,
	onCancel,
	remainingVacationDays,
	workingSchedules,
	userId,
}) => {
	const [busy, setBusy] = useState(false);
	const [overlapError, setOverlapError] = useState(false);
	const schema = useMemo(() => {
		return yup.object({
			absenceTypeId: yup.string().required(),
			startDate: yup.date().required(),
			endDate: yup
				.date()
				.nullable()
				.required()
				.min(yup.ref("startDate"), "Das Starddatum muss vor dem Enddatum liegen."),
			comment: yup.string().nullable().default(""),
			firstDayIsHalf: yup.boolean().default(false),
			lastDayIsHalf: yup.boolean().default(false),
		});
	}, []);
	const defaultValues = useMemo(() => {
		return {
			absenceTypeId: absenceData.absenceTypeId,
			startDate: startOfDay(new Date(absenceData.startDate)),
			endDate: startOfDay(new Date(absenceData.endDate)),
			comment: absenceData.comment || "",
			firstDayIsHalf: absenceData.firstDayIsHalf,
			lastDayIsHalf: absenceData.lastDayIsHalf,
		};
	}, [absenceData]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
		watch,
		trigger,
	} = useForm<UpdateAbsenceData>({
		mode: "onChange",
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateAbsenceData>(schema),
	});
	const startDate = watch("startDate");
	const endDate = watch("endDate");

	useEffect(() => {
		trigger();
		setOverlapError(false);
	}, [startDate, endDate]);

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: busy,
	});

	const onSubmit = useCallback(async (data: UpdateAbsenceData) => {
		const startDateFormatted = format(data.startDate, "yyyy-MM-dd");
		const endDateFormatted = format(data.endDate, "yyyy-MM-dd");

		try {
			setBusy(true);
			await updateAbsence({
				userId,
				absenceId: absenceData.id,
				data: { ...data, startDate: startDateFormatted, endDate: endDateFormatted },
			});
			onSuccess();
		} catch (error) {

			const apiError = handleApiError(error);
			if (apiError.type === ApiResponseErrorType.VALIDATION) {
				if (apiError.messages.endDate.find((message) => message.rule === "absencesOverlapRule")) {
					setOverlapError(true);
					setBusy(false);
				}
			}
		}


	}, [userId]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex flex-col justify-start w-full min-h-full"
		>
			<Sidebar.Header>
				<Headline type="h2">Abwesenheit bearbeiten</Headline>
			</Sidebar.Header>
			<Sidebar.Content>
				{busy && <Sidebar.BusyOverlay />}
				<CreateOrUpdateAbsenceFormSection
					absenceTypeSelectOptions={absenceTypeSelectOptions}
					control={control}
					overlapError={overlapError}
					remainingVacationDays={remainingVacationDays}
					watch={watch}
					workingSchedules={workingSchedules} />
			</Sidebar.Content>
			<Sidebar.Footer>
				<FormHasErrorsHint show={!isValid} className="mr-2"/>
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					abbrechen
				</Button>
			</Sidebar.Footer>
		</form>);
};

export default UpdateAbsenceForm;
