import BreadcrumbsPortal from "components/Breadcrumbs";
import Decimal from "decimal.js-light";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ContentWrapper from "~/components/ContentWrapper";
import Button from "~/components/form/Button";
import PageHeading from "~/components/headings/PageHeading/PageHeading.tsx";
import SectionHeading from "~/components/headings/SectionHeading/SectionHeading.tsx";
import { appRoutes } from "~/constants/appRoute.ts";
import { useAuth } from "~/contexts/AuthContext";
import AbsencePageTabs from "~/modules/absence/components/AbsencePageTabs";
import AbsenceViewUserSelect from "~/modules/absence/components/AbsencePageUserSelect";
import CreateWorkingScheduleSidebar from "~/modules/absence/components/CreateWorkingScheduleSidebar";
import DeleteWorkingScheduleModal from "~/modules/absence/components/DeleteWorkingScheduleModal";
import RemainingVacationDays from "~/modules/absence/components/RemainingVacationDays";
import UpdateWorkingScheduleSidebar from "~/modules/absence/components/UpdateWorkingScheduleSidebar";
import WorkingSchedulesTable from "~/modules/absence/components/WorkingSchedulesTable";
import { AbsenceSubRoutes } from "~/modules/user/router/UserRouter/UserRouter.tsx";
import { PermissionNames } from "~/types/entityNames.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type WorkingSchedulesViewProps = {
	userId: string,
	vacationLedgerReport: VacationLedgerReportType;
	workingSchedules: WorkingScheduleType[];
	workingSchedulesMinDate: Date;
};

export type RenderWorkingScheduleTableRowFunction = ({
	compactView,
	setWorkingScheduleIdToDelete,
	setWorkingScheduleIdToUpdate,
	userCanAdminVacations,
	workingScheduleData,
}: {
	compactView?: boolean,
	setWorkingScheduleIdToDelete: (id: string) => void,
	setWorkingScheduleIdToUpdate: (id: string) => void,
	userCanAdminVacations: boolean
	workingScheduleData: WorkingScheduleType,

}) => ReactElement;

const WorkingSchedulesView: React.FunctionComponent<WorkingSchedulesViewProps> = ({
	userId,
	vacationLedgerReport,
	workingSchedules,
	workingSchedulesMinDate,
}) => {
	const { hasAnyPermission } = useAuth();
	const { t } = useTranslation();
	const [showCreateWorkingScheduleSidebar, setShowCreateWorkingScheduleSidebar] = useState(false);
	const [workingScheduleIdToUpdate, setWorkingScheduleIdToUpdate] = useState<string | null>(null);
	const [workingScheduleIdToDelete, setWorkingScheduleIdToDelete] = useState<string | null>(null);

	const userCanAdminVacations = hasAnyPermission(PermissionNames.CanManageAbsences);
	const remainingVacationDays = new Decimal(parseFloat(vacationLedgerReport.daysRemaining));

	const renderTableRow: RenderWorkingScheduleTableRowFunction = useCallback(({
		workingScheduleData,
		userCanAdminVacations = false,
		compactView = false,
		setWorkingScheduleIdToUpdate,
		setWorkingScheduleIdToDelete,

	}) => {
		const workingDays: boolean[] = [
			workingScheduleData.monday,
			workingScheduleData.tuesday,
			workingScheduleData.wednesday,
			workingScheduleData.thursday,
			workingScheduleData.friday,
		];

		return <WorkingSchedulesTable.Row key={"workingScheduleData-" + workingScheduleData.id}
										  compactView={compactView}
										  validFrom={formatDateWithGermanMonth(new Date(workingScheduleData.validFrom))}
										  workingDays={workingDays}
										  isEditable={userCanAdminVacations}
										  isDeletable={userCanAdminVacations && workingScheduleData.isDeletable}
										  onEditClick={() => setWorkingScheduleIdToUpdate(workingScheduleData.id)}
										  onDeleteClick={() => setWorkingScheduleIdToDelete(workingScheduleData.id)}
		/>;
	}, []);

	const workingScheduleTableRows = useMemo(() => {
		if (workingSchedules.length > 0) {
			return workingSchedules.sort(byObjectProperty("validFrom", "desc")).map((schedule) => {
				return renderTableRow({
					workingScheduleData: schedule,
					userCanAdminVacations,
					setWorkingScheduleIdToUpdate,
					setWorkingScheduleIdToDelete,
				});
			});
		}
		return [];
	}, [workingSchedules, userCanAdminVacations, renderTableRow]);

	const selectedWorkingScheduleDataToUpdate = useMemo(() => {
		if (workingSchedules && workingScheduleIdToUpdate) {
			return workingSchedules.find((schedule) => schedule.id === workingScheduleIdToUpdate);
		}
		return null;
	}, [workingScheduleIdToUpdate, workingSchedules]);

	const selectedWorkingScheduleDataToDelete = useMemo(() => {
		if (workingSchedules && workingScheduleIdToDelete) {

			const workingScheduleData = workingSchedules.find((schedule) => schedule.id === workingScheduleIdToDelete);
			if(workingScheduleData){
				return workingScheduleData;
			}
		}
		return null;
	}, [workingScheduleIdToDelete, workingSchedules]);

	return (
		<>
			<BreadcrumbsPortal pages={[appRoutes.absences()]}
							   className="bg-white" />
			<PageHeading title={t("timetracking.title", "Arbeitszeitmodelle")} />
			<PageHeading.BottomBar>
				<div
					className="w-full flex items-center gap-2 text-sm font-medium text-gray-700 hover:text-gray-900 gap-x-5 z-40">
					<RemainingVacationDays remainingVacationDays={remainingVacationDays} />
					<AbsenceViewUserSelect userIsVacationManager={userCanAdminVacations}
										   userId={userId}
										   currentSubPath={AbsenceSubRoutes.workingSchedules} />
				</div>
				<Button onClick={() => setShowCreateWorkingScheduleSidebar(true)}>
					{t("projects.btnNewProject", "Neues Arbeitszeitmodell")}
				</Button>
			</PageHeading.BottomBar>
			<ContentWrapper className="isolate">
				<SectionHeading sticky
								style={{ top: 73 }}>
					<AbsencePageTabs selectedTabName={AbsenceSubRoutes.workingSchedules} />
				</SectionHeading>
				<div>
					<WorkingSchedulesTable>
						{workingScheduleTableRows}
					</WorkingSchedulesTable>
				</div>
			</ContentWrapper>
			<CreateWorkingScheduleSidebar
				onClose={() => setShowCreateWorkingScheduleSidebar(false)}
				isOpen={showCreateWorkingScheduleSidebar}
				userId={userId}
				workingSchedulesMinDate={workingSchedulesMinDate}
			/>
			<UpdateWorkingScheduleSidebar onClose={() => setWorkingScheduleIdToUpdate(null)}
										  isOpen={!!selectedWorkingScheduleDataToUpdate}
										  workingScheduleData={selectedWorkingScheduleDataToUpdate}
										  workingSchedulesMinDate={workingSchedulesMinDate} />

				<DeleteWorkingScheduleModal
					isOpen={!!selectedWorkingScheduleDataToDelete}
					onCloseClick={() => setWorkingScheduleIdToDelete(null)}
					renderTableRow={renderTableRow}
					workingScheduleData={selectedWorkingScheduleDataToDelete} />
		</>
	);
};

export default WorkingSchedulesView;