import { PropsWithChildren } from "react";
import { Control, FieldErrors, FieldValues } from "react-hook-form";
import FormSection from "~/components/form/FormSection";
import Select from "~/components/form/Select";

import { FormInputOption } from "~/types/form.ts";

type ProgramAndQuotaForm<T extends FieldValues> = {
	control: Control<T>;
	errors: FieldErrors<T>;
	programSelectOptions: FormInputOption[];
	quotaSelectOptions: FormInputOption[];
	programInputDisabled: boolean;
	quotaInputDisabled: boolean;
};

type ProgramAndQuotaFormSectionType = {
	programId: string | null;
	quotaId: string | null;
};

const ProgramAndQuotaFormSection = <T extends ProgramAndQuotaFormSectionType>({
	control,
	errors,
	programSelectOptions,
	quotaSelectOptions,
	programInputDisabled,
	quotaInputDisabled,
}: PropsWithChildren<ProgramAndQuotaForm<T>>) => {
	return (
		<FormSection title="Programm & Abrechnungs-kontingent">
			<div className="grid gap-x-6 gap-y-2 grid-cols-6">
				<div className="col-span-6">
					<Select
						name="programId"
						disabled={programInputDisabled}
						label="Programm"
						optionsData={programSelectOptions}
						control={control}
						error={errors.programId?.message as string}
					/>
				</div>
				<div className="col-span-6">
					<Select
						name="quotaId"
						disabled={quotaInputDisabled}
						label="Abrechnungskontingent"
						optionsData={quotaSelectOptions}
						control={control}
						error={errors.quotaId?.message as string}
					/>
				</div>
			</div>
		</FormSection>
	);
};

export default ProgramAndQuotaFormSection;
