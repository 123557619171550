import { useMemo } from "react";
import { getSelectBoxDefaultOptionsData, createSelectBoxOptionData } from "~/utils/form/formUtils.ts";
import { FormInputOption } from "~/types/form.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";
import { useAllSalesFunnels } from "~/modules/client/api/salesFunnel/salesFunnelQueries.ts";


const useSalesFunnelSelectOptions = (includeCtaOption: boolean = false): FormInputOption[] => {
	const { data: salesFunnelData } = useAllSalesFunnels();
	return useMemo(() => {
		if (salesFunnelData) {
			const options = salesFunnelData.sort(byObjectProperty("listPosition")).map((salesFunnel) => {
				return createSelectBoxOptionData(salesFunnel.displayName, salesFunnel.id);
			});

			if (includeCtaOption) {
				return [getSelectBoxDefaultOptionsData(), ...options];
			}
			return options;
		}
		return [];
	}, [salesFunnelData, includeCtaOption]);
};

export default useSalesFunnelSelectOptions;

