import { Control, FieldValues } from "react-hook-form";
import CurrencyInput from "~/components/form/CurrencyInput";

import FormSection from "~/components/form/FormSection";
import Input from "~/components/form/Input";

type OrderBudgetFormSectionProps<T extends FieldValues> = {
	control: Control<T>;
	placeholder?: string;
};

type OrderBudgetFormSectionType = {
	manDays: number;
	budgetCents: number;
};

const OrderBudgetFormSection = <T extends OrderBudgetFormSectionType>({
																		  control,
																	  }: OrderBudgetFormSectionProps<T>) => {
	return (
		<FormSection title="Umfang">
			<div className="grid gap-x-6 gap-y-2 grid-cols-6">
				<div className="col-span-3">
					<Input
						name="manDays"
						control={control}
						label="Personentage"
						type={"number"}

					/>
				</div>
				<div className="col-span-3">
					<CurrencyInput
						name={"budgetCents"}
						control={control}
						label="Budget (EUR)"
					/>
				</div>
			</div>
		</FormSection>
	);
};

export default OrderBudgetFormSection;
