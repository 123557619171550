import {
	AbsenceTypeNames,
	EmploymentStatusNames,
	GenderNames,
	PermissionNames,
	TimeTrackingTypeNames,
	WorkingStatusNames,
} from "~/types/entityNames.ts";

import { OrderInvoicingStatus } from "~/modules/billing/types/billingEnums.ts";

export default {
	absenceType: {
		[AbsenceTypeNames.Holiday]: "Feiertag",
		[AbsenceTypeNames.InternalEvent]: "Interner Event",
		[AbsenceTypeNames.MaternityLeave]: "Mutterschutz",
		[AbsenceTypeNames.ParentalLeave]: "Elternzeit",
		[AbsenceTypeNames.Sabbatical]: "Sabbatical",
		[AbsenceTypeNames.Sick]: "Krank",
		[AbsenceTypeNames.Special]: "Sondereffekt",
		[AbsenceTypeNames.Training]: "Schulung",
		[AbsenceTypeNames.Vacation]: "Urlaub",
	},
	orderInvoicingStatus: {
		[OrderInvoicingStatus.Idle]: "Todo",
		[OrderInvoicingStatus.Created]: "Erstellt",
		[OrderInvoicingStatus.Sent]: "Versendet",
		[OrderInvoicingStatus.Paid]: "Bezahlt",
	},
	employmentStatus: {
		[EmploymentStatusNames.Employed]: "Festangestellt",
		[EmploymentStatusNames.Freelancer]: "Freelancer",
		[EmploymentStatusNames.Left]: "Ausgeschieden",
		[EmploymentStatusNames.Student]: "Werksstudent",
		[EmploymentStatusNames.Intern]: "Praktikant",
	},
	gender: {
		[GenderNames.Male]: "Männlich",
		[GenderNames.Female]: "Weiblich",
		[GenderNames.Diverse]: "Divers",
	},
	permission: {
		[PermissionNames.CanManageUsers]: "User verwalten",
		[PermissionNames.CanManageProjects]: "Projekte verwalten",
		[PermissionNames.CanManageInvoices]: "Rechnungen verwalten",
		[PermissionNames.CanManageTimeTrackings]: "Zeiterfassungen anderer Benutzer verwalten",
		[PermissionNames.CanManageAbsences]: "Abwesenheiten verwalten",
	},
	timeTrackingTypes: {
		[TimeTrackingTypeNames.Project]: "Projekt",
		[TimeTrackingTypeNames.Internal]: "Intern",
		[TimeTrackingTypeNames.Sales]: "Sales",
	},
	workingStatus: {
		[WorkingStatusNames.Active]: "Aktiv",
		[WorkingStatusNames.Quotation]: "Angebot",
		[WorkingStatusNames.Hold]: "On hold",
		[WorkingStatusNames.Cancelled]: "Abgebrochen",
		[WorkingStatusNames.Closed]: "Abgeschlossen",
	},
};
