import { Navigate, Outlet } from "react-router-dom";

import React from "react";
import { appRoutes } from "~/constants/appRoute.ts";
import { useAuth } from "~/contexts/AuthContext";

type ProtectedRoutesProps = {};

const ProtectedRoutes: React.FunctionComponent<ProtectedRoutesProps> = ({}) => {
	const { user, isCheckingAuthStatus } = useAuth();
	return !isCheckingAuthStatus && user ? <Outlet /> : <Navigate to={appRoutes.login().path} />;
};

export default ProtectedRoutes;
