import { tv } from "tailwind-variants";
import { VariantProps } from "~/types/tailwindVariants";

export const hourCountVariants = tv({
	base: "flex justify-center items-center p-2 font-bold h-full rounded-r-md",
	variants: {
		theme: {
			project: "bg-primary-500 group-hover:bg-primary-700 text-white bg-opacity-100",
			internal:"bg-gray-500 bg-opacity-80 group-hover:bg-gray-600 text-white",
			sales:"bg-gray-700 group-hover:bg-gray-800 text-white",
			default: "bg-gray-200 group-hover:bg-gray-300 text-gray-500",
			completed:"bg-green-500 group-hover:bg-green-600 text-white",
			absence:""
		},
	},
	defaultVariants: {
		theme: "default",
	},
});


export type HourCountVariants = VariantProps<typeof hourCountVariants>;
export type HourCountThemes = "project" | "internal" | "sales" | "default"|"completed"|"absence";

