import {
	AbsenceSubRoutes,
	TimeTrackingSubRoutes,
	USER_BASE_ROUTE,
} from "~/modules/user/router/UserRouter/UserRouter.tsx";

export interface AppRoute {
	path: string;
	displayName: string;
}

export const appRoutes = {
	absences: () => ({ path: USER_BASE_ROUTE + "/" + AbsenceSubRoutes.absences, displayName: "Abwesenheiten" }),
	base: () => ({ path: "/", displayName: "Home" }),
	client: (displayName?: string) => ({ path: "/clients/:clientId", displayName }),
	clients: () => ({ path: "/clients", displayName: "Kunden" }),
	contact: (displayName?: string) => ({ path: "/contacts/:contactId", displayName }),
	contacts: () => ({ path: "/contacts", displayName: "Kontakte" }),
	devError: () => ({ path: "/dev-error", displayName: "Dev Error" }),
	landing: () => ({ path: "/", displayName: "Home" }),
	login: () => ({ path: "/login", displayName: "Login" }),
	logout: () => ({ path: "/logout", displayName: "Logout" }),
	monthlyBillingReport: () => ({ path: "/billing/monthly-report/", displayName: "Abrechnung" }),
	passwordResetRequest: () => ({ path: "/request-password-reset", displayName: "Passwort zurücksetzen" }),
	passwordReset: () => ({ path: "/reset-password/:token", displayName: "Passwort zurücksetzen" }),
	project: (displayName?: string) => ({ path: "/projects/:projectId", displayName }),
	projects: () => ({ path: "/projects", displayName: "Projekte" }),
	timeTracking: () => ({
		path: USER_BASE_ROUTE + "/" + TimeTrackingSubRoutes.timeTracking,
		displayName: "Zeiterfassung",
	}),
	user: () => ({ path: USER_BASE_ROUTE, displayName: "User" }),
	userProfile: () => ({ path: "/profile", displayName: "Profil" }),
	users: () => ({ path: "/users", displayName: "Users" }),
};

type ReplaceOptions = {
	[key: string]: string;
};

export const generateFullPath = (path: string, replaceOptions: ReplaceOptions) => {
	let finalPath = path;
	Object.keys(replaceOptions).forEach((key) => (finalPath = finalPath.replace(key, replaceOptions[key])));
	return finalPath;
};
