import { QueriesOptions, useQuery } from "react-query";
import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getMonthlyBillingReport } from "~/modules/billing/api/monthlyBillingReport/monthlyBillingReportApiDispatchers.ts";

export const MONTHLY_BILLING_REPORT_BASE_QUERY_KEY = "billableProjectPhase";

export const MONTHLY_BILLING_REPORT_QUERY_KEY = (month: string) => [MONTHLY_BILLING_REPORT_BASE_QUERY_KEY, {month}];

export const useMonthlyBillingReport = ({ month, options }: {
	month: string,
	options?: QueriesOptions<any>
}) => useQuery(
	MONTHLY_BILLING_REPORT_QUERY_KEY(month),
	() => getMonthlyBillingReport({ month }).then(getDataFromResponse),
	options,
);