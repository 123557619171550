import { format } from "date-fns";
import { de } from "date-fns/locale";
import { useMemo } from "react";
import {
	createSelectBoxOptionData,
} from "~/utils/form/formUtils.ts";
import { FormInputOption } from "~/types/form.ts";


const useMonthSelectOptions = (): FormInputOption[] => {
	return useMemo(() => {
		let options: FormInputOption[] = [];

		for (let i = 0; i < 12; i++) {
			const date = new Date(2023, i, 1);  // Here, 2023 can be any year since month names don't change year to year
			const monthName = format(date, "MMMM", { locale: de });
			options.push(createSelectBoxOptionData(monthName, i.toString()));
		}
		return options;

	}, []);
};

export default useMonthSelectOptions;

