import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { ComboBoxPlain } from "~/components/form/ComboBox/ComboBox.tsx";
import { appRoutes } from "~/constants/appRoute.ts";
import useUserSelectOptions, { internalStaffFilterFn } from "~/hooks/form/formOptionsData/useUsersSelectOptions.ts";

type AbsenceViewUserSelectProps = {
	userIsVacationManager: boolean;
	userId: string;
	currentSubPath: string;
};

const AbsenceViewUserSelect: React.FunctionComponent<AbsenceViewUserSelectProps> = ({
	userIsVacationManager,
	userId,
	currentSubPath = "",
}) => {
	const navigate = useNavigate();
	const userSelectOptions = useUserSelectOptions(internalStaffFilterFn);

	const handleChange = useCallback((userId: string|null) => {
		if(userId){
			navigate(generatePath(appRoutes.user().path, { userId }) + "/" + currentSubPath);
		}
	}, [navigate]);

	if (!userIsVacationManager) return null;
	return (
		<div className="flex gap-x-5 items-center ml-auto mr-5">
			<div>Mitarbeiter auswählen:</div>
			<div className="w-[14rem]">
				<ComboBoxPlain
					allowNew={false}
					optionsData={userSelectOptions}
					onChange={handleChange}
					value={userId} />
			</div>
		</div>
	);


};

export default AbsenceViewUserSelect;