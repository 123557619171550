import { createContext, useContext, useState, FunctionComponent, PropsWithChildren } from "react";

interface CommonDataLoadingInterface {
	isLoading: boolean;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CommonDataLoading = createContext<CommonDataLoadingInterface | undefined>(undefined);

export const CommonDataLoadingProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);

	return (
		<CommonDataLoading.Provider value={{ isLoading, setIsLoading }}>
			{children}
		</CommonDataLoading.Provider>
	);
};

export const useCommonDataLoading = (): CommonDataLoadingInterface => {
	const context = useContext(CommonDataLoading);
	if (!context) {
		throw new Error("useLoading must be used within a LoadingProvider");
	}
	return context;
};