import { UniqueValidatorArrayObject } from "~/utils/form/validationRules.ts";
import { sanitizeStringForComparison } from "~/utils/form/formUtils.ts";
import { useMemo } from "react";
import { useProjectsProjectPhases } from "~/modules/project/api/projectPhase/projectPhaseQueries";

export const useProjectPhaseTitleValidationComparisonData = (projectId: string): UniqueValidatorArrayObject[] => {
	const { data: allProjectsData } = useProjectsProjectPhases({ projectId, options: { enabled: !!projectId } });

	return useMemo(() => {
		if (allProjectsData) {
			return allProjectsData.map((phase) => {
				return {
					id: phase.id,
					value: sanitizeStringForComparison(phase.title),
				};
			});
		}

		return [];
	}, [allProjectsData]);
};
