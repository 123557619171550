import clsx from "clsx";

import { format, isSameDay, isWeekend } from "date-fns";
import { de } from "date-fns/locale";
import TimeTrackingBar from "modules/timeTracking/components/components/Day/components/TimeTrackingBar";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserType } from "~/modules/user/api/user/userTypes.ts";

import ButtonNewItem from "~/components/buttons/ButtonNewItem";
import { TimeTrackingExtendedType } from "~/modules/timeTracking/api/timeTracking/timeTrackingTypes.ts";
import HourCount from "~/modules/timeTracking/components/components/Day/components/HourCount";
import {
	TimeTrackingBarThemes,
} from "~/modules/timeTracking/components/components/Day/components/TimeTrackingBar/timeTrackingBarVariants.ts";

import DeleteTimeTrackingModal from "modules/timeTracking/components/DeleteTimeTrackingModal";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { isDateBetween } from "~/utils/dateAndTimeUtils.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";
import { sumUp } from "~/utils/sumUp.ts";

import Details from "./components/Details.tsx";

type DayProps = {
	allAbsenceTypes?: AbsenceTypeType[];
	date: Date;
	holidays?: HolidayType[];
	monthIsClosed: boolean;
	setScrollToElementRef?: (ref: HTMLDivElement) => void;
	setSelectedDate: (date: Date) => void;
	setSelectedTimeTrackingId: (id: string | null) => void;
	setTimeTrackingIdToUpdate: (id: string | null) => void;
	selectedDate: Date | null;
	selectedTimeTrackingId: string | null;
	timeTrackingData?: TimeTrackingExtendedType[];
	userAbsencesData?: AbsenceType[];
	users: UserType[];
	visible: boolean;
};

const maxMinutes = 480; // 12 hours

const Day: React.FC<DayProps> = ({
	allAbsenceTypes,
	date,
	holidays,
	monthIsClosed,
	selectedDate,
	selectedTimeTrackingId,
	setScrollToElementRef,
	setSelectedDate,
	setSelectedTimeTrackingId,
	setTimeTrackingIdToUpdate,
	timeTrackingData,
	userAbsencesData,
	visible,
}) => {
	const { t } = useTranslation();

	if (undefined === timeTrackingData) return null;

	const isWeekendDay = isWeekend(date);
	const holiday = holidays?.filter((h) => isSameDay(Date.parse(h.date), date));
	const absence = userAbsencesData?.filter(({ startDate, endDate }) => {
		return isDateBetween(date, new Date(startDate), new Date(endDate)) && !isWeekendDay;
	});
	const [timeTrackingIdToDelete, setTimeTrackingIdToDelete] = useState<string | null>(null);
	const className = `${!visible && isWeekendDay ? "hidden" : ""}`;

	let absenceName = undefined;
	if (undefined !== absence && 0 < absence.length) {
		absenceName = allAbsenceTypes?.find((at) => at.id === absence[0].absenceTypeId)?.name;
	}

	const isHalfDay =
		undefined !== absence &&
		0 < absence.length &&
		((isSameDay(Date.parse(absence[0].startDate), date) && absence[0].firstDayIsHalf) ||
			(isSameDay(Date.parse(absence[0].endDate), date) && absence[0].lastDayIsHalf));

	const totalMinutes = sumUp(timeTrackingData, "minutes");
	const totalHours = totalMinutes / 60;

	const timeTrackingDataToDelete = useMemo(() => {
		if (timeTrackingData && timeTrackingIdToDelete) {
			return timeTrackingData.find((data) => data.id === timeTrackingIdToDelete) || null;
		}
		return null;
	}, [timeTrackingData, timeTrackingIdToDelete]);

	const isToday = isSameDay(date, new Date());
	return (
		<div className={clsx(className, "py-4 pr-4 flex flex-row border border-t border-gray-50 ", selectedDate && isSameDay(date, selectedDate) ? "bg-accent-700" : "")}
			 ref={isToday ? setScrollToElementRef : null}>
			<div className="flex flex-col w-16 flex-shrink-0 cursor-pointer">
				<div
					className={clsx("font-bold text-xl text-center", isToday ? "text-blue-600" : "")}>{format(date, "dd", { locale: de })}</div>
				<div
					className={clsx("font-xs text-gray-400 text-center", isToday ? "text-blue-600" : "")}>{format(date, "eee", { locale: de })}</div>
			</div>

			<div className="w-full flex flex-row rounded-md bg-gray-100 text-gray-500">
				<div className="flex flex-grow gap-2 p-2">
					{undefined !== holiday && 0 < holiday.length &&
						<TimeTrackingBar width={20}>{holiday[0].displayName}</TimeTrackingBar>}
					{undefined !== absenceName && (
						<TimeTrackingBar theme="absence"
										 width={isHalfDay ? 50 : 100}>{t(normalizeTKey("entities:absenceType." + absenceName))}</TimeTrackingBar>
					)}
					{timeTrackingData.map((data) => {
						let width = 100;
						if (data.id !== selectedTimeTrackingId) {
							const base = totalMinutes > maxMinutes ? totalMinutes : maxMinutes;
							width = (data.minutes / base) * 100;
						}


						let barTheme: TimeTrackingBarThemes;
						let title;
						switch (true) {
							case "1" === data.timeTrackingTypeId:
								barTheme = "project";
								title = data.projectPhaseTitle;
								break;
							case "2" === data.timeTrackingTypeId:
								barTheme = "internal";
								title = "Intern";
								break;
							case "3" === data.timeTrackingTypeId:
								barTheme = "sales";
								title = "Sales";
								break;
							default:
								barTheme = "default";
						}

						return (
							<TimeTrackingBar
								key={"time-tracking-" + data.id}
								hours={formatNumberWithComma(data.minutes / 60, 2)}
								theme={barTheme}
								width={width}
								onClick={data.id === selectedTimeTrackingId ? () => setSelectedTimeTrackingId(null) : () => setSelectedTimeTrackingId(data.id)}
							>
								{data.id === selectedTimeTrackingId ? (
									<Details
										data={data}
										monthIsClosed={monthIsClosed}
										onClickDelete={() => setTimeTrackingIdToDelete(data.id)}
										onClickEdit={() => setTimeTrackingIdToUpdate(data.id)}
										onClickClose={() => setSelectedTimeTrackingId(null)}
									/>
								) : (
									width > 5 && (
										<>
											{title}
										</>
									)
								)}
							</TimeTrackingBar>
						);
					})}
					{!monthIsClosed && <div className="flex flex-grow">
						<ButtonNewItem
							size="sm"
							onClick={() => setSelectedDate(date)}
						></ButtonNewItem>
					</div>}
				</div>
				{timeTrackingData.length > 0 && (
					<div className="ml-auto h-full">
						<HourCount theme={totalHours >= 8 ? "completed" : undefined}>{formatNumberWithComma(totalHours, 2)}
						</HourCount>
					</div>
				)}

			</div>
			<DeleteTimeTrackingModal
				isOpen={!!timeTrackingDataToDelete}
				onClose={() => {console.log('closing'),setTimeTrackingIdToDelete(null)}}
				timeTrackingData={timeTrackingDataToDelete} />
		</div>
	);
};

export default Day;
