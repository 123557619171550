import { yupResolver } from "@hookform/resolvers/yup";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "~/components/form/Button";
import FormSection, { FormSectionGroup } from "~/components/form/FormSection";
import Select from "~/components/form/Select";
import SubmitButton from "~/components/form/SubmitButton";
import Headline from "~/components/Headline";
import Hint from "~/components/Hint";
import Sidebar from "~/components/Sidebar/Sidebar.tsx";
import useWorkingStatusOptions from "~/hooks/form/formOptionsData/useWorkingStatusOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { updateProjectPhaseStatus } from "~/modules/project/api/projectPhase/projectPhaseApiDispatchers.ts";
import { UpdateProjectPhaseStatusData } from "~/modules/project/api/projectPhase/projectPhaseTypes.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type UpdateProjectPhaseStatusFormProps = {
	currentStatusId: string;
	onSuccess: (newStatusId: string) => void;
	onCancel: () => void;
	phasesCount: number;
	phaseHasTimeTrackings: boolean;
	projectId: string;
	projectPhaseId: string;
};

const UpdateProjectPhaseStatusForm: React.FunctionComponent<UpdateProjectPhaseStatusFormProps> = ({
	currentStatusId,
	onCancel,
	onSuccess,
	phasesCount,
	phaseHasTimeTrackings,
	projectPhaseId,
	projectId,
}) => {
	const [busy, setBusy] = useState(false);
	const workingStatusOptions = useWorkingStatusOptions();

	const schema = useMemo(() => {
		return yup.object({
			workingStatusId: yup.string().required(),
		});
	}, []);

	const defaultValues = useMemo(() => {
		return {
			workingStatusId: currentStatusId,
		};

	}, [currentStatusId]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isValid, isSubmitted },
		watch,
	} = useForm<UpdateProjectPhaseStatusData>({
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateProjectPhaseStatusData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isDirty,
		isSubmitted,
		isValid,
		isLoading: busy,
		enableInitially: false,
	});

	const onSubmit = async (data: UpdateProjectPhaseStatusData) => {
		try {
			setBusy(true);
			await updateProjectPhaseStatus({ projectId, projectPhaseId, data });
			onSuccess(data.workingStatusId);
		} catch (error) {
			console.log(error);
		}
	};

	const selectedStatusId = watch("workingStatusId");
	const showTimeTrackingsWarning = currentStatusId !== "5" && phaseHasTimeTrackings && selectedStatusId === "5";

	const actionListElements = useMemo(() => {
		const listElements = [];

		if (selectedStatusId === currentStatusId) {
			return [];
		}


		if (["4", "5"].includes(selectedStatusId)) {
			listElements.push("Alle aktiven Staffings dieser Phase werden auf \"Inaktiv\" gesetzt.");
		}

		return listElements;
	}, [selectedStatusId, currentStatusId, phasesCount]);

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex flex-col justify-start w-full min-h-full"
		>
			<Sidebar.Header>
				<Headline type="h2">Status der Phase ändern</Headline>
			</Sidebar.Header>
			<Sidebar.Content>
				{busy && <Sidebar.BusyOverlay />}
				<FormSectionGroup>
					<FormSection title="Neuer Status">
						<div className="grid gap-x-6 gap-y-2 grid-cols-6">
							<div className="col-span-6">
								<Select control={control}
										name="workingStatusId"
										optionsData={workingStatusOptions} />
							</div>
						</div>
					</FormSection>
					{actionListElements.length > 0 && !showTimeTrackingsWarning && <FormSection title="Weitere Aktionen">
						<p className="mt-1 text-gray-800 text-sm">Durch diese Statusänderung werden folgende
							Aktionen automatisch ausgeführt:</p>
						<ul className="text-gray-800 text-sm list-disc list-inside">
							{actionListElements.map((element, index) => <li key={index}>{element}</li>)}
						</ul>
					</FormSection>}
					{showTimeTrackingsWarning && <FormSection title="Achtung">
						<Hint size="sm"
							  theme="error">Diese Statusänderung ist nicht möglich, da auf diesem Projekt bereits Zeiten
							erfasst wurden.</Hint>
					</FormSection>}
				</FormSectionGroup>
			</Sidebar.Content>
			<Sidebar.Footer>
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable || showTimeTrackingsWarning}>
					Speichern
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					abbrechen
				</Button>
			</Sidebar.Footer>
		</form>
	);
};

export default UpdateProjectPhaseStatusForm;