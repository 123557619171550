import {
	CheckCircleIcon,
	ExclamationTriangleIcon,
	InformationCircleIcon,
	XCircleIcon,
} from "@heroicons/react/20/solid";
import React, { PropsWithChildren } from "react";

import { HintSizeVariants, HintThemeVariants, hintVariants } from "~/components/Hint/hintVariants.ts";

export type HintTypes = "info" | "error" | "success" | "warning";

type InfoBoxProps = {
	title?: string;
	theme?: HintThemeVariants;
	size?: HintSizeVariants;
};

const Hint: React.FunctionComponent<PropsWithChildren<InfoBoxProps>> = ({
	size,
	title,
	theme = "info",
	children,
}) => {
	const iconProps = { className: "h-5 w-5", "aria-hidden": true };
	const { wrapper } = hintVariants({ size, theme });
	return (
		<div className={wrapper()}
		>
			<div className="flex">
				<div className="flex flex-shrink-0 items-center justify-start">
					{"info" === theme && <InformationCircleIcon {...iconProps} />}
					{"error" === theme && <XCircleIcon {...iconProps} />}
					{"success" === theme && <CheckCircleIcon {...iconProps} />}
					{"warning" === theme && <ExclamationTriangleIcon {...iconProps} />}
				</div>
				<div className="ml-3">
					{title && <p className="font-medium font-lg">{title}</p>}
					{children}
				</div>
			</div>
		</div>
	);
};

export default Hint;
