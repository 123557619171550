import { yupResolver } from "@hookform/resolvers/yup";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "~/components/form/Button";
import FormHasErrorsHint from "~/components/form/FormHasErrorsHint";
import FormSection, { FormSectionGroup } from "~/components/form/FormSection";
import Input from "~/components/form/Input";
import SubmitButton from "~/components/form/SubmitButton";
import Headline from "~/components/Headline";
import Sidebar from "~/components/Sidebar/Sidebar.tsx";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	useCompanyValidationComparisonData,
} from "~/hooks/form/validationComparisonData/useCompanyValidationComparisonData.ts";
import { updateCompany } from "~/modules/client/api/company/companyApiDispatchers.ts";
import { CompanyType, UpdateCompanyData } from "~/modules/client/api/company/companyTypes.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type UpdateCompanyFormProps = {
	companyData: CompanyType;
	onCancel: () => void;
	onSuccess: () => void;
};

interface UpdateCompanyFormData extends UpdateCompanyData {
}

const UpdateCompanyForm: React.FunctionComponent<UpdateCompanyFormProps> = ({ companyData, onCancel, onSuccess }) => {
	const [busy, setBusy] = useState(false);

	const { displayNameComparisonData, corporateNameComparisonData } =
		useCompanyValidationComparisonData();
	const schema = useMemo(() => {
		return yup.object({
			corporateName: yup.string().required().unique(corporateNameComparisonData, companyData.id),
			displayName: yup.string().required().unique(displayNameComparisonData, companyData.id),
		});
	}, [corporateNameComparisonData, displayNameComparisonData, companyData]);

	const defaultValues = useMemo(() => {
		return {
			corporateName: companyData.corporateName,
			displayName: companyData.displayName,
		};
	}, [companyData]);

	const {
		control,
		handleSubmit,
		formState: { isValid, isDirty, isSubmitted },
	} = useForm<UpdateCompanyFormData>({
		mode: "onChange",
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateCompanyFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isDirty,
		isSubmitted,
		isValid,
		isLoading: busy,
	});

	const onSubmit = async (data: UpdateCompanyFormData) => {
		setBusy(true);
		try {
			await updateCompany({ companyId: companyData.id, data });
			onSuccess();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex flex-col justify-start w-full min-h-full"
		>
			<Sidebar.Header>
				<Headline type="h2">Kunden bearbeiten</Headline>
			</Sidebar.Header>
			<Sidebar.Content>
				{busy && <Sidebar.BusyOverlay />}
				<FormSectionGroup>
					<FormSection title="Kunde">
						<div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
							<div className="sm:col-start-1 col-span-full">
								<Input
									name="corporateName"
									control={control}
									label="Name Legaleinheit"
									placeholder="Grandioso Gmbh & Co. KG"
								/>
							</div>
							<div className="sm:col-start-1 col-span-3">
								<Input
									name="displayName"
									control={control}
									label="Kundenname"
									placeholder="Grandioso"
								/>
							</div>
						</div>
					</FormSection>
				</FormSectionGroup>
			</Sidebar.Content>
			<Sidebar.Footer>
				<FormHasErrorsHint show={!isValid} className="mr-2"/>
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					abbrechen
				</Button>
			</Sidebar.Footer>
		</form>
	);
};

export default UpdateCompanyForm;