import { format } from "date-fns";
import Decimal from "decimal.js-light";
import { EntryType } from "~/modules/absence/types/vacationLedgerEnums.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

export function formatVacationDays(vacationDays: Decimal): string {
	const formattedVacationDays = formatNumberWithComma(vacationDays.toNumber(), 2);
	return vacationDays.isNegative() ? formattedVacationDays : "+" + formattedVacationDays;
}

export const convertVacationLedgerEntryFormDataToApiData = (formData: VacationLedgerFormType): CreateOrUpdateVacationLedgerEntryDataType => {
	const validFromFormatted = format(formData.validFrom, "yyyy-MM-dd");
	let workDays = new Decimal(formData.workDays / 100);
	if (formData.entryTypeId === EntryType.Deduction) {
		workDays = workDays.negated();
	}

	return {
		...formData, validFrom: validFromFormatted, workDays: workDays.toNumber(),
	};
};