import { AxiosResponse, isAxiosError } from "axios";
import { ApiResponse, ApiResponseErrorType, ErrorResponse, ErrorResult } from "~/api/apiResponseTypes.ts";

export const getDataFromResponse = <T>(res: AxiosResponse<ApiResponse<T>>): T => res.data.data;

export const getRootDataFromResponse = <T>(res: AxiosResponse<T>): T => res.data;

export function handleApiError(error: unknown): ErrorResult {
	if (isAxiosError(error)) {
		const errorCode = error.response?.status || 0;

		if (errorCode === 422) {
			const validationErrors: ErrorResponse = error.response?.data ?? { errors: {} };

			return {
				type: ApiResponseErrorType.VALIDATION,
				code: errorCode,
				messages: validationErrors.errors
			};
		} else {
			return {
				type: ApiResponseErrorType.AXIOS,
				code: errorCode,
				message: error.message
			};
		}
	} else {
		return {
			type: ApiResponseErrorType.UNKNOWN,
			message: String(error)
		};
	}
}
