import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { getDataFromResponse } from "~/api/axiosUtils.ts";
import FormHasErrorsHint from "~/components/form/FormHasErrorsHint";

import FormSection, { FormSectionGroup } from "~/components/form/FormSection";

import AccountingDetailsFormSection from "~/components/form/formSections/AccountingDetailsFormSection";
import AddressFormSection from "~/components/form/formSections/AddressFormSection/AddressFormSection.tsx";
import CommentFormSection from "~/components/form/formSections/CommentFormSection";
import Input from "~/components/form/Input";
import SubmitButton from "~/components/form/SubmitButton";
import Headline from "~/components/Headline";
import Sidebar from "~/components/Sidebar";
import { DEFAULT_COUNTRY_ID } from "~/constants/form.ts";
import useCountrySelectOptions from "~/hooks/form/formOptionsData/useCountrySelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	useClientValidationComparisonData,
} from "~/hooks/form/validationComparisonData/useClientValidationComparisonData.ts";
import { createClient } from "~/modules/client/api/client/clientApiDispatchers.ts";
import { useAllClients } from "~/modules/client/api/client/clientQueries.ts";
import { CreateClientData } from "~/modules/client/api/client/clientTypes.ts";
import { useAllCompanies } from "~/modules/client/api/company/companyQueries.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = {
	onSuccess: (newCompanyId: string) => void;
	companyId: string;
};

interface CreateClientFormData extends Omit<CreateClientData, "paymentTargetDays" | "companyId"> {
	paymentTargetDays: number;
	paymentTargetDaysCustom?: number | null;
}

const CreateClientForm: React.FC<Props> = ({ onSuccess, companyId }) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);

	const { data: allCompaniesData } = useAllCompanies();
	const {data: allClientsData} = useAllClients();

	const companyData = useMemo(() => {
		if (allCompaniesData) {
			return allCompaniesData.find((company) => company.id === companyId);
		}
	}, [allCompaniesData]);

	const clientAdressData = useMemo(() => {
	    if(allClientsData){
			const clientData = allClientsData.filter((client) => client.companyId === companyId).sort(byObjectProperty('id'));
			if(clientData.length > 0){
				return clientData[0];
			}
	    }
	    return null
	    }, [allClientsData, companyId]);

	const displayNameComparisonData = useClientValidationComparisonData();

	const schema = useMemo(() => {
		return yup.object({
			city: yup.string().default(null),
			comment: yup.string().default(null),
			countryId: yup.string().default(null),
			displayName: yup.string().required().unique(displayNameComparisonData),
			houseNumber: yup.string().default(null),
			invoiceByMail: yup.boolean().required(),
			isVatExempt: yup.boolean().required(),
			paymentTargetDays: yup.number().required(),
			paymentTargetDaysCustom: yup.number()
				.nullable()
				.when('paymentTargetDays', (field1Value:number[], schema) => {
					return field1Value[0] === 0
						? schema.required()
						: schema.nullable();
				}),
			street: yup.string().default(null),
			travelExpensesIncluded: yup.boolean().required(),
			zipCode: yup.string().default(null),
		});
	}, [displayNameComparisonData]);

	const defaultValues = useMemo(() => {
		return {
			city: clientAdressData?.city || "",
			comment: "",
			countryId: DEFAULT_COUNTRY_ID,
			displayName: "",
			houseNumber: clientAdressData?.houseNumber || "",
			invoiceByMail: true,
			isVatExempt: false,
			paymentTargetDays: 30,
			paymentTargetDaysCustom: null,
			street: clientAdressData?.street || "",
			travelExpensesIncluded: true,
			zipCode: clientAdressData?.zipCode || "",
		};
	}, [companyId, clientAdressData]);
	const {
		handleSubmit,
		control,
		watch,
		formState: { isValid, isDirty, isSubmitted },
		trigger
	} = useForm<CreateClientFormData>({
		defaultValues,
		resolver: yupResolver<CreateClientFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({ isDirty, isSubmitted, isValid, isLoading: busy });

	const showCustomPaymentTargetInput = watch("paymentTargetDays") === 0;

	const countrySelectOptions = useCountrySelectOptions();

	const onSubmit: SubmitHandler<CreateClientFormData> = async (data) => {
		setBusy(true);
		const { paymentTargetDays, paymentTargetDaysCustom, ...rest } = data;
		let paymentTargetDaysValue =
			paymentTargetDays === 0 && paymentTargetDaysCustom ? paymentTargetDaysCustom : paymentTargetDays;

		const newClientData = { paymentTargetDays: paymentTargetDaysValue, companyId, ...rest };

		try {
			const newClientResponse = await createClient(newClientData);
			const clientId = getDataFromResponse(newClientResponse).id;
			onSuccess(clientId);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (showCustomPaymentTargetInput) {
			trigger('paymentTargetDaysCustom');
		}
	}, [showCustomPaymentTargetInput]);

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex flex-col justify-start w-full min-h-full"
		>
			<Sidebar.Header>
				<Headline type="h2">Neue Firma für Kunden "{companyData?.displayName}" anlegen</Headline>
			</Sidebar.Header>
			<Sidebar.Content>
				{busy && <Sidebar.BusyOverlay />}
				<FormSectionGroup>
					<FormSection title="Firma">
						<div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
							<div className="sm:col-start-1 col-span-full">
								<Input
									name="displayName"
									label="Name"
									control={control}
									placeholder="Name des Kunden" />
							</div>
						</div>
					</FormSection>

					<AddressFormSection<CreateClientFormData>
						control={control}
						countrySelectOptions={countrySelectOptions}
					/>

					<AccountingDetailsFormSection<CreateClientFormData>
						control={control}
						showCustomPaymentTargetInput={showCustomPaymentTargetInput}
					/>

					<CommentFormSection
						control={control}
						label="Kommentar"
						title="Kommentar"
						placeholder="Hier können allgemeine Anmerkungen für die Rechnungserstellung hinterlegt werden."
					/>
				</FormSectionGroup>
			</Sidebar.Content>
			<Sidebar.Footer>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
			</Sidebar.Footer>
		</form>
	);
};

export default CreateClientForm;
