import { ALL_CLIENTS_QUERY_KEY, useAllClients } from "~/modules/client/api/client/clientQueries.ts";
import { useCallback, useEffect, useState } from "react";

import Button from "~/components/form/Button";
import { ClientType } from "~/modules/client/api/client/clientTypes.ts";
import CreateClientForm from "~/modules/client/components/forms/CreateClientForm";
import Sidebar from "~/components/Sidebar";
import { appRoutes } from "~/constants/appRoute.ts";
import { generatePath } from "react-router-dom";
import { useQueryClient } from "react-query";

type ClientViewSidebarProps = {
	selectedCompanyId: string | null;
	closeSidebar: () => void;
};

const CreateClientSidebar: React.FunctionComponent<ClientViewSidebarProps> = ({ selectedCompanyId, closeSidebar }) => {
	const [newClientId, setNewClientId] = useState<string | null>(null);
	const [newClientData, setNewClientData] = useState<ClientType | null>(null);
	const queryClient = useQueryClient();
	const { data: allClientsData, isLoading: allClientsLoading } = useAllClients();

	useEffect(() => {
		if (newClientId && allClientsData && !allClientsLoading) {
			const newClient = allClientsData?.find((client) => client.companyId === newClientId);
			if (newClient) {
				setNewClientData(newClient);
			}
		}
	}, [newClientId, allClientsData, allClientsLoading]);

	useEffect(() => {
		if (!selectedCompanyId) {
			setNewClientId(null);
			setNewClientData(null);
		}
	}, [selectedCompanyId]);

	const handleSuccess = useCallback(
		async (clientId: string) => {

			await queryClient.refetchQueries({ queryKey: ALL_CLIENTS_QUERY_KEY });
			setNewClientId(clientId);
			closeSidebar();
		},
		[queryClient],
	);

	return (
		<Sidebar closeOnOutsideClick={false} open={selectedCompanyId !== null} setOpen={closeSidebar}>
			{!newClientId && !newClientData && selectedCompanyId && (
				<CreateClientForm companyId={selectedCompanyId} onSuccess={handleSuccess} />
			)}
			{newClientData && (
				<div className="px-8 py-6">
					<div className="text-xl text-center font-bold mb-4 text-gray-800">
						Neue Firma "{newClientData.displayName}" erstellt
					</div>
					<div className="mb-4 text-center text-gray-800">Was möchtest Du als nächstes tun?</div>
					<div className="flex flex-col space-y-4">
						<Button
							className="justify-center"
							to={generatePath(appRoutes.client("").path, { clientId: newClientData.id })}
						>
							Firmenseite öffnen
						</Button>
					</div>
				</div>
			)}
		</Sidebar>
	);
};

export default CreateClientSidebar;
