import { ExclamationCircleIcon, EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import React, { forwardRef, MouseEvent, useCallback, useState } from "react";
import { Control, useController } from "react-hook-form";
import FormInput from "~/components/form/FormInput";
import Label from "~/components/form/Label";


interface SharedProps
	extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "name" | "onBlur" | "type"> {
	autoFocus?: boolean;
	className?: string;
	error?: string;
	errorIconOnly?: boolean;
	label?: string;
	name: string;
	placeholder?: string;
	type?: string;
}

interface InputPlainProps extends SharedProps {
	value: number | string;
	onBlur?: () => void;
}


export const InputPlain = forwardRef<HTMLInputElement, InputPlainProps>(
	({ onChange, onBlur, value, name, label, error, type = "text", placeholder, className, autoFocus }, ref) => {
		const [selectedType, setSelectedType] = useState(type);
		const togglePasswordVisibility = useCallback((event: MouseEvent) => {
			event.preventDefault();
			setSelectedType(state => state === "password" ? "text" : "password");
		}, []);

		return (
			<FormInput>
				{label && <Label htmlFor={name}>{label}</Label>}
				<div className="relative rounded-md shadow-sm">
					<input
						className={clsx(
							"flex w-full rounded-md border-0 px-1.5 h-10",
							"shadow-sm",
							"ring-1 ring-inset ring-gray-300 focus:ring-blue-500 focus:ring-2 focus:ring-inset",
							"text-sm",
							className,
						)}
						value={value ||""}
						autoFocus={autoFocus}
						name={name}
						type={selectedType}
						id={name}
						placeholder={placeholder}
						ref={ref}
						onChange={onChange}
						onBlur={onBlur}
						autoComplete="off"
					/>
					{type === "password" && <button
						className="absolute right-0 top-0 h-full mr-2 flex items-center"
						onClick={togglePasswordVisibility}
					>
						{selectedType === "password" ? <EyeSlashIcon className="h-5 w-5 fill-gray-500" /> :
							<EyeIcon className="h-5 w-5 fill-gray-600" />}
					</button>}
					{error && (
						<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
							<ExclamationCircleIcon className="h-5 w-5 text-red-500"
												   aria-hidden="true" />
						</div>
					)}
				</div>
				{error && (
					<p className="mt-2 text-sm text-red-600"
					   id="email-error">
						{error}
					</p>
				)}
			</FormInput>
		);
	});

interface InputProps extends SharedProps {
	name: string;
	control: Control<any>;
	defaultValue?: number;
	useControllerError?: boolean;
	rules?: any;
}

const Input: React.FC<InputProps> = ({
	name,
	control,
	defaultValue = 0,
	useControllerError = true,
	error,
	rules,
	...rest
}) => {
	const {
		field,
		fieldState: { error: errorFromRHF },
	} = useController({
		name,
		control,
		rules,
	});

	let errorMessage = useControllerError ? errorFromRHF?.message : error;
	return <InputPlain {...field} {...rest} error={errorMessage} />;
};


export default Input;
