import { useAuth } from "~/contexts/AuthContext";
import useAsyncEffect from "use-async-effect";

const LogoutPage: React.FC = () => {
	const { signOut } = useAuth();

	useAsyncEffect(async () => {
		console.log('signing out');
		await signOut();
	}, []);

	return null;
};

export default LogoutPage;
