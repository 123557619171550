import { PropsWithChildren } from "react";

type ErrorProps = {};

const FormInputError: React.FunctionComponent<PropsWithChildren<ErrorProps>> = ({ children }) => {
	return <p className="mt-2 text-sm text-red-600" id="email-error">
		{children}
	</p>;
};

export default FormInputError;