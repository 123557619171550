import React, { useCallback } from "react";
import { useAuth } from "~/contexts/AuthContext";
import { All_LOCATIONS_QUERY_KEY, USER_LOCATIONS_QUERY_KEY } from "~/modules/location/api/location/locationQueries.ts";

import { OrderType } from "~/modules/project/api/order/orderTypes.ts";
import { PROJECTS_ORDERS_QUERY_KEY } from "~/modules/project/api/order/orderQueries.ts";
import { PROJECT_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import { PROJECTS_PHASES_QUERY_KEY } from "~/modules/project/api/projectPhase/projectPhaseQueries.ts";
import Sidebar from "~/components/Sidebar";
import { USERS_ACTIVE_STAFFINGS_QUERY_KEY } from "~/modules/project/api/staffing/staffingQueries.ts";
import UpdateOrderForm from "~/modules/project/components/forms/UpdateOrderForm";
import { useQueryClient } from "react-query";
import { USER_DELIVERABLES_QUERY_KEY } from "~/modules/timeTracking/api/deliverable/deliverableQueries.ts";

type UpdateOrderSidebarProps = {
	isOpen: boolean;
	setOpen: (open: boolean) => void;
	clientId: string;
	projectId: string;
	orderData: OrderType;
};

const UpdateOrderSidebar: React.FunctionComponent<UpdateOrderSidebarProps> = ({
	isOpen,
	setOpen,
	clientId,
	projectId,
	orderData,
}) => {
	const queryClient = useQueryClient();
	const { user } = useAuth();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries(PROJECTS_PHASES_QUERY_KEY(projectId));
		await queryClient.refetchQueries(PROJECTS_ORDERS_QUERY_KEY(projectId));
		await queryClient.refetchQueries(PROJECT_QUERY_KEY(projectId));
		queryClient.refetchQueries(USERS_ACTIVE_STAFFINGS_QUERY_KEY(user!.id));
		queryClient.refetchQueries(USER_DELIVERABLES_QUERY_KEY(user!.id));
		queryClient.refetchQueries(USER_LOCATIONS_QUERY_KEY(user!.id));
		await queryClient.refetchQueries(All_LOCATIONS_QUERY_KEY);
		setOpen(false);
	}, [queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => setOpen(!isOpen)}>
			<UpdateOrderForm
				clientId={clientId}
				orderData={orderData}
				onSuccess={handleSuccess}
				onCancel={() => setOpen(false)}
			/>
		</Sidebar>
	);
};

export default UpdateOrderSidebar;
