import { useCallback } from "react";
import { useQueryClient } from "react-query";
import Sidebar from "~/components/Sidebar/Sidebar.tsx";
import {
	CLIENTS_INVOICE_RECIPIENTS_QUERY_KEY,
} from "~/modules/billing/api/invoiceRecipient/invoiceRecipientQueries.ts";
import CreateOrUpdateInvoiceRecipientForm
	from "~/modules/client/components/ClientDetailsView/components/ClientsInvoiceRecipientsList/components/CreateOrUpdateInvoiceRecipientForm";

type CreateInvoiceRecipientSidebarProps = {
	clientId: string;
	isOpen: boolean;
	onClose: () => void;
};

const CreateInvoiceRecipientSidebar: React.FunctionComponent<CreateInvoiceRecipientSidebarProps> = ({
	clientId,
	isOpen,
	onClose,
}) => {
	const queryClient = useQueryClient();

	const handleSuccess = useCallback(async () => {
		await queryClient.invalidateQueries(CLIENTS_INVOICE_RECIPIENTS_QUERY_KEY(clientId));
		onClose();
	}, [queryClient]);
	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={onClose}>
			<>
				<CreateOrUpdateInvoiceRecipientForm
					clientId={clientId}
					onSuccess={handleSuccess}
					onCancelClick={onClose} />
			</>
		</Sidebar>
	);
};

export default CreateInvoiceRecipientSidebar;