import {
	ChatBubbleBottomCenterTextIcon,
	DocumentTextIcon,
	PencilIcon,
	TrashIcon,
	UsersIcon,
} from "@heroicons/react/20/solid";
import ProjectStatsCompactBarChart from "~/components/charts/ProjectStatsCompactBarChart";
import { useMemo, useState } from "react";
import { useClientsContactPersons } from "~/modules/client/api/clientContactPerson/clientContactPersonQueries.ts";
import { useClientsInvoiceRecipients } from "~/modules/billing/api/invoiceRecipient/invoiceRecipientQueries.ts";
import { OrderType } from "~/modules/project/api/order/orderTypes.ts";
import { ProjectRole } from "~/modules/project/api/projectRole/projectRoleTypes.ts";
import { StaffingType } from "~/modules/project/api/staffing/staffingTypes.ts";
import { UserType } from "~/modules/user/api/user/userTypes.ts";
import ButtonNewItem from "~/components/buttons/ButtonNewItem";
import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import SectionHeading from "~/components/headings/SectionHeading";
import Headline from "~/components/Headline";
import Status from "~/components/Status";

import CreateStaffingSidebar
	from "modules/project/components/ProjectDetailsView/components/CreateStaffingSidebar";
import DeleteOrderModal from "modules/project/components/ProjectDetailsView/components/DeleteOrderModal";
import DeleteStaffingModal from "modules/project/components/ProjectDetailsView/components/DeleteStaffingModal";
import Staffing from "~/modules/project/components/ProjectDetailsView/components/OrderView/components/Staffing.tsx";
import UpdateOrderSidebar from "~/modules/project/components/ProjectDetailsView/components/UpdateOrderSidebar";
import UpdateStaffingSidebar from "~/modules/project/components/ProjectDetailsView/components/UpdateStaffingSidebar";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatDateRange } from "~/utils/dateAndTimeUtils.ts";
import getOneOfCollection from "~/utils/getOneOfCollection.ts";

interface OrderProps {
	allUsers: UserType[];
	clientId: string;
	orderData: OrderType;
	projectRoles: ProjectRole[];
	staffings: StaffingType[];
	totalOrders: number;
	isFirstInList?: boolean;
	showBudget: boolean;
}

type VisibleSections = "staffings" | "details" | "comments";

const OrderView: React.FC<OrderProps> = ({
	allUsers,
	clientId,
	isFirstInList,
	orderData,
	projectRoles,
	showBudget,
	staffings,
	totalOrders,
}) => {
	const [visibleSection, setVisibleSection] = useState<VisibleSections | null>("staffings");

	const {
		budgetCents,
		budgetCentsTracked,
		manDays: manDaysPlanned,
		minutesTracked,
		clientContactPersonId,
		comment,
		confirmedAt,
		endDate,
		invoiceByMail,
		invoiceRecipientId,
		isBilledMonthly,
		isFixedPrice,
		isForeignCountry,
		isSkillBased,
		manDays,
		orderNumber,
		paymentTargetDays,
		startDate,
		title,
		travelExpensesIncluded,
	} = orderData;
	const [showCreateStaffingSidebar, setShowCreateStaffingSidebar] = useState(false);
	const [showUpdateOrderSidebar, setShowUpdateOrderSidebar] = useState(false);
	const [showDeleteOrderSidebar, setShowDeleteOrderSidebar] = useState(false);
	const [selectedStaffingIdForUpdate, setSelectedStaffingIdForUpdate] = useState<string | null>(null);
	const [selectedStaffingIdForDeletion, setSelectedStaffingIdForDeletion] = useState<string | null>(null);
	const { data: contactPersonsData } = useClientsContactPersons(clientId);
	const { data: invoiceRecipientsData } = useClientsInvoiceRecipients(clientId);

	const invoiceRecipientName = useMemo(() => {
		if (invoiceRecipientId) {
			const invoiceRecipient = getOneOfCollection(invoiceRecipientsData, invoiceRecipientId);
			return invoiceRecipient?.displayName + " (Zentraler Rechnungsempfänger)";
		} else if (clientContactPersonId) {
			const contactPerson = getOneOfCollection(contactPersonsData, clientContactPersonId);
			return contactPerson?.fullName + " (Kontaktperson)";
		}

		return "n/a";
	}, [contactPersonsData, invoiceRecipientsData, invoiceRecipientId, clientContactPersonId]);

	const orderDetailsData = [
		{ label: "Personentage", content: manDays },
		{ label: "Budget", content: formatCentsToCurrency(budgetCents) },
		{
			label: "Laufzeit",
			content: startDate && endDate ? `${formatDateRange(new Date(startDate), new Date(endDate))}` : "n/a",
		},
		{
			label: "Rechnungsempfänger",
			content: <>{invoiceRecipientName}</>,
		},
		{
			label: "Abrechnungsart",
			content: (
				<>
					{isFixedPrice ? "Festpreis" : "Tagessatz"}, {isBilledMonthly ? "monatlich" : "individuell"}
				</>
			),
		},
		{ label: "Reisekosten", content: travelExpensesIncluded ? "included" : "On top" },
		{ label: "Rechnung", content: invoiceByMail ? "Mail" : "Post" },
		{ label: "Abrechnung Rolle", content: isSkillBased ? "Projektrolle" : "Skillklasse" },
		{ label: "Zahlungsziel", content: paymentTargetDays + " Tage" },

		{ label: "Leistung/Rechnung Ausland", content: isForeignCountry ? "ja" : "nein" },
	];

	const popOverItems = useMemo(() => {
		const items = [
			{
				label: "Bestellung bearbeiten",
				onClick: () => setShowUpdateOrderSidebar(true),
				icon: PencilIcon,
			}];

		if (minutesTracked === 0 && totalOrders > 1) {
			items.push({
				label: "Bestellung löschen",
				onClick: () => setShowDeleteOrderSidebar(true),
				icon: TrashIcon,
			});
		}

		return items;

	}, [minutesTracked, totalOrders]);

	const enrichedStaffings = useMemo(() => {
		if (staffings && allUsers && projectRoles) {
			const projectLeadRoleId = projectRoles.find((pr) => pr.displayName === "Projektleiter")?.id;
			const enrichedStaffings = staffings.map((staffing) => {
				const user = allUsers.find((u) => staffing.userId === u.id)!;
				return {
					fullName: user.fullName,
					firstName: user.firstName,
					lastName: user.lastName,
					userIsActive: user.isActive,
					...staffing,
				};
			});

			return enrichedStaffings.sort((a, b) => {
				// Sort by isActive
				if (a.isActive && !b.isActive) {
					return -1;
				}
				if (!a.isActive && b.isActive) {
					return 1;
				}

				// Sort by projectLeadRoleId within the active and inactive blocks
				if (a.projectRoleId === projectLeadRoleId && b.projectRoleId !== projectLeadRoleId) {
					return -1;
				}
				if (a.projectRoleId !== projectLeadRoleId && b.projectRoleId === projectLeadRoleId) {
					return 1;
				}

				// Sort by fullName within those blocks
				return a.fullName.localeCompare(b.fullName);
			});

		}
		return null;
	}, [staffings, allUsers, projectRoles]);

	return (
		<>
			<section className="flex flex-col">
				<div className="p-4 bg-gray-300 bg-opacity-100 text-gray-500 transition-colors duration-200 rounded-t-lg">
					<div className="flex justify-between gap-8">
						<Headline type="h2"
								  className="mb-0">
							{title}
						</Headline>
						<div className="flex justify-end items-center gap-2">
							<Status theme={null === confirmedAt ? "error" : "success"}>
								{null === confirmedAt ? "Nicht bestätigt" : "Bestätigt"}
							</Status>
							<Status>
								Bestellnummer: {orderNumber || <span className="opacity-40 ml-1">nicht vorhanden</span>}
							</Status>
							<ButtonWithPopover
								theme="dark"
								items={popOverItems}
							/>
						</div>
					</div>
					{totalOrders > 1 && <dl className="mt-4 grid grid-cols-1 gap-5 sm:grid-cols-2">
						<div className="rounded-lg bg-white px-2 py-3 shadow">
							<dt className="text-xs font-normal truncate text-gray-500">Laufzeit</dt>
							<dd className="text-m font-semibold tracking-tight text-gray-900">
								{startDate && endDate ? `${formatDateRange(new Date(startDate), new Date(endDate))}` : "n/a"}
							</dd>
						</div>
						<div className="rounded-lg bg-white px-2 py-3 shadow">
							<dt className="text-xs font-normal mb-2 truncate text-gray-500">
								Verbrauch
							</dt>
							<dd className="text-3xl font-semibold tracking-tight text-gray-900">
								<ProjectStatsCompactBarChart
									manDaysPlanned={manDaysPlanned}
									manDaysTracked={Math.round(minutesTracked / 60 / 8)}
									budgetCentsPlanned={budgetCents}
									budgetCentsTracked={budgetCentsTracked}
									showBudget={showBudget}
									valuesVisible={false}
								/>
							</dd>
						</div>
					</dl>}
				</div>
				<div className="rounded-b-lg bg-primary-500 bg-opacity-5">
					<div className="px-4">
						<SectionHeading theme={undefined === visibleSection ? "none" : "dark"}>
							<SectionHeading.Tabs
								tabs={[
									{
										name: `Staffing (${staffings.length})`,
										value: "staffings",
										icon: <UsersIcon className="w-5" />,
										active: isFirstInList,
									},
									{
										name: "Bestelldetails",
										value: "details",
										icon: <DocumentTextIcon className="w-5" />,
										active: false,
									},
									{
										name: `Kommentare (${!comment ? 0 : 1})`,
										value: "comments",
										icon: <ChatBubbleBottomCenterTextIcon className="w-5" />,
										active: false,
									},
								]}
								onChange={(tab) => setVisibleSection(tab)}
								deselectable
							/>
						</SectionHeading>
					</div>

					<div>
						{"staffings" === visibleSection && (
							<dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 m-4">
								{enrichedStaffings && enrichedStaffings
									.map(
										({
											budgetCentsTracked,
											dailyRateCents,
											id,
											isActive,
											manDays,
											minutesTracked,
											projectRoleId,
											firstName,
											lastName,
											fullName,
											projectId,
											userId,
											userIsActive
										}) => {
											const firstNameSanitized = firstName ? firstName : "--";
											const lastNameSanitized = lastName ? lastName : "--";
											const fullNameSanitized = fullName ? fullName : "--";

											const projectRole = projectRoles.find((pr) => projectRoleId === pr.id);

											return (
												<Staffing
													key={id}
													budgetCentsPlanned={manDays * dailyRateCents}
													budgetCentsTracked={budgetCentsTracked}
													dailyRateCents={dailyRateCents}
													firstName={firstNameSanitized}
													fullName={fullNameSanitized}
													lastName={lastNameSanitized}
													manDaysPlanned={manDays}
													manDaysTracked={Math.round(minutesTracked / 60 / 8)}
													onEditClick={() => setSelectedStaffingIdForUpdate(id)}
													onEditRemove={() => setSelectedStaffingIdForDeletion(id)}
													projectId={projectId}
													projectRole={projectRole}
													showBudget={showBudget}
													staffedUserId={userId}
													staffingId={id}
													staffingIsActive={isActive}
													userIsActive={userIsActive}
												/>
											);
										},
									)}
								<ButtonNewItem size="sm"
											   theme="inline"
											   onClick={() => setShowCreateStaffingSidebar(true)}>
									Staffing hinzufügen
								</ButtonNewItem>
							</dl>
						)}

						{"details" === visibleSection && (
							<dl className="divide-y divide-gray-100 m-4">
								{orderDetailsData.map(({ label, content }, dIndex) => (
									<div key={dIndex}
										 className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
										<dt className="text-sm font-medium leading-6 text-gray-900">{label}</dt>
										<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
											{content}
										</dd>
									</div>
								))}
							</dl>
						)}

						{"comments" === visibleSection && (
							<>
								{comment ? (
									<div className="text-sm m-4">{comment}</div>
								) : (
									<div className="text-xs text-gray-500 m-4">Keine Kommentare vorhanden.</div>
								)}
							</>
						)}
					</div>
				</div>
			</section>
			<UpdateOrderSidebar
				isOpen={showUpdateOrderSidebar}
				setOpen={setShowUpdateOrderSidebar}
				clientId={clientId}
				projectId={orderData.projectId}
				orderData={orderData}
			/>

			<CreateStaffingSidebar
				isOpen={showCreateStaffingSidebar}
				onClose={()=>setShowCreateStaffingSidebar(false)}
				orderId={orderData.id}
				projectId={orderData.projectId}
				projectPhaseId={orderData.projectPhaseId}
			/>

			{selectedStaffingIdForUpdate && (
				<UpdateStaffingSidebar
					isOpen={!!selectedStaffingIdForUpdate}
					close={() => setSelectedStaffingIdForUpdate(null)}
					projectId={orderData.projectId}
					staffingId={selectedStaffingIdForUpdate}
				/>
			)}
			<DeleteStaffingModal
				isOpen={!!selectedStaffingIdForDeletion}
				staffingId={selectedStaffingIdForDeletion}
				projectId={orderData.projectId}
				onCloseClick={() => setSelectedStaffingIdForDeletion(null)}
			/>
			<DeleteOrderModal
				isOpen={showDeleteOrderSidebar}
				orderId={orderData.id}
				orderTitle={orderData.title}
				projectId={orderData.projectId}
				onCloseClick={() => setShowDeleteOrderSidebar(false)}
			/>
		</>
	);
};

export default OrderView;
