import { useAggregatedData } from "~/hooks/useAggregateData";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAllClients } from "~/modules/client/api/client/clientQueries";
import { useAllClientContactPersons } from "~/modules/client/api/clientContactPerson/clientContactPersonQueries";
import { useAllCompanies } from "~/modules/client/api/company/companyQueries.ts";
import ContactsIndexView from "~/modules/client/components/contact/ContactsIndexView";
import LoadingPage from "~/pages/LoadingPage";
import { PermissionNames } from "~/types/entityNames.ts";

const ContactsIndexPage: React.FC = () => {
	const guard = userViewGuard(PermissionNames.CanManageProjects);
	if(guard) return guard;
	const { data, options } = useAggregatedData([
		{ hook: () => useAllCompanies(), name: "companies" },
		{ hook: () => useAllClients(), name: "clients" },
		{ hook: () => useAllClientContactPersons(), name: "contacts" },
	]);

	if (options.loadingPcent < 100) return <LoadingPage pcent={options.loadingPcent} />;

	return <ContactsIndexView {...data} />;
};

export default ContactsIndexPage;
