import { useMemo } from "react";
import { getSelectBoxDefaultOptionsData, createSelectBoxOptionData } from "~/utils/form/formUtils.ts";
import { FormInputOption } from "~/types/form.ts";
import { useAllLocations } from "~/modules/location/api/location/locationQueries.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

const useLocationOptions = (includeCtaOption: boolean = false): FormInputOption[] => {
	const { data: locationData } = useAllLocations();
	return useMemo(() => {
		if (locationData) {
			const options = locationData.sort(byObjectProperty("displayName")).map((location) => {
				return createSelectBoxOptionData(location.displayName, location.id);
			});

			if (includeCtaOption) {
				return [getSelectBoxDefaultOptionsData(), ...options];
			}
			return options;
		}
		return [];
	}, [locationData, includeCtaOption]);
};

export default useLocationOptions;

