import { useMemo } from "react";
import { getSelectBoxDefaultOptionsData, createSelectBoxOptionData } from "~/utils/form/formUtils.ts";
import { FormInputOption } from "~/types/form.ts";
import { useAllLanguages } from "~/modules/country/api/language/languageQueries.ts";


const useLanguageSelectOptions = (includeCtaOption: boolean = false): FormInputOption[] => {
	const { data: languageData } = useAllLanguages();
	return useMemo(() => {
		if (languageData) {
			const options = languageData.map((language) => {
				return createSelectBoxOptionData(language.displayName, language.id);
			});

			if (includeCtaOption) {
				return [getSelectBoxDefaultOptionsData(), ...options];
			}
			return options;
		}
		return [];
	}, [languageData, includeCtaOption]);
};

export default useLanguageSelectOptions;

