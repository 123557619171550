import { getAllCompanies, getCompany } from "~/modules/client/api/company/companyApiDispatchers.ts";
import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { QueriesOptions, useQuery } from "react-query";

const ENTITY_QUERY_KEY = "company";

export const All_COMPANIES_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];
export const COMPANY_QUERY_KEY = (companyId: string) => [ENTITY_QUERY_KEY, companyId];

export const useAllCompanies = (options?: QueriesOptions<any>) => useQuery(
	All_COMPANIES_QUERY_KEY,
	() => getAllCompanies().then(getDataFromResponse),
	options,
);

export const useCompany = (companyId: string, options?: QueriesOptions<any>) => useQuery(
	COMPANY_QUERY_KEY(companyId),
	() => getCompany(companyId).then(getDataFromResponse),
	options,
);