type HolidayMapType = { [key: string]: Decimal };
import { isSameDay } from "date-fns";
import Decimal from "decimal.js-light";
import { convertLocalToUTCDate } from "~/utils/dateAndTimeUtils.ts";

export function calculateVacationDaysFromTimespan({
	startDate,
	endDate,
	holidays,
	workingSchedules,
	firstDayIsHalf = false,
	lastDayIsHalf = false,
}: {
	startDate: string,
	endDate: string,
	holidays: HolidayType[],
	workingSchedules: WorkingScheduleType[],
	firstDayIsHalf: boolean,
	lastDayIsHalf: boolean,
}): Decimal {
	const workingDays = ["monday", "tuesday", "wednesday", "thursday", "friday"];
	const holidaysMap = holidays.reduce((acc: HolidayMapType, curr) => {
		acc[curr.date] = new Decimal(curr.workDays);
		return acc;
	}, {});

	const schedules = workingSchedules
		.sort((a, b) => new Date(b.validFrom).getTime() - new Date(a.validFrom).getTime());

	let defaultSchedule = schedules.find(schedule => new Date(schedule.validFrom) <= new Date(startDate));
	if (!defaultSchedule) {
		defaultSchedule = {
			id: "default",
			userId: "userId",
			validFrom: startDate,
			monday: true,
			tuesday: true,
			wednesday: true,
			thursday: true,
			friday: true,
			isDeletable: false,
		};
	}

	let currentDate = convertLocalToUTCDate(startDate);
	const endDateObj = convertLocalToUTCDate(endDate);
	let totalDays = new Decimal(0);
	const startDateIso = convertLocalToUTCDate(startDate);
	const endDateIso = convertLocalToUTCDate(endDate);

	while (currentDate <= endDateObj) {
		const currentDateString = currentDate.toISOString().split("T")[0];
		const holidayValue = holidaysMap[currentDateString];

		// Check if it's a weekend.
		if (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
			currentDate.setDate(currentDate.getDate() + 1);
			continue;
		}

		// Check if it's a holiday.
		if (holidayValue !== undefined) {
			totalDays = totalDays.add(new Decimal(1).sub(holidayValue));
		} else {
			const currentSchedule = schedules.find(schedule => schedule.validFrom <= currentDateString) || defaultSchedule;
			const currentDayKey = workingDays[currentDate.getUTCDay() - 1] as keyof WorkingScheduleType;

			// Check if it's a working day.
			if (currentSchedule[currentDayKey]) {
				if (firstDayIsHalf && isSameDay(currentDate, startDateIso)) {
					totalDays = totalDays.add(0.5);
				} else if (lastDayIsHalf && isSameDay(currentDate, endDateIso)) {
					totalDays = totalDays.add(0.5);
				} else {
					totalDays = totalDays.add(1);
				}
			}
		}

		currentDate.setDate(currentDate.getDate() + 1);
	}

	return totalDays;
}
