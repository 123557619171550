import { isAfter } from "date-fns";
import Decimal from "decimal.js-light";
import AbsenceViewUserSelect from "~/modules/absence/components/AbsencePageUserSelect";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import BreadcrumbsPortal from "components/Breadcrumbs";
import ContentWrapper from "~/components/ContentWrapper";
import Button from "~/components/form/Button";
import PageHeading from "~/components/headings/PageHeading/PageHeading.tsx";
import SectionHeading from "~/components/headings/SectionHeading/SectionHeading.tsx";
import { appRoutes } from "~/constants/appRoute.ts";
import { useAuth } from "~/contexts/AuthContext";
import AbsencePageTabs from "~/modules/absence/components/AbsencePageTabs";
import AbsencesTable from "~/modules/absence/components/AbsencesTable";
import CreateAbsenceSidebar from "~/modules/absence/components/CreateAbsenceSidebar";
import DeleteAbsenceModal from "~/modules/absence/components/DeleteAbsenceModal";
import RemainingVacationDays from "~/modules/absence/components/RemainingVacationDays";
import UpdatedAbsenceSidebar from "~/modules/absence/components/UpdateAbsenceSidebar";
import { AbsenceSubRoutes } from "~/modules/user/router/UserRouter/UserRouter.tsx";
import { AbsenceTypeId } from "~/types/entityIds.ts";

import { AbsenceTypeNames, PermissionNames } from "~/types/entityNames.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { formatDateRange } from "~/utils/dateAndTimeUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type AbsencesViewProps = {
	absences: AbsenceType[];
	absenceTypes: AbsenceTypeType[];
	userId: string,
	vacationLedgerReport: VacationLedgerReportType;
	workingSchedules: WorkingScheduleType[];
};

const editableAbsenceTypes = [AbsenceTypeNames.Training, AbsenceTypeNames.InternalEvent, AbsenceTypeNames.Special];
// @ToDo Create edit and delete form for absences
const AbsencesView: React.FunctionComponent<AbsencesViewProps> = ({
	absences,
	absenceTypes,
	userId,
	vacationLedgerReport,
	workingSchedules,
}) => {
	const { hasAnyPermission } = useAuth();
	const { t } = useTranslation();
	const [showCreateAbsenceForm, setShowCreateAbsenceForm] = useState(false);
	const [absenceIdToUpdate, setAbsenceIdToUpdate] = useState<string | null>(null);
	const [absenceIdToDelete, setAbsenceIdToDelete] = useState<string | null>(null);

	const userCanAdminVacations = hasAnyPermission(PermissionNames.CanManageAbsences);
	const remainingVacationDays = new Decimal(parseFloat(vacationLedgerReport.daysRemaining));
	const absenceTableRows = useMemo(() => {
		if (absences.length > 0) {
			return absences.sort(byObjectProperty("startDate", "desc")).map((absence) => {
				const isVacation = absence.absenceTypeId == AbsenceTypeId.Vacation;

				let absenceTypeDisplayName = "--";
				const absenceType = absenceTypes
					.find((absenceType) => absenceType.id === absence.absenceTypeId);
				if (absenceType) {
					absenceTypeDisplayName = t(normalizeTKey(`entities:absenceType.${absenceType.name}`));
				}

				const timeSpan = formatDateRange(new Date(absence.startDate), new Date(absence.endDate)) || "--";
				let vacationDays = null;

				if (isVacation) {
					let vacationDaysDecimal = new Decimal(absence.vacationLedgerEntry?.workDays || absence.workDays);
					if (vacationDaysDecimal.isNegative()) {
						vacationDaysDecimal = vacationDaysDecimal.negated();
					}
					vacationDays = vacationDaysDecimal.toString().replace(".", ",");
				}


				const isDeletable = userCanAdminVacations || isAfter(new Date(absence.startDate), new Date());

				const isEditable = userCanAdminVacations || isDeletable || editableAbsenceTypes.includes(absenceType?.name as AbsenceTypeNames);


				return <AbsencesTable.Row key={"absence-" + absence.id}
										  absenceType={absenceTypeDisplayName}
										  comment={absence.comment}
										  timespan={timeSpan}
										  vacationDays={vacationDays}
										  isDeletable={isDeletable}
										  isEditable={isEditable}
										  onEditClick={() => setAbsenceIdToUpdate(absence.id)}
										  onDeleteClick={() => setAbsenceIdToDelete(absence.id)}
				/>;
			});
		}
		return [];
	}, [absences, absenceTypes, userCanAdminVacations]);

	const absenceDataToUpdate = useMemo(() => {
		if (absenceIdToUpdate) {
			return absences.find(absence => absence.id === absenceIdToUpdate);

		}
		return null;
	}, [absenceIdToUpdate, absences]);

	const absenceDataToDelete = useMemo(() => {
		if (absenceIdToDelete && absenceTypes) {
			const absence = absences.find(absence => absence.id === absenceIdToDelete);
			if (absence) {
				const typeName = absenceTypes.find(absenceType => absenceType.id === absence.absenceTypeId)?.name || "";
				return {
					...absence,
					typeName,
				};
			}

		}
		return null;
	}, [absenceIdToDelete, absences, absenceTypes]);

	return (
		<>
			<BreadcrumbsPortal pages={[appRoutes.absences()]}
							   className="bg-white" />
			<PageHeading title={t("timetracking.title", "Abwesenheiten")} />
			<PageHeading.BottomBar>
				<div
					className="w-full flex items-center gap-2 text-sm font-medium text-gray-700 hover:text-gray-900 gap-x-5 z-40">
					<RemainingVacationDays remainingVacationDays={remainingVacationDays} />
					<AbsenceViewUserSelect userIsVacationManager={userCanAdminVacations}
										   userId={userId}
										   currentSubPath={AbsenceSubRoutes.absences} />
				</div>
				<Button onClick={() => setShowCreateAbsenceForm(true)}>
					{t("projects.btnNewProject", "Neue Abwesenheit")}
				</Button>
			</PageHeading.BottomBar>
			<ContentWrapper className="isolate">
				<SectionHeading sticky
								style={{ top: 73 }}>
					<AbsencePageTabs selectedTabName={AbsenceSubRoutes.absences} />
				</SectionHeading>
				<div>
					<AbsencesTable>
						{absenceTableRows}
					</AbsencesTable>
				</div>
			</ContentWrapper>
			<CreateAbsenceSidebar
				isOpen={showCreateAbsenceForm}
				onClose={() => setShowCreateAbsenceForm(false)}
				remainingVacationDays={remainingVacationDays}
				workingSchedules={workingSchedules}
				userId={userId}
			/>
			<UpdatedAbsenceSidebar
				isOpen={!!absenceDataToUpdate}
				absenceData={absenceDataToUpdate}
				onClose={() => setAbsenceIdToUpdate(null)}
				remainingVacationDays={remainingVacationDays}
				userId={userId}
				workingSchedules={workingSchedules} />
			<DeleteAbsenceModal absenceData={absenceDataToDelete}
								isOpen={!!absenceDataToDelete}
								onCloseClick={() => setAbsenceIdToDelete(null)} />
		</>
	);
};

export default AbsencesView;