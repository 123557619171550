import clsx from "clsx";

import { useMemo } from "react";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";
import styles from "./ProjectStatsCompactBarChart.module.scss";


interface ProjectStatsCompactBarChartProps {
	manDaysPlanned: number;
	manDaysTracked: number;
	budgetCentsPlanned: number;
	budgetCentsTracked: number;
	showBudget?: boolean;
	valuesVisible?: boolean;
}

const ProjectStatsCompactBarChart: React.FC<ProjectStatsCompactBarChartProps> = ({
																  manDaysPlanned,
																  manDaysTracked,
																  budgetCentsPlanned,
																  budgetCentsTracked,
																  showBudget = false,
																  valuesVisible = true,
															  }) => {
	const bars = useMemo(() => {
		const unit = showBudget ? "" : "Tage";
		const plannedValue = showBudget ? budgetCentsPlanned : manDaysPlanned;
		const trackedValue = showBudget ? budgetCentsTracked : manDaysTracked;
		const widthBaseValue = plannedValue >= trackedValue ? plannedValue : trackedValue;
		const formatter = showBudget ? formatCentsToCurrency : (value: number) => formatNumberWithComma(value, 2);
		if (trackedValue === 0) {
			return [
				{
					barColorClass: "bg-green-700",
					barWidthPercentage: 100,
					labelOffsetLeft: 50,
					labelText: `Rest: ${formatter(plannedValue)} ${unit}`,
				},
			];

		} else if (plannedValue > trackedValue) {
			const widthPercentageFirstBar = ((trackedValue / widthBaseValue) * 100);
			const widthPercentageSecondBar = (((plannedValue - trackedValue) / widthBaseValue) * 100);

			return [{
				barColorClass: "bg-orange-600",
				barWidthPercentage: widthPercentageFirstBar.toFixed(2),
				labelOffsetLeft: widthPercentageFirstBar / 2,
				labelText: `Verfügt: ${formatter(trackedValue)} ${unit}`,
			},
				{
					barColorClass: "bg-green-700",
					barWidthPercentage: widthPercentageSecondBar.toFixed(2),
					labelOffsetLeft: widthPercentageFirstBar + widthPercentageSecondBar / 2,
					labelText: `Rest: ${formatter(plannedValue - trackedValue)} ${unit}`,
				},
			];
		} else if (plannedValue === trackedValue) {
			return [
				{
					barColorClass: "bg-orange-600",
					barWidthPercentage: 100,
					labelOffsetLeft: 50,
					labelText: `Verfügt: ${formatter(trackedValue)} ${unit}`,
				},
			];

		} else {
			const widthPercentageFirstBar = ((plannedValue / widthBaseValue) * 100);
			const widthPercentageSecondBar = (((trackedValue - plannedValue) / widthBaseValue) * 100);
			return [
				{
					barWidthPercentage: widthPercentageFirstBar.toFixed(2),
					barColorClass: "bg-red-600 ",
					labelOffsetLeft: 50,
					labelText: `Verfügt: ${formatter(trackedValue)} ${unit} (+${formatter(trackedValue - plannedValue)} ${unit})`,
				},
				{
					barWidthPercentage: widthPercentageSecondBar.toFixed(2),
					barColorClass: "bg-red-600 ",
					labelOffsetLeft: null,
					labelText: null,
				},
			];
		}
	}, [manDaysPlanned,
		manDaysTracked,
		budgetCentsPlanned,
		budgetCentsTracked,
		showBudget,
		valuesVisible]);
	return (
		<div className="w-full group flex flex-col relative">
			<div className="flex justify-between w-full h-4 gap-px group">
				{bars.map((bar, index) => {
					return (
						<div
							key={"barChart-item-"[index]}
							className={clsx(
								`group relative h-full transition-all duration-200`,
								"bg-opacity-100 group-hover:bg-opacity-80",
								"hover:bg-opacity-80",
								"first:rounded-l-xl last:rounded-r-xl",
								bar.barColorClass,
							)}
							style={{ width: `${bar.barWidthPercentage}%` }}
						>

						</div>
					);
				})}
			</div>
			<div className="absolute w-full">
				{bars.map((label, index) => {
					if (label.labelText) {
						return <span
							key={"barChart-label-" + index + new Date()}
							style={{ left: `${label.labelOffsetLeft}%` }}
							className={clsx(
								"group-hover:opacity-100 transition-opacity bg-gray-800 px-2 py-1 " +
								"text-xs text-gray-100 rounded-md absolute " +
								"m-4 mx-auto whitespace-nowrap font-normal " +
								"-translate-x-1/2 translate-y-1/2 ",
								styles.arrowUp,
								{
									"group-hover:opacity-100 opacity-0": !valuesVisible,
								},
							)}
						>
								<>
									{label.labelText}
								</>
						</span>;
					}
				})}
			</div>
		</div>
	);
};

export default ProjectStatsCompactBarChart;