import { Bars3Icon, ChartPieIcon, ClockIcon, FolderIcon, UsersIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment, useMemo, useState } from "react";
import { generatePath, NavLink, useLocation } from "react-router-dom";

import Avatar from "~/components/Avatar";
import { Cog6ToothIcon, CurrencyEuroIcon } from "@heroicons/react/20/solid";
import Logo from "~/components/Logo";
import ImpersonationWidget from "~/components/MainNavigation/components/ImpersonationWidget";
import NavItem from "./components/NavItem.tsx";
import { PermissionNames, RoleNames } from "~/types/entityNames.ts";
import { appRoutes } from "~/constants/appRoute.ts";
import { useAuth } from "~/contexts/AuthContext";

type BaseNavigationItem = {
	name: string;
	href: string;
	hrefRange?: string[];
	icon: React.FC;
	current: boolean;
	permission: PermissionNames | null;
}

const baseNavigation = (userId: string): BaseNavigationItem[] => {
	return [
		{
			name: "Zeiterfassung",
			href: generatePath(appRoutes.timeTracking().path, { userId }),
			icon: ClockIcon,
			current: false,
			permission: null,
		},
		{
			name: "Kunden",
			href: appRoutes.clients().path,
			hrefRange: [appRoutes.clients().path, appRoutes.contacts().path],
			icon: UsersIcon,
			current: false,
			permission: PermissionNames.CanManageProjects,
		},
		{
			name: "Projekte",
			href: appRoutes.projects().path,
			icon: FolderIcon,
			current: false,
			permission: PermissionNames.CanManageProjects,
		},
		{
			name: "Abrechnung",
			href: appRoutes.monthlyBillingReport().path,
			icon: CurrencyEuroIcon,
			current: false,
			permission: PermissionNames.CanManageInvoices,
		},
		{
			name: "Abwesenheit",
			href: generatePath(appRoutes.absences().path, { userId }),
			icon: ChartPieIcon,
			current: false,
			permission: null,
		},
		{
			name: "Admin",
			href: appRoutes.users().path,
			icon: Cog6ToothIcon,
			current: false,
			permission: PermissionNames.CanManageUsers,
		},
	];
};

const userNavigation = {
	profile: { name: "Profile", href: appRoutes.userProfile().path, current: false, permission: null },
	logout: { name: "Logout", href: appRoutes.logout().path, current: false, permission: null },
};

const Navigation: React.FC = () => {
	const location = useLocation();
	const { user, isCheckingAuthStatus, hasAnyPermission, hasAnyRole, isImpersonating } = useAuth();

	const navigationAvailableForUser = useMemo(() => {
		if (user) {
			return baseNavigation(user.id).filter(({ permission }) => hasAnyPermission(permission));
		}
		return [];
	}, [baseNavigation, user, hasAnyPermission]);

	if (undefined === user) return;

	return (
		<div
			className={clsx("flex grow flex-col gap-y-5 overflow-y-auto px-6 shadow-md shadow-inner", !isImpersonating ? "bg-primary-500" : "bg-[magenta]")}>
			<div className="flex h-16 grow-1 items-center">
				<NavLink to={appRoutes.base().path} className="w-28 text-white hover:text-secondary-500">
					<Logo />
				</NavLink>
			</div>
			<nav className="flex flex-1 flex-col">
				<ul role="list" className="flex flex-1 flex-col gap-y-7">
					<li>
						<ul role="list" className="-mx-2 space-y-1">
							{!isCheckingAuthStatus &&
								user &&
								navigationAvailableForUser.map(({ name, href, hrefRange, icon }) => (
									<NavItem
										key={name}
										name={name}
										href={href}
										icon={icon}
										current={
											undefined === hrefRange
												? location.pathname.includes(href)
												: 0 < hrefRange.filter((h) => location.pathname.includes(h)).length
										}
									/>
								))}
						</ul>
					</li>

					<li className="-mx-6 mt-auto">
						{(hasAnyRole(RoleNames.Admin) || isImpersonating) && <div
							className="flex items-center gap-x-4 pl-20 pr-6 pb-3 text-sm font-semibold leading-6 text-white hover:text-secondary-500">
							<ImpersonationWidget />
						</div>}

						<NavLink
							to={userNavigation.profile.href}
							className="flex items-center gap-x-4 px-6 text-sm font-semibold leading-6 text-white hover:text-secondary-500"
						>
							<Avatar />
							<span aria-hidden="true">
								{user?.firstName} {user?.lastName}
							</span>
						</NavLink>
						<NavLink
							className="flex items-center gap-x-4 pl-20 pr-6 pb-3 text-sm font-semibold leading-6 text-white hover:text-secondary-500"
							to={userNavigation.logout.href}
						>
							{userNavigation.logout.name}
						</NavLink>
					</li>
				</ul>
			</nav>
		</div>
	)
		;
};

const MainNavigation: React.FC = () => {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const { user } = useAuth();

	return (
		<>
			<Transition.Root show={sidebarOpen} as={Fragment}>
				<Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
					<Transition.Child
						as={Fragment}
						enter="transition-opacity ease-linear duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-linear duration-300"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-900/80" />
					</Transition.Child>

					<div className="fixed inset-0 flex">
						<Transition.Child
							as={Fragment}
							enter="transition ease-in-out duration-300 transform"
							enterFrom="-translate-x-full"
							enterTo="translate-x-0"
							leave="transition ease-in-out duration-300 transform"
							leaveFrom="translate-x-0"
							leaveTo="-translate-x-full"
						>
							<Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
								<Transition.Child
									as={Fragment}
									enter="ease-in-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in-out duration-300"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="absolute left-full top-0 flex w-16 justify-center pt-5">
										<button
											type="button"
											className="-m-2.5 p-2.5"
											onClick={() => setSidebarOpen(false)}
										>
											<span className="sr-only">Close sidebar</span>
											<XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
										</button>
									</div>
								</Transition.Child>
								<Navigation />
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>

			<div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col z-50">
				<Navigation />
			</div>

			<div
				className="sticky top-0 z-40 flex items-center gap-x-6 bg-primary-500 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
				<button
					type="button"
					className="-m-2.5 p-2.5 text-white lg:hidden"
					onClick={() => setSidebarOpen(true)}
				>
					<span className="sr-only">Open sidebar</span>
					<Bars3Icon className="h-6 w-6" aria-hidden="true" />
				</button>
				<div className="flex flex-1 justify-start">
					<NavLink to={appRoutes.base().path} className="w-28">
						<Logo />
					</NavLink>
				</div>
				<NavLink
					to={userNavigation.profile.href}
					className="flex items-center gap-x-4 px-6 text-sm font-semibold leading-6 text-white hover:text-secondary-500"
				>
					<Avatar firstName={user?.firstName} lastName={user?.lastName} />
				</NavLink>
			</div>
		</>
	);
};

export default MainNavigation;
