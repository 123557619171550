import { yupResolver } from "@hookform/resolvers/yup";
import React, { useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import ComboBox from "~/components/form/ComboBox";
import { updateStaffing } from "~/modules/project/api/staffing/staffingApiDispatchers.ts";
import { StaffingType, UpdateStaffingData } from "~/modules/project/api/staffing/staffingTypes.ts";

import Button from "~/components/form/Button/Button.tsx";

import FormSection, { FormSectionGroup } from "~/components/form/FormSection";
import Select from "~/components/form/Select";
import SubmitButton from "~/components/form/SubmitButton";
import Switch from "~/components/form/Switch";
import Headline from "~/components/Headline";
import Sidebar from "~/components/Sidebar";
import StaffingBudgetFormSection from "~/modules/project/components/forms/formSections/StaffingBudgetFormSection";
import useProjectsProjectRolesOptions from "~/hooks/form/formOptionsData/useProjectsProjectRolesOptions.ts";
import useUserSelectOptions, { staffableUsersFilterFn } from "~/hooks/form/formOptionsData/useUsersSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type CreateStaffingFormProps = {
	onSuccess: () => void;
	onCancel: () => void;
	staffingData: StaffingType;
};

interface UpdateStaffingFormData extends Omit<UpdateStaffingData, "orderId" | "projectId" | "projectPhaseId"> {
}

const UpdateStaffingForm: React.FC<CreateStaffingFormProps> = ({ onSuccess, onCancel, staffingData }) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);

	const userSelectOptions = useUserSelectOptions(staffableUsersFilterFn);
	const projectRoleSelectOptions = useProjectsProjectRolesOptions({ projectId: staffingData.projectId });

	const schema = useMemo(() => {
		return yup.object({
			isActive: yup.boolean().required(),
			dailyRateCents: yup.number().required(),
			manDays: yup.number().required(),
			projectRoleName: yup.string().required(),
			userId: yup.string().required(),
		});
	}, []);

	const defaultValues = useMemo(() => {
		const projectRoleDisplayName = projectRoleSelectOptions.find((projectRole) => projectRole.value === staffingData.projectRoleId)?.label || "";

		return {
			isActive: staffingData.isActive,
			dailyRateCents: staffingData.dailyRateCents,
			manDays: staffingData.manDays,
			projectRoleName: projectRoleDisplayName,
			userId: staffingData.userId,
		};
	}, [staffingData, projectRoleSelectOptions]);

	const {
		handleSubmit,
		control,
		formState: { errors, isValid, isDirty, isSubmitted },
		watch,
	} = useForm<UpdateStaffingFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateStaffingFormData>(schema),
	});


	const formIsSubmittable = useFormIsSubmittable({
		enableInitially: false,
		isSubmitted,
		isValid,
		isLoading: busy,
		isDirty,
	});

	const onSubmit: SubmitHandler<UpdateStaffingFormData> = async (data: UpdateStaffingFormData) => {
		setBusy(true);
		const staffingUpdateData = {
			...data,
			projectId: staffingData.projectId,
			projectPhaseId: staffingData.projectPhaseId,
			orderId: staffingData.orderId,
		};

		try {
			await updateStaffing({
				projectId: staffingData.projectId,
				staffingId: staffingData.id,
				staffingData: staffingUpdateData,
			});
			onSuccess();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex flex-col justify-start w-full min-h-full"
		>
			<Sidebar.Header>
				<Headline type="h2">Staffing bearbeiten</Headline>
			</Sidebar.Header>
			<Sidebar.Content>
				{busy && <Sidebar.BusyOverlay />}
				<FormSectionGroup>
					<FormSection title="Mitarbeiter">
						<div className="grid gap-x-6 gap-y-2 grid-cols-6">
							<div className="col-span-6">
								<Select
									name={"userId"}
									disabled={true}
									control={control}
									optionsData={userSelectOptions}
								/>
							</div>
						</div>
					</FormSection>
					<FormSection title="Zeiterfassung">
						<div className="grid gap-x-6 gap-y-2 grid-cols-6">
							<div className="col-span-6">
								<Switch name={"isActive"}
										control={control}
										labelOn={"Aktiv"}
										labelOff={"Inaktiv"} />
							</div>
						</div>
					</FormSection>
					<FormSection title="Rolle im Projekt">
						<div className="grid gap-x-6 gap-y-2 grid-cols-6">
							<div className="col-span-6">
								<ComboBox
									placeholder="Name der Rolle"
									name={"projectRoleName"}
									control={control}
									optionsData={projectRoleSelectOptions}
								/>
							</div>
						</div>
					</FormSection>
					<StaffingBudgetFormSection control={control}
											   errors={errors}
											   watch={watch} />
				</FormSectionGroup>
			</Sidebar.Content>
			<Sidebar.Footer>
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					abbrechen
				</Button>
			</Sidebar.Footer>
		</form>
	);
};

export default UpdateStaffingForm;
