import { PROJECT_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import { PROJECTS_PHASES_QUERY_KEY } from "~/modules/project/api/projectPhase/projectPhaseQueries.ts";
import Sidebar from "~/components/Sidebar";
import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { PROJECTS_STAFFINGS_QUERY_KEY } from "~/modules/project/api/staffing/staffingQueries.ts";
import UpdateProjectPhaseStatusForm
	from "~/modules/project/components/ProjectDetailsView/components/UpdateProjectPhaseStatusForm";

type UpdateProjectPhaseStatusSidebarProps = {
	currentPhaseStatusId: string;
	isOpen: boolean;
	phasesCount: number;
	phaseHasTimeTrackings: boolean;
	projectId: string;
	projectPhaseId: string;
	onClose: (open: boolean) => void;
};

const UpdateProjectPhaseStatusSidebar: React.FunctionComponent<UpdateProjectPhaseStatusSidebarProps> = ({
	currentPhaseStatusId,
	isOpen,
	phasesCount,
	phaseHasTimeTrackings,
	projectId,
	projectPhaseId,
	onClose,

}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async (newStatusId: string) => {
		queryClient.refetchQueries(PROJECT_QUERY_KEY(projectId));
		if (["3", "4", "5"].includes(newStatusId)) {
			queryClient.refetchQueries(PROJECTS_STAFFINGS_QUERY_KEY(projectId));
		}
		await queryClient.refetchQueries(PROJECTS_PHASES_QUERY_KEY(projectId));
		onClose(false);
	}, [queryClient, projectId]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose(!isOpen)}>
			<UpdateProjectPhaseStatusForm
				currentStatusId={currentPhaseStatusId}
				onSuccess={handleSuccess}
				onCancel={() => onClose(false)}
				phaseHasTimeTrackings={phaseHasTimeTrackings}
				phasesCount={phasesCount}
				projectId={projectId}
				projectPhaseId={projectPhaseId}
			/>
		</Sidebar>
	);
};

export default UpdateProjectPhaseStatusSidebar;
