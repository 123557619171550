import { useMemo } from "react";
import { mapDataToSelectOptionsData } from "~/utils/form/formUtils.ts";
import { FormInputOption } from "~/types/form.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";
import { useClientsInvoiceRecipients } from "~/modules/billing/api/invoiceRecipient/invoiceRecipientQueries.ts";


const useClientsInvoiceRecipientSelectOptions = (clientId: string): FormInputOption[] => {
	const { data: clientsInvoiceRecipientsData } = useClientsInvoiceRecipients(clientId, { enabled: !!clientId });
	return useMemo(() => {
		if (clientsInvoiceRecipientsData && clientId) {
			const sortedContactsData = clientsInvoiceRecipientsData.sort(byObjectProperty("displayName"));
			return mapDataToSelectOptionsData(sortedContactsData, "id", "displayName");
		}
		return [];
	}, [clientsInvoiceRecipientsData, clientId]);
};

export default useClientsInvoiceRecipientSelectOptions;

