import { useMemo } from "react";
import { UniqueValidatorArrayObject } from "~/utils/form/validationRules.ts";
import { sanitizeStringForComparison } from "~/utils/form/formUtils.ts";
import { useAllProjects } from "~/modules/project/api/project/projectQueries.ts";


export const useProjectTitleValidationComparisonData = (): UniqueValidatorArrayObject[] => {
	const {
		data: allProjectsData,
	} = useAllProjects();

	return useMemo(() => {
		if (allProjectsData) {
			return allProjectsData.map((project) => {
				return {
					id: project.id,
					value: sanitizeStringForComparison(project.title),
				};
			});
		}

		return [];
	}, [allProjectsData]);
};