import { ArrowSmallRightIcon, EnvelopeIcon, PhoneIcon, StarIcon } from "@heroicons/react/20/solid";

import Avatar from "~/components/Avatar";
import Headline from "~/components/Headline";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

interface Props {
	firstName: string;
	lastName: string;
	clientDisplayName?: string;
	title: string | null;
	description: string | null;
	emailBusiness: string | null;
	phone: string | null;
	href: string | null;
}

const ContactCard: React.FC<Props> = ({
	firstName,
	lastName,
	clientDisplayName,
	title,
	description,
	emailBusiness,
	phone,
	href,
}) => {
	const CardContent = () => (
		<div className="flex w-full items-center justify-between space-x-6 p-6 bg-white group">
			<div className="flex-1 truncate">
				<div className="flex items-center space-x-3">
					<Headline type="h3" className="group-hover:text-secondary-500">
						{title} {firstName} {lastName}
					</Headline>
				</div>
				{clientDisplayName && (
					<div className="flex flex-row gap-1 items-center truncate mt-1 text-sm text-gray-500">
						<StarIcon className="w-4 h-4" />
						{clientDisplayName}
					</div>
				)}
				{description && (
					<div className="flex flex-row gap-1 items-center truncate text-xs text-gray-500">
						<ArrowSmallRightIcon className="w-4 h-4" />
						{description}
					</div>
				)}
			</div>
			<Avatar firstName={firstName} lastName={lastName} />
		</div>
	);
	return (
		<div
			className={clsx(
				"col-span-1 divide-y divide-gray-200 rounded-lg shadow transform-gpu transition-transform duration-200",
				"bg-white overflow-hidden",
				"hover:shadow-md",
			)}
		>
			{href ? (
				<NavLink to={href}>
					<CardContent />
				</NavLink>
			) : (
				<CardContent />
			)}

			{(emailBusiness || phone) && (
				<div>
					<div className="-mt-px flex divide-x divide-gray-200">
						{emailBusiness && (
							<div className="flex w-0 flex-1">
								<a
									href={`mailto:${emailBusiness}`}
									className={clsx(
										"relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 py-4",
										"rounded-bl-lg border border-transparent",
										"text-sm font-semibold text-gray-700",
										"hover:bg-secondary-500 hover:text-white",
										"transition-all duration-200",
									)}
								>
									<EnvelopeIcon className="h-5 w-5 text-current" aria-hidden="true" />
									Email
								</a>
							</div>
						)}
						{phone && (
							<div className="-ml-px flex w-0 flex-1">
								<a
									href={`tel:${phone}`}
									className={clsx(
										"relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 py-4",
										"rounded-br-lg border border-transparent",
										"text-sm font-semibold text-gray-900",
										"hover:bg-secondary-500 hover:text-white",
										"transition-all duration-200",
									)}
								>
									<PhoneIcon className="h-5 w-5 text-current" aria-hidden="true" />
									Anrufen
								</a>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default ContactCard;
