import React, { useCallback } from "react";
import { useQueryClient } from "react-query";
import Sidebar from "~/components/Sidebar";
import {
	USERS_VACATION_LEDGER_ENTRIES_QUERY_KEY,
	USERS_VACATION_LEDGER_REPORT_QUERY_KEY,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import { USERS_WORKING_SCHEDULES_QUERY_KEY } from "~/modules/absence/api/workingSchedule/workingScheduleQueries.ts";
import UpdateWorkingScheduleForm from "~/modules/absence/components/UpdateWorkingScheduleForm";

type UpdateWorkingScheduleSidebarProps = {
	onClose: () => void;
	isOpen: boolean;
	workingScheduleData?: WorkingScheduleType | null;
	workingSchedulesMinDate: Date;
};

const UpdateWorkingScheduleSidebar: React.FunctionComponent<UpdateWorkingScheduleSidebarProps> = ({
	onClose,
	isOpen,
	workingScheduleData,
	workingSchedulesMinDate,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(
		async () => {
			if (workingScheduleData) {
				const userId = workingScheduleData.userId;
				queryClient.refetchQueries(USERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(userId));
				await queryClient.invalidateQueries(USERS_WORKING_SCHEDULES_QUERY_KEY(userId));
				await queryClient.invalidateQueries(USERS_VACATION_LEDGER_REPORT_QUERY_KEY(userId));
				onClose();
			}
		},
		[queryClient, workingScheduleData],
	);


	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={onClose}>
			{!!workingScheduleData && <UpdateWorkingScheduleForm
				onCancel={onClose}
				onSuccess={handleSuccess}
				workingScheduleData={workingScheduleData}
				workingSchedulesMinDate={workingSchedulesMinDate}
			/>}
		</Sidebar>
	);
};

export default UpdateWorkingScheduleSidebar;
