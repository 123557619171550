import { Fragment, useMemo } from "react";
import ResponsiveBar from "~/components/charts/ProjectStatsBarChart/components/ResponsiveBar/ResponsiveBar.tsx";
import Label from "~/components/form/Label";


interface ProjectStatsBarChartProps {
	manDaysPlanned: number;
	manDaysTracked: number;
	budgetCentsPlanned: number;
	budgetCentsTracked: number;
	showBudget?: boolean;
}

const ProjectStatsBarChart: React.FC<ProjectStatsBarChartProps> = ({
																				manDaysPlanned,
																				manDaysTracked,
																				budgetCentsPlanned,
																				budgetCentsTracked,
																				showBudget = false,
																			}) => {
	const bars = useMemo(() => {
		const plannedValue = showBudget ? budgetCentsPlanned : manDaysPlanned;
		const trackedValue = showBudget ? budgetCentsTracked : manDaysTracked;
		const rest = plannedValue - trackedValue;
		let restWidthPercentage = (rest / plannedValue) * 100;
		if(restWidthPercentage < 0) restWidthPercentage = 0;
		const largestValue = Math.max(plannedValue, trackedValue, rest);

		const bars = [
			{
				label: "Plan",
				value: plannedValue,
				widthPercentage: (plannedValue / largestValue) * 100,
				className: "bg-primary-500",
			},
			{
				label: "Verfügt",
				value: trackedValue,
				widthPercentage: (trackedValue / largestValue) * 100,
				className: plannedValue >= trackedValue ? "bg-orange-600" : "bg-red-600",
			},
			{
				label: "Rest",
				value: rest > 0 ? rest : 0,
				widthPercentage:restWidthPercentage,
				className: "bg-green-700",
			},
		];


		return bars.map((bar, index) => {
			return (
				<Fragment key={"bar-chart-" + bar.label}>
					<Label size="sm" lineHeight="none">
						{bar.label}
					</Label>
					<div className="w-full col-span-11 flex items-center font-normal">
						<ResponsiveBar key={"chart-bar-" + index}
									   value={bar.value}
									   className={bar.className}
									   showBudget={showBudget}
									   percentage={bar.widthPercentage} />
					</div>
				</Fragment>
			);
		});
	}, [manDaysPlanned,
		manDaysTracked,
		budgetCentsPlanned,
		budgetCentsTracked,
		showBudget]);
	return (
		<div className="w-full grid grid-cols-12 gap-x-1 gap-y-1.5">
			{bars}
		</div>
	);
};

export default ProjectStatsBarChart;
