import { useMemo } from "react";
import { t } from "i18next";
import { createSelectBoxOptionData } from "~/utils/form/formUtils.ts";
import { FormInputOption } from "~/types/form.ts";
import { useAllEmploymentStatuses } from "~/modules/user/api/employmentStatus/employmentStatusQueries.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";


const useEmploymentStatusSelectOptions = (showPubliclyAvailableOnly = false): FormInputOption[] => {
	const { data: employmentStatusData } = useAllEmploymentStatuses();
	return useMemo(() => {
		if (employmentStatusData) {
			let employmentStatusDataFiltered = employmentStatusData;

			if (showPubliclyAvailableOnly) {
				employmentStatusDataFiltered = employmentStatusData.filter((employmentStatus) => ["2", "3", "4"].includes(employmentStatus.id));
			}

			return employmentStatusDataFiltered.sort(byObjectProperty("listPosition")).map((employmentStatus) => {
				const label = t(`entities:employmentStatus.${employmentStatus.name}` as unknown as TemplateStringsArray);
				return createSelectBoxOptionData(label, employmentStatus.id);
			});
		}
		return [];
	}, [employmentStatusData, showPubliclyAvailableOnly]);
};

export default useEmploymentStatusSelectOptions;

