import React, { useState } from "react";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import Button from "~/components/form/Button";
import Headline from "~/components/Headline/Headline";
import Hint from "~/components/Hint";
import Modal from "~/components/Modal";
import Paragraph from "~/components/Paragraph";
import { USERS_MONTHLY_CLOSINGS_QUERY_KEY } from "~/modules/timeTracking/api/monthlyClosing/monthlyClosingQueries.ts";
import { createMonthlyClosing } from "~/modules/timeTracking/api/monthlyClosing/monthlyClosingApiDispatchers.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import { useQueryClient } from "react-query";

type CreateMonthlyClosingModalProps = {
	isOpen: boolean;
	month: string;
	onClose: () => void;
	userId: string;
	year: string;
};

const CreateMonthlyClosingModal: React.FunctionComponent<CreateMonthlyClosingModalProps> = ({
	isOpen,
	onClose,
	month,
	userId,
	year,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");

	const queryClient = useQueryClient();

	const handleConfirmationClick = async () => {
		try {
			setBusy(true);
			const date = formatDateToYYYYMMDD(new Date(parseInt(year), parseInt(month), 1));

			await createMonthlyClosing({ userId, month: date });

			await queryClient.refetchQueries(USERS_MONTHLY_CLOSINGS_QUERY_KEY(userId));
			onClose();
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.month.find(
						(validationError) => validationError.rule === "monthlyClosingNotNotExistsRule",
					)
				) {
					onClose();
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	return (
		<Modal open={isOpen}
			   setOpen={onClose}>
			<div className="flex flex-col gap-3">
				<Headline type="h2">Monat schließen</Headline>
				<div>
					{!error ? (
						<Hint theme="info">
							<Paragraph>Bitte überprüfe alle Erfassungen auf Richtigkeit, bevor Du den Monat schließt.</Paragraph>
						</Hint>
					) : (
						<Hint theme="error">{error}</Hint>
					)}
				</div>
				<div className="mt-4">
					<Button disabled={busy || !!error}
							onClick={handleConfirmationClick}>
						Monat schließen
					</Button>
					<Button theme="none"
							onClick={onClose}>
						abbrechen
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default CreateMonthlyClosingModal;
