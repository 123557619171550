import { tv } from "tailwind-variants";
import { VariantProps } from "~/types/tailwindVariants";

export const timeTrackingBarVariants = tv({
	slots: {
		barWrapper: "flex rounded-md overflow-hidden text-gray-500 group",
		barInner: "p-2 w-full justify-start content-center whitespace-nowrap min-w-0 overflow-hidden overflow-ellipsis flex-shrink max-w-1/2",
	},
	variants: {
		theme: {
			project: {
				barInner: "bg-primary-400 group-hover:bg-primary-500 text-white",
			},
			internal: {
				barInner: "bg-gray-400 group-hover:bg-gray-500 text-white",
			},
			sales: {
				barInner: "bg-gray-600 group-hover:bg-gray-700 text-white",
			},
			absence:{
				barInner: "bg-primary-200 text-white",
			},
			default: {
				button: "bg-white",
			},
		},

	},
	defaultVariants: {
		theme: "default",
	},
});


export type TimeTrackingBarVariants = VariantProps<typeof timeTrackingBarVariants>;
export type TimeTrackingBarThemes = "project" | "internal" | "sales" | "default"|"absence";

