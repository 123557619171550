import { AxiosPromise } from "axios";

export interface ApiResponse<T> {
	data: T;
}

export type AxiosApiResponsePromise<T> = AxiosPromise<ApiResponse<T>>;

export enum ApiResponseErrorType {
	VALIDATION = 'VALIDATION',
	AXIOS = 'AXIOS',
	UNKNOWN = 'UNKNOWN'
}

export type ValidationErrorDetail = {
	rule: string;
	message: string;
};

export type ValidationErrors = {
	[key: string]: ValidationErrorDetail[];
};

export type ErrorResponse = {
	errors: ValidationErrors;
};

export type ValidationErrorType = {
	type: ApiResponseErrorType.VALIDATION;
	code: number;
	messages: ValidationErrors;
};

export type AxiosErrorType = {
	type: ApiResponseErrorType.AXIOS;
	code: number;
	message: string;
};

export type UnknownErrorType = {
	type: ApiResponseErrorType.UNKNOWN;
	message: string;
};

export type ErrorResult = ValidationErrorType | AxiosErrorType | UnknownErrorType;
