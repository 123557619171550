import { useState, useEffect, useRef } from 'react';

type PageStateConfig<T> = {
	pageKey: string;
	defaultState: T;
};

function hasSameShape(objA: any, objB: any): boolean {
	const keysA = Object.keys(objA).sort();
	const keysB = Object.keys(objB).sort();

	// Check if key lengths or key names differ
	if (keysA.length !== keysB.length || !keysA.every((key, index) => key === keysB[index])) return false;

	// Recursively check nested objects
	for (let key of keysA) {
		if (Array.isArray(objA[key]) && Array.isArray(objB[key])) {
			 // both are arrays, consider them same shape
		} else if (typeof objA[key] === 'object' && objA[key] !== null && objB[key] && typeof objB[key] === 'object') {
			if (!hasSameShape(objA[key], objB[key])) return false;
		} else if (typeof objA[key] !== typeof objB[key]) {
			return false;
		}
	}

	return true;
}

/**
 * `usePageStateStorage` is a custom React hook designed to synchronize component state with `localStorage`.
 *
 * Features and Behaviors:
 *
 * 1. **Initialization from `localStorage`**:
 *    - On the component's initial render, the hook checks `localStorage` for data associated with a provided `pageKey`.
 *    - If data is found and it matches the shape of the provided `defaultState`, it initializes the component state (`pageState`) with this stored data.
 *    - If no matching data is found, or the data doesn't match the shape of the `defaultState`, the component state is initialized with the `defaultState`.
 *    - This shape-checking ensures backward compatibility; if the shape of the state changes in a newer version of the component, it will fall back to the default state instead of trying to use potentially incompatible stored data.
 *
 * 2. **Automatic Saving to `localStorage`**:
 *    - Whenever `pageState` changes (using `setPageState`), the new state is automatically saved to `localStorage` with the associated `pageKey`.
 *    - This ensures that the component's state remains persistent across browser sessions or page reloads.
 *
 * 3. **Manual Control**:
 *    - The hook provides `saveState` and `getState` functions allowing explicit control over reading from or writing to `localStorage`.
 *    - This is useful for scenarios where direct manipulation of the stored state is required without affecting the component's state, or vice versa.
 *
 * Parameters:
 * - `pageKey`: A unique string used to distinguish stored data for different pages or components.
 * - `defaultState`: An object representing the default state structure and values. Used for initializing the component state and shape-checking against stored data.
 *
 * Returns:
 * - `pageState`: The current state of the component, initialized from `localStorage` or the `defaultState`.
 * - `setPageState`: A function to update the component's state. Changes trigger automatic saving to `localStorage`.
 * - `saveState`: A manual function to save a given state to `localStorage`.
 * - `getState`: A manual function to retrieve the current state from `localStorage`.
 *
 * Example Usage:
 * const { pageState, setPageState } = usePageStateStorage({ pageKey: 'myComponent', defaultState: { count: 0 } });
 */

function usePageStateStorage<T>({ pageKey: initialPageKey, defaultState }: PageStateConfig<T>) {
	const [pageKey] = useState(initialPageKey);
	const prefix = "pageState-";
	const isFirstReadRef = useRef(true);
	const isInitializationRef = useRef(true);

	const generateKey = (): string => {
		return prefix + pageKey;
	};

	const getPageState = (): T => {
		const key = generateKey();
		const serializedState = localStorage.getItem(key);

		if (serializedState && isFirstReadRef.current) {
			try {
				const parsedState = JSON.parse(serializedState);
				if (hasSameShape(parsedState, defaultState)) {
					isFirstReadRef.current = false;
					return parsedState;
				}
			} catch (e) {
				console.error("Error parsing state for pageKey:", pageKey, e);
			}
		}
		isFirstReadRef.current = false;
		return defaultState;
	};

	const [pageState, setPageState] = useState<T>(getPageState);
	const saveState = (state: T): void => {
		const key = generateKey();
		const serializedState = JSON.stringify(state);
		localStorage.setItem(key, serializedState);
	};

	useEffect(() => {
		if (isInitializationRef.current) {
			isInitializationRef.current = false;
		} else {
			saveState(pageState);
		}
	}, [pageState]);

	return {
		pageState,
		setPageState,
		/*saveState,*/
		getPageState
	};
}

export default usePageStateStorage;
