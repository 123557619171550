import React from "react";
import { NavLink } from "react-router-dom";
import Status from "~/components/Status";
import { appRoutes, generateFullPath } from "~/constants/appRoute.ts";

import { ClientContactPersonType } from "~/modules/client/api/clientContactPerson/clientContactPersonTypes.ts";
import { ProjectType } from "~/modules/project/api/project/projectTypes.ts";
import { WorkingStatusType as WorkingStatusType } from "~/modules/project/api/workingStatus/workingStatusTypes.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";

interface Props {
	data: ProjectType;
	workingStatuses: WorkingStatusType[];
	contacts?: ClientContactPersonType[];
}

const ProjectCompactInfoItem: React.FC<Props> = ({
	data: { id, title, clientContactPersonId, isActive, endDate },
	contacts,
}) => {
	const clientContactPerson = undefined !== contacts ? contacts.find((c) => clientContactPersonId === c.id) : null;
	return (
		<li className="flex items-center justify-between gap-x-6 py-5">
			<div className="min-w-0">
				<div className="flex items-start gap-x-3">
					<p className="text-sm font-semibold leading-6 text-gray-900">{title}</p>
					<Status theme={isActive ? "success" : "error"}>
						{isActive ? "Aktiv" : "Inaktiv"}
					</Status>
				</div>
				<div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
					<p className="whitespace-nowrap">
						Enddatum: {endDate ? formatDateWithGermanMonth(new Date(endDate)): "k.A."}
					</p>
					<svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
						<circle cx={1} cy={1} r={1} />
					</svg>
					<p className="truncate">
						Kontaktperson {clientContactPerson ? clientContactPerson.fullName : <i>(keine Angabe)</i>}
					</p>
				</div>
			</div>
			<div className="flex flex-none items-center gap-x-4">
				<NavLink
					to={generateFullPath(appRoutes.project(title).path, { ":projectId": id })}
					className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
				>
					Projekt anschauen<span className="sr-only">, {title}</span>
				</NavLink>
			</div>
		</li>
	);
};

export default ProjectCompactInfoItem;
