import { All_LOCATIONS_QUERY_KEY, USER_LOCATIONS_QUERY_KEY } from "~/modules/location/api/location/locationQueries.ts";
import { PROJECTS_ORDERS_QUERY_KEY } from "~/modules/project/api/order/orderQueries.ts";
import { PROJECT_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import { PROJECTS_PHASES_QUERY_KEY } from "~/modules/project/api/projectPhase/projectPhaseQueries.ts";
import { PROJECT_ROLES_BY_PROJECT_QUERY_KEY } from "~/modules/project/api/projectRole/projectRoleQueries.ts";
import CreateStaffingForm from "~/modules/project/components/forms/CreateStaffingForm";
import {
	PROJECTS_STAFFINGS_QUERY_KEY,
	USERS_ACTIVE_STAFFINGS_QUERY_KEY,
} from "~/modules/project/api/staffing/staffingQueries.ts";
import Sidebar from "~/components/Sidebar";
import { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useAuth } from "~/contexts/AuthContext";
import { USER_DELIVERABLES_QUERY_KEY } from "~/modules/timeTracking/api/deliverable/deliverableQueries.ts";
import { ALL_USERS_QUERY_KEY } from "~/modules/user/api/user/userQueries.ts";
import { UserType } from "~/modules/user/api/user/userTypes.ts";
import CreateUserForm from "~/modules/user/components/forms/CreateUserForm";

type CreateStaffingSidebarProps = {
	isOpen: boolean;
	onClose: () => void;
	orderId: string;
	projectId: string;
	projectPhaseId: string;
};

const CreateStaffingSidebar: React.FunctionComponent<CreateStaffingSidebarProps> = ({
	isOpen,
	onClose,
	orderId,
	projectId,
	projectPhaseId,
}) => {
	const { user } = useAuth();
	const [showCreateUserForm, setShowCreateUserForm] = useState(false);
	const [createdUserId, setCreatedUserId] = useState<string | null>(null);

	const queryClient = useQueryClient();

	const handleCreateUserSuccess = useCallback(async (newUserData: UserType) => {
		await queryClient.refetchQueries(ALL_USERS_QUERY_KEY);
		setCreatedUserId(newUserData.id);
		setShowCreateUserForm(false);
	}, []);

	const handleSuccess = useCallback(async (staffedUserId: string) => {
		await queryClient.refetchQueries(PROJECTS_ORDERS_QUERY_KEY(projectId));
		await queryClient.refetchQueries(PROJECTS_PHASES_QUERY_KEY(projectId));
		await queryClient.refetchQueries(PROJECT_QUERY_KEY(projectId));
		await queryClient.refetchQueries(All_LOCATIONS_QUERY_KEY);
		await queryClient.refetchQueries(PROJECTS_STAFFINGS_QUERY_KEY(projectId));
		await queryClient.refetchQueries(PROJECT_ROLES_BY_PROJECT_QUERY_KEY(projectId));
		if (staffedUserId === user?.id) {
			queryClient.refetchQueries(USERS_ACTIVE_STAFFINGS_QUERY_KEY(user?.id));
			queryClient.refetchQueries(USER_DELIVERABLES_QUERY_KEY(user?.id));
			queryClient.refetchQueries(USER_LOCATIONS_QUERY_KEY(user?.id));
		}
		onClose();
	}, [queryClient]);

	useEffect(() => {
		if (!isOpen) {
			setCreatedUserId(null);
			setShowCreateUserForm(false);
		}
	}, [isOpen]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={onClose}>
			{showCreateUserForm ? <CreateUserForm
					onSuccess={handleCreateUserSuccess}
					onCancel={() => setShowCreateUserForm(false)} /> :

				<CreateStaffingForm
					selectedUserId={createdUserId}
					onCreateUserClick={() => setShowCreateUserForm(true)}
					orderId={orderId}
					projectId={projectId}
					projectPhaseId={projectPhaseId}
					onSuccess={handleSuccess}
					onCancel={onClose}
				/>}
		</Sidebar>
	);
};


export default CreateStaffingSidebar;
