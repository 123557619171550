import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import CreateOrderSidebar from "modules/project/components/ProjectDetailsView/components/CreateOrderSidebar";
import CreateProjectPhaseSidebar
	from "modules/project/components/ProjectDetailsView/components/CreateProjectPhaseSidebar";
import DeleteProjectModal from "modules/project/components/ProjectDetailsView/components/DeleteProjectModal";
import Orders from "modules/project/components/ProjectDetailsView/components/Orders";
import UpdateProjectSidebar from "modules/project/components/ProjectDetailsView/components/UpdateProjectSidebar";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, NavLink } from "react-router-dom";
import Breadcrumbs from "~/components/Breadcrumbs/components/Breadcrumbs";
import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import Comment from "~/components/Comment";
import ContentWrapper from "~/components/ContentWrapper";
import Button from "~/components/form/Button";
import { SwitchPlain } from "~/components/form/Switch/Switch.tsx";
import PageHeading from "~/components/headings/PageHeading/PageHeading.tsx";
import SectionHeading from "~/components/headings/SectionHeading";
import Headline from "~/components/Headline";
import HeroSection, { heroSectionBackgroundColor, HeroSectionHighlights } from "~/components/sections/HeroSection";
import Stats from "~/components/Stats";
import Status from "~/components/Status";
import { appRoutes } from "~/constants/appRoute.ts";
import { ClientType } from "~/modules/client/api/client/clientTypes.ts";
import { ClientContactPersonType } from "~/modules/client/api/clientContactPerson/clientContactPersonTypes.ts";
import { CompanyType } from "~/modules/client/api/company/companyTypes.ts";
import { Country } from "~/modules/country/api/country/countryTypes.ts";
import { OrderType } from "~/modules/project/api/order/orderTypes.ts";
import { ProjectType } from "~/modules/project/api/project/projectTypes.ts";
import { ProjectPhaseType } from "~/modules/project/api/projectPhase/projectPhaseTypes.ts";
import { ProjectRole } from "~/modules/project/api/projectRole/projectRoleTypes.ts";
import { StaffingType } from "~/modules/project/api/staffing/staffingTypes.ts";
import { WorkingStatusType as WorkingStatusType } from "~/modules/project/api/workingStatus/workingStatusTypes.ts";
import { formatManDays } from "~/modules/timeTracking/utils/timeTrackingUtils.ts";
import { UserType } from "~/modules/user/api/user/userTypes.ts";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";
import ProjectPhases from "./components/Phases";

interface ProjectDetailsViewProps {
	projectData: ProjectType;
	phases: ProjectPhaseType[];
	orders: OrderType[];
	staffings: StaffingType[];
	allUsers: UserType[];
	client: ClientType;
	company: CompanyType;
	clientContactPersons: ClientContactPersonType[];
	projectRoles: ProjectRole[];
	workingStatuses: WorkingStatusType[];
	locations: Location[];
	countries: Country[];
}

/*const getTotalManDays = ({ phases, staffings }: { phases: ProjectPhaseType[]; staffings: StaffingType[] }) => {
	return 0 === staffings.length ? sumUp(phases, "manDays") : sumUp(staffings, "manDays");
};*/

const ProjectDetailsView: React.FC<ProjectDetailsViewProps> = ({
	projectData,
	phases,
	orders,
	staffings,
	allUsers,
	client,
	company,
	projectRoles,
	workingStatuses,
	locations,
	countries,
}) => {
	const { t } = useTranslation();
	const [selectedPhaseId, setSelectedPhaseId] = useState<null | string>(null);
	const [showCreatePhaseSidebar, setShowCreatePhaseSidebar] = useState<boolean>(false);
	const [showCreateOrderSidebar, setShowCreateOrderSidebar] = useState(false);
	const [showUpdateProjectSidebar, setShowUpdateProjectSidebar] = useState<boolean>(false);
	const [showDeleteProjectSidebar, setShowDeleteProjectSidebar] = useState<boolean>(false);
	const [showBudget, setShowBudget] = useState(false);

	const {
		budgetCentsPlanned,
		budgetCentsTracked,
		clientId,
		comment,
		endDate,
		isActive,
		isInternal,
		managedBy,
		manDaysPlanned,
		startDate,
		title,
	} = projectData;

	useEffect(() => {
		if (0 === phases.length) return;
		// after deleting a phase, the selectedPhaseId might not exist anymore
		const phaseExists = phases.find((p) => p.id === selectedPhaseId);
		if (selectedPhaseId && phaseExists) return;

		setSelectedPhaseId(phases[phases.length - 1].id);
	}, [phases, selectedPhaseId]);

	const projectHasConfirmedOrder = useMemo(() => {
		if (orders) {
			return !!orders.find((order) => order.confirmedAt !== null);
		}
		return false;
	}, [orders]);

	const totalBudgetFormatted = formatCentsToCurrency(
		projectHasConfirmedOrder ? budgetCentsTracked : budgetCentsPlanned, 0,
	);

	const totalManDaysFormatted = formatManDays(Math.round(manDaysPlanned)) + " PT";

	const projectLead = allUsers.find((u) => managedBy === u.id)?.fullName || "n/a";
	const clientDisplayName = client.displayName;
	const companyDisplayName = company.displayName;

	const allComments = [
		...phases.filter((p) => p.comment).map((p) => ({ text: p.comment, type: `Phase (${p.title})` })),
	];


	const selectedPhaseData = useMemo(() => {
		if (phases && selectedPhaseId) {
			return phases.find((p) => p.id === selectedPhaseId);
		}
		return null;
	}, [phases, selectedPhaseId]);

	comment && allComments.unshift({ text: comment, type: "Projekt" });

	return (
		<>
			<PageHeading.BottomBar showBottomBorder={true}>
				<div className="w-full">
					<Breadcrumbs pages={[appRoutes.projects(), title]}
								 className={heroSectionBackgroundColor} />
				</div>
				<div className="flex justify-end w-full gap-x-2">
					<SwitchPlain
						value={showBudget}
						onChange={(enabled) => setShowBudget(enabled)}
						label={t("project.toggleSwitchDayCosts", "Arbeitstage / Euro")}
						labelOn="Euro"
						labelOff="Personentage"
						allLabelsVisible={true}
					/>
					<Button onClick={() => setShowCreatePhaseSidebar(true)}>
						{t("projects.orders.btnAddNew", "Neue Phase")}
					</Button>
					<Button disabled={!selectedPhaseId}
							onClick={() => setShowCreateOrderSidebar(true)}>
						{t("projects.orders.btnAddNew", "Neue Bestellung")}
					</Button>

				</div>
			</PageHeading.BottomBar>
			<HeroSection>
				<div className="flex justify-between">
					<div className="flex flex-col items-start">
						<Headline type="h1"
								  className="text-primary-500">
							{title}
						</Headline>
					</div>
					<div className="flex justify-between items-center gap-2">
						{isInternal && <Status theme="error">INTERN</Status>}
						<Status theme={isActive ? "success" : "error"}>
							{isActive ? "Aktiv" : "Inaktiv"}
						</Status>
						<ButtonWithPopover
							theme="dark"
							items={[
								{
									label: "Projekt bearbeiten",
									onClick: () => setShowUpdateProjectSidebar(true),
									icon: PencilIcon,
								},
								{
									label: "Projekt löschen",
									onClick: () => setShowDeleteProjectSidebar(true),
									icon: TrashIcon,
								},
							]}
						/>
					</div>
				</div>
			</HeroSection>
			<HeroSectionHighlights>
				<Stats
					fields={[
						{
							label: "Kunde",
							element: (
								<NavLink
									to={generatePath(appRoutes.client("").path, { clientId })}
									className="hover:text-primary-100 transition-color duration-200 flex flex-col"
								>
									<div>{companyDisplayName}</div>
									<div className="italic text-xs">{clientDisplayName}</div>
								</NavLink>
							),
							flexBasePercentage: 25,
						},
						{
							label: `Gesamtbudget${!projectHasConfirmedOrder ? " (Plan)" : ""}`,
							element: (
								<div className="flex flex-col">
									<span>{showBudget ? totalBudgetFormatted : totalManDaysFormatted}</span>
									<span className="text-sm">{showBudget ? totalManDaysFormatted : totalBudgetFormatted}</span>
								</div>
							),
							flexBasePercentage: 20,
						},
						{
							label: "Phasen",
							element: phases.length,
							flexBasePercentage: 6,
						},
						{
							label: "Timing",
							element: <div className="flex flex-col">
								{!startDate && !endDate && "n/a"}
								{startDate && <span>{formatDateWithGermanMonth(new Date(startDate), false)} - </span>}
								{endDate && <span>{formatDateWithGermanMonth(new Date(endDate), false)}</span>}
							</div>,
							flexBasePercentage: 25,
						},
						{
							label: "Ansprechpartner",
							element: projectLead,
							flexBasePercentage: 30,
						},

					]}
				/>
			</HeroSectionHighlights>

			<div className="flex flex-col gap-y-8">
				<ContentWrapper className="w-full">
					{!!selectedPhaseData && (
						<ProjectPhases
							projectId={projectData.id}
							phases={phases}
							allUsers={allUsers}
							showBudget={showBudget}
							selectedPhaseData={selectedPhaseData}
							setSelectedPhaseId={setSelectedPhaseId}
							workingStatuses={workingStatuses}
							allLocations={locations}
							countries={countries}
						/>
					)}
				</ContentWrapper>

				<ContentWrapper className="w-full">
					{selectedPhaseId && (
						<Orders
							allUsers={allUsers}
							clientId={clientId}
							orders={orders}
							projectPhaseId={selectedPhaseId}
							projectRoles={projectRoles}
							staffings={staffings}
							showBudget={showBudget}
						/>
					)}
				</ContentWrapper>

				<ContentWrapper className="w-full">
					<SectionHeading>
						<Headline type="h2">Kommentare</Headline>
					</SectionHeading>
					{0 === allComments.length ? (
						<div className="text-xs text-gray-500 mt-4">Keine Kommentare vorhanden</div>
					) : (
						<Comment.Group>
							{allComments.map(({ type, text }, cIndex) => (
								<Comment key={cIndex}>
									<Comment.Type>{type}</Comment.Type>
									{text}
								</Comment>
							))}
						</Comment.Group>
					)}
				</ContentWrapper>
			</div>
			<UpdateProjectSidebar
				isOpen={showUpdateProjectSidebar}
				setOpen={setShowUpdateProjectSidebar}
				projectId={projectData.id}
			/>

			<DeleteProjectModal
				isOpen={showDeleteProjectSidebar}
				projectId={projectData.id}
				projectTitle={projectData.title}
				onCloseClick={() => setShowDeleteProjectSidebar(false)}
			/>

			<CreateProjectPhaseSidebar
				clientId={clientId}
				isOpen={showCreatePhaseSidebar}
				setOpen={setShowCreatePhaseSidebar}
				setSelectedPhaseId={setSelectedPhaseId}
				projectId={projectData.id}
			/>
			<CreateOrderSidebar
				isOpen={showCreateOrderSidebar}
				setOpen={() => setShowCreateOrderSidebar(false)}
				clientId={clientId}
				projectId={projectData.id}
				projectPhaseId={selectedPhaseId}
			/>
		</>
	);
};

export default ProjectDetailsView;
