import { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";

export function indexUsersWorkingSchedules({ userId }: {
	userId: string
}): AxiosApiResponsePromise<WorkingScheduleType[]> {
	return axiosApi.get(`users/${userId}/working-schedules`);
}

export function getWorkingSchedulesMinDate(): AxiosApiResponsePromise<WorkingSchedulesMinDateType> {
	return axiosApi.get("working-schedules/min-date");
}

export function createWorkingSchedule({ userId, data }: {
	userId: string,
	data: CreateOrUpdateWorkingScheduleData
}): AxiosApiResponsePromise<WorkingScheduleType> {
	return axiosApi.post(`users/${userId}/working-schedules`, data);
}

export function updateWorkingSchedule({ userId, workingScheduleId, data }: {
	userId: string,
	workingScheduleId: string,
	data: CreateOrUpdateWorkingScheduleData
}): AxiosApiResponsePromise<WorkingScheduleType> {
	return axiosApi.put(`users/${userId}/working-schedules/${workingScheduleId}`, data);
}

export function deleteWorkingSchedule({ userId, workingScheduleId }: {
	userId: string,
	workingScheduleId: string
}): AxiosApiResponsePromise<WorkingScheduleType> {
	return axiosApi.delete(`users/${userId}/working-schedules/${workingScheduleId}`);
}


