import { ChevronDownIcon, ChevronUpIcon, EyeSlashIcon, LockClosedIcon, LockOpenIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

import BreadcrumbsPortal from "components/Breadcrumbs";
import { eachDayOfInterval, getISOWeek } from "date-fns";
import { t } from "i18next";
import DeleteMonthlyClosingModal from "modules/timeTracking/components/DeleteMonthlyClosingModal";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import ContentWrapper from "~/components/ContentWrapper";
import Button from "~/components/form/Button";
import { ComboBoxPlain } from "~/components/form/ComboBox/ComboBox.tsx";
import PageHeading from "~/components/headings/PageHeading";
import MonthAndYearNavigation from "~/components/MonthAndYearNavigation";
import { MonthAndYearNavigationHandlerPropsType } from "~/components/MonthAndYearNavigation/MonthAndYearNavigation.tsx";
import { appRoutes } from "~/constants/appRoute.ts";
import { TIME_TRACKING_INDEX_PAGE_KEY } from "~/constants/pageStateStorageKeys.ts";
import usePageStateStorage from "~/hooks/usePageStateStorage";
import useScrollRestoration from "~/hooks/useScrollRestoration";
import useScrollToElement from "~/hooks/useScrollToElement";
import { useUsersAbsences } from "~/modules/absence/api/absence/absenceQueries.ts";
import { LocationType, UserAvailableLocationType } from "~/modules/location/api/location/locationTypes.ts";
import { UsersActiveStaffingType } from "~/modules/project/api/staffing/staffingTypes.ts";
import { UserAvailableDeliverableType } from "~/modules/timeTracking/api/deliverable/deliverableTypes.ts";
import { MonthlyClosingType } from "~/modules/timeTracking/api/monthlyClosing/monthlyClosingTypes.ts";
import { useUsersTimeTrackings } from "~/modules/timeTracking/api/timeTracking/timeTrackingQueries.ts";
import { TimeTrackingTypeType } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeTypes.ts";
import Week from "~/modules/timeTracking/components/components/Week";
import CreateMonthlyClosingModal from "~/modules/timeTracking/components/CreateMonthlyClosingSidebar";
import CreateTimeTrackingSidebar from "~/modules/timeTracking/components/CreateTimeTrackingSidebar";
import UpdateTimeTrackingSidebar from "~/modules/timeTracking/components/UpdateTimeTrackingSidebar";
import { getStartAndEndOfMonth } from "~/modules/timeTracking/utils/timeTrackingUtils.ts";

import { UserType } from "~/modules/user/api/user/userTypes.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { FormInputOption } from "~/types/form.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";

type TimeTrackingViewProps = {
	activeStaffings: UsersActiveStaffingType[];
	allAbsenceTypes: AbsenceTypeType[];
	availableDeliverables: UserAvailableDeliverableType[];
	availableLocations: UserAvailableLocationType[];
	currentMonth: string;
	currentYear: string;
	holidays: HolidayType[];
	locations: LocationType[];
	month: string;
	monthSelectOptions: FormInputOption[];
	timeTrackingTypes: TimeTrackingTypeType[];
	userCanManageTimeTracking: boolean;
	userId: UserType["id"];
	users: UserType[];
	userSelectOptions: FormInputOption[];
	usersMonthlyClosingsData: MonthlyClosingType[];
	year: string;
	yearSelectOptions: FormInputOption[];
};


type PageStateType = {
	hideWeekends: boolean;
	sortOrder: "asc" | "desc";
}

const defaultPageState: PageStateType = {
	hideWeekends: true,
	sortOrder: "asc",
};

const scrollToTodayOffset = -200;

//@ts-ignore
const TimeTrackingView: React.FunctionComponent<TimeTrackingViewProps> = ({
	activeStaffings,
	allAbsenceTypes,
	availableDeliverables,
	availableLocations,
	currentMonth,
	currentYear,
	holidays,
	locations,
	month,
	monthSelectOptions,
	timeTrackingTypes,
	userCanManageTimeTracking,
	userId,
	users,
	userSelectOptions,
	usersMonthlyClosingsData,
	year,
	yearSelectOptions,
}) => {
	const navigate = useNavigate();
	const location = useLocation();

	const { pageState, setPageState } = usePageStateStorage({
		pageKey: TIME_TRACKING_INDEX_PAGE_KEY,
		defaultState: defaultPageState,
	});

	const { setScrollToElementRef, scrollToRef, currentRef } = useScrollToElement();

	const { saveScrollPosition } = useScrollRestoration(TIME_TRACKING_INDEX_PAGE_KEY);
	useScrollRestoration(TIME_TRACKING_INDEX_PAGE_KEY);

	const [showCreateMonthlyClosingSidebar, setShowCreateMonthlyClosingSidebar] = useState(false);
	const [showDeleteMonthlyClosingSidebar, setShowDeleteMonthlyClosingSidebar] = useState(false);
	const [hideWeekends, setHideWeekends] = useState(pageState.hideWeekends);
	const [sortOrder, setSortOrder] = useState<PageStateType["sortOrder"]>(pageState.sortOrder);
	const [selectedTimeTrackingId, setSelectedTimeTrackingId] = useState<string | null>(null);
	const [timeTrackingIdToUpdate, setTimeTrackingIdToUpdate] = useState<string | null>(null);

	const [selectedDate, setSelectedDate] = useState<Date | null>(null);

	const getTimeTrackingPath = useCallback(({ month, year }: { month: string, year: string }) => {
		return generatePath(appRoutes.timeTracking().path, { userId }) + `?year=${year}&month=${month}`;
	}, [userId]);


	const timeTrackingFilterData = useMemo(() => {
		if (month && year) {
			return getStartAndEndOfMonth(new Date(parseInt(year), parseInt(month)));
		}
		return { startDate: "", endDate: "" };
	}, [month, year]);

	const { isLoading: timeTrackingsAreLoading, data: usersTimeTrackingData } = useUsersTimeTrackings({
		userId: userId,
		filter: timeTrackingFilterData!,
		options: { enabled: !!timeTrackingFilterData },
	});

	const { isLoading: absencesAreLoading, data: userAbsencesData } = useUsersAbsences({ userId: userId });


	const monthlyClosingId = useMemo(() => {
		if (year && month && usersMonthlyClosingsData) {
			const startOfSelectedMonth = formatDateToYYYYMMDD(new Date(parseInt(year), parseInt(month), 1));
			const monthlyClosing = usersMonthlyClosingsData.find(closing => closing.month === startOfSelectedMonth);
			return monthlyClosing?.id || null;
		}

		return null;
	}, [year, month, usersMonthlyClosingsData]);

	useEffect(() => {
		setPageState({ hideWeekends, sortOrder });
	}, [hideWeekends, sortOrder]);


	useEffect(() => {
		setPageState({ hideWeekends, sortOrder });
	}, [hideWeekends, sortOrder]);


	const handleUserSelectChange = (userId: string | null) => {
		if (userId) {
			navigate(generatePath(appRoutes.timeTracking().path, { userId }));
		}
	};

	const handleMonthOrYearSelectChange = ({ month, year }: MonthAndYearNavigationHandlerPropsType) => {
		saveScrollPosition(0);
		navigate(getTimeTrackingPath({ year, month }));
	};

	const handleGoToPrevOrNextMonthClick = ({ month, year }: MonthAndYearNavigationHandlerPropsType) => {
		navigate(getTimeTrackingPath({ year, month }));
	};

	const handleGoToTodayClick = useCallback(() => {
		navigate(getTimeTrackingPath({
			year: currentYear,
			month: (parseInt(currentMonth) + 1).toString(),
		}), { state: { scrollToToday: true } });
	}, [currentMonth, currentYear, scrollToRef]);


	let daysInMonth =
		month &&
		eachDayOfInterval({
			start: new Date(parseInt(year, 10), parseInt(month), 1),
			end: new Date(parseInt(year, 10), parseInt(month) + 1, 0),
		});

	if (daysInMonth && sortOrder === "desc") {
		daysInMonth = daysInMonth?.sort((a, b) => b.getTime() - a.getTime());
	}

	const weeksInMonth: { week: number; days: Date[] }[] = [];

	if (daysInMonth) {
		daysInMonth?.forEach((date) => {
			const week = getISOWeek(date);
			let weekData = weeksInMonth.find((weekData) => weekData.week === week);
			if (!weekData) {
				weekData = { week, days: [] };
				weeksInMonth.push(weekData);
			}
			weekData.days.push(date);
		});
	}

	if (sortOrder === "desc") {
		weeksInMonth.sort((a, b) => b.week - a.week);
	} else {
		weeksInMonth.sort((a, b) => a.week - b.week);
	}

	useLayoutEffect(() => {
		if (currentRef && location.state?.scrollToToday) {
			// hacky... but works
			setTimeout(() => {
				scrollToRef(scrollToTodayOffset);
			}, 100);

		}
	}, [currentRef, location]);

	const selectedTimeTrackingDataForUpdate = useMemo(() => {
		if (timeTrackingIdToUpdate && usersTimeTrackingData) {
			return usersTimeTrackingData.find(timeTracking => timeTracking.id === timeTrackingIdToUpdate) || null;
		}

		return null;
	}, [timeTrackingIdToUpdate, usersTimeTrackingData]);

	return (
		<>
			<BreadcrumbsPortal pages={[appRoutes.timeTracking()]}
							   className="bg-white" />
			<PageHeading title={t("timetracking.title", "Zeiterfassung")} />
			<PageHeading.BottomBar>
				<div
					className="w-full flex justify-between items-center gap-2 text-sm font-medium text-gray-700 hover:text-gray-900">
					<div className="flex flex-row items-center">
						<MonthAndYearNavigation onGoToNextMonthClick={handleGoToPrevOrNextMonthClick}
												onGoToPrevMonthClick={handleGoToPrevOrNextMonthClick}
												onGoToTodayClick={handleGoToTodayClick}
												onMonthSelectChange={handleMonthOrYearSelectChange}
												onYearSelectChange={handleMonthOrYearSelectChange}
												month={month}
												year={year}
												monthSelectOptions={monthSelectOptions}
												yearSelectOptions={yearSelectOptions} />

						{userCanManageTimeTracking && (
							<div className="w-[14rem]">
								<ComboBoxPlain
									allowNew={false}
									value={userId}
									optionsData={userSelectOptions}
									onChange={handleUserSelectChange}
								/>
							</div>
						)}
					</div>

					<div className="ml-auto">
						<Button theme="none"
								size="sm"
								onClick={() => setHideWeekends(!hideWeekends)}>
							<EyeSlashIcon className="w-4 h-4" />
							Wochenenden {hideWeekends ? "anzeigen" : "verbergen"}
						</Button>
						<Button
							theme="white"
							size="sm"
							onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
						>
							{sortOrder === "asc" ? (
								<>
									<ChevronDownIcon className="w-4 h-4" />
									{`Sortieren ${daysInMonth.length}>1`}
								</>
							) : (
								<>
									<ChevronUpIcon className="w-4 h-4" />
									{`Sortieren 1<${daysInMonth.length}`}
								</>
							)}
						</Button>

					</div>
					<Button theme="plain"
							size="auto"
							rounded="none"
							className={clsx("w-6 h-6 cursor-pointer", monthlyClosingId ? "text-danger-500" : "text-gray-400")}>
						{monthlyClosingId ?
							<LockClosedIcon className="h-full w-full"
											onClick={() => setShowDeleteMonthlyClosingSidebar(true)} /> :
							<LockOpenIcon className="h-full w-full"
										  onClick={() => setShowCreateMonthlyClosingSidebar(true)} />}
					</Button>
				</div>
			</PageHeading.BottomBar>

			<ContentWrapper className="pt-8">
				{timeTrackingsAreLoading || absencesAreLoading ? (
					<div className="w-full h-20 flex justify-center">
						<LoadingPage />
					</div>
				) : (
					<div className="flex flex-col gap-y-4">
						{weeksInMonth.map(({ week, days }) => (
							<Week
								allAbsenceTypes={allAbsenceTypes}
								days={days}
								holidays={holidays}
								key={week}
								monthIsClosed={!!monthlyClosingId}
								setSelectedDate={setSelectedDate}
								setSelectedTimeTrackingId={setSelectedTimeTrackingId}
								setTimeTrackingIdToUpdate={setTimeTrackingIdToUpdate}
								selectedDate={selectedDate}
								selectedTimeTrackingId={selectedTimeTrackingId}
								setScrollToElementRef={setScrollToElementRef}
								timeTrackingData={usersTimeTrackingData}
								userAbsencesData={userAbsencesData}
								users={users}
								visible={!hideWeekends}
								week={week}
							/>
						))}
					</div>
				)}
			</ContentWrapper>
			<CreateMonthlyClosingModal
				month={month}
				isOpen={showCreateMonthlyClosingSidebar}
				onClose={() => setShowCreateMonthlyClosingSidebar(false)}
				userId={userId}
				year={year} />

			<DeleteMonthlyClosingModal
				isOpen={showDeleteMonthlyClosingSidebar && !!monthlyClosingId}
				userCanManageTimeTrackings={userCanManageTimeTracking}
				monthlyClosingId={monthlyClosingId}
				onClose={() => setShowDeleteMonthlyClosingSidebar(false)}
				userId={userId}
			/>
			<CreateTimeTrackingSidebar activeStaffings={activeStaffings}
									   availableDeliverables={availableDeliverables}
									   availableLocations={availableLocations}
									   close={() => setSelectedDate(null)}
									   isOpen={selectedDate !== null}
									   date={selectedDate}
									   locations={locations}
									   selectedUserId={userId}
									   timeTrackingTypes={timeTrackingTypes}
									   users={users} />

			<UpdateTimeTrackingSidebar activeStaffings={activeStaffings}
									   availableDeliverables={availableDeliverables}
									   availableLocations={availableLocations}
									   close={() => setTimeTrackingIdToUpdate(null)}
									   isOpen={!!selectedTimeTrackingDataForUpdate}
									   date={selectedTimeTrackingDataForUpdate?.date ? new Date(selectedTimeTrackingDataForUpdate.date) : null}
									   locations={locations}
									   timeTrackingData={selectedTimeTrackingDataForUpdate}
									   selectedUserId={userId}
									   timeTrackingTypes={timeTrackingTypes}
									   users={users} />
		</>
	);
};

export default TimeTrackingView;
