import { getAllClients, getClient } from "~/modules/client/api/client/clientApiDispatchers.ts";
import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { QueriesOptions, useQuery } from "react-query";

const ENTITY_QUERY_KEY = "client";

export const CLIENT_QUERY_KEY = (clientId: string) => [ENTITY_QUERY_KEY, clientId];
export const ALL_CLIENTS_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useClient = (clientId: string, options?: QueriesOptions<any>) => useQuery(
	CLIENT_QUERY_KEY(clientId),
	() => getClient(clientId).then(getDataFromResponse),
	options,
);

export const useAllClients = (options?: QueriesOptions<any>) => useQuery(
	ALL_CLIENTS_QUERY_KEY,
	() => getAllClients().then(getDataFromResponse),
	options,
);