import BreadcrumbsPortal from "components/Breadcrumbs";
import { isAfter } from "date-fns";
import Decimal from "decimal.js-light";
import { t } from "i18next";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteVacationLedgerEntryModal from "~/modules/absence/components/DeleteVacationLedgerEntryModal";
import { UserType } from "~/modules/user/api/user/userTypes.ts";
import ContentWrapper from "~/components/ContentWrapper";
import Button from "~/components/form/Button";
import PageHeading from "~/components/headings/PageHeading/PageHeading.tsx";
import SectionHeading from "~/components/headings/SectionHeading/SectionHeading.tsx";
import { appRoutes } from "~/constants/appRoute.ts";
import { useAuth } from "~/contexts/AuthContext";
import AbsencePageTabs from "~/modules/absence/components/AbsencePageTabs";
import AbsenceViewUserSelect from "~/modules/absence/components/AbsencePageUserSelect";
import CreateVacationLedgerEntrySidebar from "~/modules/absence/components/CreateVacationLedgerEntrySidebar";
import RemainingVacationDays from "~/modules/absence/components/RemainingVacationDays";
import UpdateVacationLedgerEntrySidebar from "~/modules/absence/components/UpdateVacationLedgerEntrySidebar";
import VacationLedgerTable from "~/modules/absence/components/VacationLedgerTable";
import { AbsenceSubRoutes } from "~/modules/user/router/UserRouter/UserRouter.tsx";
import { AbsenceTypeNames, PermissionNames } from "~/types/entityNames.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type VacationLedgerViewProps = {
	userId: string,
	users: UserType[];
	vacationLedgerReport: VacationLedgerReportType;
	vacationLedgerEntries: VacationLedgerEntryType[];
};

export type VacationLedgerEntrySummaryType = {
	id: string;
	comment: string | null;
	type: string;
	userId: string;
	validFrom: string;
	vacationDays: Decimal
}

const getVacationDayDecimal = (entry: VacationLedgerEntryType): Decimal => new Decimal(parseFloat(entry.workDays));

const entryIsVacation = (entry: VacationLedgerEntryType): boolean => !!entry.absenceId;

function getTypeNameFromEntryType(entry: VacationLedgerEntryType): string {
	if (entryIsVacation(entry)) {
		return t(normalizeTKey(`entities:absenceType.${AbsenceTypeNames.Vacation}`));
	} else if (getVacationDayDecimal(entry).isNegative()) {
		return "Abzug";
	}
	return "Gutschrift";

}


const VacationLedgerView: React.FunctionComponent<VacationLedgerViewProps> = ({
	userId,
	users,
	vacationLedgerReport,
	vacationLedgerEntries,
}) => {
	const { hasAnyPermission } = useAuth();
	const { t } = useTranslation();
	const [showCreateVacationLedgerForm, setShowCreateVacationLedgerForm] = useState(false);
	const [entryIdToUpdate, setEntryIdToUpdate] = useState<string | null>(null);
	const [entryIdToDelete, setEntryIdToDelete] = useState<string | null>(null);

	const userCanAdminVacations = hasAnyPermission(PermissionNames.CanManageAbsences);
	const remainingVacationDays = new Decimal(parseFloat(vacationLedgerReport.daysRemaining));
	const vacationLedgerEntriesRows = useMemo(() => {
		if (vacationLedgerEntries.length > 0) {
			return vacationLedgerEntries.sort(byObjectProperty("validFrom", "desc")).map((ledgerEntry) => {
				const isVacation = entryIsVacation(ledgerEntry);
				const vacationDays = getVacationDayDecimal(ledgerEntry);
				const type = getTypeNameFromEntryType(ledgerEntry);


				const validFrom = formatDateWithGermanMonth(new Date(ledgerEntry.validFrom)) || "--";

				const isDeletable = !isVacation && userCanAdminVacations && isAfter(new Date(ledgerEntry.validFrom), new Date());

				const isEditable = isDeletable && userCanAdminVacations;

				const createdBy = users.find(user => user.id === ledgerEntry.createdBy)?.fullName || "--";

				return <VacationLedgerTable.Row key={"absence-" + ledgerEntry.id}
												type={type}
												createdBy={createdBy}
												comment={ledgerEntry.comment}
												validFrom={validFrom}
												vacationDays={vacationDays}
												isDeletable={isDeletable}
												isEditable={isEditable}
												onEditClick={() => setEntryIdToUpdate(ledgerEntry.id)}
												onDeleteClick={() => setEntryIdToDelete(ledgerEntry.id)}
				/>;
			});
		}
		return [];
	}, [vacationLedgerEntries, userCanAdminVacations, setEntryIdToUpdate, users]);

	const selectedEntryLedgerToUpdateData = useMemo(() => {
		if (entryIdToUpdate) {
			return vacationLedgerEntries.find(ledgerEntry => ledgerEntry.id === entryIdToUpdate);
		}
		return null;
	}, [vacationLedgerEntries, entryIdToUpdate]);

	const selectedEntryLedgerToDeleteData: VacationLedgerEntrySummaryType | null = useMemo(() => {
		if (entryIdToDelete) {
			const entry = vacationLedgerEntries.find(ledgerEntry => ledgerEntry.id === entryIdToDelete);

			if (entry) {
				return {
					id: entry.id,
					comment: entry.comment,
					type: getTypeNameFromEntryType(entry),
					userId: entry.userId,
					validFrom: formatDateWithGermanMonth(new Date(entry.validFrom)) || "--",
					vacationDays: getVacationDayDecimal(entry),
				};
			}
		}
		return null;
	}, [vacationLedgerEntries, entryIdToDelete]);

	return (
		<>
			<BreadcrumbsPortal pages={[appRoutes.absences()]}
							   className="bg-white" />
			<PageHeading title={t("timetracking.title", "Urlaubskonto")} />
			<PageHeading.BottomBar>
				<div
					className="w-full flex items-center gap-2 text-sm font-medium text-gray-700 hover:text-gray-900 gap-x-5 z-40">
					<RemainingVacationDays remainingVacationDays={remainingVacationDays} />
					{userCanAdminVacations &&
						<AbsenceViewUserSelect
							userIsVacationManager={userCanAdminVacations}
							userId={userId}
							currentSubPath={AbsenceSubRoutes.vacationLedger}
						/>}
				</div>
				{userCanAdminVacations && <Button onClick={() => setShowCreateVacationLedgerForm(true)}>
					{t("projects.btnNewProject", "Neuer Eintrag")}
				</Button>}
			</PageHeading.BottomBar>
			<ContentWrapper className="isolate">
				<SectionHeading sticky
								style={{ top: 73 }}>
					<AbsencePageTabs selectedTabName={AbsenceSubRoutes.vacationLedger} />
				</SectionHeading>
				<div>
					<VacationLedgerTable>
						{vacationLedgerEntriesRows}
					</VacationLedgerTable>
				</div>
			</ContentWrapper>
			<CreateVacationLedgerEntrySidebar
				isOpen={showCreateVacationLedgerForm}
				onClose={() => setShowCreateVacationLedgerForm(false)}
				userId={userId} />

			<UpdateVacationLedgerEntrySidebar
				isOpen={!!selectedEntryLedgerToUpdateData}
				onClose={() => setEntryIdToUpdate(null)}
				vacationLedgerEntryData={selectedEntryLedgerToUpdateData} />

			<DeleteVacationLedgerEntryModal
				isOpen={!!selectedEntryLedgerToDeleteData}
				vacationLedgerEntryData={selectedEntryLedgerToDeleteData}
				onCloseClick={() => setEntryIdToDelete(null)} />
		</>
	);
};

export default VacationLedgerView;