import clsx from "clsx";
import { MouseEvent, useCallback, useEffect, useMemo } from "react";

import { Control, FieldErrors, UseFormSetValue, UseFormTrigger, UseFormWatch } from "react-hook-form";
import ButtonNewItem from "~/components/buttons/ButtonNewItem";

import Button from "~/components/form/Button";
import ComboBox from "~/components/form/ComboBox";
import FormInputError from "~/components/form/FormInputError";
import RestrictedNumberInput from "~/components/form/RestrictedNumberInput";
import Textarea from "~/components/form/Textarea";
import GlobeIcon from "~/components/icons/GlobeIcon";
import SpeechBubbleIcon from "~/components/icons/SpeechBubbleIcon";
import StopwatchIcon from "~/components/icons/StopwatchIcon";
import { useAuth } from "~/contexts/AuthContext";
import useLocationOptions from "~/hooks/form/formOptionsData/useLocationOptions.ts";
import { LocationType, UserAvailableLocationType } from "~/modules/location/api/location/locationTypes.ts";
import { SelectedStaffingDataType } from "~/modules/project/api/staffing/staffingTypes.ts";
import { UserAvailableDeliverableType } from "~/modules/timeTracking/api/deliverable/deliverableTypes.ts";
import { TimeTrackingFormData } from "~/modules/timeTracking/api/timeTracking/timeTrackingTypes.ts";
import Deliverables from "~/modules/timeTracking/components/components/Deliverables";
import Locations from "~/modules/timeTracking/components/components/Locations";
import HeaderWithIcon from "~/modules/timeTracking/components/forms/CreateTimeTrackingForm/components/HeaderWithIcon";
import { TimeTrackingFormNamesEnum } from "~/modules/timeTracking/types/timeTrackingTypes.ts";
import { minutesToWorkdays } from "~/modules/timeTracking/utils/timeTrackingUtils.ts";
import { UserType } from "~/modules/user/api/user/userTypes.ts";
import { TimeTrackingTypeId } from "~/types/entityIds.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

type TimeTrackingFormSectionProps = {
	availableDeliverables: UserAvailableDeliverableType[];
	availableLocations: UserAvailableLocationType[];
	control: Control<TimeTrackingFormData>;
	errors: FieldErrors<TimeTrackingFormData>;
	isEditForm: boolean;
	isSubmitted: boolean;
	locations: LocationType[];
	selectedStaffingData: SelectedStaffingDataType | null;
	selectedTimeTrackingTypeId: string | null;
	setCurrentFormName: (formId: TimeTrackingFormNamesEnum) => void;
	setValue: UseFormSetValue<TimeTrackingFormData>;
	selectedUserId: string;
	trigger: UseFormTrigger<TimeTrackingFormData>;
	users: UserType[];
	watch: UseFormWatch<TimeTrackingFormData>;
};

const TimeTrackingFormSection: React.FunctionComponent<TimeTrackingFormSectionProps> = ({
	availableDeliverables,
	availableLocations,
	control,
	errors,
	isEditForm,
	isSubmitted,
	locations,
	selectedStaffingData,
	selectedTimeTrackingTypeId,
	setCurrentFormName,
	setValue,
	selectedUserId,
	trigger,
	users,
	watch,
}) => {
	const locationOptions = useLocationOptions();
	const timeTrackingTextValue = watch("text");
	const { user: authenticatedUser } = useAuth();

	const sortAlphabetically = (a: any, b: any) => a.displayName.localeCompare(b.displayName);

	const selectedStaffingProjectId = selectedStaffingData?.projectId;

	// this is necessary to correctly display the error message when both minutes and hours are 0
	// @toDo Find a better solution
	const minutes = watch("minutes");
	const hours = watch("hours");

	useEffect(() => {
		if (isEditForm || isSubmitted) {
			trigger("hours");
		}
	}, [minutes, hours, trigger, isSubmitted, isEditForm]);

	const remainingManDays = useMemo(() => {
		if (selectedStaffingData) {
			const manDaysSelected = minutesToWorkdays(minutes + hours * 60, 1);
			return (
				selectedStaffingData.manDaysPlanned -
				minutesToWorkdays(selectedStaffingData?.minutesTracked, 1) -
				manDaysSelected
			);
		}
		return 0;
	}, [selectedStaffingData, minutes, hours]);


	const locationsData = useMemo(() => {
		const selectedLocationId = users.find((u) => u.id === selectedUserId)?.locationId;

		const preFilteredLocations = availableLocations.filter(
			(l) => selectedTimeTrackingTypeId === l.timeTrackingTypeId && l.id !== selectedLocationId,
		);
		return [
			[locations.find((l) => l.id === selectedLocationId)?.displayName],
			preFilteredLocations
				.filter((l) => l.userId === selectedUserId && l.isFavorite)
				.sort(sortAlphabetically)
				.map((l) => l.displayName),
			preFilteredLocations
				.filter((l) => l.userId === selectedUserId && !l.isFavorite)
				.sort(sortAlphabetically)
				.map((l) => l.displayName),
			preFilteredLocations
				.filter((l) => l.userId !== selectedUserId && l.isFavorite)
				.sort(sortAlphabetically)
				.map((l) => l.displayName),
			preFilteredLocations
				.filter((l) => l.userId !== selectedUserId && !l.isFavorite)
				.sort(sortAlphabetically)
				.map((l) => l.displayName),
		];
	}, [locations, availableLocations, selectedTimeTrackingTypeId]);

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - deliverables
	const deliverablesData = useMemo(() => {
		const preFilteredAvailableDeliverables = availableDeliverables.filter((d) => {
			return (
				(!selectedStaffingProjectId || selectedStaffingProjectId === d.projectId) &&
				selectedTimeTrackingTypeId === d.timeTrackingTypeId
			);
		});
		console.log(preFilteredAvailableDeliverables);
		return [
			preFilteredAvailableDeliverables
				.filter((d) => d.userId === selectedUserId && d.isFavorite)
				.sort(sortAlphabetically),
			preFilteredAvailableDeliverables
				.filter((d) => d.userId === selectedUserId && !d.isFavorite)
				.sort(sortAlphabetically),
			preFilteredAvailableDeliverables
				.filter((d) => d.userId !== selectedUserId && d.isFavorite)
				.sort(sortAlphabetically),
			preFilteredAvailableDeliverables
				.filter((d) => d.userId !== selectedUserId && !d.isFavorite)
				.sort(sortAlphabetically),
		];
	}, [availableDeliverables, selectedTimeTrackingTypeId, selectedStaffingProjectId]);

	const handleHourButtonClick = useCallback(
		(minutes: number) => {
			setValue("hours", minutes / 60, { shouldDirty: true });
			trigger("hours");
		},
		[setValue],
	);

	const handeLocationClick = useCallback(
		(value: string, event:MouseEvent) => {
			event.preventDefault();
			setValue("locationName", value, { shouldDirty: true });
			trigger("locationName");
		},
		[trigger],
	);

	const handleDeliverableClick = useCallback(
		(value: string, event:MouseEvent) => {
			event.preventDefault();
			const index = timeTrackingTextValue?.indexOf(value) ?? -1;
			const isTrimmedValueEqual = timeTrackingTextValue?.trim() === value;

			if (index >= 0 || isTrimmedValueEqual) {
				let newValue = timeTrackingTextValue?.replace(new RegExp(`\\s*${value}\\s*`, 'g'), "") ?? "";
				newValue = newValue.replace(",,", ",").replace(/^,|,$| $/g, "");

				setValue("text", newValue.trim(), { shouldDirty: true });
			} else {
				const newValue = timeTrackingTextValue?.trim().length === 0 ? value : `${timeTrackingTextValue}, ${value}`;
				setValue("text", newValue, { shouldDirty: true });
			}

			trigger("text");
		},
		[trigger, timeTrackingTextValue],
	);

	const text = watch("text");
	return (
		<><HeaderWithIcon icon={<StopwatchIcon className="w-7 fill-primary-700" />}>Zeiterfassung</HeaderWithIcon>
			<div className="flex flex-row gap-2 justify-center items-center ml-5 mt-6 gap-x-16">
				<div className="flex flex-row gap-2 justify-center items-center">
					<div className="w-[3.6rem]">
						<RestrictedNumberInput
							autoFocus={true}
							minValue={0}
							maxValue={23}
							name={"hours"}
							control={control}
							placeholder="08"
							className="text-center"
						/>
					</div>
					<span className="font-bold text-primary-500">h</span>
					<div className="w-[3.6rem]">
						<RestrictedNumberInput
							minValue={0}
							maxValue={59}
							name={"00"}
							control={control}
							placeholder="00"
							className="text-center"
							errorIconOnly={true}
						/>
					</div>
					<span className="font-bold text-primary-500">m</span>
				</div>
				<div className="border-r border-gray-200 h-6" />
				<div className="mr-auto flex flex-row">
					<Button theme="white"
							rounded="none"
							isGrouped={true}
							onClick={() => handleHourButtonClick(480)}>
						Tag
					</Button>
					<Button theme="white"
							rounded="none"
							isGrouped={true}
							onClick={() => handleHourButtonClick(240)}>
						Halber Tag
					</Button>
					<Button theme="white"
							rounded="none"
							isGrouped={true}
							onClick={() => handleHourButtonClick(360)}>
						6h
					</Button>
					<Button theme="white"
							rounded="none"
							isGrouped={true}
							onClick={() => handleHourButtonClick(120)}>
						2h
					</Button>
				</div>

			</div>
			<div className="block ml-4">
				{errors.hours && <FormInputError>{errors.hours.message}</FormInputError>}
			</div>
			{selectedTimeTrackingTypeId === TimeTrackingTypeId.Project &&
				<div className="flex justify-start items-center ml-5 mt-4 gap-x-2 font-bold text-gray-500 text-xs">
					<span>Verbleibende Tage: </span><span className={clsx(remainingManDays >= 0 ? "text-gray-500" : "text-danger-500")}>{formatNumberWithComma(remainingManDays || 0, 2)} Personentage</span>
				</div>}

			<div className="h-8" />
			<HeaderWithIcon icon={<GlobeIcon className="w-7  fill-primary-700" />}>Ort</HeaderWithIcon>
			<ComboBox
				control={control}
				name="locationName"
				optionsData={locationOptions}
				error={errors.locationName?.message}
			/>
			<div className="flex flex-row gap-2 flex-wrap justify-start items-center mt-2">
				<Locations data={locationsData}
						   onClick={handeLocationClick} />
				{selectedUserId === authenticatedUser?.id && <div>
					<ButtonNewItem onClick={() => setCurrentFormName(TimeTrackingFormNamesEnum.LOCATION_FORM)}
								   size="sm" />
				</div>}
			</div>
			<div className="h-8" />
			<HeaderWithIcon icon={
				<SpeechBubbleIcon className="w-7 pt-[0.3rem] fill-primary-700" />}>Buchungstext</HeaderWithIcon>
			<Textarea
				control={control}
				name="text"
			/>
			<div className="flex flex-row flex-wrap gap-2 justify-start items-center mt-2">
				{deliverablesData.some((a) => 0 < a.length) && (
					<>
						<Deliverables
							currentDeliverablesString={text}
							data={deliverablesData}
							onClick={handleDeliverableClick} />
					</>
				)}
				{selectedUserId === authenticatedUser?.id && <div>
					<ButtonNewItem onClick={() => setCurrentFormName(TimeTrackingFormNamesEnum.DELIVERABLE_FORM)}
								   size="sm" />
				</div>}
			</div>

		</>
	);
};

export default TimeTrackingFormSection;
