import Button from "~/components/form/Button";
import CreateUserForm from "~/modules/user/components/forms/CreateUserForm";
import Sidebar from "~/components/Sidebar";
import { UserWithConfidentialInformation } from "~/modules/user/api/user/userTypes.ts";
import { useState } from "react";

type CreateUserSidebarProps = {
	isOpen: boolean;
	closeSidebar: () => void;
};

const CreateUserSidebar: React.FunctionComponent<CreateUserSidebarProps> = ({ isOpen, closeSidebar }) => {
	const [newUserData, setNewUser] = useState<UserWithConfidentialInformation | null>(null);

	return (
		<Sidebar closeOnOutsideClick={false} open={isOpen} setOpen={closeSidebar}>
			{!newUserData && <CreateUserForm onSuccess={setNewUser} onCancel={closeSidebar} isAdminMode={true}/>}
			{newUserData && (
				<div className="px-8 py-6">
					<div className="text-xl text-center font-bold mb-4 text-gray-800">
						Neuer User "{newUserData.firstName} {newUserData.lastName}" erstellt
					</div>
					<div className="mb-4 text-center text-gray-800">Was möchtest Du als nächstes tun?</div>
					<div className="flex flex-col space-y-4">
						<Button className="justify-center" onClick={() => setNewUser(null)}>
							Weiteren User erstellen
						</Button>
						<Button className="justify-center" onClick={closeSidebar}>
							Fenster schliessen
						</Button>
					</div>
				</div>
			)}
		</Sidebar>
	);
};

export default CreateUserSidebar;
