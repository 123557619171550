import { getAllEmploymentStatuses } from "~/modules/user/api/employmentStatus/employmentStatusApiDispatchers.ts";
import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { QueriesOptions, useQuery } from "react-query";

const ENTITY_QUERY_KEY = "employmentStatus";

export const All_EMPLOYMENT_STATUSES_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useAllEmploymentStatuses = (options?: QueriesOptions<any>) => useQuery(
	All_EMPLOYMENT_STATUSES_QUERY_KEY,
	() => getAllEmploymentStatuses().then(getDataFromResponse),
	options,
);