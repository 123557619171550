import { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";

export function indexUsersAbsences({ userId }: { userId: string }): AxiosApiResponsePromise<AbsenceType[]> {
	return axiosApi.get(`users/${userId}/absences`);
}

export function createAbsence({ userId, data }: {
	userId: string,
	data: CreateAbsenceDataInterface
}): AxiosApiResponsePromise<AbsenceType> {
	return axiosApi.post(`users/${userId}/absences`, data);
}

export function updateAbsence({ userId, absenceId, data }: {
	userId: string,
	absenceId: string,
	data: UpdateAbsenceDataInterface
}): AxiosApiResponsePromise<AbsenceType> {
	return axiosApi.put(`users/${userId}/absences/${absenceId}`, data);
}

export function deleteAbsence({ userId, absenceId }: {
	userId: string,
	absenceId: string
}): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`users/${userId}/absences/${absenceId}`);
}