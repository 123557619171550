import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import DeleteEntityView, { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import { appRoutes } from "~/constants/appRoute.ts";

import {
	deleteClientContactPerson,
} from "~/modules/client/api/clientContactPerson/clientContactPersonApiDispatchers.ts";
import {
	ALL_CLIENT_CONTACT_PERSONS_QUERY_KEY, CLIENT_CONTACT_PERSONS_BY_CLIENT_QUERY_KEY,
} from "~/modules/client/api/clientContactPerson/clientContactPersonQueries.ts";


type DeleteClientContactPersonModalProps = {
	clientId: string;
	clientContactPersonId: string;
	isOpen: boolean;
	onClose: () => void;
};

const DeleteClientContactPersonModal: React.FunctionComponent<DeleteClientContactPersonModalProps> = ({
	clientId,
	clientContactPersonId,
	onClose,
	isOpen,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	useEffect(() => {
		if (isOpen) {
			setError("");
		}
	}, [isOpen]);

	const handleConfirmationClick = async () => {
		try {
			setBusy(true);
			await deleteClientContactPerson({
				clientId,
				contactPersonId: clientContactPersonId,
			});
			await queryClient.refetchQueries(ALL_CLIENT_CONTACT_PERSONS_QUERY_KEY);
			await queryClient.refetchQueries(CLIENT_CONTACT_PERSONS_BY_CLIENT_QUERY_KEY(clientId));
			navigate(appRoutes.contacts().path, { replace: true });
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.clientContactPersonId.find(
						(validationError) => validationError.rule === "clientContactPersonIsDeletableRule",
					)
				) {
					setError("Dieser Kontakt kann nicht gelöscht werden, da er noch in Verwendung ist.");
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Kontakt löschen`,
		bodyText: `Möchtest Du diesen Kontakt wirklich löschen?`,
		deleteButtonText: `Kontakt löschen`,
	};

	return (
		<DeleteEntityView
			error={error}
			texts={texts}
			isOpen={isOpen}
			onCloseClick={onClose}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		/>
	);
};

export default DeleteClientContactPersonModal;
