import { Control, FieldValues } from "react-hook-form";

import Input from "~/components/form/Input";
import RadioGroup from "~/components/form/RadioGroup";
import { PAYMENT_TARGET_DAYS_OPTIONS } from "~/constants/form.ts";

type PaymentTargetDaysInputProps<T extends FieldValues> = {
	className?: string;
	control: Control<T>;
	showCustomPaymentTargetInput: boolean;
};

export type PaymentTargetDaysInputFormData = {
	paymentTargetDays: number;
	paymentTargetDaysCustom?: number | null;
};

const PaymentTargetDaysInput = <T extends PaymentTargetDaysInputFormData>({
	className,
	control,
	showCustomPaymentTargetInput,
}: PaymentTargetDaysInputProps<T>) => {
	return (
		<div className={className}>
			<RadioGroup
				control={control}
				label="Zahlungsziel"
				name="paymentTargetDays"
				options={PAYMENT_TARGET_DAYS_OPTIONS}
				numCols={3}
			/>
			{showCustomPaymentTargetInput && (
				<div className="mt-4">
					<Input
						placeholder="90 Tage"
						name="paymentTargetDaysCustom"
						type="number"
						label="Individuelles Zahlungsziel"
						control={control}
					/>
				</div>
			)}
		</div>
	);
};

export default PaymentTargetDaysInput;
