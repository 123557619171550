import { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import {
	CreateProjectPhaseData,
	ProjectPhaseType,
	UpdateProjectPhaseData, UpdateProjectPhaseStatusData,
} from "~/modules/project/api/projectPhase/projectPhaseTypes.ts";

export function indexProjectPhasesByProjectId(projectId: string): AxiosApiResponsePromise<ProjectPhaseType[]> {
	return axiosApi.get(`/projects/${projectId}/project-phases`);
}

export function createProjectPhase(projectId: string,
	data: CreateProjectPhaseData): AxiosApiResponsePromise<ProjectPhaseType> {
	return axiosApi.post(`/projects/${projectId}/project-phases`, { ...data });
}

export function updateProjectPhase({ projectId, projectPhaseId, phaseData }: {
	projectId: string,
	projectPhaseId: string,
	phaseData: UpdateProjectPhaseData
}): AxiosApiResponsePromise<ProjectPhaseType> {
	return axiosApi.put(`/projects/${projectId}/project-phases/${projectPhaseId}`, { ...phaseData });
}

export function deleteProjectPhase({ projectPhaseId, projectId }: {
	projectId: string,
	projectPhaseId: string
}): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`/projects/${projectId}/project-phases/${projectPhaseId}`);
}

export function updateProjectPhaseStatus({ projectId, projectPhaseId, data }: {
	projectId: string,
	projectPhaseId: string,
	data: UpdateProjectPhaseStatusData
}): AxiosApiResponsePromise<ProjectPhaseType> {
	return axiosApi.patch(`/projects/${projectId}/project-phases/${projectPhaseId}/status`, data);
}
