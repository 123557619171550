import { Dialog, Transition } from "@headlessui/react";
import { Fragment, PropsWithChildren, useCallback } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import LoadingSpinner from "~/components/LoadingSpinner";

interface Props {
	open: boolean;
	setOpen: (bool: boolean) => void;
	closeOnOutsideClick?: boolean;
}

const Sidebar: React.FC<PropsWithChildren<Props>> = ({ children, open, setOpen, closeOnOutsideClick = true }) => {
	const outsideClickHandler = useCallback(() => {
		if (closeOnOutsideClick) {
			setOpen(false);
		}
	}, [closeOnOutsideClick, setOpen]);

	return (
		<Transition.Root show={open}
						 as={Fragment}>
			<Dialog as="div"
					className="relative z-50"
					onClose={outsideClickHandler}>
				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-300"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-300"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-300"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto relative w-screen max-w-2xl">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-300"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
											<button
												type="button"
												className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
												onClick={() => setOpen(false)}
											>
												<span className="sr-only">Close panel</span>
												<XMarkIcon className="h-6 w-6"
														   aria-hidden="true" />
											</button>
										</div>
									</Transition.Child>
									<div className="relative grid min-h-full h-full flex-col overflow-hidden bg-white shadow-xl">
										{children}
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

const Header: React.FC<PropsWithChildren> = ({ children }) => (
	<div className="px-5 py-5 bg-primary-500 bg-opacity-10">{children}</div>
);
const Content: React.FC<PropsWithChildren> = ({ children }) => (
	<div className="flex-grow overflow-auto p-5">{children}</div>
);
const BusyOverlay: React.FC = (showSpinner = false) =>
	(<div className="absolute inset-0 bg-gray-100 bg-opacity-50 z-50 flex justify-center items-center">
		{showSpinner && <LoadingSpinner />}
	</div>);
const Footer: React.FC<PropsWithChildren> = ({ children }) => (
	<div className="p-5 bg-white flex justify-end gap-x-2"
		 style={{ boxShadow: "0 0 4px 0 rgba(0,0,0,.2)" }}>
		{children}
	</div>
);

export default Object.assign(Sidebar, { Header, Content, BusyOverlay, Footer });
