import { BuildingOffice2Icon } from "@heroicons/react/20/solid";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

interface Props {
	displayName: string;
	city?: string;
	href?: string;
}

const ClientItem: React.FC<Props> = ({ displayName, city = "", href }) => {
	const Element = () => (
		<li className="col-span-1 flex rounded-md group shadow-sm hover:shadow-md h-full">
			<div
				className={clsx(
					"flex w-16 flex-shrink-0 items-center justify-center",
					"bg-secondary-500 rounded-l-md",
					"text-sm font-medium text-white",
					"transition-color duration-200",
					"group-hover:bg-secondary-600",
				)}
			>
				<BuildingOffice2Icon className="w-6" />
			</div>
			<div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
				<div className="flex-1 truncate px-4 py-2 text-sm">
					<span className="font-medium text-gray-900 group-hover:text-secondary-600 transition-color duration-200">
						{displayName}
					</span>
					{city && <p className="text-gray-500">{city}</p>}
				</div>
			</div>
		</li>
	);
	return href ? (
		<NavLink to={href} className="font-medium text-gray-900 hover:text-gray-600">
			<Element />
		</NavLink>
	) : (
		<Element />
	);
};

export default ClientItem;
