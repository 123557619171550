import { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";

export function indexHolidaysByYear({ year }: { year: string }): AxiosApiResponsePromise<HolidayType[]> {
	return axiosApi.get(`holidays/years/${year}`);
}

export function indexHolidays(): AxiosApiResponsePromise<HolidayType[]> {
	return axiosApi.get("holidays");
}

export function createHoliday({ data }: {
	data: CreateHolidayDataInterface
}): AxiosApiResponsePromise<AbsenceType> {
	return axiosApi.post("holidays", data);
}

export function updateHoliday({ holidayId, data }: {
	holidayId: string,
	data: UpdateHolidayDataInterface
}): AxiosApiResponsePromise<AbsenceType> {
	return axiosApi.post(`holidays/${holidayId}`, data);
}

export function deleteHoliday({ holidayId }: {
	holidayId: string,
}): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`holidays/${holidayId}`);
}