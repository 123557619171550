import { useMemo } from "react";
import { getSelectBoxDefaultOptionsData, createSelectBoxOptionData } from "~/utils/form/formUtils.ts";
import { FormInputOption } from "~/types/form.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";
import { useProjectProjectRoles } from "~/modules/project/api/projectRole/projectRoleQueries.ts";

type Props = {
	projectId: string;
	includeCtaOption?: boolean;
}

const useProjectsProjectRolesOptions = ({ projectId, includeCtaOption }: Props): FormInputOption[] => {
	const { data: projectRolesData } = useProjectProjectRoles({projectId});
	return useMemo(() => {
		if (projectRolesData) {
			const options = projectRolesData.sort(byObjectProperty("displayName")).map((projectRole) => {
				return createSelectBoxOptionData(projectRole.displayName, projectRole.id);
			});

			if (includeCtaOption) {
				return [getSelectBoxDefaultOptionsData(), ...options];
			}
			return options;
		}
		return [];
	}, [projectRolesData, includeCtaOption]);
};

export default useProjectsProjectRolesOptions;

