import { getAllCompanyLocations } from "~/modules/client/api/companyLocation/companyLocationApiDispatchers.ts";
import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { QueriesOptions, useQuery } from "react-query";

const ENTITY_QUERY_KEY = "companyLocation";

export const ALL_COMPANY_LOCATIONS_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useAllCompanyLocations = (options?: QueriesOptions<any>) => useQuery(
	ALL_COMPANY_LOCATIONS_QUERY_KEY,
	() => getAllCompanyLocations().then(getDataFromResponse),
	options,
);