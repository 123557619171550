import { getAllProjectRoles, getProjectsProjectRoles } from "~/modules/project/api/projectRole/projectRoleApiDispatchers.ts";
import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { QueriesOptions, useQuery } from "react-query";
import { UseQueryBaseParams } from "~/types/reactQueryTypes.ts";

const ENTITY_QUERY_KEY = "projectRole";

export const ALL_PROJECT_ROLES_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];
export const PROJECT_ROLES_BY_PROJECT_QUERY_KEY = (projectId: string) => [ENTITY_QUERY_KEY, { projectId }];


export const useAllProjectRoles = (options?: QueriesOptions<any>) => useQuery(
	ALL_PROJECT_ROLES_QUERY_KEY,
	() => getAllProjectRoles().then(getDataFromResponse),
	options,
);

export const useProjectProjectRoles = ({ projectId, options }: { projectId: string } & UseQueryBaseParams) => useQuery(
	PROJECT_ROLES_BY_PROJECT_QUERY_KEY(projectId),
	() => getProjectsProjectRoles(projectId).then(getDataFromResponse),
	options,
);