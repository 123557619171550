import { getAllUsers } from "~/modules/user/api/user/userApiDispatchers.ts";
import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { QueriesOptions, useQuery } from "react-query";

const ENTITY_QUERY_KEY = "user";

export const ALL_USERS_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useAllUsers = (options?: QueriesOptions<any>) => useQuery(
	ALL_USERS_QUERY_KEY,
	() => getAllUsers().then(getDataFromResponse),
	options,
);