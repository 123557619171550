import { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";

export function indexUsersVacationLedgerEntries({ userId }: {
	userId: string
}): AxiosApiResponsePromise<VacationLedgerEntryType[]> {
	return axiosApi.get(`users/${userId}/vacation-ledger-entries`);
}

export function createVacationLedgerEntry({ userId, data }: {
	userId: string,
	data: CreateOrUpdateVacationLedgerEntryDataType
}): AxiosApiResponsePromise<AbsenceType> {
	return axiosApi.post(`users/${userId}/vacation-ledger-entries`, data);
}

export function updateVacationLedgerEntry({ userId, vacationLedgerEntryId, data }: {
	userId: string,
	vacationLedgerEntryId: string,
	data: any
}): AxiosApiResponsePromise<AbsenceType> {
	return axiosApi.put(`users/${userId}/vacation-ledger-entries/${vacationLedgerEntryId}`, data);
}

export function deleteVacationLedgerEntry({ userId, vacationLedgerEntryId }: {
	userId: string,
	vacationLedgerEntryId: string
}): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`users/${userId}/vacation-ledger-entries/${vacationLedgerEntryId}`);
}

export function getUsersVacationLedgerReport({ userId }: {
	userId: string,
}): AxiosApiResponsePromise<VacationLedgerReportType> {
	return axiosApi.get(`users/${userId}/vacation-ledger-entries/report`);
}