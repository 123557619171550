import { getAllCountries } from "~/modules/country/api/country/countryApiDispatchers";
import { getDataFromResponse } from "~/api/axiosUtils";
import { useAllCountries } from "~/modules/country/api/country/countryQueries";

export const getCountry = async (id: string) => {
	let countryData;
	try {
		const response = await getAllCountries().then(getDataFromResponse);
		countryData = response.find((c) => id === c.id);
		return countryData;
	} catch (error) {}
	return countryData;
};

interface Props {
	id: string;
}

const CountryDisplay: React.FC<Props> = ({ id }) => {
	const { data } = useAllCountries();
	if (undefined === data || undefined === id) return null;
	const countryData = data.find((c) => id === c.id);
	if (undefined === countryData) return null;
	const { displayName } = countryData;
	return <>{displayName}</>;
};

export default CountryDisplay;
