import { useMemo } from "react";
import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";
import { UserType } from "~/modules/user/api/user/userTypes.ts";
import { EmploymentStatusId } from "~/types/entityIds.ts";
import { FormInputOption } from "~/types/form.ts";
import { createSelectBoxOptionData } from "~/utils/form/formUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";


export const staffableUsersFilterFn = (user: UserType) => user.employmentStatusId !== EmploymentStatusId.Left;

export const employeesFilterFn = (user: UserType) => user.employmentStatusId === EmploymentStatusId.Employed;

export const internalStaffFilterFn = (user: UserType) => user.employmentStatusId !== EmploymentStatusId.Freelancer && user.employmentStatusId !== EmploymentStatusId.Left;

export const employeesWithoutLeftFilterFn = (user: UserType) => user.employmentStatusId !== EmploymentStatusId.Freelancer;

const useUserSelectOptions = (filterFunction?: (user: UserType) => boolean): FormInputOption[] => {
	const { data: usersData } = useAllUsers();
	return useMemo(() => {
		if (usersData) {
			let filteredUsers = usersData;
			if (filterFunction) {
				filteredUsers = usersData.filter(filterFunction);
			}
			return filteredUsers.sort(byObjectProperty("firstName")).map((user) => {
				return createSelectBoxOptionData(user.fullName, user.id);
			});

		}
		return [];
	}, [usersData, filterFunction]);
};

export default useUserSelectOptions;

