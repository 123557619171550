import MonthlyBillingReportView from "modules/billing/components/MonthlyBillingReportView";
import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import useMonthSelectOptions from "~/hooks/form/formOptionsData/useMonthSelectOptions.ts";
import useYearSelectOptions from "~/hooks/form/formOptionsData/useYearSelectOptions.ts";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { PermissionNames } from "~/types/entityNames.ts";

type MonthlyBillingReportPageProps = {};


const currentMonth = new Date().getMonth().toString();
const currentYear = new Date().getFullYear().toString();

const MonthlyBillingReportPage: React.FunctionComponent<MonthlyBillingReportPageProps> = ({}) => {
	const guard = userViewGuard(PermissionNames.CanManageInvoices);
	if(guard) return guard;

	const [searchParams, setSearchParams] = useSearchParams();

	const monthSelectOptions = useMonthSelectOptions();
	const yearSelectOptions = useYearSelectOptions(2018, new Date().getFullYear());

	const [year, month] = useMemo(() => {
		const year = searchParams.get("year");
		const month = searchParams.get("month");
		const isValidYear = yearSelectOptions.find((option) => option.value === year);
		const isValidMonth = monthSelectOptions.find((option) => option.value === (parseInt(month || "") - 1).toString());

		if (!year || !month || !isValidYear || !isValidMonth) {
			return [];
		}

		return [year, (parseInt(month) - 1).toString()];
	}, [searchParams]);

	useEffect(() => {
		if (!year || !month) {
			setSearchParams({ year: currentYear, month: (parseInt(currentMonth) + 1).toString() });
		}
	}, [year, month]);

	if (!year || !month) return null;


	return <MonthlyBillingReportView
		currentMonth={currentMonth}
		currentYear={currentYear}
		month={month}
		monthSelectOptions={monthSelectOptions}
		year={year}
		yearSelectOptions={yearSelectOptions}

	/>;
};

export default MonthlyBillingReportPage;