import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "~/components/form/Button/Button.tsx";
import FormHasErrorsHint from "~/components/form/FormHasErrorsHint";
import { FormSectionGroup } from "~/components/form/FormSection";

import AddressFormSection from "~/components/form/formSections/AddressFormSection";
import SubmitButton from "~/components/form/SubmitButton";
import Headline from "~/components/Headline";
import Sidebar from "~/components/Sidebar";

import {
	DEFAULT_COUNTRY_ID,
	DEFAULT_LANGUAGE_ID,
	DEFAULT_SALES_FUNNEL_ID,
	DEFAULT_SALES_TYPE_ID,
} from "~/constants/form.ts";
import { useAuth } from "~/contexts/AuthContext";
import useCountrySelectOptions from "~/hooks/form/formOptionsData/useCountrySelectOptions.ts";
import useGenderSelectOptions from "~/hooks/form/formOptionsData/useGenderSelectOptions.ts";
import useLanguageSelectOptions from "~/hooks/form/formOptionsData/useLanguageSelectOptions.ts";
import useSalesFunnelSelectOptions from "~/hooks/form/formOptionsData/useSalesFunnelSelectOptions.ts";
import useSalesTypeSelectOptions from "~/hooks/form/formOptionsData/useSalesTypeSelectOptions.ts";
import useUserSelectOptions, { employeesFilterFn } from "~/hooks/form/formOptionsData/useUsersSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { useAllClients } from "~/modules/client/api/client/clientQueries.ts";
import {
	createClientContactPerson,
} from "~/modules/client/api/clientContactPerson/clientContactPersonApiDispatchers.ts";
import { CreateClientContactPersonData } from "~/modules/client/api/clientContactPerson/clientContactPersonTypes.ts";
import ContactCompanyFormSection
	from "~/modules/client/components/contact/forms/formSections/ContactCompanyFormSection";
import ContactContactFormSection
	from "~/modules/client/components/contact/forms/formSections/ContactContactFormSection";
import ContactPersonalInformationFormSection
	from "~/modules/client/components/contact/forms/formSections/ContactPersonalInformationFormSection";
import ContactSalesFormSection from "~/modules/client/components/contact/forms/formSections/ContactSalesFormSection";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import {
	mapDataToSelectOptionsData,
	preventSubmitOnEnter,
	transformEmptyDateValueToNull,
} from "~/utils/form/formUtils.ts";

type CreateClientContactPersonFormProps = {
	onSuccess: () => void;
	onCancel: () => void;
	clientId?: string;
	companyId: string;
	disableClientSelect?: boolean;
};

interface CreateClientContactPersonFormData extends Omit<CreateClientContactPersonData, "dateOfBirth"> {
	dateOfBirth: Date | null;
}

const CreateClientContactPersonForm: React.FC<CreateClientContactPersonFormProps> = ({
	onSuccess,
	onCancel,
	companyId,
	clientId,
}) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);
	const { user } = useAuth();

	const schema = useMemo(() => {
		return yup.object({
			city: yup.string().default(null),
			clientId: yup.string().required(),
			countryId: yup.string().required(),
			dateOfBirth: yup.date().transform(transformEmptyDateValueToNull).nullable().required(),
			emailBusiness: yup.string().email().default(null),
			emailPrivate: yup.string().email().default(null),
			firstName: yup.string().required(),
			genderId: yup.string().required(),
			houseNumber: yup.string().default(null),
			jobTitle: yup.string().default(null),
			languageId: yup.string().required(),
			lastName: yup.string().required(),
			managedBy: yup.string().required(),
			mobilePhone: yup.string().default(null),
			nobilityTitle: yup.string().default(null),
			phone: yup.string().default(null),
			salesFunnelId: yup.string().required(),
			salesTypeId: yup.string().required(),
			street: yup.string().default(null),
			title: yup.string().default(null),
			useInformalSalutation: yup.boolean().required(),
			zipCode: yup.string().default(null),
		});
	}, []);

	const defaultValues = useMemo(() => {
		return {
			city: "",
			clientId: clientId || "",
			countryId: DEFAULT_COUNTRY_ID,
			dateOfBirth: null,
			emailBusiness: "",
			emailPrivate: "",
			firstName: "",
			genderId: "",
			houseNumber: "",
			jobTitle: "",
			languageId: DEFAULT_LANGUAGE_ID,
			lastName: "",
			managedBy: user?.id || "",
			mobilePhone: "",
			nobilityTitle: "",
			phone: "",
			salesFunnelId: DEFAULT_SALES_FUNNEL_ID,
			salesTypeId: DEFAULT_SALES_TYPE_ID,
			street: "",
			title: "",
			useInformalSalutation: false,
			zipCode: "",
		};
	}, [user, clientId]);

	const {
		handleSubmit,
		control,
		formState: { errors, isDirty, isValid, isSubmitted },
		setValue,
		watch,
	} = useForm<CreateClientContactPersonFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<CreateClientContactPersonFormData>(schema),
	});
	const formIsSubmittable = useFormIsSubmittable({ isDirty, isSubmitted, isValid, isLoading: busy });
	const countrySelectOptions = useCountrySelectOptions();
	const genderSelectOptions = useGenderSelectOptions();
	const languageSelectOptions = useLanguageSelectOptions();
	const managerSelectOptions = useUserSelectOptions(employeesFilterFn);
	const salesFunnelSelectOptions = useSalesFunnelSelectOptions();
	const salesTypeSelectOptions = useSalesTypeSelectOptions();

	const { data: allClientsData } = useAllClients();

	const clientSelectOptions = useMemo(() => {
		if (allClientsData) {
			const companiesClients = allClientsData.filter((client) => client.companyId === companyId);
			return mapDataToSelectOptionsData(companiesClients, "id", "displayName");
		}
		return [];
	}, [allClientsData, companyId]);

	useEffect(() => {
		if (!clientId && clientSelectOptions.length === 1) {
			setValue("clientId", clientSelectOptions[0].value);
		}
	}, [clientSelectOptions, clientId]);

	const onSubmit: SubmitHandler<CreateClientContactPersonFormData> = async (data) => {
		setBusy(true);
		const dateOfBirth = data.dateOfBirth ? formatDateToYYYYMMDD(new Date(data.dateOfBirth)) : null;
		const newContactPersonData = { ...data, dateOfBirth };
		try {
			await createClientContactPerson(newContactPersonData.clientId, newContactPersonData);

			onSuccess();
		} catch (error) {
			console.log(error);
		}
	};

	const selectedClientId = watch("clientId");

	useEffect(() => {
		if (selectedClientId) {
			const selectedClientData = allClientsData?.find((client) => client.id === selectedClientId);
			if (selectedClientData) {
				setValue("street", selectedClientData.street);
				setValue("houseNumber", selectedClientData.houseNumber);
				setValue("zipCode", selectedClientData.zipCode);
				setValue("city", selectedClientData.city);
				setValue("countryId", selectedClientData.countryId);
			}
		}
	}, [selectedClientId, allClientsData]);

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex flex-col justify-start w-full min-h-full"
		>
			<Sidebar.Header>
				<Headline type="h2">Neuen Kontakt anlegen</Headline>
			</Sidebar.Header>
			<Sidebar.Content>
				{busy && <Sidebar.BusyOverlay />}
				<FormSectionGroup>
					<ContactCompanyFormSection<CreateClientContactPersonFormData>
						control={control}
						disableClientSelect={!!clientId}
						clientSelectOptions={clientSelectOptions}
						errors={errors}
					/>

					<ContactPersonalInformationFormSection<CreateClientContactPersonFormData>
						control={control}
						errors={errors}
						genderSelectOptions={genderSelectOptions}
					/>

					<ContactContactFormSection<CreateClientContactPersonFormData>
						control={control} />

					<AddressFormSection
						control={control}
						countrySelectOptions={countrySelectOptions}
					/>

					<ContactSalesFormSection
						control={control}
						errors={errors}
						languageSelectOptions={languageSelectOptions}
						managerSelectOptions={managerSelectOptions}
						salesFunnelSelectOptions={salesFunnelSelectOptions}
						salesTypeSelectOptions={salesTypeSelectOptions}
					/>
				</FormSectionGroup>
			</Sidebar.Content>
			<Sidebar.Footer>
				<FormHasErrorsHint
					show={isSubmitted && !isValid}
					className="mr-2" />
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					abbrechen
				</Button>
			</Sidebar.Footer>
		</form>
	);
};

export default CreateClientContactPersonForm;
