import { Control, FieldErrors, FieldValues, Path } from "react-hook-form";
import FormSection from "~/components/form/FormSection";
import RadioGroup from "~/components/form/RadioGroup";
import Select from "~/components/form/Select";
import { USE_INFORMAL_SALUTATION_OPTIONS } from "~/constants/form.ts";

import { FormInputOption } from "~/types/form.ts";

type ContactSalesFormSectionProps<T extends FieldValues> = {
	control: Control<T>;
	errors: FieldErrors<T>;
	languageSelectOptions: FormInputOption[];
	managerSelectOptions: FormInputOption[];
	salesFunnelSelectOptions: FormInputOption[];
	salesTypeSelectOptions: FormInputOption[];
};

type ContactSalesFormData = {
	managedBy: string;
	languageId: string;
	useInformalSalutation: boolean;
	salesFunnelId: string;
	salesTypeId: string;
};

const ContactSalesFormSection = <T extends ContactSalesFormData>({
	control,
	errors,
	languageSelectOptions,
	managerSelectOptions,
	salesFunnelSelectOptions,
	salesTypeSelectOptions,
}: ContactSalesFormSectionProps<T>) => {
	return (
		<FormSection title="Sales">
			<div className="grid grid-cols-6 gap-x-6 gap-y-6">
				<div className="col-span-3">
					<Select
						name="managedBy"
						label={"Manager"}
						error={errors.managedBy?.message as Path<T>}
						optionsData={managerSelectOptions}
						control={control}
					/>
				</div>
				<div className="col-span-3">
					<Select
						name="languageId"
						label={"Sprache"}
						error={errors.languageId?.message as Path<T>}
						optionsData={languageSelectOptions}
						control={control}
					/>
				</div>
				<div className="col-span-6">
					<RadioGroup
						control={control}
						label="Anrede"
						name="useInformalSalutation"
						options={USE_INFORMAL_SALUTATION_OPTIONS}
						numCols={2}
					/>
				</div>
				<div className="col-span-3">
					<Select
						name="salesFunnelId"
						label={"Sales Funnel"}
						error={errors.salesFunnelId?.message as Path<T>}
						optionsData={salesFunnelSelectOptions}
						control={control}
					/>
				</div>
				<div className="col-span-3">
					<Select
						name="salesTypeId"
						label={"Sales Typ"}
						error={errors.salesTypeId?.message as Path<T>}
						optionsData={salesTypeSelectOptions}
						control={control}
					/>
				</div>
			</div>
		</FormSection>
	);
};

export default ContactSalesFormSection;
