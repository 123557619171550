import {
	getStaffing,
	indexStaffingByProjectId,
	indexUsersActiveStaffings,
} from "~/modules/project/api/staffing/staffingApiDispatchers.ts";
import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { QueriesOptions, useQuery } from "react-query";

const ENTITY_QUERY_KEY = "staffing";

interface UseProjectsStaffingsParamsType extends QueriesOptions<any> {
	projectId: string;
}

export const PROJECTS_STAFFINGS_QUERY_KEY = (projectId: string) => [ENTITY_QUERY_KEY, { projectId }];
export const STAFFING_QUERY_KEY = (staffingId: string) => [ENTITY_QUERY_KEY, { staffingId }];

export const USERS_ACTIVE_STAFFINGS_QUERY_KEY = (userId: string) => [ENTITY_QUERY_KEY, { userId, "active": true }];
export const useStaffing = ({ projectId, staffingId, options }: {
	projectId: string,
	staffingId: string,
	options?: QueriesOptions<any>
}) => useQuery(
	STAFFING_QUERY_KEY(staffingId),
	() => getStaffing({ projectId, staffingId }).then(getDataFromResponse),
	options,
);

export const useProjectsStaffings = ({ projectId, options }: UseProjectsStaffingsParamsType) => useQuery(
	PROJECTS_STAFFINGS_QUERY_KEY(projectId),
	() => indexStaffingByProjectId({ projectId }).then(getDataFromResponse),
	options,
);

export const useUsersActiveStaffings = ({ userId, options }: {
	userId: string,
	options?: QueriesOptions<any>
}) => useQuery(
	USERS_ACTIVE_STAFFINGS_QUERY_KEY(userId),
	() => indexUsersActiveStaffings({ userId }).then(getDataFromResponse),
	options,
);