import { generatePath, useNavigate, useParams } from "react-router-dom";
import SectionHeading from "~/components/headings/SectionHeading/SectionHeading.tsx";
import { appRoutes } from "~/constants/appRoute.ts";
import { AbsenceSubRoutes } from "~/modules/user/router/UserRouter/UserRouter.tsx";


type AbsencePageTabsProps = {
	selectedTabName: AbsenceSubRoutes;
};


const AbsencePageTabs: React.FunctionComponent<AbsencePageTabsProps> = ({ selectedTabName }) => {
	const navigate = useNavigate();
	const { userId } = useParams();
	return <SectionHeading.Tabs
		tabs={[
			{
				name: "Abwesenheiten",
				value: AbsenceSubRoutes.absences,
				active: selectedTabName === AbsenceSubRoutes.absences,
			},
			{
				name: "Urlaubskonto",
				value: AbsenceSubRoutes.vacationLedger,
				active: selectedTabName === AbsenceSubRoutes.vacationLedger,
			},
			{
				name: "Arbeitszeitmodelle",
				value: AbsenceSubRoutes.workingSchedules,
				active: selectedTabName === AbsenceSubRoutes.workingSchedules,
			},


		]}
		onChange={(tabValue) => {
			navigate(generatePath(appRoutes.user().path, { userId }) + "/" + tabValue, {replace:true});
		}}
	/>;
};

export default AbsencePageTabs;