import ContentWrapper from "~/components/ContentWrapper";
import { PropsWithChildren } from "react";
import clsx from "clsx";

export const heroSectionBackgroundColor = "bg-white";

const HeroSection: React.FC<PropsWithChildren<{ className?: string }>> = ({ children, className }) => (
	<div className={clsx(heroSectionBackgroundColor, className)}>
		<header className="py-8">
			<ContentWrapper>{children}</ContentWrapper>
		</header>
	</div>
);

export default HeroSection;

export const HeroSectionHighlights: React.FC<PropsWithChildren> = ({ children }) => (
	<>
		<div className={`${heroSectionBackgroundColor} h-12 border-b`} />
		<div className="transform-gpu -translate-y-12">
			<div className="px-4 sm:px-6 lg:px-8">
				<ContentWrapper className="p-4 pb-6 rounded-lg bg-primary-900 shadow text-white">
					{children}
				</ContentWrapper>
			</div>
		</div>
	</>
);
