import clsx from "clsx";
import { createPortal } from "react-dom";
import Breadcrumbs from "~/components/Breadcrumbs/components/Breadcrumbs";
import { AppRoute } from "~/constants/appRoute.ts";

interface Props {
	pages: (AppRoute | string)[];
	className?: string;
}

const BreadcrumbsPortal: React.FC<Props> = ({ pages, className }) => {
	const portalTarget = document.getElementById("breadcrumb");
	if (null === portalTarget) return null;
	return createPortal(
		<nav className={clsx("flex py-4", className)} aria-label="Breadcrumbs">
			<div className="mx-auto max-w-7xl w-full px-2 sm:px-6 lg:px-8">
				<Breadcrumbs pages={pages}/>
			</div>
		</nav>,
		portalTarget,
	);
};
export default BreadcrumbsPortal;
