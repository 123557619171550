import { StatusThemeVariants } from "~/components/Status/statusVariants.ts";
import { OrderInvoicingStatus } from "~/modules/billing/types/billingEnums.ts";

export function getInvoicingStatusTheme(status: OrderInvoicingStatus): StatusThemeVariants {
	switch (status) {
		case OrderInvoicingStatus.Idle:
			return "default";
		case OrderInvoicingStatus.Sent:
			return "highlight";
		case OrderInvoicingStatus.Created:
			return "info";
		case OrderInvoicingStatus.Paid:
			return "success";
		default:
			return "default";

	}
}

export const invoicingStatusFilterOptions = [
	{
		name: OrderInvoicingStatus.Idle,
		theme: getInvoicingStatusTheme(OrderInvoicingStatus.Idle),
	},
	{
		name: OrderInvoicingStatus.Created,
		theme: getInvoicingStatusTheme(OrderInvoicingStatus.Created),
	},
	{
		name: OrderInvoicingStatus.Sent,
		theme: getInvoicingStatusTheme(OrderInvoicingStatus.Sent),
	},
	{
		name: OrderInvoicingStatus.Paid,
		theme: getInvoicingStatusTheme(OrderInvoicingStatus.Paid),
	},
];