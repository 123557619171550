import { yupResolver } from "@hookform/resolvers/yup";
import React, { useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { getDataFromResponse } from "~/api/axiosUtils.ts";
import FormHasErrorsHint from "~/components/form/FormHasErrorsHint";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { createProjectPhase } from "~/modules/project/api/projectPhase/projectPhaseApiDispatchers.ts";
import { CreateProjectPhaseData } from "~/modules/project/api/projectPhase/projectPhaseTypes.ts";
import Button from "~/components/form/Button/Button.tsx";

import FormSection, { FormSectionGroup } from "~/components/form/FormSection";

import BudgetFormSection from "~/components/form/formSections/BudgetFormSection";
import CommentFormSection from "~/components/form/formSections/CommentFormSection";
import PlaceOfPerformanceFormSection from "~/components/form/formSections/PlaceOfPerformanceFormSection";
import TimingFormSection from "~/components/form/formSections/TimingFormSection";
import TitleFormSection from "~/components/form/formSections/TitleFormSection";
import RadioGroup from "~/components/form/RadioGroup";
import Select from "~/components/form/Select";
import SubmitButton from "~/components/form/SubmitButton";
import Headline from "~/components/Headline";
import Sidebar from "~/components/Sidebar";
import OrderInvoiceRecipientSection from "~/modules/project/components/forms/formSections/OrderInvoiceRecipientSection";
import { DEFAULT_COUNTRY_ID } from "~/constants/form.ts";
import { useAuth } from "~/contexts/AuthContext";
import useApprovalLikelihoodOptions from "~/hooks/form/formOptionsData/useApprovalLikelihoodOptions.ts";
import useClientsContactPersonsSelectOptions
	from "~/hooks/form/formOptionsData/useClientsContactPersonsSelectOptions.tsx";
import useClientsInvoiceRecipientSelectOptions
	from "~/hooks/form/formOptionsData/useClientsInvoiceRecipientsSelectOptions.ts";
import useCountrySelectOptions from "~/hooks/form/formOptionsData/useCountrySelectOptions.ts";
import useLocationOptions from "~/hooks/form/formOptionsData/useLocationOptions.ts";
import useUserSelectOptions, { employeesFilterFn } from "~/hooks/form/formOptionsData/useUsersSelectOptions.ts";
import useWorkingStatusOptions from "~/hooks/form/formOptionsData/useWorkingStatusOptions.ts";
import {
	useProjectPhaseTitleValidationComparisonData,
} from "~/hooks/form/validationComparisonData/useProjectPhaseTitleValidationComparisonData.ts";
import { WorkingStatusId } from "~/types/entityIds.ts";
import { preventSubmitOnEnter, transformEmptyDateValueToNull } from "~/utils/form/formUtils.ts";

type CreateProjectPhaseFormProps = {
	clientId: string;
	projectId: string;
	onSuccess: (value: string) => void;
	onCancel: () => void;
};

interface CreateProjectPhaseFormData extends CreateProjectPhaseData {
}

const CreateProjectPhaseForm: React.FC<CreateProjectPhaseFormProps> = ({
	clientId,
	projectId,
	onSuccess,
	onCancel,
}) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);
	const { user } = useAuth();
	const titleComparisonData = useProjectPhaseTitleValidationComparisonData(projectId);

	const schema = useMemo(() => {
		return yup.object({
			approvalLikelihoodId: yup.string().required(),
			budgetCentsPlanned: yup.number().required(),
			clientContactPersonId: yup.string().nullable().default(null),
			comment: yup.string().default(null),
			countryId: yup.string().required(),
			endDate: yup
				.date()
				.transform(transformEmptyDateValueToNull)
				.nullable()
				.required()
				.min(yup.ref("startDate"), "Das Starddatum muss vor dem Enddatum liegen."),
			invoiceRecipientId: yup.string().nullable().default(null),
			locationName: yup.string().required(),
			manDaysPlanned: yup.number().required(),
			phaseManagedBy: yup.string().default(null),
			projectId: yup.string().required(),
			startDate: yup.date().transform(transformEmptyDateValueToNull).nullable().required(),
			title: yup.string().required().unique(titleComparisonData),
			workingStatusId: yup.string().required(),
		}).test("atLeastOneField", "Bitte wähle einen Kontakt oder zentralen Rechnungsempfänger aus.", (value) =>

			Boolean(value.clientContactPersonId || value.invoiceRecipientId),
		);
	}, [titleComparisonData]);

	const defaultValues = useMemo(() => {
		return {
			approvalLikelihoodId: "1",
			budgetCentsPlanned: 0,
			clientContactPersonId: null,
			comment: "",
			countryId: DEFAULT_COUNTRY_ID,
			endDate: "" as unknown as Date,
			invoiceRecipientId: null,
			locationName: "",
			manDaysPlanned: 0,
			projectId: projectId || "",
			phaseManagedBy: user?.id,
			startDate: "" as unknown as Date,
			title: "",
			workingStatusId: "1",
		};
	}, [user, projectId]);

	const {
		handleSubmit,
		control,
		formState: { errors, isDirty, isSubmitted, isValid },
		setValue,
		trigger,
		watch,
	} = useForm<CreateProjectPhaseFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<CreateProjectPhaseFormData>(schema),
	});
	const approvalLikelihoodOptions = useApprovalLikelihoodOptions();
	const countrySelectOptions = useCountrySelectOptions();
	const managerSelectOptions = useUserSelectOptions(employeesFilterFn);
	const locationOptions = useLocationOptions();
	const workingStatusOptions = useWorkingStatusOptions();
	const clientContactPersonSelectOptions = useClientsContactPersonsSelectOptions(clientId);
	const clientsInvoiceRecipientSelectOptions = useClientsInvoiceRecipientSelectOptions(clientId);

	const formIsSubmittable = useFormIsSubmittable({ isDirty, isSubmitted, isValid, isLoading: busy });

	const onSubmit: SubmitHandler<CreateProjectPhaseFormData> = async (data: CreateProjectPhaseFormData) => {
		setBusy(true);
		if (data.workingStatusId !== WorkingStatusId.Quotation) {
			data.approvalLikelihoodId = null;
		}

		try {
			const res = await createProjectPhase(projectId || data.projectId, data);
			const newProjectPhaseId = getDataFromResponse(res).id;
			onSuccess(newProjectPhaseId);
		} catch (error) {
			console.log(error);
		}
	};

	const showApprovalLikelihoodInput = watch("workingStatusId") === "1";

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex flex-col justify-start w-full min-h-full"
		>
			<Sidebar.Header>
				<Headline type="h2">Neue Projektphase erstellen</Headline>
			</Sidebar.Header>
			<Sidebar.Content>
				{busy && <Sidebar.BusyOverlay />}
				<FormSectionGroup>
					<TitleFormSection control={control}
									  title="Name" />

					<FormSection title="Status">
						<div className="grid gap-x-6 gap-y-2 grid-cols-6">
							<div className="col-span-6">
								<Select control={control}
										name="workingStatusId"
										optionsData={workingStatusOptions} />
							</div>
							{showApprovalLikelihoodInput && (
								<div className="col-span-6">
									<RadioGroup
										control={control}
										name="approvalLikelihoodId"
										options={approvalLikelihoodOptions}
										label="Bewertung"
									/>
								</div>
							)}
						</div>
					</FormSection>
					<BudgetFormSection control={control}
									   title="Plandaten"
									   errors={errors} />

					<TimingFormSection control={control}
									   errors={errors}
									   title="Laufzeit" />
					<PlaceOfPerformanceFormSection
						errors={errors}
						control={control}
						locationOptions={locationOptions}
						countrySelectOptions={countrySelectOptions}
					/>

					<FormSection title="Verantwortlicher der Phase ">
						<div className="grid gap-x-6 gap-y-2 grid-cols-6">
							<div className="col-span-6">
								<Select
									name="phaseManagedBy"
									label={"Angebot Manager"}
									error={errors.phaseManagedBy?.message}
									optionsData={managerSelectOptions}
									control={control}
								/>
							</div>
						</div>
					</FormSection>
					<OrderInvoiceRecipientSection
						control={control}
						clientContactPersonSelectOptions={clientContactPersonSelectOptions}
						// @ts-ignore
						errorMessage={errors[""]?.message}
						invoiceRecipientSelectOptions={clientsInvoiceRecipientSelectOptions}
						setValue={setValue}
						trigger={trigger}
						watch={watch}
					/>
					<CommentFormSection
						control={control}
						placeholder={"Hier können allgemeine Anmerkungen zur Projektphase hinterlegt werden."}
						label="Kommentar"
						title="Anmerkungen"
					/>
				</FormSectionGroup>
			</Sidebar.Content>
			<Sidebar.Footer>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					Abbrechen
				</Button>
			</Sidebar.Footer>
		</form>
	);
};

export default CreateProjectPhaseForm;
