import { CheckIcon, EyeIcon, EyeSlashIcon, PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import DeleteProjectPhaseModal from "modules/project/components/ProjectDetailsView/components/DeleteProjectPhaseModal";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import Card from "~/components/Card";
import ProjectStatsBarChart from "~/components/charts/ProjectStatsBarChart";
import Button from "~/components/form/Button";
import Label from "~/components/form/Label";
import SectionHeading from "~/components/headings/SectionHeading";
import Headline from "~/components/Headline";
import CalendarIcon from "~/components/icons/CalendarIcon";
import CaptainFemaleIcon from "~/components/icons/CaptainFemaleIcon";
import GlobeIcon from "~/components/icons/GlobeIcon";
import ManIcon from "~/components/icons/ManIcon";
import { WorkingStatus } from "~/components/Status";
import { Country } from "~/modules/country/api/country/countryTypes.ts";
import { ProjectPhaseType } from "~/modules/project/api/projectPhase/projectPhaseTypes.ts";
import { useProjectsStaffings } from "~/modules/project/api/staffing/staffingQueries.ts";
import { WorkingStatusType } from "~/modules/project/api/workingStatus/workingStatusTypes.ts";
import PhaseInfoBoxElement
	from "~/modules/project/components/ProjectDetailsView/components/Phases/components/PhaseInfoBoxElement";
import PhaseSelect
	from "~/modules/project/components/ProjectDetailsView/components/Phases/components/PhaseSelectOptions";
import UpdateProjectPhaseSidebar
	from "~/modules/project/components/ProjectDetailsView/components/UpdateProjectPhaseSidebar";
import UpdateProjectPhaseStatusSidebar
	from "~/modules/project/components/ProjectDetailsView/components/UpdateProjectPhaseStatusSidebar";
import { formatProjectPhaseTitle } from "~/modules/project/utils/projectUtils.ts";
import { minutesToWorkdays } from "~/modules/timeTracking/utils/timeTrackingUtils.ts";
import { UserType } from "~/modules/user/api/user/userTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { formatDateRange } from "~/utils/dateAndTimeUtils.ts";
import getOneOfCollection from "~/utils/getOneOfCollection.ts";

interface PhasesProps {
	allLocations: Location[];
	allUsers: UserType[];
	countries: Country[];
	phases: ProjectPhaseType[];
	projectId: string;
	selectedPhaseData: ProjectPhaseType;
	setSelectedPhaseId: (value: string) => void;
	showBudget: boolean;
	workingStatuses: WorkingStatusType[];
}

const ProjectPhases: React.FC<PhasesProps> = ({
	allLocations,
	allUsers,
	countries,
	phases,
	projectId,
	selectedPhaseData,
	setSelectedPhaseId,
	showBudget,
	workingStatuses,
}) => {
	const { t } = useTranslation();
	const [showUpdatePhaseSidebar, setShowUpdatePhaseSidebar] = useState<boolean>(false);
	const [showDeletePhaseSidebar, setShowDeletePhaseSidebar] = useState<boolean>(false);
	const [showUpdateStatusSidebar, setShowUpdateStatusSidebar] = useState(false);
	const [allPhasesVisible, setAllPhasesVisible] = useState<boolean>(false);

	const selectedPhaseId = selectedPhaseData.id;
	const {
		data: projectsStaffingsData,
	} = useProjectsStaffings({ projectId });

	if (0 === phases.length) {
		return (
			<SectionHeading>
				<Headline type="h2">Phasen</Headline>
				<p>Keine Phasen vorhanden</p>
			</SectionHeading>
		);
	}

	const phaseHasTimeTrackings = useMemo(() => {
		if (projectsStaffingsData && selectedPhaseId) {
			return projectsStaffingsData.filter((s) => s.projectPhaseId === selectedPhaseId && s.minutesTracked > 0).length > 0;
		}
		return false;
	}, [projectsStaffingsData, selectedPhaseId]);

	const phaseProjectLeadName = useMemo(() => {
		if (projectsStaffingsData && selectedPhaseId && allUsers) {
			const projectLeadStaffings = projectsStaffingsData.filter((s) => s.projectPhaseId === selectedPhaseId && s.projectRoleId === "1");
			if (projectLeadStaffings.length > 0) {
				const projectLeadStaffing = projectLeadStaffings[0];
				const projectLead = allUsers.find((u) => projectLeadStaffing.userId === u.id);
				return projectLead?.fullName;
			}
		}
		return null;
	}, [projectsStaffingsData, selectedPhaseId, allUsers]);

	const {
		title,
		budgetCentsConfirmed,
		budgetCentsPlanned,
		budgetCentsTracked,
		locationId,
		countryId,
		startDate,
		endDate,
		manDaysConfirmed,
		manDaysPlanned,
		minutesTracked,
		phaseNumber,
		phaseManagedBy,
		workingStatusId,
	} = selectedPhaseData;

	const placeOfPerformanceLocation = null === locationId ? "-" : getOneOfCollection(allLocations, locationId);
	const placeOfPerformanceCountry = null === countryId ? "-" : getOneOfCollection(countries, countryId, "displayName");

	const placeOfPerformance = !placeOfPerformanceLocation && !placeOfPerformanceCountry ? "-" : `${placeOfPerformanceLocation?.displayName}, ${placeOfPerformanceCountry}`;

	const workingStatusName = getOneOfCollection(workingStatuses, workingStatusId, "name");
	const phaseManager = allUsers.find((u) => phaseManagedBy === u.id);
	const manDaysTracked = minutesToWorkdays(minutesTracked);

	const budgetCents = budgetCentsConfirmed === 0 ? budgetCentsPlanned : budgetCentsConfirmed;
	const manDays = parseFloat(manDaysConfirmed) === 0 ? manDaysPlanned : manDaysConfirmed;

	return (
		<>
			<SectionHeading>
				<div className="flex items-center gap-x-2">
					<h2
						className="text-secondary-500 font-bold ">
						<span className="text-[1.6rem]">Phase {formatProjectPhaseTitle(title, phaseNumber)}</span>
					</h2>
					<Button
						theme={allPhasesVisible ? "primary" : "white"}
						size="sm"
						onClick={() => setAllPhasesVisible(!allPhasesVisible)}
					>
						{allPhasesVisible ? (
							<>
								<EyeSlashIcon className="w-4 h-4" />
								Phasenauswahl schließen
							</>
						) : (
							<>
								<EyeIcon className="w-4 h-4" />
								{`${phases.length} Phase${phases.length > 1 ? "n" : ""} anzeigen`}
							</>
						)}
					</Button>
					<div className={"ml-auto flex justify-between text-normal"}>
						<div className="ml-auto flex justify-end gap-2 items-center">
							<WorkingStatus id={workingStatusId}>
								{t(normalizeTKey("entities:workingStatus." + workingStatusName))}
							</WorkingStatus>
							<ButtonWithPopover
								items={[
									{
										label: "Phase bearbeiten",
										onClick: () => setShowUpdatePhaseSidebar(true),
										icon: PencilIcon,
									},
									{
										label: "Status ändern",
										onClick: () => setShowUpdateStatusSidebar(true),
										icon: CheckIcon,
									},
									{
										label: "Phase löschen",
										onClick: () => setShowDeletePhaseSidebar(true),
										disabled: phaseHasTimeTrackings,
										icon: TrashIcon,
									},
								]}
								theme="dark"
							/>
						</div>
					</div>
				</div>
			</SectionHeading>

			<PhaseSelect
				isOpen={allPhasesVisible}
				phases={phases}
				selectedPhaseId={selectedPhaseId}
				setSelectedPhaseId={setSelectedPhaseId}
				setAllPhasesVisible={setAllPhasesVisible}
				showBudget={showBudget}
				workingStatuses={workingStatuses}
			/>
			<div
				className={clsx("duration-200 transition-opacity mt-4", {
					"opacity-10": allPhasesVisible,
				})}
			>
				<dl className="mt-4 flex floex-row gap-x-5">
					<Card className="flex flex-row gap-x-2 w-1/2">
						<div className="flex flex-col gap-y-4">
							<PhaseInfoBoxElement label="Laufzeit"
												 icon={<CalendarIcon className="w-8  fill-primary-700" />}>
								{startDate && endDate ? `${formatDateRange(new Date(startDate), new Date(endDate))}` : "n/a"}
							</PhaseInfoBoxElement>
							<PhaseInfoBoxElement label="Leistungsort"
												 icon={<GlobeIcon className="w-8  fill-primary-700" />}>
								{placeOfPerformance}
							</PhaseInfoBoxElement>
						</div>
						<div className="h-full border-r-[1px] border-primary-700 mx-4" />
						<div className="flex flex-col gap-y-4">
							<PhaseInfoBoxElement label="Angebotsmanager"
												 icon={
													 <ManIcon className={clsx("w-7", phaseManager?.fullName ? "fill-primary-700" : "fill-gray-300")} />}>
								<span className={clsx(!phaseManager && "text-gray-400")}>{phaseManager?.fullName || "--"}</span>
							</PhaseInfoBoxElement>
							<PhaseInfoBoxElement label="Projektleitung"
												 icon={
													 <CaptainFemaleIcon className={clsx("w-7", phaseProjectLeadName ? "fill-primary-700" : "fill-gray-300")} />}>
								<span className={clsx(!phaseProjectLeadName && "text-gray-400")}>{phaseProjectLeadName || "--"}</span>
							</PhaseInfoBoxElement>
						</div>
					</Card>
					<Card className="w-1/2">
						<Label size="sm"
							   lineHeight="none">Verbrauch</Label>
						<dd className="mt-1">
							<ProjectStatsBarChart
								budgetCentsPlanned={budgetCents}
								budgetCentsTracked={budgetCentsTracked}
								manDaysPlanned={parseFloat(manDays)}
								manDaysTracked={manDaysTracked}
								showBudget={showBudget}
							/>
						</dd>
					</Card>
				</dl>
			</div>

			<UpdateProjectPhaseSidebar
				isOpen={showUpdatePhaseSidebar}
				onClose={setShowUpdatePhaseSidebar}
				projectId={projectId}
				projectPhaseData={selectedPhaseData}
			/>
			<DeleteProjectPhaseModal
				isOpen={showDeletePhaseSidebar}
				projectId={projectId}
				projectPhaseId={selectedPhaseId}
				projectPhaseTitle={selectedPhaseData.title}
				onClose={() => setShowDeletePhaseSidebar(false)} />
			<UpdateProjectPhaseStatusSidebar
				currentPhaseStatusId={selectedPhaseData.workingStatusId}
				phaseHasTimeTrackings={phaseHasTimeTrackings}
				isOpen={showUpdateStatusSidebar}
				phasesCount={phases.length}
				projectId={projectId}
				projectPhaseId={selectedPhaseId}
				onClose={() => setShowUpdateStatusSidebar(false)} />
		</>
	);
};

export default ProjectPhases;
