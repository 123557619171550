import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/20/solid";

import AddContactPersonToClientSidebar from "modules/client/components/AddContactPersonToClientSidebar";
import AddProjectToClientSidebar from "modules/client/components/AddProjectToClientSidebar";
import ClientContactsList from "modules/client/components/ClientDetailsView/components/ClientContactsList";
import ClientsProjectsList from "modules/client/components/ClientDetailsView/components/ClientsProjectsList";
import UpdateClientSidebar from "modules/client/components/UpdateClientSidebar";
import { useMemo, useState } from "react";
import BreadcrumbsPortal from "~/components/Breadcrumbs";
import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import ContentWrapper from "~/components/ContentWrapper";
import Button from "~/components/form/Button";
import SectionHeading from "~/components/headings/SectionHeading";
import Headline from "~/components/Headline";
import Hint from "~/components/Hint";
import HeroSection, { heroSectionBackgroundColor } from "~/components/sections/HeroSection";
import { appRoutes } from "~/constants/appRoute.ts";
import { InvoiceRecipientType } from "~/modules/billing/api/invoiceRecipient/invoiceRecipientTypes.ts";
import { ClientType } from "~/modules/client/api/client/clientTypes.ts";
import { ClientContactPersonType } from "~/modules/client/api/clientContactPerson/clientContactPersonTypes.ts";
import ClientsBillingDetails from "~/modules/client/components/ClientDetailsView/components/ClientsBillingDetails";
import ClientsInvoiceRecipientsList
	from "~/modules/client/components/ClientDetailsView/components/ClientsInvoiceRecipientsList";
import DeleteClientModal from "~/modules/client/components/DeleteClientModal";
import { ProjectType } from "~/modules/project/api/project/projectTypes.ts";
import { WorkingStatusType as WorkingStatusType } from "~/modules/project/api/workingStatus/workingStatusTypes.ts";

interface ClientViewProps {
	clientData: ClientType;
	contacts: ClientContactPersonType[];
	invoiceRecipients: InvoiceRecipientType[];
	projects: ProjectType[];
	workingStatuses: WorkingStatusType[];
}

const ClientDetailsView: React.FC<ClientViewProps> = ({
	clientData,
	contacts,
	invoiceRecipients,
	projects,
	workingStatuses,
}) => {
	const [activeTabSection, setActiveTabSection] = useState<"billingDetails" | "contacts" | "invoiceRecipients" | "projects" | "quotas">("contacts");

	const [showNewContactForm, setShowNewContactForm] = useState(false);
	const [showEditForm, setShowEditForm] = useState(false);
	const [showDeletionModal, setShowDeletionModal] = useState(false);
	const [showAddProjectForm, setShowAddProjectForm] = useState(false);

	const popoverItems = useMemo(() => {
		return [
			{
				label: "bearbeiten",
				onClick: () => setShowEditForm(true),
				icon: PencilIcon,
			},
			{
				label: "löschen",
				onClick: () => setShowDeletionModal(true),
				icon: TrashIcon,
				disabled: contacts?.length > 0 || projects?.length > 0 || invoiceRecipients?.length > 0,
			},
		];
	}, [contacts, projects, invoiceRecipients]);

	return (
		<>
			<BreadcrumbsPortal pages={[appRoutes.clients(), clientData.displayName]}
							   className={heroSectionBackgroundColor} />
			<HeroSection>
				<div className="flex justify-between">
					<div className="flex flex-col text-primary-500">
						<Headline type="h1"
								  color="text-current">
							{clientData.displayName}
						</Headline>
					</div>
					<ButtonWithPopover
						theme="dark"
						items={popoverItems}
					/>
				</div>
			</HeroSection>
			{/*			<HeroSectionHighlights>
				<Stats
					fields={[
						{ label: "Währung", element: currencyDisplayName },
						{ label: "Rechnung per Mail", element: invoiceByMail ? "ja" : "nein" },
						{ label: "USt befreit", element: isVatExempt ? "ja" : "nein" },
						{ label: "Zahlungsziel", element: paymentTargetDays, unit: "Tage" },
						{ label: "Reisekosten included", element: travelExpensesIncluded ? "ja" : "nein" },
					]}
				/>
			</HeroSectionHighlights>*/}

			<div className="flex flex-col gap-y-8">
				<ContentWrapper className="w-full">
					<SectionHeading sticky
									style={{ top: 0 }}>
						<div className="flex justify-between items-center w-full">
							<SectionHeading.Tabs
								tabs={[
									{ name: "Abrechnungsdetails", value: "billingDetails", active: true },
									{ name: "Projekte", value: "projects" },
									{ name: "Kontakte", value: "contacts", active: true },
									{ name: "Zentrale Rechnungsempfänger", value: "invoiceRecipients", active: true },
									{ name: "Quotas", value: "quotas" },
								]}
								onChange={setActiveTabSection}
							/>
							{"projects" === activeTabSection && (
								<div>
									<Button size="sm"
											onClick={() => {
											}}>
										<PlusIcon className="h-5 w-5"
												  aria-hidden="true" />
										Neues Projekt
									</Button>
								</div>
							)}
						</div>
					</SectionHeading>

					<div className="mt-4">
						{"billingDetails" === activeTabSection &&
							<ClientsBillingDetails
								clientData={clientData}
							/>}
						{"invoiceRecipients" === activeTabSection &&
							<ClientsInvoiceRecipientsList
								clientId={clientData.id}
								clientDisplayName={clientData.displayName}
								invoiceRecipients={invoiceRecipients}
							/>}
						{"contacts" === activeTabSection && contacts && (
							<ClientContactsList
								contacts={contacts}
								displayName={clientData.displayName}
								onAddContactClick={() => setShowNewContactForm(true)}
							/>
						)}

						{"projects" === activeTabSection && projects && (
							<ClientsProjectsList
								contacts={contacts}
								projects={projects}
								workingStatuses={workingStatuses}
								onAddProjectClick={() => setShowAddProjectForm(true)}
							/>
						)}

						{"quotas" === activeTabSection && projects && <Hint>Coming soon...</Hint>}
					</div>
				</ContentWrapper>

				{showNewContactForm && (
					<AddContactPersonToClientSidebar
						isOpen={true}
						setOpen={setShowNewContactForm}
						clientId={clientData.id}
						companyId={clientData.companyId}
					/>
				)}


				<UpdateClientSidebar isOpen={showEditForm}
									 setOpen={setShowEditForm}
									 clientData={clientData} />


				<AddProjectToClientSidebar isOpen={showAddProjectForm}
										   setOpen={setShowAddProjectForm}
										   clientId={clientData.id} />

				<DeleteClientModal clientId={clientData.id}
								   isOpen={showDeletionModal}
								   onClose={() => setShowDeletionModal(false)} />
			</div>
		</>
	);
};

export default ClientDetailsView;
