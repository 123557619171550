import { useMemo } from "react";
import { getSelectBoxDefaultOptionsData, mapDataToSelectOptionsData } from "~/utils/form/formUtils.ts";
import { FormInputOption } from "~/types/form.ts";
import { useAllCareerLevels } from "~/modules/user/api/careerLevel/careerLevelQueries.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";


const useCareerLevelOptions = (includeCtaOption: boolean = false): FormInputOption[] => {
	const { data: careerLevelData } = useAllCareerLevels();
	return useMemo(() => {
		if (careerLevelData) {
			const careerLevelDataSorted = [...careerLevelData].sort(byObjectProperty("displayName"));
			const options = mapDataToSelectOptionsData(careerLevelDataSorted, "id", "displayName");

			if (includeCtaOption) {
				return [getSelectBoxDefaultOptionsData(), ...options];
			}
			return options;
		}
		return [];
	}, [careerLevelData, includeCtaOption]);
};

export default useCareerLevelOptions;

