import { getAllCurrencies } from "~/modules/country/api/currency/currencyApiDispatchers.ts";
import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { QueriesOptions, useQuery } from "react-query";

const ENTITY_QUERY_KEY = "currency";
export const ALL_CURRENCIES_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useAllCurrencies = (options?: QueriesOptions<any>) => useQuery(
	ALL_CURRENCIES_QUERY_KEY,
	() => getAllCurrencies().then(getDataFromResponse),
	options,
);