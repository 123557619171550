import React, { useCallback } from "react";
import { useQueryClient } from "react-query";
import Sidebar from "~/components/Sidebar";
import {
	USERS_VACATION_LEDGER_ENTRIES_QUERY_KEY,
	USERS_VACATION_LEDGER_REPORT_QUERY_KEY,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import UpdateVacationLedgerEntryForm from "~/modules/absence/components/UpdateVacationLedgerEntryForm";

type CreateVacationLedgerEntrySidebarProps = {
	isOpen: boolean;
	onClose: () => void;
	vacationLedgerEntryData?: VacationLedgerEntryType|null,
};

const UpdateVacationLedgerEntrySidebar: React.FunctionComponent<CreateVacationLedgerEntrySidebarProps> = ({
	isOpen,
	onClose,
	vacationLedgerEntryData,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(
		async () => {
			if (vacationLedgerEntryData) {
				await queryClient.invalidateQueries(USERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(vacationLedgerEntryData.userId));
				await queryClient.invalidateQueries(USERS_VACATION_LEDGER_REPORT_QUERY_KEY(vacationLedgerEntryData.userId));
				onClose();
			}
		},
		[queryClient, vacationLedgerEntryData],
	);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={onClose}>
			{vacationLedgerEntryData && <UpdateVacationLedgerEntryForm
				onSuccess={handleSuccess}
				onCancel={onClose}
				vacationLedgerEntryData={vacationLedgerEntryData}
			/>}
		</Sidebar>
	);
};

export default UpdateVacationLedgerEntrySidebar;
