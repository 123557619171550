export enum GenderIds {
	Male = "male",
	Female = "female",
	Diverse = "divers",
}

/*export enum PermissionIds {
	CanManageAbsences = "manage-absences",
	CanManageProjects = "manage-projects",
	CanManageInvoices = "manage-invoices",
	CanManageUsers = "manage-users",
	CanManageTimeTrackings = "manage-time-trackings",
}

export enum RoleIds {
	Admin = "admin",
	ProjectManager = "project-manager",
	InvoiceManager = "invoice-manager",
	TeamMember = "team-member",
	HrManager = "hr-manager",
}*/

export enum EmploymentStatusId {
	Employed = "1",
	Freelancer = "2",
	Intern = "3",
	Student = "4",
	Left = "5",

}

export enum WorkingStatusId {
	Quotation = "1",
	Active = "2",
	Hold = "3",
	Closed = "4",
	Cancelled = "5",
}

export enum AbsenceTypeId {
	Vacation = "1",
	Sick = "2",
	Holiday = "3",
	Training = "4",
	Sabbatical = "5",
	MaternityLeave = "6",
	ParentalLeave = "7",
	InternalEvent = "8",
	Special = "9",
}

export enum TimeTrackingTypeId {
	Project = "1",
	Internal = "2",
	Sales = "3",
}
