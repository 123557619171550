import { appRoutes, generateFullPath } from "~/constants/appRoute.ts";
import { useEffect, useMemo, useRef, useState } from "react";

import BreadcrumbsPortal from "~/components/Breadcrumbs";
import ButtonNewItem from "~/components/buttons/ButtonNewItem";
import { ClientType } from "~/modules/client/api/client/clientTypes.ts";
import { ClientContactPersonType } from "~/modules/client/api/clientContactPerson/clientContactPersonTypes.ts";
import CompaniesListHead from "~/modules/client/components/CompaniesListHead/CompaniesListHead.tsx";
import { CompanyType } from "~/modules/client/api/company/companyTypes.ts";
import ContactCard from "~/modules/client/components/contact/components/ContactCard";
import ContentWrapper from "~/components/ContentWrapper";
import CreateClientContactPersonSidebar from "~/modules/client/components/contact/components/CreateClientContactPersonSidebar";
import NoEntriesFound from "~/components/NoEntriesFound";
import PageHeading from "~/components/headings/PageHeading";
import SearchInput from "~/components/form/SearchInput";
import SectionHeading from "~/components/headings/SectionHeading";
import { byObjectProperty } from "~/utils/sortFunctions.ts";
import { isMobileOrTablet } from "~/utils/mobileAndTabletDetection.ts";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
	companies?: CompanyType[];
	clients?: ClientType[];
	contacts?: ClientContactPersonType[];
}

const ContactsIndexView: React.FC<Props> = ({ companies, clients, contacts }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [newContactCompanyId, setNewContactCompanyId] = useState<string | null>(null);
	const [search, setSearch] = useState("");

	const searchInputRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		if (searchInputRef.current && !isMobileOrTablet()) {
			searchInputRef.current.focus();
		}
	}, []);

	const filteredContacts = useMemo(() => {
		if (contacts) {
			return contacts.filter((contact) => {
				return contact.fullName.toLowerCase().includes(search.trim().toLowerCase());
			});
		}
		return [];
	}, [search, contacts]);

	return (
		<>
			<BreadcrumbsPortal pages={[appRoutes.contacts()]}
							   className="bg-white" />
			<PageHeading title={t("contacts.title", "Alle Kontakte")} />

			<div className="border-b bg-white py-4 sticky top-0 z-10">
				<ContentWrapper>
					<div className="w-48">
						<SearchInput
							placeholder="Name..."
							value={search}
							onChange={setSearch}
							ref={searchInputRef} />
					</div>
				</ContentWrapper>
			</div>

			<ContentWrapper>
				<SectionHeading sticky
								style={{ top: 73 }}>
					<div className="flex justify-between items-center">
						<SectionHeading.Tabs
							tabs={[
								{ name: "Kunden", value: "clients" },
								{ name: "Kontakte", value: "contacts", active: true },
							]}
							onChange={(tab) => {
								"clients" === tab && navigate(appRoutes.clients().path, { replace: true });
								"contacts" === tab && navigate(appRoutes.contacts().path, { replace: true });
							}}
						/>
					</div>
				</SectionHeading>

				<div className="mt-8 flex flex-col gap-10">
					{0 === filteredContacts.length ? (
						<NoEntriesFound />
					) : (
						companies &&
						clients &&
						companies
							.sort(byObjectProperty("displayName"))
							.map(({ id: companyId, displayName: companyDisplayName }) => {
								const companyClients = clients.filter((clients) => clients.companyId === companyId);
								const clientIds = companyClients.map((client) => client.id);
								const companiesContactPersons = filteredContacts.filter((clientContactPerson) =>
									clientIds.includes(clientContactPerson.clientId),
								);
								if (companiesContactPersons.length === 0) return null;
								return (
									<div key={companyId}>
										<CompaniesListHead displayName={companyDisplayName} />

										<div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 my-4">
											{companiesContactPersons
												.sort((a, b) => a.lastName.localeCompare(b.lastName))
												.map(
													({
														 id,
														 clientId,
														 firstName,
														 lastName,
														 jobTitle,
														 title,
														 phone,
														 emailBusiness,
													 }) => {
														const clientDetails = clients.find(
															(client) => clientId === client.id,
														);
														const clientDisplayName =
															undefined === clientDetails
																? ""
																: clientDetails.displayName;
														return (
															<ContactCard
																key={`client-${id}`}
																clientDisplayName={clientDisplayName}
																firstName={firstName}
																lastName={lastName}
																title={title}
																description={jobTitle}
																phone={phone}
																emailBusiness={emailBusiness}
																href={generateFullPath(appRoutes.contact().path, {
																	":contactId": id,
																})}
															/>
														);
													},
												)}
											{0 === search.length && (
												<ButtonNewItem
													size="sm"
													onClick={() => setNewContactCompanyId(companyId)}
												/>
											)}
										</div>
									</div>
								);
							})
					)}
				</div>
			</ContentWrapper>
			{newContactCompanyId && (
				<CreateClientContactPersonSidebar
					isOpen={true}
					setOpen={() => setNewContactCompanyId(null)}
					companyId={newContactCompanyId}
				/>
			)}
		</>
	);
};

export default ContactsIndexView;
