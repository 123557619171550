import Button from "~/components/form/Button";
import LoadingSpinner from "~/components/LoadingSpinner";
import clsx from "clsx";

interface Props {
	busy?: boolean;
	disabled?: boolean;
	formName?: string;
}

const SubmitButton: React.FC<React.PropsWithChildren<Props>> = ({ children, busy, disabled, formName, ...rest }) => (
	<Button
		type="submit"
		disabled={disabled}
		form={formName}
		{...rest}
		className={clsx("flex gap-1", { "opacity-50": disabled })}
		theme="secondary"
	>
		{children}
		{busy && <LoadingSpinner scale={0.7} />}
	</Button>
);

export default SubmitButton;
