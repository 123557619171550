import { XMarkIcon } from "@heroicons/react/20/solid";
import Button from "~/components/form/Button";
import { TimeTrackingExtendedType } from "~/modules/timeTracking/api/timeTracking/timeTrackingTypes.ts";
import { TimeTrackingTypeId } from "~/types/entityIds.ts";

interface DetailsProps {
	data: TimeTrackingExtendedType;
	monthIsClosed: boolean;
	onClickDelete: () => void;
	onClickEdit: () => void;
	onClickClose: () => void;
}

const Details: React.FC<DetailsProps> = ({
	data: {
		projectTitle,
		projectPhaseTitle,
		orderTitle,
		locationName,
		text,
		timeTrackingTypeId,
	},
	monthIsClosed,
	onClickDelete,
	onClickEdit,
	onClickClose,
}) => {
	const title = () => {
		if (timeTrackingTypeId === TimeTrackingTypeId.Project) {
			return projectTitle;
		}

		if (timeTrackingTypeId === TimeTrackingTypeId.Internal) {
			return "Intern";
		}

		if (timeTrackingTypeId === TimeTrackingTypeId.Project) {
			return "Sales";
		}
	};
	const isProject = timeTrackingTypeId === TimeTrackingTypeId.Project;
	return (
		<div className="w-full">
			<div className="flex justify-between">
				<div className="">
					{title()}
				</div>
				<div className="flex items-center">
					{!monthIsClosed && <><Button theme="accent"
													onClick={onClickEdit}
													size="sm">
						bearbeiten
					</Button>
						<Button className="ml-2"
								theme="danger"
								onClick={onClickDelete}
								size="sm">
							löschen
						</Button>
					</>}
					<Button size="sm"
							theme="none"
							onClick={onClickClose}>
						<XMarkIcon className="w-5 h-5 text-white -mr-2" />
					</Button>
				</div>
			</div>
			<dl className="divide-y divide-gray-600 text-sm leading-6 whitespace-pre-line min-w-full">
				{isProject &&
					<div className="px-0 py-2 opacity-60">
						{`${projectPhaseTitle} `}
						<span className="opacity-60">
							| PO:
						</span>
						{` ${orderTitle} `}
						<span className="opacity-60">
							| Ort:
						</span>
						{` ${locationName}`}
					</div>}
				<div className="px-0 flex flex-row item-top pt-2 ">
					<dt className="text-sm mr-2">Buchungstext:</dt>
					<dd className="text-sm whitespace-pre-line">{text || "-"}</dd>
				</div>
			</dl>
		</div>
	);
};

export default Details;
