export const getAllUsersEndpoint = 'users';

export const resetPasswordEndpoint = "users/password";

export const createUserEndpoint = "users";

export function updateUserEndpoint(userId: string): string {
	return `users/${userId}`;
}

export function sendUserInvitationEndpoint(userId: string): string {
	return `users/${userId}/invitation`;
}

export function updateUserStatusEndpoint(userId: string): string {
	return `users/${userId}/status`;
}

