import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { appRoutes } from "~/constants/appRoute.ts";
import { useAuth } from "~/contexts/AuthContext";
import { useCommonData } from "~/hooks/useCommonData/useCommonData";
import UserRouter from "~/modules/user/router/UserRouter";
import AdminUsersPage from "~/pages/admin/users/AdminUsersPage.tsx";
import AppLayout from "~/pages/AppLayout";
import MonthlyBillingReportPage from "~/pages/billing/MonthlyInvoicingPage";
import ClientDetailsPage from "~/pages/client/ClientDetailsPage.tsx";
import ClientsIndexPage from "~/pages/client/ClientsIndexPage.tsx";
import ContactDetailsPage from "~/pages/contact/ContactDetailsPage.tsx";
import ContactsIndexPage from "~/pages/contact/ContactsIndexPage.tsx";
import Error404Page from "~/pages/Error404Page.tsx";
import LandingPage from "~/pages/landingPage";
import LoginPage from "~/pages/login/LoginPage.tsx";
import LogoutPage from "~/pages/logout/LogoutPage";
import ProtectedRoutes from "~/pages/mainRouter/ProtectedRoutes/ProtectedRoutes";
import ProjectDetailsPage from "~/pages/project/ProjectDetailsPage.tsx";
import ProjectsIndexPage from "~/pages/project/ProjectsIndexPage.tsx";
import RootPage from "~/pages/RootPage.tsx";
import RequestPasswordReset from "~/pages/users/RequestPasswordReset";
import ResetPassword from "~/pages/users/ResetPassword";
import { setIntendedLocation } from "~/services/intendedLocation";

type MainRouterProps = {};

const MainRouter: React.FC<MainRouterProps> = ({}) => {
	const { user, isCheckingAuthStatus } = useAuth();
	const location = useLocation();

	useCommonData(!!user);
	useEffect(() => {
		if (!isCheckingAuthStatus && !user && ![appRoutes.login().path].includes(location.pathname)) {
			setIntendedLocation();
		}
	}, [location.pathname, user, isCheckingAuthStatus]);

	return (
		<>
			{!isCheckingAuthStatus && (
				<Routes>
					<Route element={<AppLayout />}>
						<Route element={<ProtectedRoutes />}>
							<Route path=""
								   element={<LandingPage />} />
							<Route path={appRoutes.clients().path}
								   element={<ClientsIndexPage />} />
							<Route path={appRoutes.client().path}
								   element={<ClientDetailsPage />} />
							<Route path={appRoutes.contacts().path}
								   element={<ContactsIndexPage />} />
							<Route path={appRoutes.contact().path}
								   element={<ContactDetailsPage />} />
							<Route path={appRoutes.monthlyBillingReport().path}
								   element={<MonthlyBillingReportPage />} />
							<Route path={appRoutes.projects().path}
								   element={<ProjectsIndexPage />} />
							<Route path={appRoutes.project().path}
								   element={<ProjectDetailsPage />} />
							<Route path={appRoutes.logout().path}
								   element={<LogoutPage />} />
							<Route path={appRoutes.user().path + "/*"}
								   element={<UserRouter />} />
							<Route path={appRoutes.users().path}
								   element={<AdminUsersPage />} />
							<Route path="*"
								   element={<Error404Page />} />
						</Route>
					</Route>
					<Route element={<RootPage />}>
						<Route path={appRoutes.passwordReset().path}
							   element={<ResetPassword />} />
						<Route path={appRoutes.passwordResetRequest().path}
							   element={<RequestPasswordReset />} />
						<Route path={appRoutes.login().path}
							   element={<LoginPage />} />
						<Route path={"*"}
							   element={<Navigate to={appRoutes.login().path} />} />
					</Route>
				</Routes>
			)}
		</>
	);

};

export default MainRouter;
