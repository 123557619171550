import { QueriesOptions, useQuery } from "react-query";
import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getWorkingSchedulesMinDate,
	indexUsersWorkingSchedules,
} from "~/modules/absence/api/workingSchedule/workingScheduleApiDispatchers.ts";

export const WORKING_SCHEDULE_BASE_QUERY_KEY = "workingSchedules";

export const USERS_WORKING_SCHEDULES_QUERY_KEY = (userId: string) => [WORKING_SCHEDULE_BASE_QUERY_KEY, {userId}];

export const useUsersWorkingSchedules = ({ userId, options }: {
	userId: string,
	options?: QueriesOptions<any>
}) => useQuery(
	USERS_WORKING_SCHEDULES_QUERY_KEY(userId),
	() => indexUsersWorkingSchedules({ userId }).then(getDataFromResponse),
	options,
);

export const WORKING_SCHEDULES_MIN_DATE_QUERY_KEY = [WORKING_SCHEDULE_BASE_QUERY_KEY, "min-date"];

export const useWorkingSchedulesMinDate= (options?: QueriesOptions<any>) => useQuery(
	WORKING_SCHEDULES_MIN_DATE_QUERY_KEY,
	() => getWorkingSchedulesMinDate().then(getDataFromResponse),
	options,
);
