import { UserIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

interface Props {
	firstName?: string;
	lastName?: string;
	imageUrl?: string;
	active?: boolean;
	width?: string;
	height?: string;
}

const Avatar: React.FC<Props> = ({ active, firstName, lastName, imageUrl, width = "w-10", height = "h-10" }) => {
	const avatarClassName = `${width} ${height} relative flex-shrink-0 rounded-full bg-gray-300 flex justify-center items-center select-none`;

	if (undefined === imageUrl) {
		if (undefined === firstName && undefined === lastName) {
			return (
				<div className={avatarClassName}>
					<UserIcon className={clsx("text-primary-500 w-4/6")} />
				</div>
			);
		}
		return (
			<div className={clsx(avatarClassName, "text-m font-bold text-white")}>
				{firstName && firstName.substring(0, 1)}
				{lastName && lastName.substring(0, 1)}
				{active !== undefined &&
					<div className={clsx("absolute top-0 right-0 w-2.5 h-2.5 ring-1 ring-white bg-green-500 rounded-full", active ? "bg-success-500" : "bg-red-400")} />}
			</div>
		);
	}
	return <img className={avatarClassName}
				src={imageUrl}
				alt="" />;
};

export default Avatar;
