import { Control, FieldValues } from "react-hook-form";

import FormSection from "~/components/form/FormSection";
import Input from "~/components/form/Input";

type OrderTitleNumberFormSectionProps<T extends FieldValues> = {
	control: Control<T>;
};

type FormDataType = {
	title: string;
	orderNumber: string | null;
};

const OrderTitleNumberFormSection = <T extends FormDataType>({
	control,
}: OrderTitleNumberFormSectionProps<T>) => {
	return (
		<FormSection title="Bestellung">
			<div className="grid gap-x-6 gap-y-2 grid-cols-6">
				<div className="col-span-6">
					<Input
						name="title"
						control={control}
						label="Bezeichung"
					/>
				</div>
				<div className="col-span-6">
					<Input
						label="Bestellnummer"
						name="orderNumber"
						control={control}
					/>
				</div>
			</div>
		</FormSection>
	);
};

export default OrderTitleNumberFormSection;
