import { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi";

import {
	CreateTimeTrackingDataType,
	GetUsersTimeTrackingsFilterType,
	TimeTrackingExtendedType,
	TimeTrackingType,
	UpdateTimeTrackingType,
} from "~/modules/timeTracking/api/timeTracking/timeTrackingTypes.ts";

export function getUsersTimeTrackings(
	userId: string,
	filter?: GetUsersTimeTrackingsFilterType,
): AxiosApiResponsePromise<TimeTrackingExtendedType[]> {
	return axiosApi.get(`users/${userId}/time-trackings`, { params: filter });
}

// returns all working days (holidays and absences are considered) of a month that the user has not reached the necessary amount of working hours for
export function getUsersUntrackedDaysByMonth(userId: string, startOfMonth: string): AxiosApiResponsePromise<string[]> {
	return axiosApi.get(`users/${userId}/time-trackings/${startOfMonth}/untracked-days`);
}

export const getTimeTracking = (userId: any, timeTrackingId: string): AxiosApiResponsePromise<TimeTrackingType> =>
	axiosApi.get(`users/${userId}/time-trackings/${timeTrackingId}`);

export const createTimeTracking = (
	userId: string,
	data: CreateTimeTrackingDataType,
): AxiosApiResponsePromise<TimeTrackingType> => axiosApi.post(`users/${userId}/time-trackings`, { ...data });


export function updateTimeTracking({ userId, timeTrackingId, data }: {
	userId: string,
	timeTrackingId: string,
	data: UpdateTimeTrackingType,
}): AxiosApiResponsePromise<TimeTrackingType> {
	return axiosApi.put(`users/${userId}/time-trackings/${timeTrackingId}`, { ...data });
}

export const deleteTimeTracking = ({ userId, timeTrackingId }: {
	userId: any,
	timeTrackingId: string
}): AxiosApiResponsePromise<void> =>
	axiosApi.delete(`users/${userId}/time-trackings/${timeTrackingId}`);
