import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import DeleteEntityView, { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import Label from "~/components/form/Label";
import {
	deleteVacationLedgerEntry,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryApiDispatchers.ts";
import {
	USERS_VACATION_LEDGER_ENTRIES_QUERY_KEY,
	USERS_VACATION_LEDGER_REPORT_QUERY_KEY,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import { VacationLedgerEntrySummaryType } from "~/modules/absence/components/VacationLedgerView/VacationLedgerView.tsx";
import { formatVacationDays } from "~/modules/absence/utils/vacationLedgerFormUtils.ts";

type DeleteVacationLedgerEntryModalProps = {
	vacationLedgerEntryData: VacationLedgerEntrySummaryType | null;
	isOpen: boolean;
	onCloseClick: () => void;
};

const DeleteVacationLedgerEntryModal: React.FunctionComponent<DeleteVacationLedgerEntryModalProps> = ({
	vacationLedgerEntryData,
	onCloseClick,
	isOpen,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();

	useEffect(() => {
		if (isOpen) {
			setError("");
		}
	}, [isOpen]);

	const handleConfirmationClick = async () => {
		if (!vacationLedgerEntryData) return;
		try {
			setBusy(true);
			await deleteVacationLedgerEntry({
				userId: vacationLedgerEntryData.userId,
				vacationLedgerEntryId: vacationLedgerEntryData.id,
			});
			await queryClient.invalidateQueries(USERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(vacationLedgerEntryData.userId));
			await queryClient.invalidateQueries(USERS_VACATION_LEDGER_REPORT_QUERY_KEY(vacationLedgerEntryData.userId));
			onCloseClick();
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.vacationLedgerEntryId?.find(
						(validationError) => validationError.rule === "vacationLedgerEntryIsDeletableOrUpdatableRule",
					)
				) {
					setError("Der Eintrag kann nicht gelöscht werden.");
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Urlaubskontoeintrag löschen`,
		bodyText: `Möchtest Du diesen Eintrag wirklich löschen?`,
		deleteButtonText: `Eintrag löschen`,
	};

	return (
		<DeleteEntityView
			error={error}
			texts={texts}
			isOpen={isOpen}
			onCloseClick={onCloseClick}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		>
			{!!vacationLedgerEntryData && <div className="flex flex-col gap-y-2 mb-4">
				<div>
					<Label>Typ</Label>
					<span className="font-bold text-md">{vacationLedgerEntryData.type}</span>
				</div>
				<div>
					<Label>Kommentar</Label>
					<span className="font-bold text-md">{vacationLedgerEntryData.comment || "k.A."}</span>
				</div>
				<div>
					<Label>Gültig ab</Label>
					<span className="font-bold text-md">{vacationLedgerEntryData.validFrom}</span>
				</div>
				<div>
					<Label>Urlaubstage</Label>
					<span className={clsx("font-bold text-md", vacationLedgerEntryData.vacationDays.isNegative() ? "text-red-500" : "text-green-500")}>{formatVacationDays(vacationLedgerEntryData.vacationDays)}</span>
				</div>
			</div>}

		</DeleteEntityView>
	);
};

export default DeleteVacationLedgerEntryModal;
