import { t } from "i18next";
import { DEFAULT_PAYMENT_TARGET_DAYS_OPTIONS, PAYMENT_TARGET_DAYS_DEFAULT_VALUE } from "~/constants/form.ts";
import { FormInputOption } from "~/types/form.ts";

// removes all non-alphanumeric characters and converts to lowercase and remove all spaces
export const sanitizeStringForComparison = (string: string): string => {
	return string.toLowerCase().replace(/[^a-z0-9]/g, "");
};

export const sanitizeEmailForComparison = (email: string): string => {
	return email.toLowerCase().replace(/[^a-z0-9@.]/g, "");
};


export const mapDataToSelectOptionsData = <T>(data: T[],
	valuePropName: keyof T,
	labelPropName: keyof T,
	includeDefaultOption?: boolean): FormInputOption[] => {
	let selectOptions = data
		.filter(item => typeof item[valuePropName] === "string" && typeof item[labelPropName] === "string")
		.map(item => createSelectBoxOptionData(item[labelPropName] as string, item[valuePropName] as string));

	if (includeDefaultOption) {
		selectOptions = [getSelectBoxDefaultOptionsData(), ...selectOptions];
	}

	return selectOptions;
};

export const createSelectBoxOptionData = (displayName: string, value: string): FormInputOption => {
	return {
		label: displayName,
		value,
	};
};

export const getSelectBoxDefaultOptionsData = (): FormInputOption => {
	return createSelectBoxOptionData(t("form:select.defaultOption"), "");
};

export const preventSubmitOnEnter = (event: React.KeyboardEvent<HTMLFormElement>) => {
	if (event.key === "Enter") {
		event.preventDefault();
	}
};

export const getPaymentTargetDefaultValues = (paymentTargetDays: number | null): {
	paymentTargetDays: number,
	paymentTargetDaysCustom: number | null
} => {
	paymentTargetDays = paymentTargetDays || PAYMENT_TARGET_DAYS_DEFAULT_VALUE;
	let paymentTargetDaysCustom = null;
	if (!DEFAULT_PAYMENT_TARGET_DAYS_OPTIONS.includes(paymentTargetDays)) {
		paymentTargetDays = 0;
		paymentTargetDaysCustom = paymentTargetDays;
	}

	return {
		paymentTargetDays,
		paymentTargetDaysCustom,
	};
};

export function transformEmptyDateValueToNull(value: Date | string, originalValue: Date | string | null) {
	return originalValue === "" ? null : value;
}


