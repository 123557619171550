import { Control, FieldValues } from "react-hook-form";
import CheckButton from "~/components/form/CheckButton";
import DatePickerInput from "~/components/form/DatePickerInput";
import FormInputError from "~/components/form/FormInputError";
import FormSection, { FormSectionGroup } from "~/components/form/FormSection";
import {
	WorkingScheduleFormData,
} from "~/modules/absence/components/CreateWorkingScheduleForm/CreateWorkingScheduleForm.tsx";

type WorkingScheduleFormSectionProps<T extends FieldValues> = {
	control: Control<T>;
	minDate: Date;
	noWorkdaySelectedErrorMessage: string|null
};

const WorkingScheduleFormSection = <T extends WorkingScheduleFormData>({
	control,
	minDate,
	noWorkdaySelectedErrorMessage
}: WorkingScheduleFormSectionProps<T>) => {
	return <FormSectionGroup>
		<FormSection title="Arbeitstage">
			<div className="flex flex-col gap-2 mt-2">
				<CheckButton
					control={control}
					name={`monday`}
				>
					Montag
				</CheckButton>
				<CheckButton
					control={control}
					name={`tuesday`}
				>
					Dienstag
				</CheckButton>
				<CheckButton
					control={control}
					name={`wednesday`}
				>
					Mittwoch
				</CheckButton>
				<CheckButton
					control={control}
					name={`thursday`}
				>
					Donnerstag
				</CheckButton>
				<CheckButton
					control={control}
					name={`friday`}
				>
					Freitag
				</CheckButton>
			</div>
			{noWorkdaySelectedErrorMessage && <div className="pl-2"><FormInputError>{noWorkdaySelectedErrorMessage}</FormInputError></div>}
		</FormSection>

		<FormSection title="Gültig ab">
			<div className="grid gap-x-6 gap-y-2 grid-cols-6">
				<div className="col-span-3">
					<DatePickerInput
						minDate={minDate}
						name="validFrom"
						control={control}
					/>
				</div>
				<p className="mt-3 text-sm leading-6 col-span-6 text-gray-600">Das Gültigkeitsdatum bestimmt
					den
					Zeitpunkt, von dem an der Eintrag in die Berechnung des verbleibenden Urlaubsanspruchs
					einbezogen wird.</p>
			</div>
		</FormSection>
		<FormSection title="Hinweis">
			<p className="text-sm leading-6 col-span-6 text-gray-600">Sobald ein neues Arbeitszeitmodell für einen
				Mitarbeiter erstellt oder bearbeitet wird, findet eine Neuberechnung der Urlaubstage für den
				betreffenden Zeitraum statt (i.e. Das Start- oder Enddatum liegt an oder nach dem Gültigkeitsdatum).</p>
		</FormSection>
	</FormSectionGroup>;
};

export default WorkingScheduleFormSection;