import { PropsWithChildren } from "react";
import Tag from "~/components/Tag";

interface CommentProps {}

const Comment: React.FC<PropsWithChildren<CommentProps>> = ({ children }) => (
	<div className="flex flex-col gap-2 justify-start items-start bg-white p-2 rounded-md text-sm">{children}</div>
);
const Group: React.FC<PropsWithChildren<CommentProps>> = ({ children }) => (
	<div className="flex flex-col gap-4 my-4">{children}</div>
);
const Type: React.FC<PropsWithChildren<CommentProps>> = ({ children }) => <Tag>{children}</Tag>;

export default Object.assign(Comment, { Type, Group });
