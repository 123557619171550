import { Control, FieldErrors, FieldValues, Path } from "react-hook-form";
import CurrencyInput from "~/components/form/CurrencyInput";
import FormSection, { FormSectionProps } from "~/components/form/FormSection";

import Input from "~/components/form/Input";

type BudgetFormSectionProps<T extends FieldValues> = FormSectionProps & {
	control: Control<T>;
	errors: FieldErrors<T>;
	placeholder?: string;
};

type BudgetFormSectionType = {
	manDaysPlanned: number;
	budgetCentsPlanned: number;
};

const BudgetFormSection = <T extends BudgetFormSectionType>({
	control,
	errors,
	title,
}: BudgetFormSectionProps<T>) => {
	return (
		<FormSection title={title}>
			<div className="grid gap-x-6 gap-y-2 grid-cols-6">
				<div className="col-span-3">
					<Input
						name="manDaysPlanned"
						control={control}
						label="Personentage"
						type={"number"}
					/>
				</div>
				<div className="col-span-3">
					<CurrencyInput
						control={control}
						name={"budgetCentsPlanned" as Path<T>}
						label="Budget (EUR)"
						error={errors.budgetCentsPlanned?.message as string}
					/>
				</div>
			</div>
		</FormSection>
	);
};

export default BudgetFormSection;
