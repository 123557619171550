import Decimal from "decimal.js-light";
import { Control, FieldValues, UseFormWatch } from "react-hook-form";
import DatePickerInput from "~/components/form/DatePickerInput";
import FormInputError from "~/components/form/FormInputError";
import FormSection, { FormSectionGroup } from "~/components/form/FormSection";
import CommentFormSection from "~/components/form/formSections/CommentFormSection";
import Select from "~/components/form/Select";
import Switch from "~/components/form/Switch";
import VacationLedgerFormSection from "~/modules/absence/components/formSections/VacationLedgerFormSection";
import { FormInputOption } from "~/types/form.ts";

type CreateOrUpdateAbsenceFormDataType = {
	absenceTypeId: string;
	comment: string | null;
	endDate: Date;
	firstDayIsHalf: boolean;
	lastDayIsHalf: boolean;
	startDate: Date;
}

type CreateOrUpdateAbsenceFormSectionProps<T extends FieldValues> = {
	absenceTypeSelectOptions: FormInputOption[];
	control: Control<T>;
	overlapError: boolean;
	remainingVacationDays: Decimal;
	watch: UseFormWatch<T>
	workingSchedules: WorkingScheduleType[];
};

const CreateOrUpdateAbsenceFormSection = <T extends CreateOrUpdateAbsenceFormDataType>({
	absenceTypeSelectOptions,
	control,
	overlapError,
	remainingVacationDays,
	watch,
	workingSchedules,
}: CreateOrUpdateAbsenceFormSectionProps<T>) => {
	return <FormSectionGroup>
		<FormSection title="Abwesenheitsart">
			<div className="grid gap-x-6 gap-y-2 grid-cols-6">
				<div className="col-span-6">
					<Select
						name="absenceTypeId"
						optionsData={absenceTypeSelectOptions}
						control={control}
					/>
				</div>
			</div>
		</FormSection>

		<FormSection title="Zeitraum">
			<div className="grid gap-x-6 gap-y-2 grid-cols-6">
				<div className="col-span-3">
					<DatePickerInput
						name="startDate"
						label="Startdatum"
						control={control}
					/>
				</div>
				<div className="col-span-3">
					<DatePickerInput
						position="bottom-end"
						name="endDate"
						label="Enddatum"
						control={control}
					/>
				</div>
				<div className="col-span-3 mt-1">
					<Switch
						label={"Halber erster Tag"}
						name={"firstDayIsHalf"}
						control={control} />
				</div>
				<div className="col-span-3 mt-1">
					<Switch
						label={"Halber letzter Tag"}
						name={"lastDayIsHalf"}
						control={control} />
				</div>
				{overlapError && <div className="col-span-6">
					<FormInputError>
						{"Es existiert bereits eine Abwesenheit in diesem Zeitraum."}
					</FormInputError>
				</div>}
			</div>

		</FormSection>
		<VacationLedgerFormSection
			watch={watch}
			initialRemainingVacationDays={remainingVacationDays}
			workingSchedules={workingSchedules}
		/>
		<CommentFormSection
			control={control}
			title="Kommentar"
			placeholder={"Hier biite den Grund für die Abwesenheit angeben, falls dieser sich nicht aus der Abwesenheitsart ergibt."}
		/>
	</FormSectionGroup>;
};

export default CreateOrUpdateAbsenceFormSection;