import { QueriesOptions, useQuery } from "react-query";
import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { indexHolidays, indexHolidaysByYear } from "~/modules/absence/holiday/holidayApiDispatchers.ts";

export const HOLIDAYS_DEFAULT_QUERY_KEY = "holidays";

export const HOLIDAYS_BY_YEAR_QUERY_KEY = (year: string) => [HOLIDAYS_DEFAULT_QUERY_KEY, year];
export const ALL_HOLIDAYS_QUERY_KEY = [HOLIDAYS_DEFAULT_QUERY_KEY, "all"];
export const useHolidaysByYear = ({ year, options }: { year: string, options?: QueriesOptions<any> }) => useQuery(
	HOLIDAYS_BY_YEAR_QUERY_KEY(year),
	() => indexHolidaysByYear({ year }).then(getDataFromResponse),
	options,
);

export const useAllHolidays = (options?: QueriesOptions<any>) => useQuery(
	ALL_HOLIDAYS_QUERY_KEY,
	() => indexHolidays().then(getDataFromResponse),
	options,
);