import { Control, FieldErrors, FieldValues } from "react-hook-form";
import CurrencyInput from "~/components/form/CurrencyInput";
import DatePickerInput from "~/components/form/DatePickerInput";
import FormSection, { FormSectionGroup } from "~/components/form/FormSection";
import CommentFormSection from "~/components/form/formSections/CommentFormSection";
import Select from "~/components/form/Select";
import { FormInputOption } from "~/types/form.ts";

type VacationLedgerEntryFormSectionProps<T extends FieldValues> = {
	entryTypeSelectOptions: FormInputOption[];
	control: Control<T>;
	errors: FieldErrors<T>;
};

const VacationLedgerEntryFormSection = <T extends VacationLedgerFormType>({
	control,
	entryTypeSelectOptions,
}: VacationLedgerEntryFormSectionProps<T>) => {
	return <FormSectionGroup>
		<FormSection title="Art des Eintrags">
			<div className="grid gap-x-6 gap-y-2 grid-cols-6">
				<div className="col-span-6">
					<Select
						name="entryTypeId"
						optionsData={entryTypeSelectOptions}
						control={control}
					/>
				</div>
			</div>
		</FormSection>

		<FormSection title="Wert & Gültigkeit">
			<div className="grid gap-x-6 gap-y-2 grid-cols-6">
				<div className="col-span-3">
					<CurrencyInput
						label="Wert"
						name={"workDays"}
						control={control}
					/>
				</div>
				<div className="col-span-3">
					<DatePickerInput
						label="Gültig ab"
						name="validFrom"
						control={control}
					/>
				</div>

				<p className="mt-3 text-sm leading-6 col-span-6 text-gray-600">Das Gültigkeitsdatum bestimmt
					den
					Zeitpunkt, von dem an der Eintrag in die Berechnung des verbleibenden Urlaubsanspruchs
					einbezogen wird.</p>
			</div>
		</FormSection>
		<CommentFormSection
			control={control}
			title="Grund"
			placeholder={"Hier bitte den Grund für den Eintrag angeben."}
		/>
	</FormSectionGroup>;
};

export default VacationLedgerEntryFormSection;