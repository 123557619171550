import { Control, FieldValues } from "react-hook-form";
import FormSection, { FormSectionProps } from "~/components/form/FormSection";

import Input from "~/components/form/Input";

type TitleFormSectionProps<T extends FieldValues> = FormSectionProps & {
	control: Control<T>;
};

const TitleFormSection = <T extends FieldValues>({ control, title }: TitleFormSectionProps<T>) => {
	return (
		<FormSection title={title}>
			<div className="grid gap-x-6 gap-y-2 grid-cols-6">
				<div className="col-span-6">
					<Input
						name="title"
						control={control}
						placeholder="Titel"
					/>
				</div>
			</div>
		</FormSection>
	);
};

export default TitleFormSection;
