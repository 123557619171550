import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import React from "react";
import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";


type AbsenceTableProps = {
	children?: React.ReactNode;
};

const AbsencesTable: React.FunctionComponent<AbsenceTableProps> = ({ children }) => {
	return (
		<table className="min-w-full border-separate border-spacing-y-2">
			<thead>
			<tr>
				<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
					Typ
				</th>
				<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
					Kommentar
				</th>
				<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
					Zeitraum
				</th>
				<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
					Urlaubstage
				</th>
				<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
					<span className="sr-only">Edit</span>
				</th>
			</tr>
			</thead>
			<tbody>{children}</tbody>
		</table>
	);
};

type AbsenceTableRowProps = {
	absenceType: string;
	isDeletable: boolean;
	isEditable: boolean;
	comment: string | null;
	timespan: string;
	vacationDays: string | null;
	onEditClick: () => void;
	onDeleteClick: () => void;
};

const Row: React.FunctionComponent<AbsenceTableRowProps> = ({
															 absenceType,
															 comment,
															 timespan,
															 vacationDays,
															 isDeletable,
															 isEditable,
															 onEditClick,
															 onDeleteClick,
														 }) => {
	return (
		<tr className="bg-white hover:bg-gray-200">
			<td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm">
				{absenceType}
			</td>
			<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
				{comment || "--"}
			</td>
			<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{timespan}</td>
			<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
				{vacationDays || "--"}
			</td>
			<td className="relative whitespace-nowrap ml-auto pr-3 text-sm text-end">
				{(isEditable || isDeletable) &&
					<ButtonWithPopover
						theme="dark"
						items={[
							{
								label: "Bearbeiten",
								onClick: onEditClick,
								icon: PencilIcon,
							},
							{
								label: "Löschen",
								onClick: onDeleteClick,
								icon: TrashIcon
							},
						]}
					/>
				}
			</td>
		</tr>
	);
};


export default Object.assign(AbsencesTable, { Row });
