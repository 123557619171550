import { Control, FieldErrors, FieldValues } from "react-hook-form";
import FormSection from "~/components/form/FormSection";
import { PaymentTargetDaysInputFormData } from "~/components/form/PaymentTargetInput";
import Select from "~/components/form/Select";
import Switch from "~/components/form/Switch";

import { FormInputOption } from "~/types/form.ts";

type OrderForeignCountryFormSectionProps<T extends FieldValues> = {
	control: Control<T>;
	errors: FieldErrors<T>;
	currencySelectOptions: FormInputOption[];
	showCurrencySelect: boolean;
};

interface OrderForeignCountryFormSectionFormData extends PaymentTargetDaysInputFormData {
	isForeignCountry: boolean;
	currencyId: string | null;
}

const OrderForeignCountryFormSection = <T extends OrderForeignCountryFormSectionFormData>({
	control,
	errors,
	currencySelectOptions,
	showCurrencySelect,
}: OrderForeignCountryFormSectionProps<T>) => {
	return (
		<FormSection title="Rechnungsempfänger">
			<div className="grid gap-x-6 gap-y-2 grid-cols-6">
				<div className="col-span-6">
					<Switch label="Leistung / Rechnung Ausland" name={"isForeignCountry"} control={control} />
				</div>
				{showCurrencySelect && (
					<div className="col-span-6">
						<Select
							control={control}
							label="Währung"
							name="currencyId"
							optionsData={currencySelectOptions}
							error={errors.currencyId?.message as string}
						/>
					</div>
				)}
			</div>
		</FormSection>
	);
};

export default OrderForeignCountryFormSection;
