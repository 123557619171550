import ProjectsIndexView from "modules/project/components/ProjectsIndexView";
import { useAggregatedData } from "~/hooks/useAggregateData";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAllClients } from "~/modules/client/api/client/clientQueries";
import { useAllProjects } from "~/modules/project/api/project/projectQueries";
import { useAllWorkingStatuses } from "~/modules/project/api/workingStatus/workingStatusQueries";
import { useAllUsers } from "~/modules/user/api/user/userQueries";
import LoadingPage from "~/pages/LoadingPage";
import { PermissionNames } from "~/types/entityNames.ts";

interface Props {}

const ProjectsIndexPage: React.FC<Props> = () => {
	const guard = userViewGuard(PermissionNames.CanManageProjects);
	if(guard) return guard;

	const { data, options } = useAggregatedData([
		{ hook: () => useAllProjects(), name: "projects" },
		{ hook: () => useAllClients(), name: "clients" },
		{ hook: () => useAllUsers(), name: "users" },
		{ hook: () => useAllWorkingStatuses(), name: "workingStatuses" },
	]);

	const { projects, clients, users, workingStatuses } = data;

	if (options.loadingPcent < 100) return <LoadingPage pcent={options.loadingPcent} />;

	return <ProjectsIndexView projects={projects} clients={clients} users={users} workingStatuses={workingStatuses} />;
};

export default ProjectsIndexPage;
