import { PROJECT_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import { PROJECTS_PHASES_QUERY_KEY } from "~/modules/project/api/projectPhase/projectPhaseQueries.ts";
import { ProjectPhaseType } from "~/modules/project/api/projectPhase/projectPhaseTypes.ts";
import Sidebar from "~/components/Sidebar";
import UpdateProjectPhaseForm from "~/modules/project/components/forms/UpdateProjectPhaseForm";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

type AddProjectPhaseToProjectSidebarProps = {
	isOpen: boolean;
	onClose: (open: boolean) => void;
	projectId: string;
	projectPhaseData: ProjectPhaseType;
};

const UpdateProjectPhaseSidebar: React.FunctionComponent<AddProjectPhaseToProjectSidebarProps> = ({
	isOpen,
	onClose,
	projectId,
	projectPhaseData,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		queryClient.refetchQueries(PROJECT_QUERY_KEY(projectId));
		await queryClient.refetchQueries(PROJECTS_PHASES_QUERY_KEY(projectId));
		onClose(false);
	}, [queryClient, projectId]);

	return (
		<Sidebar closeOnOutsideClick={false} open={isOpen} setOpen={() => onClose(!isOpen)}>
			<UpdateProjectPhaseForm
				phaseData={projectPhaseData}
				onSuccess={handleSuccess}
				onCancel={() => onClose(false)}
			/>
		</Sidebar>
	);
};

export default UpdateProjectPhaseSidebar;
