import { t } from "i18next";
import React, { useEffect, useMemo, useRef, useState } from "react";

import BreadcrumbsPortal from "~/components/Breadcrumbs";
import ContentWrapper from "~/components/ContentWrapper";
import Button from "~/components/form/Button";
import SearchInput from "~/components/form/SearchInput/SearchInput.tsx";
import PageHeading from "~/components/headings/PageHeading";
import { appRoutes } from "~/constants/appRoute.ts";
import { EmploymentStatus } from "~/modules/user/api/employmentStatus/employmentStatusTypes.ts";
import { Gender } from "~/modules/user/api/gender/genderTypes.ts";
import { UserWithConfidentialInformation } from "~/modules/user/api/user/userTypes.ts";
import CreateUserSidebar from "~/modules/user/components/components/CreateUserSidebar";
import UpdateUserSidebar from "~/modules/user/components/components/UpdateUserSidebar";
import UpdateUserStatusSidebar from "~/modules/user/components/components/UpdateUserStatusSidebar";
import UsersTable from "~/modules/user/components/components/UsersTable";
import UserTableRow from "~/modules/user/components/components/UserTableRow";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import getOneOfCollection from "~/utils/getOneOfCollection.ts";
import { isMobileOrTablet } from "~/utils/mobileAndTabletDetection.ts";

type AdminUsersViewProps = {
	allUsersData?: UserWithConfidentialInformation[];
	allCareerLevelsData?: CareerLevel[];
	allLocationsData?: Location[];
	allEmploymentStatusesData?: EmploymentStatus[];
	allGendersData?: Gender[];
	allPermissionsData?: Permission[];
};

const AdminUsersView: React.FunctionComponent<AdminUsersViewProps> = ({
	allUsersData,
	allCareerLevelsData,
	allLocationsData,
	allEmploymentStatusesData,
	allGendersData,
	allPermissionsData,
}) => {
	const [selectedUserEditId, setSelectedUserEditId] = useState<string | null>(null);
	const [selectedUserUpdateStatusId, setSelectedUserUpdateStatusId] = useState<string | null>(null);
	const [showCreateUserForm, setShowCreateUserForm] = useState(false);
	const [search, setSearch] = useState("");
	const searchInputRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		if (searchInputRef.current && !isMobileOrTablet()) {
			searchInputRef.current.focus();
		}
	}, []);

	const filteredUsers = useMemo(() => {
		if (allUsersData) {
			return allUsersData.filter((user) => user.fullName.toLowerCase().includes(search.trim().toLowerCase()));
		}

		return [];
	}, [search, allUsersData]);

	const usersSorted = useMemo(() => {
		return filteredUsers?.sort((a, b) => {
			return a.lastName.localeCompare(b.lastName);
		});
	}, [filteredUsers]);

	const userDataEdit = useMemo(() => {
		if (allUsersData && selectedUserEditId) {
			return getOneOfCollection(allUsersData, selectedUserEditId);
		}
	}, [allUsersData, selectedUserEditId]);

	const userDataStatusUpdate = useMemo(() => {
		if (allUsersData && selectedUserUpdateStatusId) {
			return getOneOfCollection(allUsersData, selectedUserUpdateStatusId);
		}
	}, [allUsersData, selectedUserUpdateStatusId]);

	const allUserCards = useMemo(() => {
		return usersSorted?.map((user) => {
			const careerLevel = user.careerLevelId
				? getOneOfCollection(allCareerLevelsData, user.careerLevelId, "displayName")
				: "n/a";
			const employmentStatus = user.employmentStatusId
				? getOneOfCollection(allEmploymentStatusesData, user.employmentStatusId, "name")
				: "n/a";
			let companyLocationName = "";

			if (user.locationId) {
				let location = getOneOfCollection(allLocationsData, user.locationId);

				if (location) {
					companyLocationName = location.displayName;
				}
			}

			const permissionNames = user.permissions.map((permission: Permission) => {
				// @ts-ignore
				return t(`entities:permission.${permission.name}`, "Unbekannte Berechtigung");
			});

			return (
				<UserTableRow
					key={user.id}
					careerLevel={careerLevel}
					companyLocation={companyLocationName}
					employmentStatus={t(normalizeTKey(`entities:employmentStatus.${employmentStatus}`))}
					email={user.email}
					firstName={user.firstName}
					isActive={user.isActive}
					lastName={user.lastName}
					shortName={user.shortName}
					permissionNames={permissionNames}
					onEditClick={() => {
						setSelectedUserEditId(user.id);
					}}
					onUpdateStatusClick={() => {
						setSelectedUserUpdateStatusId(user.id);
					}}
				/>
			);
		});
	}, [
		usersSorted,
		allCareerLevelsData,
		allLocationsData,
		allEmploymentStatusesData,
		allGendersData,
		allPermissionsData,
		setSelectedUserEditId,
		setSelectedUserUpdateStatusId,
	]);

	return (
		<>
			<BreadcrumbsPortal pages={["Admin", appRoutes.users()]} className="bg-white" />

			<PageHeading title="Alle User" />
			<PageHeading.BottomBar>
				<div className="flex justify-between w-full">
					<div className="w-48">
						<SearchInput value={search} onChange={setSearch} ref={searchInputRef} />
					</div>
					<Button onClick={() => setShowCreateUserForm(true)}>Neuer User</Button>
				</div>
			</PageHeading.BottomBar>

			<ContentWrapper>
				<UsersTable>{allUserCards}</UsersTable>
				{showCreateUserForm && (
					<CreateUserSidebar
						isOpen={showCreateUserForm}
						closeSidebar={() => {
							setShowCreateUserForm(false);
						}}
					/>
				)}
				{userDataEdit && (
					<UpdateUserSidebar
						isOpen={null !== selectedUserEditId}
						closeSidebar={() => setSelectedUserEditId(null)}
						userData={userDataEdit}
					/>
				)}

				{userDataStatusUpdate && (
					<UpdateUserStatusSidebar
						isOpen={null !== selectedUserUpdateStatusId}
						closeSidebar={() => setSelectedUserUpdateStatusId(null)}
						userData={userDataStatusUpdate}
					/>
				)}
			</ContentWrapper>
		</>
	);
};

export default AdminUsersView;
