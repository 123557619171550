import { Control, FieldValues } from "react-hook-form";

import FormSection from "~/components/form/FormSection";
import Switch from "~/components/form/Switch";

type OrderStatusFormSectionProps<T extends FieldValues> = {
	control: Control<T>;
};

type FormDataType = {
	confirmed: boolean;
};

const OrderStatusFormSection = <T extends FormDataType>({ control }: OrderStatusFormSectionProps<T>) => {
	return (
		<FormSection title="Bestätigt">
			<div className="grid gap-x-6 gap-y-2 grid-cols-6">
				<div className="col-span-6">
					<Switch
						name={"confirmed"}
						control={control}
						labelOn="Ja"
						labelOff="Nein"
						allLabelsVisible
					/>
				</div>
			</div>
		</FormSection>
	);
};

export default OrderStatusFormSection;
