import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { Control, Controller } from "react-hook-form";

import { PropsWithChildren } from "react";
import clsx from "clsx";

type CheckButtonProps = {
	name: string;
	control: Control<any>;
};

const CheckButton: React.FunctionComponent<PropsWithChildren<CheckButtonProps>> = ({
	name,
	control,
	children,
}) => {
	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange, value: checked } }) => (
				<label
					className={clsx(
						"flex gap-2",
						"cursor-pointer text-sm p-2 rounded",
						"transition-color duration-200",
						checked
							? "bg-accent-500 hover:bg-accent-600 text-white"
							: "bg-gray-100 text-gray-700 hover:bg-gray-200",
					)}
				>
					{checked ? <CheckCircleIcon className="w-5 h-5" /> : <XCircleIcon className="w-5 h-5" />}
					<input
						className="hidden"
						type="checkbox"
						checked={checked}
						onChange={onChange}
						name={name}
					/>
					{children}
				</label>
			)}
		/>
	);
};

export default CheckButton;
