import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "~/components/form/Button/Button.tsx";
import ComboBox from "~/components/form/ComboBox";

import FormSection, { FormSectionGroup } from "~/components/form/FormSection";
import CommentFormSection from "~/components/form/formSections/CommentFormSection";
import TitleFormSection from "~/components/form/formSections/TitleFormSection";
import Select from "~/components/form/Select";
import SubmitButton from "~/components/form/SubmitButton";
import Headline from "~/components/Headline";
import Sidebar from "~/components/Sidebar/Sidebar.tsx";
import useClientsContactPersonsSelectOptions
	from "~/hooks/form/formOptionsData/useClientsContactPersonsSelectOptions.tsx";
import useClientsProgramsSelectOptions from "~/hooks/form/formOptionsData/useClientsProgramSelectOptions.ts";
import useClientsQuotaSelectOptions from "~/hooks/form/formOptionsData/useClientsQuotaSelectOptions.ts";
import useUserSelectOptions, { employeesFilterFn } from "~/hooks/form/formOptionsData/useUsersSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	useProjectTitleValidationComparisonData,
} from "~/hooks/form/validationComparisonData/useProjectTitleValidationComparisonData.ts";
import { updateProject } from "~/modules/project/api/project/projectApiDispatchers.ts";
import { ProjectType, UpdateProjectData } from "~/modules/project/api/project/projectTypes.ts";
import ProgramAndQuotaFormSection from "~/modules/project/components/forms/formSections/ProgramAndQuotaFormSection";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type CreateProjectFormProps = {
	projectData: ProjectType;
	onSuccess: () => void;
	onCancel: () => void;
};

interface UpdateProjectFormData extends UpdateProjectData {
}

const UpdateProjectForm: React.FC<CreateProjectFormProps> = ({ projectData, onSuccess, onCancel }) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);
	const titleComparisonData = useProjectTitleValidationComparisonData();

	const schema = useMemo(() => {
		return yup.object({
			clientContactPersonId: yup.string().required(),
			comment: yup.string().default(null),
			managedBy: yup.string().required(),
			programId: yup.string().default(null),
			quotaId: yup.string().default(null),
			title: yup.string().required().unique(titleComparisonData, projectData.id),
		});
	}, [titleComparisonData, projectData]);

	const defaultValues = useMemo(() => {
		return {
			clientContactPersonId: projectData.clientContactPersonId,
			comment: projectData.comment || "",
			managedBy: projectData.managedBy,
			programId: projectData.programId || "",
			quotaId: projectData.quotaId || "",
			title: projectData.title,
		};
	}, [projectData]);

	const {
		handleSubmit,
		control,
		formState: { errors, isDirty, isValid, isSubmitted },
		trigger,
	} = useForm<UpdateProjectFormData>({
		mode: "onChange",
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateProjectFormData>(schema),
	});

	const managerSelectOptions = useUserSelectOptions(employeesFilterFn);
	const clientContactPersonSelectOptions = useClientsContactPersonsSelectOptions(projectData.clientId, projectData.clientContactPersonId);
	const programSelectOptions = useClientsProgramsSelectOptions(projectData.clientId);
	const quotaSelectOptions = useClientsQuotaSelectOptions(projectData.clientId);
	const isSubmittable = useFormIsSubmittable({
		isDirty,
		isSubmitted,
		isValid,
		isLoading: busy,
		enableInitially: false,
	});

	useEffect(() => {
		trigger();
	}, []);

	const onSubmit: SubmitHandler<UpdateProjectFormData> = async (data: UpdateProjectFormData) => {
		setBusy(true);

		try {
			await updateProject({ projectId: projectData.id, projectData: data });
			onSuccess();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex flex-col justify-start w-full min-h-full"
		>
			<Sidebar.Header>
				<Headline type="h2">Projekt bearbeiten</Headline>
			</Sidebar.Header>
			<Sidebar.Content>
				{busy && <Sidebar.BusyOverlay />}
				<FormSectionGroup>
					<TitleFormSection control={control}
									  title="Projektname" />

					<FormSection title="Ansprechpartner Kunde">
						<div className="grid gap-x-6 gap-y-2 grid-cols-6">
							<div className="col-span-6">
								<Select
									name="clientContactPersonId"
									optionsData={clientContactPersonSelectOptions}
									control={control}
									error={errors.clientContactPersonId?.message as string}
								/>
							</div>
						</div>
					</FormSection>
					<ProgramAndQuotaFormSection
						control={control}
						errors={errors}
						programInputDisabled={programSelectOptions.length === 0}
						programSelectOptions={programSelectOptions}
						quotaInputDisabled={quotaSelectOptions.length === 0}
						quotaSelectOptions={quotaSelectOptions}
					/>
					<FormSection title="Verantwortlichkeit">
						<div className="grid gap-x-6 gap-y-2 grid-cols-6">
							<div className="col-span-6">
								<ComboBox
									allowNew={false}
									name="managedBy"
									label={"Angebot Manager"}
									error={errors.managedBy?.message}
									optionsData={managerSelectOptions}
									control={control}
								/>
							</div>
						</div>
					</FormSection>
					<CommentFormSection
						control={control}
						placeholder={"Hier können allgemeine Anmerkungen zum Projekt hinterlegt werden."}
						label="Kommentar"
						title="Anmerkungen"
					/>
				</FormSectionGroup>
			</Sidebar.Content>
			<Sidebar.Footer>
				<SubmitButton busy={busy}
							  disabled={!isSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					abbrechen
				</Button>
			</Sidebar.Footer>
		</form>
	);
};

export default UpdateProjectForm;
