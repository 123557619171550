import { PROJECT_ROLES_BY_PROJECT_QUERY_KEY } from "~/modules/project/api/projectRole/projectRoleQueries.ts";
import {
	PROJECTS_STAFFINGS_QUERY_KEY,
	useProjectsStaffings,
	USERS_ACTIVE_STAFFINGS_QUERY_KEY,
} from "~/modules/project/api/staffing/staffingQueries.ts";
import { useCallback, useMemo } from "react";
import { useAuth } from "~/contexts/AuthContext";

import LoadingPage from "~/pages/LoadingPage.tsx";
import Sidebar from "~/components/Sidebar";
import UpdateStaffingForm from "~/modules/project/components/forms/UpdateStaffingForm";
import { useQueryClient } from "react-query";

type UpdateStaffingSidebarProps = {
	isOpen: boolean;
	close: () => void;
	projectId: string;
	staffingId: string;
};

const UpdateStaffingSidebar: React.FunctionComponent<UpdateStaffingSidebarProps> = ({
	isOpen,
	close,
	projectId,
	staffingId,
}) => {
	const { data: projectsStaffingsData } = useProjectsStaffings({ projectId });
	const { user } = useAuth();
	const staffingData = useMemo(() => {
		if (projectsStaffingsData) {
			const staffingData = projectsStaffingsData?.find((staffing) => staffing.id === staffingId);
			if (!staffingData) {
				queryClient.invalidateQueries(PROJECTS_STAFFINGS_QUERY_KEY(projectId));
				close();
			}
			return staffingData;
		}
	}, [projectsStaffingsData]);

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.invalidateQueries(PROJECTS_STAFFINGS_QUERY_KEY(projectId));
		await queryClient.refetchQueries(PROJECT_ROLES_BY_PROJECT_QUERY_KEY(projectId));
		if (user && staffingData && staffingData.userId === user?.id) {
			queryClient.refetchQueries(USERS_ACTIVE_STAFFINGS_QUERY_KEY(user.id));
		}

		close();
	}, [queryClient, staffingData, user]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => close()}>
			{!staffingData ? (
				<LoadingPage />
			) : (
				<UpdateStaffingForm staffingData={staffingData}
									onSuccess={handleSuccess}
									onCancel={() => close()} />
			)}
		</Sidebar>
	);
};

export default UpdateStaffingSidebar;
