import { tv } from "tailwind-variants";
import { VariantProps } from "~/types/tailwindVariants";

export const buttonNewItemVariants = tv({
	slots: {
		button: `relative flex justify-center items-center w-full
				rounded-lg border-2 border-dashed border-gray-300
				focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2
				text-gray-400`,
		icon: "text-current",
	},
	variants: {
		theme: {
			stacked: {
				button: "flex-col",
			},
			inline: {
				button: "flex-row gap-1",
			},
		},
		size: {
			sm: {
				button: "p-1",
				icon: "w-6 h-6",
			},
			default: {
				button: "p-4",
				icon: "w-12 h-12",
			},
		},
		disabled: {
			true: {
				button: "opacity-30 pointer-events-none",
			},
			false:{
				button: "hover:border-gray-400 hover:text-gray-500"
			}
		},
	},
	defaultVariants: {
		size: "default",
		theme: "stacked",
	},
});


export type ButtonNewItemVariantProps = VariantProps<typeof buttonNewItemVariants>;
export type ButtonNewItemSizeVariantProps = "sm" | "default";
export type ButtonNewItemThemeVariantProps = "stacked" | "inline";
