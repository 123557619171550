import { useEffect, useState } from "react";

import Button from "~/components/form/Button";
import { ClientType } from "~/modules/client/api/client/clientTypes.ts";
import CreateCompanyForm from "~/modules/client/components/forms/CreateCompanyForm";
import Sidebar from "~/components/Sidebar";
import { appRoutes } from "~/constants/appRoute.ts";
import { generatePath } from "react-router-dom";
import { useAllClients } from "~/modules/client/api/client/clientQueries.ts";

type ClientViewSidebarProps = {
	isOpen: boolean;
	setOpen: (open: boolean) => void;
};

const CreateCompanySidebar: React.FunctionComponent<ClientViewSidebarProps> = ({ isOpen, setOpen }) => {
	const [newCompanyId, setNewCompanyId] = useState<string | null>(null);
	const [newClientData, setNewClientData] = useState<ClientType | null>(null);
	/*	const [showNewProjectForm, setShowNewProjectForm] = useState(false);
	const [setShowContactPersonForm, setSetShowContactPersonForm] = useState(false);*/

	const { data: allClientsData } = useAllClients();

	useEffect(() => {
		if (newCompanyId && allClientsData) {
			const newClient = allClientsData?.find((client) => client.companyId === newCompanyId);
			if (newClient) {
				setNewClientData(newClient);
			}
		}
	}, [newCompanyId, allClientsData]);

	useEffect(() => {
		if (!isOpen) {
			setNewCompanyId(null);
			setNewClientData(null);
		}
	}, [isOpen]);

	return (
		<Sidebar closeOnOutsideClick={false} open={isOpen} setOpen={() => setOpen(!isOpen)}>
			{!newCompanyId && !newClientData && (
				<CreateCompanyForm onSuccess={setNewCompanyId} onCancel={() => setOpen(false)} />
			)}
			{newClientData && (
				<div className="px-8 py-6">
					<div className="text-xl text-center font-bold mb-4 text-gray-800">
						Neuer Kunde "{newClientData.displayName}" erstellt
					</div>
					<div className="mb-4 text-center text-gray-800">Was möchtest Du als nächstes tun?</div>
					<div className="flex flex-col space-y-4">
						{/*		<Button className="justify-center" onClick={() => setShowNewProjectForm(true)}>Projekt für den
						Kunden
						erstellen</Button>
					<Button className="justify-center" onClick={() => setShowNewProjectForm(true)}>Kontakt für den
						Kunden
						erstellen</Button>*/}
						<Button
							className="justify-center"
							to={generatePath(appRoutes.client("").path, { clientId: newClientData.id })}
						>
							Kundenseite öffnen
						</Button>
					</div>
				</div>
			)}
		</Sidebar>
	);
};

export default CreateCompanySidebar;
