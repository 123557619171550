import { Control, FieldValues } from "react-hook-form";
import FormSection, { FormSectionProps } from "~/components/form/FormSection";

import Textarea from "~/components/form/Textarea";

type CommentFormSectionProps<T extends FieldValues> = FormSectionProps & {
	control: Control<T>
	placeholder?: string;
	label?: string;
	title: string;
};

type CommentFormSectionFormDataType = {
	comment: string | null;
};

const CommentFormSection = <T extends CommentFormSectionFormDataType>({
	control,
	label,
	placeholder,
	title,
}: CommentFormSectionProps<T>) => {
	return (
		<FormSection title={title}>
			<Textarea
				control={control}
				name="comment"
				label={label}
				placeholder={placeholder}
			/>
		</FormSection>
	);
};

export default CommentFormSection;
