import React, { useCallback, useState } from "react";
import { All_LOCATIONS_QUERY_KEY } from "~/modules/location/api/location/locationQueries.ts";

import { ALL_PROJECTS_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import Button from "~/components/form/Button";
import CreateProjectForm from "~/modules/project/components/forms/CreateProjectForm";
import { PROJECTS_STAFFINGS_QUERY_KEY } from "~/modules/project/api/staffing/staffingQueries.ts";
import { ProjectType } from "~/modules/project/api/project/projectTypes.ts";
import Sidebar from "~/components/Sidebar";
import { appRoutes } from "~/constants/appRoute.ts";
import { generatePath } from "react-router-dom";
import { useQueryClient } from "react-query";

type CreateProjectSidebarProps = {
	isOpen: boolean;
	setOpen: (open: boolean) => void;
};

const CreateProjectSidebar: React.FunctionComponent<CreateProjectSidebarProps> = ({ isOpen, setOpen }) => {
	const [newProjectData, setNewProjectData] = useState<ProjectType | null>(null);

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(
		async (project: ProjectType) => {
			queryClient.refetchQueries(All_LOCATIONS_QUERY_KEY);
			queryClient.invalidateQueries(PROJECTS_STAFFINGS_QUERY_KEY(project.id));
			await queryClient.refetchQueries(ALL_PROJECTS_QUERY_KEY());
			setNewProjectData(project);
		},
		[queryClient],
	);

	return (
		<Sidebar closeOnOutsideClick={false} open={isOpen} setOpen={() => setOpen(!isOpen)}>
			{!newProjectData && <CreateProjectForm onSuccess={handleSuccess} onCancel={() => setOpen(false)} />}
			{newProjectData && (
				<div className="px-8 py-6">
					<div className="text-xl text-center font-bold mb-4 text-gray-800">
						Neues Project "{newProjectData.title}" erstellt
					</div>
					<div className="mb-4 text-center text-gray-800">Was möchtest Du als nächstes tun?</div>
					<div className="flex flex-col space-y-4">
						<Button
							className="justify-center"
							to={generatePath(appRoutes.project("").path, { projectId: newProjectData.id })}
						>
							Projektseite öffnen
						</Button>
						<Button className="justify-center" onClick={() => setOpen(false)}>
							Fenster schliessen
						</Button>
					</div>
				</div>
			)}
		</Sidebar>
	);
};

export default CreateProjectSidebar;
