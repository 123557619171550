import { CheckCircleIcon } from "@heroicons/react/20/solid";
import React, { useCallback, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import Button from "~/components/form/Button";
import Input from "~/components/form/Input";
import SubmitButton from "~/components/form/SubmitButton";

import Headline from "~/components/Headline";
import LoadingSpinner from "~/components/LoadingSpinner";
import Logo from "~/components/Logo";
import { appRoutes } from "~/constants/appRoute.ts";
import { validatePasswordResetToken } from "~/modules/user/api/passwordReset/passwordResetApiDispatchers";
import { resetPassword } from "~/modules/user/api/user/userApiDispatchers";

type FormData = {
	password: string;
	passwordConfirmation: string;
};

const ResetPassword: React.FC = () => {
	const {
		control,
		handleSubmit,
		formState: { errors },
		watch,
	} = useForm<FormData>();
	const { token } = useParams<{ token: string }>();
	const [busy, setBusy] = useState(false);

	const [tokenIsValid, setTokenIsValid] = useState<boolean | null>(null);
	const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);
	const [apiError, setApiError] = useState<boolean | null>(null);

	useAsyncEffect(async () => {
		if (undefined === token) {
			setTokenIsValid(false);
			return;
		}
		try {
			await validatePasswordResetToken(token);
			setTokenIsValid(true);
		} catch (error) {
			setTokenIsValid(false);
		}
	}, [token]);

	const onSubmit: SubmitHandler<FormData> = useCallback(
		async (data) => {
			setBusy(true);
			if (undefined === token) {
				return;
			}
			try {
				await resetPassword(data.password, data.passwordConfirmation, token);
				setUpdateSuccess(true);
			} catch (error) {
				console.log(error);
				setApiError(true);
			}
			setBusy(false);
		},
		[token],
	);

	return (
		<div className="w-full max-w-md flex flex-col gap-4">
			<div className="text-primary-500 w-44 ml-8">
				<Logo />
			</div>
			<div className="bg-white p-8 rounded-md shadow-md">
				<Headline type="h2"
						  className="text-primary-500 mb-4">
					Neues Passwort festlegen
				</Headline>
				{tokenIsValid === null && (
					<div className="flex justify-center">
						<LoadingSpinner />
					</div>
				)}
				{tokenIsValid === false &&
					<><p className="text-gray-900 leading-6">Dieser Link is leider nicht mehr gültig. Solltest du immer noch ein
						neues Passwort benötigen. Starte den Vorgang bitte erneut.</p>
						<Button className="mt-5" to={appRoutes.passwordResetRequest().path}>Passwort zurücksetzen</Button>
					</>}
				{tokenIsValid && !updateSuccess &&(
					<>
						<form onSubmit={handleSubmit(onSubmit)}
							  className="space-y-6">
							<div className="flex flex-col gap-4">
								<Input
									name="password"
									type="password"
									label="Neues Passwort"
									control={control}
									error={errors.password && "Das Passwort muss mindestens 8 Zeichen lang sein."}
								/>

								<Input
									name="passwordConfirmation"
									type="password"
									label="Passwort wiederholen"
									control={control}
									rules={{ validate: (value: string) => value === watch("password") }}
									error={errors.passwordConfirmation && "Die Passwörter stimmen nicht überein."}
								/>

								<div className="flex items-center justify-between">
									<SubmitButton busy={busy}>Update</SubmitButton>
								</div>
								{apiError && <div className="text-red-500 mt-2">Speichern fehlgeschlagen.</div>}
							</div>
						</form>
					</>
				)}
				{updateSuccess && (
					<>
						<div className="flex flex-row items-center gap-x-2 mb-2">
							<CheckCircleIcon className="fill-success-500 h-20" /><span>Das Passwort wurde erfolgreich gespeichert.</span>
						</div>
						<Button theme="none"
								to={appRoutes.login().path}>Zurück zum Login</Button>
					</>
				)
				}
			</div>
		</div>
	);
};

export default ResetPassword;
