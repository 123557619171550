//@ts-ignore
import { ReactComponent as LogoSvg } from "./logo-nordnord.svg";

const Logo: React.FC = () => (
	<div className="flex items-center">
		<LogoSvg className="w-full fill-current text-current transition transition-colors duration-200" />
	</div>
);

export default Logo;
