import clsx from "clsx";
import { t } from "i18next";
import React from "react";
import { ProjectPhaseType } from "~/modules/project/api/projectPhase/projectPhaseTypes.ts";
import { WorkingStatusType } from "~/modules/project/api/workingStatus/workingStatusTypes.ts";
import { WorkingStatus } from "~/components/Status";
import styles from "~/modules/project/components/ProjectDetailsView/components/Phases/Phases.module.scss";
import { formatProjectPhaseTitle } from "~/modules/project/utils/projectUtils.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatDateRange } from "~/utils/dateAndTimeUtils.ts";
import getOneOfCollection from "~/utils/getOneOfCollection.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

type PhaseSelectProps = {
	isOpen: boolean,
	phases: ProjectPhaseType[];
	selectedPhaseId: string;
	setAllPhasesVisible: (value: boolean) => void;
	setSelectedPhaseId: (value: string) => void;
	showBudget: boolean;
	workingStatuses: WorkingStatusType[];
};

const PhaseSelect: React.FunctionComponent<PhaseSelectProps> = ({
																	isOpen,
																	phases,
																	selectedPhaseId,
																	setSelectedPhaseId,
																	setAllPhasesVisible,
																	showBudget,
																	workingStatuses
																}) => {
	return <div
		className={clsx("bg-primary-500 bg-opacity-5 rounded-lg grid transition-all duration-200", {
			[styles.phasesVisible]: isOpen,
			"my-4": isOpen,
			[styles.phasesInvisible]: !isOpen,
		})}
	>
		<div className="overflow-hidden">
			<div className="p-4">
				<table className="w-full whitespace-nowrap text-left">
					<colgroup>
						<col className="w-full sm:w-6/12" />
						<col className="lg:w-2/12" />
						<col className="lg:w-2/12" />
						<col className="lg:w-2/12" />
					</colgroup>
					<thead className="border-b border-white/10 text-sm">
					<tr>
						<th scope="col"
							className="py-2 pl-4 pr-8 font-semibold">
							Phasentitel
						</th>
						<th
							scope="col"
							className="hidden py-2 pl-0 pr-4 font-semibold sm:table-cell text-right"
						>
							{showBudget ? "Budget" : "Personentage"}
						</th>
						<th
							scope="col"
							className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20"
						>
							Laufzeit
						</th>
						<th
							scope="col"
							className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20"
						>
							Status
						</th>
					</tr>
					</thead>
					<tbody className="divide-y divide-white/4">
					{phases.map(({
									 id,
									 budgetCentsConfirmed,
									 budgetCentsPlanned,
									 phaseNumber,
									 manDaysConfirmed,
									 manDaysPlanned,
									 startDate,
									 title,
									 endDate,
									 workingStatusId,
								 }) => {
						const _workingStatusName = getOneOfCollection(
							workingStatuses,
							workingStatusId,
							"name",
						);

						const budgetCents = budgetCentsConfirmed === 0 ? budgetCentsPlanned : budgetCentsConfirmed;
						const manDays = parseFloat(manDaysConfirmed) === 0 ? manDaysPlanned : manDaysConfirmed;
						const productionPeriod = startDate && endDate ? `${formatDateRange(new Date(startDate), new Date(endDate))}` : "n/a";
						return (
							<tr
								key={id}
								className={clsx("cursor-pointer text-sm group", {
									"is-active": selectedPhaseId === id,
								})}
								onClick={() => {
									setSelectedPhaseId(id);
									setAllPhasesVisible(false);
								}}
							>
								<td className="m-0 p-0">
									<div
										className={
											"flex items-center h-12 pl-4 pr-8 rounded-l-xl " +
											"transition-colors duration-200 " +
											"hover:bg-primary-500 hover:bg-opacity-10 " +
											"group-hover:bg-primary-500 group-hover:bg-opacity-10 " +
											"group-[.is-active]:bg-primary-500 group-[.is-active]:bg-opacity-20 " +
											"group-[.is-active]:font-bold group-[.is-active]:text-primary-700"
										}
									>
										<div className="truncate text-sm">{formatProjectPhaseTitle(title, phaseNumber)}</div>
									</div>
								</td>
								<td className="m-0 p-0">
									<div
										className={
											"flex items-center justify-end h-12 pl-0 pr-4 " +
											"transition-colors duration-200 " +
											"hover:bg-primary-500 hover:bg-opacity-10 " +
											"group-hover:bg-primary-500 group-hover:bg-opacity-10 " +
											"group-[.is-active]:bg-primary-500 group-[.is-active]:bg-opacity-20 " +
											"group-[.is-active]:font-bold group-[.is-active]:text-primary-700"
										}
									>
										{showBudget ? formatCentsToCurrency(budgetCents) : formatNumberWithComma(parseFloat(manDays), 2)}
									</div>
								</td>
								<td className="m-0 p-0">
									<div
										className={
											"flex items-center h-12 pl-0 pr-4 " +
											"transition-colors duration-200 " +
											"hover:bg-primary-500 hover:bg-opacity-10 " +
											"group-hover:bg-primary-500 group-hover:bg-opacity-10 " +
											"group-[.is-active]:bg-primary-500 group-[.is-active]:bg-opacity-20 " +
											"group-[.is-active]:font-bold group-[.is-active]:text-primary-700"
										}
									>
										{productionPeriod}
									</div>
								</td>
								<td className="m-0 p-0">
									<div
										className={
											"flex items-center h-12 pl-0 pr-4 rounded-r-xl " +
											"transition-colors duration-200 " +
											"hover:bg-primary-500 hover:bg-opacity-10 " +
											"group-hover:bg-primary-500 group-hover:bg-opacity-10 " +
											"group-[.is-active]:bg-primary-500 group-[.is-active]:bg-opacity-20 " +
											"group-[.is-active]:font-bold group-[.is-active]:text-primary-700"
										}
									>
										<WorkingStatus id={workingStatusId}>
											{t(normalizeTKey("entities:workingStatus." + _workingStatusName))}
										</WorkingStatus>
									</div>
								</td>
							</tr>
						);
					})}
					</tbody>
				</table>
			</div>
		</div>
	</div>;

};

export default PhaseSelect;