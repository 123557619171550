import { PropsWithChildren } from "react";
import { LabelLineHeightVariants, LabelSizeVariants, labelVariants } from "~/components/form/Label/labelVariants.ts";

type LabelProps = {
	lineHeight?: LabelLineHeightVariants;
	htmlFor?: string;
	size?: LabelSizeVariants;
};

const Label: React.FunctionComponent<PropsWithChildren<LabelProps>> = ({ htmlFor, lineHeight, size, children }) => {
	return (
		<label htmlFor={htmlFor}
			   className={labelVariants({ size, lineHeight })}>
			{children}
		</label>
	);
};

export default Label;
