import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import React from "react";

interface SearchInputProps {
	value: string;
	onChange: (value: string) => void;
	placeholder?: string;
}

const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(function SearchInput(
	{ value, onChange, placeholder },
	ref,
) {
	return (
		<div className="relative focus-within:z-10">
			<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
				<MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
			</div>

			<input
				ref={ref}
				type="text"
				name="searchInput"
				className="hidden w-full rounded-md border-0 pl-10 h-10 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-500 sm:block"
				placeholder={undefined === placeholder ? "Suche ..." : placeholder}
				value={value}
				autoComplete={"off"}
				onChange={(e) => {
					onChange(e.target.value);
				}}
			/>
		</div>
	);
});
export default SearchInput;
