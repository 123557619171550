import { useMemo } from "react";
import { EntryType } from "~/modules/absence/types/vacationLedgerEnums.ts";
import { FormInputOption } from "~/types/form.ts";

const useVacationLedgerEntryTypeOptions = (): FormInputOption[] => {
	return useMemo(() => {
		return [
			{
				value: EntryType.Credit,
				label: "Gutschrift",
			},
			{
				value: EntryType.Deduction,
				label: "Abzug",
			},
		];
	}, []);
};

export default useVacationLedgerEntryTypeOptions;

