import React, { useState } from "react";
import { useQueryClient } from "react-query";
import DeleteEntityView, { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import {
	USERS_VACATION_LEDGER_ENTRIES_QUERY_KEY,
	USERS_VACATION_LEDGER_REPORT_QUERY_KEY,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import { deleteWorkingSchedule } from "~/modules/absence/api/workingSchedule/workingScheduleApiDispatchers.ts";
import { USERS_WORKING_SCHEDULES_QUERY_KEY } from "~/modules/absence/api/workingSchedule/workingScheduleQueries.ts";
import WorkingSchedulesTable from "~/modules/absence/components/WorkingSchedulesTable";
import {
	RenderWorkingScheduleTableRowFunction,
} from "~/modules/absence/components/WorkingSchedulesView/WorkingSchedulesView.tsx";

type DeleteWorkingScheduleModalProps = {
	isOpen: boolean;
	onCloseClick: () => void;
	renderTableRow: RenderWorkingScheduleTableRowFunction
	workingScheduleData: WorkingScheduleType | null;
};

const DeleteWorkingScheduleModal: React.FunctionComponent<DeleteWorkingScheduleModalProps> = ({
	isOpen,
	onCloseClick,
	renderTableRow,
	workingScheduleData,

}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();

	const handleConfirmationClick = async () => {
		if (!workingScheduleData) return;
		try {
			setBusy(true);
			const userId = workingScheduleData.userId;
			await deleteWorkingSchedule({ userId: userId, workingScheduleId: workingScheduleData.id });

			queryClient.refetchQueries(USERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(userId));
			await queryClient.invalidateQueries(USERS_WORKING_SCHEDULES_QUERY_KEY(userId));
			await queryClient.invalidateQueries(USERS_VACATION_LEDGER_REPORT_QUERY_KEY(userId));
			onCloseClick();
		} catch (error) {
			setError("Ein unbekannter Fehler ist aufgetreten.");
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Arbeitszeitmodell löschen`,
		bodyText: `Möchtest Du diese Arbeitszeitmodell wirklich löschen?`,
		deleteButtonText: `Arbeitszeitmodell löschen`,
	};

	return (
		<DeleteEntityView
			error={error}
			texts={texts}
			isOpen={isOpen}
			onCloseClick={onCloseClick}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		>
			{!!workingScheduleData && <div className="mb-2">
				<WorkingSchedulesTable compactView={true}>
					{renderTableRow({
						workingScheduleData,
						userCanAdminVacations: false,
						compactView: true,
						setWorkingScheduleIdToDelete: () => {
						},
						setWorkingScheduleIdToUpdate: () => {
						},
					})}
				</WorkingSchedulesTable>
			</div>}
		</DeleteEntityView>);
};

export default DeleteWorkingScheduleModal;
