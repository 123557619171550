import { getAllGenders } from "~/modules/user/api/gender/genderApiDispatchers.ts";
import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { QueriesOptions, useQuery } from "react-query";

const ENTITY_QUERY_KEY = "gender";

export const ALL_GENDERS_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useAllGenders = (options?: QueriesOptions<any>) => useQuery(
	ALL_GENDERS_QUERY_KEY,
	() => getAllGenders().then(getDataFromResponse),
	options,
);