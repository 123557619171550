import clsx from 'clsx';
import { ReactNode, ReactElement, ElementType } from 'react';
import { NavLink } from 'react-router-dom';
import { ButtonBorderRadiusVariants, ButtonSizeVariants, ButtonThemeVariants, buttonVariants } from '~/components/form/Button/buttonVariants.ts';

interface ButtonProps {
	children?: ReactNode;
	disabled?: boolean;
	isGrouped?: boolean;
	onClick?: () => void;
	rounded?: ButtonBorderRadiusVariants;
	size?: ButtonSizeVariants;
	theme?: ButtonThemeVariants;
	to?: string;
	as?: ElementType;
}

type ButtonOrAnchorProps = ButtonProps & (React.ButtonHTMLAttributes<HTMLButtonElement> | React.AnchorHTMLAttributes<HTMLAnchorElement>);

const Button: React.FC<ButtonOrAnchorProps> = ({
	children,
	isGrouped,
	onClick,
	theme = 'secondary',
	size,
	className,
	disabled,
	rounded,
	to,
	as = 'button',
	...rest
}): ReactElement => {
	const buttonClasses = clsx(
		buttonVariants({ theme, size, disabled, isGrouped, rounded }),
		className
	);

	const Component: ElementType = to ? NavLink : as;

	const handleClick = onClick
		? (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
			if (as !== 'a' && 'submit' !== (rest as React.ButtonHTMLAttributes<HTMLButtonElement>).type) {
				e.preventDefault();
			}
			onClick();
		}
		: undefined;

	if (to) {
		return (
			<Component to={to} className={buttonClasses} {...rest}>
				{children}
			</Component>
		);
	}

	return (
		<Component className={buttonClasses} onClick={handleClick} {...rest}>
			{children}
		</Component>
	);
};

export default Button;
