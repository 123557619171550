import { isSameDay, isWeekend } from "date-fns";
import { UserType } from "~/modules/user/api/user/userTypes.ts";
import Headline from "~/components/Headline/Headline.tsx";
import { TimeTrackingExtendedType } from "~/modules/timeTracking/api/timeTracking/timeTrackingTypes.ts";

import Day from "~/modules/timeTracking/components/components/Day";

type WeekProps = {
	allAbsenceTypes?: AbsenceTypeType[];
	days: Date[];
	holidays?: HolidayType[];
	monthIsClosed: boolean;
	setScrollToElementRef: (ref: HTMLDivElement) => void;
	setSelectedDate: (date: Date | null) => void;
	setSelectedTimeTrackingId: (id: string | null) => void;
	setTimeTrackingIdToUpdate: (id: string | null) => void;
	selectedTimeTrackingId: string | null;
	selectedDate: Date | null;
	timeTrackingData?: TimeTrackingExtendedType[];
	userAbsencesData?: AbsenceType[];
	users: UserType[];
	visible: boolean;
	week: number;
};

const Week: React.FC<WeekProps> = ({
									   allAbsenceTypes,
									   days,
									   holidays,
									   monthIsClosed,
									   setScrollToElementRef,
									   setSelectedDate,
									   setSelectedTimeTrackingId,
									   setTimeTrackingIdToUpdate,
									   selectedDate,
									   selectedTimeTrackingId,
									   timeTrackingData,
									   userAbsencesData,
									   users,
									   visible,
									   week,
								   }) => {
	const weekdays = visible ? days : days.filter((day) => !isWeekend(day));
	if (weekdays.length === 0) return null;

	return (
		<div>
			<Headline type="h2">KW {week}</Headline>
			<div className="bg-white rounded-lg mt-4 overflow-hidden">
				{days.map((date) => {
					const daysTimeTrackings = timeTrackingData?.filter((timeTracking) =>
						isSameDay(new Date(timeTracking.date), date),
					);
					return (
						<Day
							allAbsenceTypes={allAbsenceTypes}
							date={date}
							holidays={holidays}
							key={date.toString()}
							monthIsClosed={monthIsClosed}
							setScrollToElementRef={setScrollToElementRef}
							setSelectedDate={setSelectedDate}
							setSelectedTimeTrackingId={setSelectedTimeTrackingId}
							setTimeTrackingIdToUpdate={setTimeTrackingIdToUpdate}
							selectedDate={selectedDate}
							selectedTimeTrackingId={selectedTimeTrackingId}
							timeTrackingData={daysTimeTrackings}
							userAbsencesData={userAbsencesData}
							users={users}
							visible={visible}
						/>
					);
				})}
			</div>
		</div>
	);
};
export default Week;
