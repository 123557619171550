import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

import Button from "~/components/form/Button";
import { FormInputOption } from "~/types/form.ts";
import { SelectPlain } from "~/components/form/Select/Select.tsx";
import { t } from "i18next";
import { useCallback, useMemo } from "react";

export type MonthAndYearNavigationHandlerPropsType = {
	month: string;
	year: string;
};

type MonthAndYearNavigationProps = {
	month: string;
	monthSelectOptions: FormInputOption[];
	onGoToNextMonthClick: ({ month, year }: MonthAndYearNavigationHandlerPropsType) => void;
	onGoToPrevMonthClick: ({ month, year }: MonthAndYearNavigationHandlerPropsType) => void;
	onGoToTodayClick: () => void;
	onMonthSelectChange: ({ month, year }: MonthAndYearNavigationHandlerPropsType) => void;
	onYearSelectChange: ({ month, year }: MonthAndYearNavigationHandlerPropsType) => void;
	year: string;
	yearSelectOptions: FormInputOption[];
};

const MonthAndYearNavigation: React.FunctionComponent<MonthAndYearNavigationProps> = ({
	month,
	monthSelectOptions,
	onGoToNextMonthClick,
	onGoToPrevMonthClick,
	onGoToTodayClick,
	onMonthSelectChange,
	onYearSelectChange,
	year,
	yearSelectOptions,
}) => {
	const handleGoToPrevMonthClick = useCallback(() => {
		let prevMonth = parseInt(month);
		let newYear = parseInt(year);

		if (prevMonth < 1) {
			prevMonth = 12;
			newYear--;
		}

		const newYearString = newYear.toString();
		const newMonthString = prevMonth.toString();
		onGoToPrevMonthClick({ month: newMonthString, year: newYearString });
	}, [month, year]);

	const handleGoToNextMonthClick = useCallback(() => {
		let nextMonth = parseInt(month) + 2;
		let newYear = parseInt(year);

		if (nextMonth > 12) {
			nextMonth = 1;
			newYear++;
		}

		const newYearString = newYear.toString();
		const newMonthString = nextMonth.toString();

		onGoToNextMonthClick({ month: newMonthString, year: newYearString });
	}, [month, year]);

	const handleMonthSelectChange = useCallback(
		(value: string) => {
			const newMonth = (parseInt(value) + 1).toString();
			onMonthSelectChange({ month: newMonth, year });
		},
		[year],
	);

	const handleYearSelectChange = useCallback(
		(year: string) => {
			onYearSelectChange({ month, year });
		},
		[month],
	);

	const [firstSelectableYear, lastSelectableYear] = useMemo(() => {
	    if(yearSelectOptions){
			return [yearSelectOptions[0].value, yearSelectOptions[yearSelectOptions.length - 1].value]
	    }
	    return []
	    }, [yearSelectOptions]);

	const prevButtonDisabled = firstSelectableYear !== undefined && "0" === month && firstSelectableYear === year;
	const nextButtonDisabled = lastSelectableYear !== undefined && "11" === month && lastSelectableYear === year;

	return (
		<div className="flex flex-row items-center">
			<Button size="sm" theme="none" onClick={handleGoToPrevMonthClick} disabled={prevButtonDisabled}>
				<ChevronLeftIcon className="w-5 h-5 hover:fill-accent-600" />
			</Button>
			<div className="flex flex-row gap-1">
				<div className="w-32">
					<SelectPlain value={month} optionsData={monthSelectOptions} onChange={handleMonthSelectChange} />
				</div>
				<SelectPlain value={year} optionsData={yearSelectOptions} onChange={handleYearSelectChange} />
			</div>
			<Button size="sm" theme="none" onClick={handleGoToNextMonthClick} disabled={nextButtonDisabled}>
				<ChevronRightIcon className="w-5 h-5 hover:fill-accent-600" />
			</Button>
			<Button
				className="mr-4"
				theme="primary"
				onClick={onGoToTodayClick}
			>
				{t("timetracking.btnCurrentMonth", "Heute")}
			</Button>
		</div>
	);
};

export default MonthAndYearNavigation;
