import { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import {
	CreateProjectData,
	ProjectSummaryType,
	ProjectType,
	UpdateProjectData,
	UpdateProjectPhaseStatusData,
} from "~/modules/project/api/project/projectTypes.ts";


export function getProject(projectId: string): AxiosApiResponsePromise<ProjectType> {
	return axiosApi.get(`projects/${projectId}`);
}

export function getAllProjects(): AxiosApiResponsePromise<ProjectSummaryType[]> {
	return axiosApi.get("projects");
}

export function getClientsProjects({ clientId }: { clientId: string }): AxiosApiResponsePromise<ProjectType[]> {
	return axiosApi.get(`clients/${clientId}/projects`);
}

export function createProject(projectData: CreateProjectData): AxiosApiResponsePromise<ProjectType> {
	return axiosApi.post("projects", { ...projectData });
}

export function updateProject({ projectId, projectData }: {
	projectId: string,
	projectData: UpdateProjectData
}): AxiosApiResponsePromise<ProjectType> {
	return axiosApi.put(`projects/${projectId}`, { ...projectData });
}

export function deleteProject({ projectId }: {
	projectId: string
}): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`projects/${projectId}`);
}

export function updateProjectStatus({ projectId, data }: {
	projectId: string,
	data: UpdateProjectPhaseStatusData
}): AxiosApiResponsePromise<ProjectType> {
	return axiosApi.patch(`projects/${projectId}/status`, data);
}

