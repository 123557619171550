import Decimal from "decimal.js-light";
import React from "react";
import { UseFormWatch } from "react-hook-form";
import { useAllHolidays } from "~/modules/absence/holiday/holidayQueries.ts";
import FormSection from "~/components/form/FormSection";
import Label from "~/components/form/Label";
import { calculateVacationDaysFromTimespan } from "~/modules/absence/utils/vacationUtils.ts";
import { AbsenceTypeId } from "~/types/entityIds.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

type VacationLedgerFormSectionProps = {
	watch: UseFormWatch<any>
	initialRemainingVacationDays: Decimal;
	workingSchedules: WorkingScheduleType[];
};

const VacationLedgerFormSection: React.FunctionComponent<VacationLedgerFormSectionProps> = ({
																								watch,
																								initialRemainingVacationDays,
																								workingSchedules,
																							}) => {
	const absenceTypeId = watch("absenceTypeId");
	const startDate = watch("startDate");
	const endDate = watch("endDate");
	const firstDayIsHalf = watch("firstDayIsHalf");
	const lastDayIsHalf = watch("lastDayIsHalf");
	const { data: allHolidaysData } = useAllHolidays();

	const vacationDays = calculateVacationDaysFromTimespan({
		startDate,
		endDate,
		firstDayIsHalf,
		lastDayIsHalf,
		holidays: allHolidaysData!,
		workingSchedules,
	});

	const remainingVacationDays = initialRemainingVacationDays.minus(vacationDays);

	if(absenceTypeId !== AbsenceTypeId.Vacation) return null;

	return <FormSection title="Urlaubskonto">
		<div className="grid gap-x-6 gap-y-2 grid-cols-6">
			<div className="col-span-3 flex flex-col">
				<Label>Arbeitstage</Label>
				<div>{formatNumberWithComma(vacationDays.toNumber(),2)}</div>
			</div>
			<div className="col-span-3 flex flex-col">
				<Label>Verbleibende Urlaubstage</Label>
				<div>{formatNumberWithComma(remainingVacationDays.toNumber(),2)}</div>

			</div>

		</div>
	</FormSection>;
};

export default VacationLedgerFormSection;