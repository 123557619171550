import React, { useState, useRef, useCallback } from "react";
import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Control, useController } from "react-hook-form";
import FormInput from "~/components/form/FormInput";
import Label from "~/components/form/Label";
import { FormInputOption } from "~/types/form.ts";
import FormInputError from "~/components/form/FormInputError";

type SharedPropsType = {
	allowNew?: boolean;
	disabled?: boolean;
	label?: string;
	placeholder?: string;
	error?: string;
	optionsData: FormInputOption[];
};

type ComboBoxPlainProps = SharedPropsType & {
	name?: string;
	errorMessage?: string;
	value?: string | null;
	onChange?: (value: string | null) => void;
};

export const ComboBoxPlain: React.FC<ComboBoxPlainProps> = ({
	allowNew = true,
	disabled,
	errorMessage,
	optionsData,
	name,
	placeholder = "",
	label,
	value,
	onChange,
}) => {
	const [query, setQuery] = useState("");
	const openButtonRef = useRef<HTMLButtonElement | null>(null);
	const filteredOptions =
		query === ""
			? optionsData
			: optionsData.filter((option) => option.label.toLowerCase().includes(query.toLowerCase()));


	const onChangeHandlerComboBox = useCallback((value: FormInputOption | null) => {
		if (onChange) {
			if (allowNew) {
				return onChange(value?.label || null);
			}
			return onChange(value?.value || null);
		}
	}, [allowNew, onChange]);

	const getDisplayValue = useCallback((value: string | null) => {
		if (optionsData) {
			if (allowNew) {
				return value || "";
			}

			return optionsData.find((option) => option.value === value)?.label || value ||"";
		}
		return "";
	}, [allowNew, optionsData]);

	return (
		<Combobox as="div"
				  name={name}
				  value={value}
				  disabled={disabled}
			//@ts-ignore
				  onChange={(value) => onChangeHandlerComboBox && onChangeHandlerComboBox(value)}>
			<FormInput>
				{label && <Label>{label}</Label>}
				<div className="relative">
					<div className="relative">
						<Combobox.Input
							autoComplete="off"
							placeholder={placeholder}
							className={clsx("flex w-full rounded-md border-0 px-1.5 pl-3 h-10",
								"shadow-sm",
								"ring-1 ring-inset ring-gray-300 focus:ring-blue-500 focus:ring-2 focus:ring-inset",
								"text-sm", disabled && "pointer-events-none opacity-50")}
							onChange={(event) => setQuery(event.target.value)}
							displayValue={(value: string |null) => getDisplayValue(value)}
						/>
						<Combobox.Button ref={openButtonRef}
										 className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
							<ChevronUpDownIcon className="h-5 w-5 text-gray-400"
											   aria-hidden="true" />
						</Combobox.Button>
					</div>
					{errorMessage && <FormInputError>{errorMessage}</FormInputError>}
					<Combobox.Options
						className={`absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md 
												bg-white text-base 
												shadow-lg ring-1 ring-black ring-opacity-5
												 focus:outline-none sm:text-sm`
						}>
						{allowNew && query.length > 0 && (
							<Combobox.Option
								value={{ id: null, label: query }}
								className={({ active }) =>
									clsx(
										"relative cursor-default select-none py-2 pl-3 pr-9 text-sm",
										active ? "bg-accent-500 text-white" : "text-gray-900",
									)
								}
							>
								<span className="bg-accent-500 text-white rounded-md px-1.5 py-1 text-xs border border-1 border-white mr-1">Neu</span> {query}
							</Combobox.Option>
						)}

						{filteredOptions.map((option) => (
							<Combobox.Option
								key={option.value}
								value={option}
								className={({ active }) =>
									clsx(
										"relative cursor-default select-none py-2 pl-3 pr-9",
										active ? "bg-accent-500 text-white" : "text-gray-900",
									)
								}
							>
								{({ active, selected }) => (
									<>
												<span
													className={clsx(
														"block truncate",
														selected && "font-semibold",
													)}
												>
													{option.label}
												</span>
										{selected && (
											<span
												className={clsx(
													"absolute inset-y-0 right-0 flex items-center pr-4",
													active ? "text-white" : "text-indigo-600",
												)}
											>
														<CheckIcon className="h-5 w-5"
																   aria-hidden="true" />
													</span>
										)}
									</>
								)}
							</Combobox.Option>
						))}
					</Combobox.Options>
				</div>
			</FormInput>
		</Combobox>
	);
};


type ComboBoxProps = SharedPropsType & {
	name: string;
	control: Control<any>;
};

const ComboBox: React.FC<ComboBoxProps> = ({ name, control, allowNew,disabled, optionsData, label, placeholder }) => {
	const { field: { onChange, value }, fieldState: { error } } = useController({
		name,
		control,
	});

	return (
		<ComboBoxPlain
			allowNew={allowNew}
			disabled={disabled}
			optionsData={optionsData}
			value={value}
			onChange={(newVal) => onChange(newVal)}
			label={label}
			placeholder={placeholder}
			errorMessage={error?.message}
		/>
	);
};

export default ComboBox;