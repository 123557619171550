import clsx from "clsx";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import CompanyLogo from "~/components/CompanyLogo";
import Status, { WorkingStatus } from "~/components/Status/Status.tsx";
import { appRoutes, generateFullPath } from "~/constants/appRoute.ts";

import { ClientType } from "~/modules/client/api/client/clientTypes.ts";
import { ProjectSummaryType } from "~/modules/project/api/project/projectTypes.ts";
import { WorkingStatusType as WorkingStatusType } from "~/modules/project/api/workingStatus/workingStatusTypes.ts";
import { formatManDays, minutesToWorkdays } from "~/modules/timeTracking/utils/timeTrackingUtils.ts";
import { AuthenticatedUser, UserType } from "~/modules/user/api/user/userTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import getOneOfCollection from "~/utils/getOneOfCollection.ts";

const MaxUsersVisible = 5;

interface ProjectsIndexSummeryPageProps {
	project: ProjectSummaryType;
	clients: ClientType[];
	users: UserType[];
	user?: AuthenticatedUser;
	workingStatuses: WorkingStatusType[];
}

const ProjectsIndexProjectSummary: React.FC<ProjectsIndexSummeryPageProps> = ({
	project,
	clients,
	users,
	user,
	workingStatuses,
}) => {
	const { t } = useTranslation();

	const {
		id,
		clientId,
		manDaysPlanned,
		minutesTracked,
		title,
		projectNumber,
		isActive,
		isInternal,
		projectPhaseStatusesIds,
	} = project;
	const totalManDays = Math.round(manDaysPlanned);
	const totalSpentDays = minutesToWorkdays(minutesTracked);
	const totalAvailableDays = totalManDays - totalSpentDays;

	const clientDisplayName = clients.find((client) => client.id === clientId)?.displayName;

	const workingStatusBatches = useMemo(() => {
		if (projectPhaseStatusesIds.length > 0) {
			return projectPhaseStatusesIds.map((id, index) => {
				const workingStatus = getOneOfCollection(workingStatuses, id);
				if (workingStatus) {
					return <WorkingStatus key={"status-badge" + index + workingStatus.id}
										  id={workingStatus.id}>
						{t(normalizeTKey("entities:workingStatus." + workingStatus.name))}
					</WorkingStatus>;
				}
			});
		}
		return null;
	}, [projectPhaseStatusesIds]);

	let staffedUserIds = project.staffedUserIds;
	if (undefined !== user && project.staffedUserIds.includes(user.id)) {
		staffedUserIds = [user.id, ...project.staffedUserIds.filter((id) => user.id !== id)];
	}

	return (
		<NavLink to={generateFullPath(appRoutes.project().path, { ":projectId": id })}>
			<div className={clsx("group/infobox group/summary")}>
				<div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
					<div>
						<div
							className={clsx("flex items-center gap-2",
								"font-Roboto font-bold text-[1.375rem] text-primary-500",
								"transition-color duration-200 group-hover/summary:text-secondary-500",
							)}
						>
							{title}
							{isInternal && <Status theme="error">INTERN</Status>}
						</div>
					</div>
					<div className="flex gap-2 justify-between flex-shrink-0">
						{workingStatusBatches}
						<Status>{clientDisplayName}</Status>
						<Status>{projectNumber}</Status>
					</div>
				</div>
				<div
					className={clsx(
						"group-hover/infobox:shadow-md",
						"w-full flex gap-4 flex-row",
						"p-4 sm:px-6 rounded-lg",
						"transition-color duration-200",
						"bg-primary-500 bg-opacity-5",
						"",
						isActive ? "border-l-[8px] border-success-500" : "border-l-[8px] border-danger-300",
					)}
				>
					<div className="flex items-center">
						<CompanyLogo width="w-12"
									 height="h-12"
									 displayName={project.companyShortName} />
					</div>
					<div className="w-24 font-bold text-primary-500">
						<div className="w-full flex justify-center text-sm font-bold ">{t("project.summary.labels.totalAvailableDays", "Offen")}</div>
						<div
							className={clsx(
								"w-full h-8 flex items-center justify-center font-bold text-xl",
								0 > totalAvailableDays ? "text-red-500" : "text-primary-500",
							)}
						>
							{formatManDays(totalAvailableDays)} PT
						</div>
					</div>
					<div className="w-24 font-bold text-primary-500">
						<div
							className="w-full flex justify-center text-sm">{t("project.summary.labels.totalManDays", "Erfasst")}</div>
						<div
							className="w-full h-8 flex items-center justify-center text-xl">
							{formatManDays(totalSpentDays)} PT
						</div>
					</div>

					<div className="ml-4 flex-grow flex flex-col">
						<small className="text-xs text-gray-400">
							{t("project.summary.labels.crew", "Team")} ({staffedUserIds.length})
						</small>
						<div className="flex justify-start items-center gap-2 mt-1">
							{staffedUserIds && (
								<>
									{staffedUserIds.map(
										(userId, index) =>
											MaxUsersVisible > index && (
												<div
													key={`staffing-user-${userId}`}
													className="p-2 bg-primary-500 bg-opacity-5 rounded-lg text-xs font-bold"
												>
													{users.find((user) => userId === user.id)?.fullName}
												</div>
											),
									)}
									{MaxUsersVisible < staffedUserIds.length && (
										<div
											className="p-2 bg-primary-500 bg-opacity-5 rounded-lg text-xs font-bold">
											+{staffedUserIds.length - MaxUsersVisible}
										</div>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</NavLink>
	);
};

export default ProjectsIndexProjectSummary;
