import ClientDetailsView from "modules/client/components/ClientDetailsView";
import { useParams } from "react-router-dom";
import { useAggregatedData } from "~/hooks/useAggregateData";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useClientsInvoiceRecipients } from "~/modules/billing/api/invoiceRecipient/invoiceRecipientQueries.ts";
import { useClient } from "~/modules/client/api/client/clientQueries";
import { useClientsContactPersons } from "~/modules/client/api/clientContactPerson/clientContactPersonQueries";
import { useAllCountries } from "~/modules/country/api/country/countryQueries";
import { useAllCurrencies } from "~/modules/country/api/currency/currencyQueries";
import { useClientsProjects } from "~/modules/project/api/project/projectQueries";
import { useAllWorkingStatuses } from "~/modules/project/api/workingStatus/workingStatusQueries";
import LoadingPage from "~/pages/LoadingPage";
import { PermissionNames } from "~/types/entityNames.ts";

const ClientDetailsPage: React.FC = () => {
	const guard = userViewGuard(PermissionNames.CanManageProjects);
	if(guard) return guard;
	const { clientId } = useParams();
	if (undefined === clientId) return null;

	const { data, options } = useAggregatedData([
		{ hook: () => useClient(clientId), name: "client" },
		{ hook: () => useClientsInvoiceRecipients(clientId), name: "invoiceRecipients" },
		{ hook: () => useClientsProjects({ clientId}), name: "projects" },
		{ hook: () => useClientsContactPersons(clientId), name: "contacts" },
		{ hook: () => useAllCountries(), name: "allCountries" },
		{ hook: () => useAllCurrencies(), name: "allCurrencies" },
		{ hook: () => useAllWorkingStatuses(), name: "workingStatuses" },
	]);

	const { client, projects, contacts, workingStatuses, invoiceRecipients } = data;

	if (options.loadingPcent < 100) return <LoadingPage pcent={options.loadingPcent} />;
	return (
		<ClientDetailsView
			clientData={client}
			projects={projects}
			invoiceRecipients={invoiceRecipients}
			contacts={contacts}
			workingStatuses={workingStatuses}

		/>
	);
};

export default ClientDetailsPage;
