import Button from "~/components/form/Button";
import { ClientContactPersonType } from "~/modules/client/api/clientContactPerson/clientContactPersonTypes.ts";
import Headline from "~/components/Headline";
import { PlusIcon } from "@heroicons/react/20/solid";
import ProjectCompactInfoItem from "~/modules/client/components/ClientDetailsView/components/ClientsProjectsList/components/ProjectCompactInfoItem";
import { ProjectType } from "~/modules/project/api/project/projectTypes.ts";
import { WorkingStatusType as WorkingStatusType } from "~/modules/project/api/workingStatus/workingStatusTypes.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

interface ClientsProjectsListProps {
	projects?: ProjectType[];
	workingStatuses: WorkingStatusType[];
	onAddProjectClick: () => void;
	contacts?: ClientContactPersonType[];
}

const ClientsProjectsList: React.FC<ClientsProjectsListProps> = ({ projects, workingStatuses, onAddProjectClick, contacts }) => {
	if (undefined === projects || (projects && 0 === projects.length))
		return (
			<>
				<div>
					<Headline type="h3">Noch keine Projekte vorhanden</Headline>
					<p className="mt-1 text-sm text-gray-500">Füge neue Projekte hinzu.</p>
					<div className="mt-6">
						<Button onClick={onAddProjectClick}>
							<PlusIcon className="h-5 w-5" aria-hidden="true" />
							Neues Projekt anlegen
						</Button>
					</div>
				</div>
			</>
		);

	return (
		<>
			<ul role="list" className="divide-y divide-gray-200">
				{projects.sort(byObjectProperty('endDate', 'desc')).map((data) => (
					<ProjectCompactInfoItem
						key={`project-${data.projectNumber}-${data.id}`}
						data={data}
						contacts={contacts}
						workingStatuses={workingStatuses}
					/>
				))}
				<Button onClick={onAddProjectClick} disabled={undefined === contacts || 0 === contacts.length}>
					<PlusIcon className="h-5 w-5" aria-hidden="true" />
					Neues Projekt anlegen
				</Button>
			</ul>
		</>
	);
};

export default ClientsProjectsList;
