import { useEffect, useMemo } from "react";
import { useCommonDataLoading } from "~/contexts/CommonDataContext/CommonDataContext";
import { useAllSalesFunnels } from "~/modules/client/api/salesFunnel/salesFunnelQueries";
import { useAllSalesTypes } from "~/modules/client/api/salesType/salesTypeQueries";
import { useAllCountries } from "~/modules/country/api/country/countryQueries";
import { useAllCurrencies } from "~/modules/country/api/currency/currencyQueries";
import { useAllLanguages } from "~/modules/country/api/language/languageQueries";
import { useAllLocations } from "~/modules/location/api/location/locationQueries";

import { useAllApprovalLikelihoods } from "~/modules/project/api/approvalLikelihood/approvalLikelihoodQueries";

import { useAllWorkingStatuses } from "~/modules/project/api/workingStatus/workingStatusQueries";
import { useAllTimeTrackingTypes } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeQueries.ts";
import { useAllCareerLevels } from "~/modules/user/api/careerLevel/careerLevelQueries";
import { useAllGenders } from "~/modules/user/api/gender/genderQueries";

export const useCommonData = (enabled = false) => {
	const { setIsLoading } = useCommonDataLoading();
	const options = useMemo(() => {
		return { enabled };
	}, [enabled]);

	const { status: approvalLikelihoodsStatus } = useAllApprovalLikelihoods(options);

	const { status: careelLevelStatus } = useAllCareerLevels(options);

	const { status: countryStatus } = useAllCountries(options);

	const { status: currencyStatus } = useAllCurrencies(options);

	const { status: genderStatus } = useAllGenders(options);

	const { status: languageStatus } = useAllLanguages(options);

	const { status: locationStatus } = useAllLocations(options);

	/*const { status: useProjectRolesStatus } = useAllProjectRoles(options);*/

	const { status: salesFunnelsStatus } = useAllSalesFunnels(options);

	const { status: salesTypesStatus } = useAllSalesTypes(options);

	const { status: timeTrackingTypeStatus } = useAllTimeTrackingTypes(options);

	const { status: workingStatusStatus } = useAllWorkingStatuses(options);

	useEffect(() => {
		const allDone = [
			approvalLikelihoodsStatus,
			careelLevelStatus,
			countryStatus,
			currencyStatus,
			genderStatus,
			languageStatus,
			locationStatus,
			salesFunnelsStatus,
			salesTypesStatus,
			timeTrackingTypeStatus,
		].every((status) => status === "success");

		if (allDone) {
			setIsLoading(false);
		}
	}, [
		approvalLikelihoodsStatus,
		careelLevelStatus,
		countryStatus,
		currencyStatus,
		genderStatus,
		languageStatus,
		locationStatus,
		salesFunnelsStatus,
		salesTypesStatus,
		timeTrackingTypeStatus,
		workingStatusStatus,
	]);
};
