import { Control, FieldErrors, FieldValues } from "react-hook-form";
import ComboBox from "~/components/form/ComboBox";
import FormSection from "~/components/form/FormSection";
import Input from "~/components/form/Input";

import { FormInputOption } from "~/types/form.ts";

type ContactCompanyFormSectionProps<T extends FieldValues> = {
	control: Control<T>;
	disableClientSelect?: boolean;
	errors: FieldErrors<T>;
	clientSelectOptions: FormInputOption[];
};

type ContactCompanyFormData = {
	clientId: string;
	jobTitle: string | null;
};

const ContactCompanyFormSection = <T extends ContactCompanyFormData>({
	control,
	disableClientSelect = false,
	errors,
	clientSelectOptions,
}: ContactCompanyFormSectionProps<T>) => {

	return (
		<FormSection title="Firmendaten">
			<div className="flex flex-col gap-y-2">
				<ComboBox
					allowNew={false}
					label={"Firma"}
					name="clientId"
					placeholder="Bitte Kunden eingeben / auswählen"
					disabled={disableClientSelect}
					optionsData={clientSelectOptions}
					control={control}
					error={errors.clientId?.message as string}
				/>
				<Input
					name="jobTitle"
					control={control}
					placeholder="Head of Sales"
					label="Job Title"
				/>
			</div>
		</FormSection>
	);
};

export default ContactCompanyFormSection;
