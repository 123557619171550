import { Menu, Transition } from "@headlessui/react";

import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Fragment, ReactElement } from "react";

interface Props {
	items: {
		disabled?: boolean;
		icon?: ReactElement | any;
		label: string;
		onClick: () => void;
	}[];
	theme?: "default" | "dark";
}

const ButtonWithPopover: React.FC<Props> = ({ items, theme = "default" }) => {
	return (
		<Menu as="div" className="relative inline-block text-left">
			<Menu.Button
				className={clsx("flex items-center hover:text-gray-700 h-10", {
					"text-white": "default" === theme,
					"text-gray-500": "dark" === theme,
				})}
			>
				<span className="sr-only">Open options</span>
				<EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
			</Menu.Button>

			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="absolute right-0 z-40 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div className="py-1">
						{items.map(({ label, icon, onClick, disabled }, itemIndex) => {
							const Icon = icon;
							return (
								<Menu.Item key={itemIndex}>
									{({ active }) => (
										<div
											onClick={onClick}
											className={clsx(
												active ? "bg-gray-100 text-gray-900" : "text-gray-700",
												"flex px-4 py-2 text-sm cursor-pointer",
												disabled && "opacity-50 pointer-events-none"
											)}
										>
											{icon && <Icon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />}
											<span>{label}</span>
										</div>
									)}
								</Menu.Item>
							);
						})}
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	);
};

export default ButtonWithPopover;
