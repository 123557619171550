import { Route, Routes, useParams } from "react-router-dom";

import AbsencesPage from "~/pages/absence/AbsencesPage";
import VacationLedgerPage from "~/pages/absence/VacationLedgerPage";
import WorkingSchedulesPage from "~/pages/absence/WorkingSchedulesPage";
import TimeTrackingPage from "~/pages/timetracking/TimeTrackingPage";

export enum AbsenceSubRoutes {
	absences = "absences",
	workingSchedules = "working-schedules",
	vacationLedger = "vacation-ledger",
}

export enum TimeTrackingSubRoutes {
	timeTracking = "time-tracking",
}

export const USER_BASE_ROUTE = "/user/:userId";

type UserRouterProps = {};

const UserRouter: React.FunctionComponent<UserRouterProps> = ({}) => {
	const { userId } = useParams();

	return (
		<>
			<Routes>
				<Route path={AbsenceSubRoutes.absences} element={<AbsencesPage userId={userId!} />} />
				<Route path={AbsenceSubRoutes.vacationLedger} element={<VacationLedgerPage userId={userId!} />} />
				<Route path={AbsenceSubRoutes.workingSchedules} element={<WorkingSchedulesPage userId={userId!} />} />
				<Route path={TimeTrackingSubRoutes.timeTracking} element={<TimeTrackingPage />} />
			</Routes>
		</>
	);
};

export default UserRouter;
