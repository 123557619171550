import { UserIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

interface Props {
	displayName?: string;
	imageUrl?: string;
	width?: string;
	height?: string;
}

const CompanyLogo: React.FC<Props> = ({ displayName, imageUrl, width = "w-16", height = "h-12" }) => {
	const elementClassName = clsx(
		`${width} ${height}`,
		"flex-shrink-0 flex justify-center items-center",
		"rounded-full bg-primary-500 select-none",
	);

	if (undefined === imageUrl) {
		if (undefined === displayName) {
			return (
				<div className={elementClassName}>
					<UserIcon className={clsx("text-primary-500 w-4/6")} />
				</div>
			);
		}
		return (
			<div className={clsx(elementClassName, "text-lg font-bold text-white")}>
				{displayName && displayName.substring(0, 3)}
			</div>
		);
	}
	return <img className={elementClassName} src={imageUrl} alt="" />;
};

export default CompanyLogo;
