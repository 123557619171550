import { useParams } from "react-router-dom";
import { useAggregatedData } from "~/hooks/useAggregateData";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAllClients } from "~/modules/client/api/client/clientQueries";
import { useClientContactPerson } from "~/modules/client/api/clientContactPerson/clientContactPersonQueries";
import { useAllSalesFunnels } from "~/modules/client/api/salesFunnel/salesFunnelQueries";
import { useAllSalesTypes } from "~/modules/client/api/salesType/salesTypeQueries";
import ContactDetailsView from "~/modules/client/components/contact/ContactDetailsView";
import { useAllLanguages } from "~/modules/country/api/language/languageQueries";
import { useAllGenders } from "~/modules/user/api/gender/genderQueries";
import LoadingPage from "~/pages/LoadingPage";
import { PermissionNames } from "~/types/entityNames.ts";
import getOneOfCollection from "~/utils/getOneOfCollection";

const ContactDetailsPage: React.FC = () => {
	const guard = userViewGuard(PermissionNames.CanManageProjects);
	if(guard) return guard;

	const { contactId } = useParams();
	if (undefined === contactId) return <div>error</div>;

	const { data, options } = useAggregatedData([
		{ hook: () => useClientContactPerson(contactId), name: "contactData" },
		{ hook: () => useAllClients(), name: "clients" },
		{ hook: () => useAllGenders(), name: "genders" },
		{ hook: () => useAllLanguages(), name: "languages" },
		{ hook: () => useAllSalesFunnels(), name: "salesFunnels" },
		{ hook: () => useAllSalesTypes(), name: "salesTypes" },
	]);

	if (options.loadingPcent < 100) return <LoadingPage pcent={options.loadingPcent} />;

	const { contactData, clients, genders, languages, salesFunnels, salesTypes } = data;

	const clientDisplayName = getOneOfCollection(clients, contactData.clientId, "displayName");
	const genderName = getOneOfCollection(genders, contactData.genderId, "displayName");
	const languageDisplayName = getOneOfCollection(languages, contactData.languageId, "displayName");
	const salesFunnelDisplayName = getOneOfCollection(salesFunnels, contactData.salesFunnelId, "displayName");
	const salesTypeDisplayName = getOneOfCollection(salesTypes, contactData.salesTypeId, "displayName");

	return (
		<ContactDetailsView
			clientContactPersonData={contactData}
			additionalData={{
				clientDisplayName,
				genderName,
				languageDisplayName,
				salesFunnelDisplayName,
				salesTypeDisplayName,
			}}
		/>
	);
};

export default ContactDetailsPage;
