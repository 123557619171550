import { endOfMonth, format, isSameMonth, isSameYear, startOfDay } from "date-fns";


import { format as formatTz } from "date-fns-tz";
import { de } from "date-fns/locale";
import parseISO from "date-fns/parseISO";

export const formatDateTimeToMonth = (dateTime: string) => {
	if (!dateTime) {
		return null;
	}
	return format(parseISO(dateTime), "MM.yyyy");
};

type DateFormatOptions = {
	day?:string;
	month?: string;
	year?: string;
}

export function formatDateRange(
	startDate: Date,
	endDate: Date,
	dateFormat?: DateFormatOptions
): string {
	const dayFormat = dateFormat?.day || "dd";
	const monthFormat = dateFormat?.month || "MMMM";
	const yearFormat = dateFormat?.year || "yyyy";
	const baseFormat = `${dayFormat}. ${monthFormat} ${yearFormat}`;
	const localeOptions = { locale: de };
	let formattedStartDate = "";
	let formattedEndDate = format(endDate, baseFormat, localeOptions);

	// Both dates in the same year
	if (isSameYear(startDate, endDate)) {
		// Both dates in the same month and year
		if (isSameMonth(startDate, endDate)) {
			formattedStartDate = format(startDate, dayFormat, localeOptions);
		} else {
			formattedStartDate = format(startDate, `${dayFormat}. ${monthFormat}`, localeOptions);
		}
	} else {
		formattedStartDate = format(startDate, baseFormat, localeOptions);
	}

	return `${formattedStartDate} - ${formattedEndDate}`;
}

export const formatDateTimeToDate = (dateTime: string, hhmm = false, yearFormat = "yy") => {
	if (!dateTime) {
		return null;
	}

	let pattern = !hhmm ? `dd.MM.${yearFormat}` : `dd.MM.${yearFormat} HH:mm`;

	return `${format(parseISO(dateTime), pattern)} ${hhmm ? "h" : ""}`;
};

export function formatDateToYYYYMMDD(date: Date): string {
	return format(date, "yyyy-MM-dd");
}

export const formatDateFnsDate = (date: Date, hhmm = false, yearFormat = "yyyy") => {
	if (!date) {
		return null;
	}

	let format = !hhmm ? `dd.MM.${yearFormat}` : `dd.MM.${yearFormat} HH:mm`;

	return `${formatTz(date, format)} ${hhmm ? "h" : ""}`;
};

export const getStartOfDayEndOfMonth = (date: Date) => {
	if (!date) {
		return null;
	}
	return startOfDay(endOfMonth(date));
};

/**
 * Convert a local date to its equivalent UTC date.
 *
 * @param date - The local date to convert.
 * @returns The equivalent UTC date.
 */
export function convertLocalToUTCDate(date: Date | string): Date {
	if (!date) {
		throw new Error("Invalid date provided.");
	}
	const localDate = new Date(date);
	return new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate()));
}

/**
 * Convert a UTC date to its equivalent local date.
 *
 * @param date - The UTC date to convert.
 * @returns The equivalent local date.
 */
export function convertUTCToLocalDate(date: Date | string): Date {
	if (!date) {
		throw new Error("Invalid date provided.");
	}
	const utcDate = new Date(date);
	return new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate());
}

export const isDateBetween = (target: Date, startDate: Date, endDate: Date): boolean => {
	// normalize dates to ignore hours
	target.setHours(0, 0, 0, 0);
	startDate.setHours(0, 0, 0, 0);
	endDate.setHours(0, 0, 0, 0);
	return target.getTime() >= startDate.getTime() && target.getTime() <= endDate.getTime();
};

export function isValidDate(year?: number, month?: number, day?: number): boolean {

	if (!year || !month || !day) {
		return false;
	}
	// Create a new date using the provided parameters.
	// Remember: In JavaScript, months are 0-indexed. So, January = 0, February = 1, etc.
	const date = new Date(year, month - 1, day);

	// Check if the constructed date's year, month, and day matches the original parameters.
	return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
}

export function formatDateWithGermanMonth(date: Date, useShortMonthName: boolean = true): string {
	const monthFormat = useShortMonthName ? "MMM" : "MMMM";
	return format(date, `dd. ${monthFormat} yyyy`, { locale: de });
}
