import { UsersActiveStaffingType } from "~/modules/project/api/staffing/staffingTypes.ts";
import Headline from "~/components/Headline/Headline.tsx";
import Hint from "~/components/Hint";
import Status from "~/components/Status";

interface ProjectsProps {
	data: UsersActiveStaffingType[];
	value: string | null;
	onChange: (staffingId: string) => void;
}

const Projects: React.FC<ProjectsProps> = ({ data, onChange }) => {
	return (
		<div className="grid gap-4">
			{data.length > 0 ? (
				data.map(({ id, projectPhases, title, projectNumber }) => (
					<div key={`project-${id}`}
						 className="flex flex-col gap-1">
						<div className="flex justify-between items-center">
							<Headline type="h2"
									  color="text-primary-500">
								{title}
							</Headline>
							<Status>{projectNumber}</Status>
						</div>
						<div className="bg-gray-200 p-2 rounded-md flex flex-wrap flex-col gap-1 justify-start">
							{projectPhases.map((phase) => {
								return phase.orders.map((order) => {
									const staffingId = order.staffings[0].id;
									return (
										<button key={`order-${order.id}`}
												className="bg-white rounded p-2 divide-y divide-gray-300 text-gray-900 hover:bg-gray-50 group"
												onClick={onChange.bind(
													this,
													staffingId,
												)}
										>
											<div className="py-2 grid grid-cols-4 gap-4 text-left">
												<dt className="text-sm text-gray-500">Phase</dt>
												<dd className="text-sm font-bold col-span-3">{phase.title}</dd>
											</div>
											<div className="py-2 grid grid-cols-4 gap-4 text-left">
												<dt className="text-sm text-gray-500 group-hover:text-gray-600">
													Bestellung
												</dt>
												<dd className="text-sm font-bold col-span-3">
													<div
														className="flex flex-row gap-1"
													>
														{order.title}
													</div>
												</dd>
											</div>
										</button>
									);
								});
							})}
						</div>
					</div>
				))
			) : (
				<Hint size="sm">Du hast derzeit keine aktiven Staffings / Bestellungen.</Hint>
			)}
		</div>
	);
};

export default Projects;
