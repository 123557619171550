import clsx from "clsx";
import Decimal from "decimal.js-light";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

type RemainingDaysProps = {
	remainingVacationDays: Decimal;
};

const RemainingVacationDays: React.FunctionComponent<RemainingDaysProps> = ({ remainingVacationDays }) => {


	return <div
		className="text-lg">Resturlaubstage: <span className={clsx(remainingVacationDays.isNegative() && "text-danger-500")}>{formatNumberWithComma(remainingVacationDays.toNumber(), 2)}</span>
	</div>;
};

export default RemainingVacationDays;