import { QueriesOptions, useQuery } from "react-query";
import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getUsersVacationLedgerReport,
	indexUsersVacationLedgerEntries,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryApiDispatchers.ts";

export const VACATION_LEDGER_ENTRY_BASE_QUERY_KEY = "vacationLedgerEntries";

export const USERS_VACATION_LEDGER_ENTRIES_QUERY_KEY = (userId: string) => [VACATION_LEDGER_ENTRY_BASE_QUERY_KEY, { userId }];
export const USERS_VACATION_LEDGER_REPORT_QUERY_KEY = (userId: string) => ["vacationLedgerReport", { userId }];
export const useUsersVacationLedgerEntries = ({ userId, options }: {
	userId: string,
	options?: QueriesOptions<any>
}) => useQuery(
	USERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(userId),
	() => indexUsersVacationLedgerEntries({ userId }).then(getDataFromResponse),
	options,
);


export const useUsersVacationLedgerReport = ({ userId, options }: { userId: string, options?: QueriesOptions<any> }) => useQuery(
	USERS_VACATION_LEDGER_REPORT_QUERY_KEY(userId),
	() => getUsersVacationLedgerReport({ userId }).then(getDataFromResponse),
	options,
);