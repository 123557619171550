import React, { useCallback, useState } from "react";

import { ALL_USERS_QUERY_KEY } from "~/modules/user/api/user/userQueries.ts";
import Button from "~/components/form/Button";
import Headline from "~/components/Headline";
import Sidebar from "~/components/Sidebar";
import { UserWithConfidentialInformation } from "~/modules/user/api/user/userTypes.ts";
import { updateUserStatus } from "~/modules/user/api/user/userApiDispatchers.ts";
import { useQueryClient } from "react-query";

type UpdateUserStatusSidebarProps = {
	isOpen: boolean;
	closeSidebar: () => void;
	userData: UserWithConfidentialInformation;
};

const UpdateUserStatusSidebar: React.FunctionComponent<UpdateUserStatusSidebarProps> = ({
	isOpen,
	closeSidebar,
	userData,
}) => {
	const [isSuccess, setIsSuccess] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const queryClient = useQueryClient();

	const updateStatus = useCallback(
		async (isActive: boolean) => {
			setIsSubmitting(true);
			try {
				await updateUserStatus(userData.id, isActive);
				queryClient.invalidateQueries(ALL_USERS_QUERY_KEY);
				setIsSuccess(true);
			} catch (error) {
				setIsSubmitting(false);
			}
		},
		[queryClient, userData],
	);

	return (
		<Sidebar closeOnOutsideClick={false} open={isOpen} setOpen={closeSidebar}>
			{userData && !isSuccess && (
				<div className="px-8 py-6">
					{isSubmitting && <div className="absolute inset-0 bg-gray-100 bg-opacity-50 z-50" />}
					<Headline type="h2">{userData.fullName}</Headline>
					<div className="mt-4">
						<Button
							className="w-full justify-center"
							theme={userData.isActive ? "danger" : "primary"}
							onClick={() => updateStatus(!userData.isActive)}
						>
							User {userData.isActive ? "deaktiveren" : "aktivieren"}
						</Button>
					</div>
				</div>
			)}
			{isSuccess && (
				<div className="px-8 py-6">
					<div className="text-xl text-center font-bold mb-4 text-gray-800">Status erfolgreich geändert!</div>
					<div className="flex flex-col space-y-4">
						<Button className="justify-center" onClick={closeSidebar}>
							Fenster schliessen
						</Button>
					</div>
				</div>
			)}
		</Sidebar>
	);
};

export default UpdateUserStatusSidebar;
