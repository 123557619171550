import { Control, FieldValues } from "react-hook-form";
import DatePickerInput from "~/components/form/DatePickerInput";
import FormSection from "~/components/form/FormSection";
import Input from "~/components/form/Input";
import Select from "~/components/form/Select";
import { FormInputOption } from "~/types/form.ts";

type UserPersonalInformationFormSectionProps<T extends FieldValues> = {
	control: Control<T>;
	genderSelectOptions: FormInputOption[];
};

type UserPersonalInformationFormData = {
	firstName: string;
	lastName: string;
	nickname: string | null;
	dateOfBirth: Date | null;
	email: string;
	phone: string | null;
	genderId: string;
};

const UserPersonalInformationFormSection = <T extends UserPersonalInformationFormData>({
	control,
	genderSelectOptions,
}: UserPersonalInformationFormSectionProps<T>) => {
	return (
		<FormSection title="Stammdaten">
			<div className="grid grid-cols-6 gap-x-6 gap-y-6">
				<div className="col-span-3">
					<Input
						name="firstName"
						control={control}
						label="Vorname"
						placeholder="Max / Maria"
					/>
				</div>
				<div className="col-span-3">
					<Input
						name="lastName"
						control={control}
						label="Nachname"
						placeholder="Mustername"
					/>
				</div>
				<div className="col-span-3">
					<Input
						name="nickname"
						placeholder="Maxi"
						control={control}
						label="Spitzname"
					/>
				</div>
				<div className="col-span-3">
					<DatePickerInput
						position="bottom-end"
						name="dateOfBirth"
						label="Geburtsdatum"
						control={control}
					/>
				</div>
				<div className="col-span-3">
					<Input
						name="email"
						label="E-Mail"
						placeholder="name@domain.com"
						control={control}
					/>
				</div>
				<div className="col-span-3">
					<Input
						label="Telefon"
						name="phone"
						placeholder="+49 XXX XX XX XXX"
						control={control}
					/>
				</div>
				<div className="col-span-3">
					<Select
						name="genderId"
						label={"Geschlecht"}
						optionsData={genderSelectOptions}
						control={control}
					/>
				</div>
			</div>
		</FormSection>
	);
};

export default UserPersonalInformationFormSection;
