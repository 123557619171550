import clsx from "clsx";
import CreateProjectSidebar from "modules/project/components/ProjectsIndexView/components/CreateProjectSidebar";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import BreadcrumbsPortal from "~/components/Breadcrumbs";
import ContentWrapper from "~/components/ContentWrapper";
import Button from "~/components/form/Button";
import PageHeading from "~/components/headings/PageHeading";
import NoEntriesFound from "~/components/NoEntriesFound";
import { appRoutes } from "~/constants/appRoute.ts";
import { PROJECTS_INDEX_PAGE_STATE_KEY } from "~/constants/pageStateStorageKeys.ts";
import { useAuth } from "~/contexts/AuthContext";
import usePageStateStorage from "~/hooks/usePageStateStorage";
import { ClientType } from "~/modules/client/api/client/clientTypes.ts";
import { ProjectSummaryType, ProjectType } from "~/modules/project/api/project/projectTypes.ts";
import { WorkingStatusType } from "~/modules/project/api/workingStatus/workingStatusTypes.ts";
import ProjectsIndexProjectSummary from "~/modules/project/components/ProjectsIndexView/components/ProjectsIndexProject";
import SearchAndFilterBar
	from "~/modules/project/components/ProjectsIndexView/components/SearchAndFilterBar/SearchAndFilterBar.tsx";
import { UserType } from "~/modules/user/api/user/userTypes.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";


interface ProjectsIndexViewProps {
	projects: ProjectSummaryType[];
	clients: ClientType[];
	users: UserType[];
	workingStatuses: WorkingStatusType[];
}

type PageState = {
	searchValue: string;
	statusFilter: string[];
	sortOrder: "asc" | "desc";
	myProjectsFilter: boolean;
};

const defaultPageState: PageState = {
	searchValue: "",
	sortOrder: "asc",
	statusFilter: ["1", "2"],
	myProjectsFilter: true,
};

const ProjectsIndexView: React.FC<ProjectsIndexViewProps> = ({ projects, clients, users, workingStatuses }) => {
	const { t } = useTranslation();
	const { user } = useAuth();
	const { pageState, setPageState } = usePageStateStorage({
		pageKey: PROJECTS_INDEX_PAGE_STATE_KEY,
		defaultState: defaultPageState,
	});
	const [showCreateProjectSidebar, setShowCreateProjectSidebar] = useState(false);

	const [searchValue, setSearchValue] = useState<string>(pageState.searchValue);
	const [statusFilter, setStatusFilter] = useState<string[]>(pageState.statusFilter);
	const [sortOrder, setSortOrder] = useState<PageState["sortOrder"]>(pageState.sortOrder);
	const [myProjectsFilter, setMyProjectsFilter] = useState(pageState.myProjectsFilter);

	useEffect(() => {
		setPageState({ searchValue, statusFilter, sortOrder, myProjectsFilter });
	}, [statusFilter, setPageState, searchValue, sortOrder, myProjectsFilter]);

	const searchKeys: (keyof ProjectType | string)[] = ["title", "projectNumber", "clientDisplayName", "staffedUserNames"];
	const clientNameById = new Map(clients.map((c) => [c.id.toString(), c.displayName]));

	const enrichedProjects = useMemo(() => {
		if (projects && users) {
			return projects.map((project) => {
				const staffedUserNames = project.staffedUserIds.map((userId) => {
					const user = users.find((u) => u.id === userId);
					if (user) {
						return user.fullName;
					}
					return "";
				}).join(",");

				return {
					...project,
					staffedUserNames,
					clientDisplayName: clientNameById.get(project.clientId),
				};
			});
		}
		return [];
	}, [projects, users]);

	const createFilter = (searchKeys: (keyof ProjectSummaryType | string)[], searchValue: string) => {
		return function(project: ProjectSummaryType) {
			if (!searchValue) return true;
			return searchKeys.some((key) => {
				const projectValue = project[key as keyof ProjectSummaryType];
				return (
					projectValue !== null &&
					projectValue !== undefined &&
					projectValue.toString().toLowerCase().includes(searchValue.toString().toLowerCase())
				);
			});
		};
	};

	const onChangeStatus = (value: string | null) => {
		if (null === value) {
			setStatusFilter([]);
		} else {
			setStatusFilter((prevState) => {
				return prevState.includes(value)
					? prevState.filter((optionValue) => optionValue !== value)
					: [...prevState, value];
			});
		}
	};
	const filteredProjects = enrichedProjects
		.filter((project) => {
			if (!myProjectsFilter || !user) return true;
			return (
				project.managedBy === user.id ||
				project.staffedUserIds.includes(user.id) ||
				project.phaseManagerIds.includes(user.id)
			);
		})
		.filter((project) => {
			if (!statusFilter.length) return true;
			return statusFilter.some(item => project.projectPhaseStatusesIds.includes(item));
		})
		.filter(createFilter(searchKeys, searchValue)).sort(byObjectProperty("endDate", sortOrder));


	return (
		<>
			<BreadcrumbsPortal pages={[appRoutes.projects()]}
							   className="bg-white" />
			<PageHeading title={t("projects.title", "Projekte")} />
			<PageHeading.BottomBar>
				<div className="flex justify-between w-full gap-4">
					<SearchAndFilterBar
						displayedProjectsCount={filteredProjects.length}
						myProjectsFilter={myProjectsFilter}
						onChangeMyProjectsFilter={setMyProjectsFilter}
						onChangeSearch={setSearchValue}
						onChangeStatus={onChangeStatus}
						searchValue={searchValue}
						sortOrder={sortOrder}
						setSortOrder={setSortOrder}
						statusFilter={statusFilter}
						totalProjectsCount={enrichedProjects.length}
					/>
					<Button onClick={() => setShowCreateProjectSidebar(true)}>
						{t("projects.btnNewProject", "Neues Projekt")}
					</Button>
				</div>
			</PageHeading.BottomBar>

			<ContentWrapper>
				<div
					className={clsx(
						"flex flex-col gap-[2.3rem]",
						"transition-all duration-200",
						statusFilter.length > 0 || !!searchValue || myProjectsFilter
							? "mt-24"
							: "mt-8",
					)}
				>
					{filteredProjects.length ? (
						filteredProjects.map((project: ProjectSummaryType) => (
							<ProjectsIndexProjectSummary
								key={project.id}
								project={project}
								clients={clients}
								users={users}
								user={user}
								workingStatuses={workingStatuses}
							/>
						))
					) : (
						<NoEntriesFound />
					)}
				</div>
			</ContentWrapper>
			{showCreateProjectSidebar && (
				<CreateProjectSidebar isOpen={showCreateProjectSidebar}
									  setOpen={setShowCreateProjectSidebar} />
			)}
		</>
	);
};

export default ProjectsIndexView;
