import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import React, { useCallback, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "~/components/form/Button";
import Input from "~/components/form/Input";
import SubmitButton from "~/components/form/SubmitButton/SubmitButton";
import Headline from "~/components/Headline/Headline";
import Logo from "~/components/Logo/Logo";
import Sidebar from "~/components/Sidebar";
import { appRoutes } from "~/constants/appRoute.ts";
import { requestPasswordRequest } from "~/modules/user/api/passwordReset/passwordResetApiDispatchers.ts";

type FormData = {
	email: string;
};

// @ToDo Validate email format via rehex, handle backend validation error
const RequestPasswordReset: React.FunctionComponent = () => {
	const [busy, setBusy] = useState(false);
	const [submitFailed, setSubmitFailed] = useState(false);
	const [submitSuccess, setSubmitSuccess] = useState(false);

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>();

	const onSubmit: SubmitHandler<FormData> = useCallback(async (data) => {
		setBusy(true);

		try {
			await requestPasswordRequest(data.email);
			setSubmitSuccess(true);
		} catch (error) {
			console.log(error);
			setSubmitFailed(true);
		}

		setBusy(false);
	}, []);

	return (
		<div className="w-full max-w-md flex flex-col gap-4">
			<div className="text-primary-500 w-44 ml-8">
				<Logo />
			</div>
			<div className="bg-white p-8 rounded-md shadow-md relative">
				{busy && <Sidebar.BusyOverlay />}
				<div className="mb-4">
					<Headline type="h2"
							  className="text-primary-500 mb-4">
						Passwort zurücksetzen
					</Headline>

				</div>
				{!submitSuccess && !submitFailed && (
					<>
						<p className="text-gray-900 leading-6">Bitte gib die mit Deinem Account verknüpfte E-Mail Adresse ein. Du erhälst dann einen
							Link, mit dem Du Dein Passwort ändern kannst..</p>
						<form onSubmit={handleSubmit(onSubmit)}
							  className="space-y-6 mt-4">
							<div className="flex flex-col gap-4">
								<Input
									name="email"
									control={control}
									label="E-Mail"
									error={errors.email && "Bitte gib eine gültige E-Mail Adresse ein."}
								/>

								<div className="flex items-center justify-between">
									<SubmitButton busy={busy}>Link senden</SubmitButton>
								</div>
							</div>
						</form>
					</>
				)}
				{submitSuccess && (
					<>
						<div className="flex flex-row items-center gap-x-2 mb-2">
							<CheckCircleIcon className="fill-success-500 h-20" /><span>Die E-mail wurde erfolgreich versendet. Bitte checke ggf. auch Deinen Spam-Ordner.</span>
						</div>
						<Button theme="none"
								to={appRoutes.login().path}>Zurück zum Login</Button>
					</>
				)}
				{submitFailed &&
					<div className="flex flex-row items-center gap-x-2">
						<XCircleIcon className="fill-danger-500 h-10" />
						<span>Das hat leider nicht geklappt...</span>
					</div>
				}
			</div>
		</div>
	);
};

export default RequestPasswordReset;
