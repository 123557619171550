import clsx from "clsx";
import { PropsWithChildren } from "react";

export type FormSectionProps = {
	title?: string;
	className?: string;
};

export const FormSectionGroup: React.FC<PropsWithChildren> = ({ children }) => (
	<div className="flex flex-col gap-6">{children}</div>
);

const FormSection: React.FC<PropsWithChildren<FormSectionProps>> = ({ title, children,className }) => (
	<section className={clsx("flex justify-between gap-4 border-b pb-6 last:border-0", className)}>
		{title && (
			<div className="w-32 pt-1 text-xs font-semibold leading-normal text-gray-400" style={{ minWidth: "8rem" }}>
				{title}
			</div>
		)}
		<div className="flex-grow pt-1">{children}</div>
	</section>
);

export default FormSection;
