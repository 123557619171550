import { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import { CompanyType, CreateCompanyData, UpdateCompanyData } from "~/modules/client/api/company/companyTypes.ts";
import axiosApi from "~/api/axiosApi.ts";


export const getCompany = (companyId: string): AxiosApiResponsePromise<CompanyType> => axiosApi.get(`companies/${companyId}`);
export const getAllCompanies = (): AxiosApiResponsePromise<CompanyType[]> => axiosApi.get("companies");

export const createCompany = (data: CreateCompanyData): AxiosApiResponsePromise<CompanyType> => axiosApi.post("companies", { ...data });

export function updateCompany({ companyId, data }: {
	companyId: string,
	data: UpdateCompanyData
}): AxiosApiResponsePromise<CompanyType> {
	return axiosApi.put(`companies/${companyId}`, { ...data });
}