import { useMemo } from "react";
import { useAllCurrencies } from "~/modules/country/api/currency/currencyQueries.ts";
import { Currency } from "~/modules/country/api/currency/currencyTypes.ts";
import { FormInputOption } from "~/types/form.ts";
import { mapDataToSelectOptionsData } from "~/utils/form/formUtils.ts";
import { byMultipleObjectProperties, SortDirection } from "~/utils/sortFunctions.ts";


const useCurrencySelectOptions = (): FormInputOption[] => {
	const { data: allCurrenciesData } = useAllCurrencies();
	return useMemo(() => {
		if (allCurrenciesData) {
			const sortOptions:{ property: keyof Currency; direction: SortDirection }[] = [
				{ property: "listPriority", direction: "desc" },
				{ property: "displayName", direction: "asc" },
			];
			const sortedCurrencies = allCurrenciesData.filter(currency => currency.listPriority !== null).sort(byMultipleObjectProperties(sortOptions));
			return mapDataToSelectOptionsData(sortedCurrencies, "id", "displayName");
		}
		return [];
	}, [allCurrenciesData]);
};

export default useCurrencySelectOptions;

