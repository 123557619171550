import React from "react";

import Headline from "~/components/Headline";

const Error404Page: React.FC = () => {


	return (
		<div id="error-page" className="flex flex-col gap-8 justify-center items-center h-screen">
			<Headline type="h1">404</Headline>
			<p>Diese Seite gibt es gar nicht.</p>
			{/*
            <p className="text-slate-400">
				<i>{errorMessage}</i>
			</p>
            */}
		</div>
	);
};

export default Error404Page;
