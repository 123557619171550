import { useMemo } from "react";
import { mapDataToSelectOptionsData } from "~/utils/form/formUtils.ts";
import { FormInputOption } from "~/types/form.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";
import { useAllPrograms } from "~/modules/project/api/program/programQueries.ts";


const useClientsProgramSelectOptions = (clientId: string): FormInputOption[] => {
	const { data: allProgramsData } = useAllPrograms();
	return useMemo(() => {
		if (allProgramsData && clientId) {
			const filteredProgramsData = allProgramsData.filter((clientContactPerson) => {
				return clientContactPerson.clientId === clientId;
			}).sort(byObjectProperty("title"));
			return mapDataToSelectOptionsData(filteredProgramsData, "id", "title");
		}
		return [];
	}, [allProgramsData, clientId]);
};

export default useClientsProgramSelectOptions;

